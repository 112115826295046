import React from "react";

export default function BtnMakeSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="136.647"
      height="136.647"
      viewBox="0 0 136.647 136.647"
    >
      <g id="btn_make" transform="translate(-216.646 -566.646)">
        <circle
          id="contianer"
          cx="68"
          cy="68"
          r="68"
          class="cls-2"
          stroke="#E2EDF0"
          fill="#efeff4"
          transform="translate(217 567)"
        />
        <path
          id="pattern"
          d="M-3981.177 369.884l-65.939 65.939zm-70.907 65.907l70.875-70.875-70.875 70.875zm70.539-60.539l-60.2 60.2zm-75.183 60.184l75.163-75.164-75.163 75.164zm-4.371-.629l78.905-78.9-78.905 78.9zm78.651-53.651l-53.4 53.4zm-82.785 52.784l82.172-82.172-82.172 82.172zm81.1-46.1l-45.03 45.03zm-85.023 45.023l85.016-85.017-85.016 85.017zm-3.733-1.268l87.481-87.481-87.481 87.481zm-3.558-1.442l89.6-89.6-89.6 89.6zm89.271-34.272l-33.947 33.947zm-92.667 32.668l91.389-91.389-91.389 91.389zm-3.244-1.756l92.876-92.877-92.876 92.877zm-3.1-1.9l94.075-94.075-94.075 94.075zm-2.96-2.039l95-95-95 95zm94.984-14.984l-14.972 14.972zm-97.81 12.81l95.648-95.648-95.648 95.648zm-2.695-2.306l96.037-96.037-96.037 96.037zm-2.564-2.435l96.166-96.167-96.166 96.167zm-2.436-2.565l96.037-96.037-96.037 96.037zm-2.305-2.694l95.648-95.648-95.648 95.648zm-2.174-2.827l95-95-95 95zm-2.04-2.96l94.075-94.075-94.075 94.075zm-1.9-3.1l92.876-92.877-92.876 92.877zm-1.756-3.244l91.388-91.389-91.388 91.389zm-1.6-3.4l89.6-89.6-89.6 89.6zm-1.442-3.558l87.481-87.481-87.481 87.481zm-1.268-3.732l85.017-85.017-85.017 85.017zm-1.078-3.923l82.172-82.172-82.172 82.172zm-.867-4.133l78.905-78.906-78.905 78.906zm-.629-4.371l75.164-75.164-75.164 75.164zm-.355-4.644l70.875-70.876-70.875 70.876zm-.033-4.968l65.94-65.94-65.94 65.94zm.368-5.368l60.2-60.2-60.2 60.2zm.9-5.9l53.4-53.4-53.4 53.4zm1.684-6.684l45.033-45.033-45.033 45.033zm3.041-8.04l33.951-33.951-33.951 33.951zm6.987-11.988l14.976-14.976-14.976 14.976z"
          class="cls-3"
          fontWeight="bold"
          stroke="rgba(0,55,199,.08)"
          fill="none"
          transform="translate(4334.117 267.116)"
        />
        <text
          id="협업_만들기"
          class="cls-4"
          fontSize="14px"
          fontFamily="KoPubWorldDotumBold,Sans-serif,
,KoPubWorldDotum"
          fill="#51A5C1"
          data-name="협업 만들기"
          transform="translate(285 675)"
        >
          <tspan x="0" y="0" textAnchor="middle">
            {props.label}
          </tspan>
        </text>
        <g id="icon_make" transform="translate(263 584)">
          <g id="그룹_466" data-name="그룹 466">
            <g
              id="그룹_465"
              data-name="그룹 465"
              transform="translate(.893 .913)"
            >
              <path
                id="패스_116"
                d="M897.512 460.225c-.121-.031-.192-.045-.192-.045l-1.8-3.61z"
                class="cls-1"
                fill="#fff"
                data-name="패스 116"
                transform="translate(-882.655 -423.641)"
              />
              <path
                id="패스_117"
                d="M870.7 443.534c2.576-.836 5.266 3.21 5.943 4.275l3.71 7.423-3.687-27.332a3.886 3.886 0 0 1 .7-2.788 3.565 3.565 0 0 1 2.388-1.439 3.665 3.665 0 0 1 4.046 3.127l3.3 24.432v4.005l14.368-1.473 1.88-15.059a3.53 3.53 0 1 1 6.992.929l-1.877 15.035.038 13.641a19.935 19.935 0 0 1-.71 5.092 46.945 46.945 0 0 0-1.9 10.787 26.872 26.872 0 0 0 .127 4.216l.006.038a3.136 3.136 0 0 1-1.628 3.055c-1.854 1.06-5.285 2.344-10.966 2.323-6.016-.023-9.269-1.619-10.858-2.816a3.154 3.154 0 0 1-1.31-2.531v-.019c-.28-2.311-.474-7.168-3.828-10.752-3.561-3.957-3.089-14.179-3.861-17.24l-5.025-11.787c-.735-1.802-.63-4.238 2.152-5.142z"
                class="cls-5"
                fill="#fff"
                data-name="패스 117"
                transform="translate(-868.161 -423.641)"
              />
            </g>
            <path
              id="패스_118"
              d="M893.569 494.727c5.777 0 9.311-1.322 11.264-2.438a4.067 4.067 0 0 0 2.08-3.948c0-.016-.009-.068-.011-.084a25.992 25.992 0 0 1-.119-4.026 46.242 46.242 0 0 1 1.871-10.6 20.837 20.837 0 0 0 .743-5.327l-.039-13.588 1.87-14.977a4.734 4.734 0 0 0-.846-3.375 4.272 4.272 0 0 0-2.937-1.762 4.344 4.344 0 0 0-3.294.932 4.6 4.6 0 0 0-1.691 3.046l-1.79 14.339-12.675 1.3v-3a1.018 1.018 0 0 0-.008-.124l-3.3-24.432a4.543 4.543 0 0 0-5.053-3.9 4.437 4.437 0 0 0-2.985 1.794 4.8 4.8 0 0 0-.871 3.457L878.742 450l-1.3-2.607a.824.824 0 0 0-.046-.082c-1.361-2.139-4.077-5.587-6.967-4.647a4.36 4.36 0 0 0-2.786 2.318 5.14 5.14 0 0 0 .08 4.037l5 11.731a32.34 32.34 0 0 1 .439 3.86c.365 4.495.864 10.649 3.612 13.7 2.83 3.024 3.26 7.072 3.516 9.49.027.253.052.488.077.7a4.054 4.054 0 0 0 1.677 3.229c1.792 1.35 5.226 2.968 11.382 2.991zm13.315-58.333a2.524 2.524 0 0 1 2.056 1.056 2.889 2.889 0 0 1 .511 2.064l-1.876 15.035a.933.933 0 0 0-.007.118l.038 13.64a18.956 18.956 0 0 1-.679 4.849 47.722 47.722 0 0 0-1.932 10.984 27.85 27.85 0 0 0 .132 4.359c0 .015.01.067.012.082a2.2 2.2 0 0 1-1.178 2.115c-1.784 1.019-5.052 2.2-10.529 2.207-5.971-.022-9.034-1.661-10.329-2.637a2.226 2.226 0 0 1-.946-1.793c0-.039 0-.1-.007-.134a46.717 46.717 0 0 1-.081-.731c-.281-2.648-.751-7.081-3.988-10.539-2.322-2.58-2.792-8.375-3.135-12.607a30.545 30.545 0 0 0-.506-4.231 1.009 1.009 0 0 0-.046-.137l-5.026-11.787a3.3 3.3 0 0 1-.1-2.557 2.634 2.634 0 0 1 1.705-1.346c1.481-.482 3.495 1.671 4.9 3.862l6.16 12.328a.9.9 0 0 0 .639.484c.35.068 8.663 1.838 8.663 16.007a.9.9 0 1 0 1.791 0c0-14.022-7.692-17.124-9.684-17.692l-1.607-3.216-.01-.021-.6-1.2-3.666-27.179a2.95 2.95 0 0 1 .532-2.123 2.662 2.662 0 0 1 1.786-1.076 2.749 2.749 0 0 1 3.046 2.369l3.288 24.343v3.943a.918.918 0 0 0 .295.676.874.874 0 0 0 .69.231l14.369-1.473a.9.9 0 0 0 .8-.792l1.879-15.059a2.781 2.781 0 0 1 1.021-1.842 2.589 2.589 0 0 1 1.619-.58z"
              class="cls-6"
              fill="#51A5C1"
              data-name="패스 118"
              transform="translate(-867.266 -422.728)"
            />
          </g>
          <g
            id="그룹_467"
            data-name="그룹 467"
            transform="translate(18.938 17.238)"
          >
            <path
              id="패스_119"
              d="M887.875 463.625a3.416 3.416 0 0 0 3.556-3.241V444.55a3.639 3.639 0 0 0-3.6-3.672 3.6 3.6 0 0 0-3.555 3.672v15.758a3.675 3.675 0 0 0 1.056 2.6 3.346 3.346 0 0 0 2.543.717z"
              class="cls-5"
              fill="#fff"
              data-name="패스 119"
              transform="translate(-883.375 -439.966)"
            />
            <path
              id="패스_120"
              d="M887.578 464.543c.114 0 .224 0 .329-.006a4.293 4.293 0 0 0 4.418-4.087v-15.9a4.476 4.476 0 1 0-8.949 0v15.758a4.569 4.569 0 0 0 1.323 3.245 3.932 3.932 0 0 0 2.879.99zm.267-1.829a2.485 2.485 0 0 1-1.887-.458 2.742 2.742 0 0 1-.791-1.948V444.55a2.682 2.682 0 0 1 2.659-2.76 2.739 2.739 0 0 1 2.71 2.76v15.8a2.52 2.52 0 0 1-2.692 2.365z"
              class="cls-6"
              fill="#51A5C1"
              data-name="패스 120"
              transform="translate(-883.376 -439.966)"
            />
          </g>
          <g
            id="그룹_468"
            data-name="그룹 468"
            transform="translate(26.1 17.24)"
          >
            <path
              id="패스_121"
              d="M880.668 440.88a3.637 3.637 0 0 1 3.6 3.671v13.632a3.638 3.638 0 0 1-3.6 3.671 3.638 3.638 0 0 1-3.6-3.671v-13.632a3.637 3.637 0 0 1 3.6-3.671z"
              class="cls-5"
              fill="#fff"
              data-name="패스 121"
              transform="translate(-876.169 -439.968)"
            />
            <path
              id="패스_122"
              d="M880.667 462.765a4.547 4.547 0 0 0 4.5-4.582v-13.632a4.5 4.5 0 1 0-9 0v13.632a4.546 4.546 0 0 0 4.5 4.582zm0-20.974a2.738 2.738 0 0 1 2.708 2.76v13.632a2.709 2.709 0 1 1-5.417 0v-13.632a2.738 2.738 0 0 1 2.709-2.76z"
              class="cls-6"
              fill="#51A5C1"
              data-name="패스 122"
              transform="translate(-876.168 -439.968)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
