import React from 'react';
import styled from "styled-components";

import '../../../assets/css/_modal.scss';
import { withTranslation } from 'react-i18next';

import * as SettingsService from '../../../services/SettingsService';
import radioCheckedImg from "../../../assets/images/radio-checked.svg";
import radioUncheckedImg from "../../../assets/images/radio-unchecked.svg";

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;
const __ISIOS__ = navigator.userAgent.match(/iPad|iPhone|iPod/i) ? true : false;


const viewOrderTypeOptions = [
    { value: 0, label: "최신순", eng: "By newest", ja: "最新のもの" },
    { value: 1, label: "오래된순", eng: "By oldest", ja: "最古のもの" },
    { value: 2, label: "이름순", eng: "By name", ja: "名前で" }
];
class ViewOrderTypeSelectModal extends React.Component {

    onClose;
    show;

    state = {
        viewOrderTypeOption: parseInt(localStorage.getItem('viewOrderType'), 10),
    };

    selectViewOrderType = (e) => {
        this.setState({
            viewOrderTypeOption: viewOrderTypeOptions[e.currentTarget.value].value,
        });
    };

    submit = () => {
        SettingsService.updateUserViewOrderTypeNode(this.state.viewOrderTypeOption)
            .then(() => SettingsService.userSettingsNode())
            .then(() => this.props.onClose())
            .catch(error => console.error(error));
    };

    modalClose = () => {
        this.props.onClose();
    }

    render() {
        const { t } = this.props;
        const currentLanguage = localStorage.getItem("i18nextLng");
        if (!this.props.show) {
            return null;
        }

        return (
            <div role="dialog" className="modal_wrap">
                <div role="document" tabIndex="-1" className="modal">
                    <button className="close_btn" aria-label="모달창 닫기 버튼" onClick={this.modalClose}></button>
                    <div>
                        <p className="title">{t('ViewOrderTypeSelectModal.selectDefaultSort')}</p>
                        <p className="subtitle">{t('ViewOrderTypeSelectModal.fixedMeetingsAreNotSorted')}</p>
                        <div className="modal_content view_order">
                            {viewOrderTypeOptions.map((option, index) =>
                                <div key={`radio-select-option-${index + 1}`} style={{ marginRight: '30px' }}>
                                    <label>
                                        <Input
                                            isIOS={__ISIOS__}
                                            isMSIE={__ISMSIE__}
                                            type="radio"
                                            value={option.value}
                                            checked={this.state.viewOrderTypeOption === option.value}
                                            onChange={this.selectViewOrderType}
                                        />
                                        {currentLanguage === "korean" ? option.label : currentLanguage === "english" ? option.eng : option.ja}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <button className="modal_submit_btn" onClick={this.submit}>
                            {t('ViewOrderTypeSelectModal.submit')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ViewOrderTypeSelectModal);

const Input = styled.input`
    width: 17px;
    -webkit-appearance: none;
    background: ${props => props.isMSIE || props.isIOS ? null : `url(${radioUncheckedImg})`}; 
    background-size: 15px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 0px 5px;
    margin-bottom: -3px;
    margin-right: 3px;
    margin-top: 5px;

    &:checked {
    background: ${props => props.isMSIE || props.isIOS ? null : `url(${radioCheckedImg})`};
    width: 17px;
    -webkit-appearance: none;
    background-size: 15px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 0px 5px;
    margin-bottom: -3px;
    margin-right: 3px;
    margin-top: 5px;
    }
`;