import { BEARER_TOKEN_PREFIX } from "../configs";
import { BODY_JSON, BODY_FILE } from "../configs";


const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

export function httpRequestExternalLink(url) {
    const headers = new Headers();
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');
    headers.append("Content-Type", "application/json;charset=UTF-8");

    let proxyUrl;

    if (__ISMSIE__) {
        proxyUrl = "";
    } else {
        proxyUrl = "https://cors-anywhere.herokuapp.com/";
    }

    const requestOptions = {
        crossDomain: true,
        method: 'GET',
        headers: headers,
        body: undefined
    };

    console.log(`[REQ][GET]${url}`);

    return fetch(proxyUrl + url, requestOptions)
        .then(handleExternalLinkResponse);
}


export function httpRequestFirst(url, method, type, body) {
    const token = localStorage.getItem('accessToken');
    const headers = new Headers();
    if (token) {
        headers.append("Authorization", `${BEARER_TOKEN_PREFIX} ${token}`);
    }
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');

    let data;
    switch (type) {
        case BODY_JSON:
            headers.append("Content-Type", "application/json;charset=UTF-8");
            if (body && body !== null) {
                data = JSON.stringify(body);
            }
            break;
        case BODY_FILE:
            data = new FormData();
            if (body && body !== null) {
                data.append('file', body);
            }
            break;
        default:
            break;
    }

    const requestOptions = {
        method: method,
        headers: headers,
        body: data,
        // credentials: 'include'
    };

    console.log(`[REQ][${method}]${url}`);

    return fetch(url, requestOptions)
        .then(handleResponse);
}




export function httpRequest(url, method, type, body) {
    const token =  localStorage.getItem('accessToken') ? `${BEARER_TOKEN_PREFIX}${localStorage.getItem('accessToken')}` : undefined;

    const headers = new Headers();
    if (token) {
        headers.append("Authorization", `${BEARER_TOKEN_PREFIX} ${token}`);
    }
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');

    let data;
    switch (type) {
        case BODY_JSON:
            headers.append("Content-Type", "application/json;charset=UTF-8");
            if (body && body !== null) {
                data = JSON.stringify(body);
            }
            break;
        case BODY_FILE:
            data = new FormData();
            if (body && body !== null) {
                data.append('file', body);
            }
            break;
        default:
            break;
    }

    const requestOptions = {
        method: method,
        headers: headers,
        body: data,
        // credentials: 'include'
    };



    console.log(`[REQ][${method}]${url}`);

    return fetch(url, requestOptions)
        .then(handleResponse);
}

export function transferFile(url, method) {
    const token =  localStorage.getItem('accessToken') ? `${BEARER_TOKEN_PREFIX}${localStorage.getItem('accessToken')}` : undefined;

    const headers = new Headers();
    if (token) {
        headers.append("Authorization", `${BEARER_TOKEN_PREFIX} ${token}`);
    }

    const requestOptions = {
        method: method,
        headers: headers,
        body: undefined
    };

    console.log(`[REQ][${method}]${url}`);

    return fetch(`${url}`, requestOptions)
        .then(handleBlobResponse);
}

function handleExternalLinkResponse(response) {
    return response.text()
        .then(text => {
            if (!response.ok) {
                const error = (text && text.message) || response.statusText;
                return Promise.reject(error);
            }
            return text;
        });
}

function handleResponse(response) {
    if (response.ok) {
        return response.text()
            .then(text => text && JSON.parse(text));
    } else {
        const error = `[${response.status}] ${response.statusText}`;
        return Promise.reject(error);
    }
    // return response.text()
    //     .then(text => {
    //         const data = text && JSON.parse(text);
    //         if (!response.ok) {
    //             const error = (data && data.message) || response.statusText;
    //             return Promise.reject(error);
    //         }
    //         return response.status === 204 ? undefined : data;
    //     });
}

function handleBlobResponse(response) {
    return response.blob()
        .then(blob => URL.createObjectURL(blob))
        .catch(error => {
            response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
            })
        });
}
