import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { ToggleButtonSelect } from "../Select";
import { scheduleColors } from '../../../configs';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Button } from 'antd';
import dateFnsParse from 'date-fns/parse';
import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import { Popup } from 'semantic-ui-react'

const FORMAT = 'yyyy/MM/dd';

let TIME_OPTIONS = [];
for (let i = 0; i < 48; i++) {
    TIME_OPTIONS.push({
        value: pad(Math.floor(i / 2) * 100 + (i % 2) * 30, 4),
        label: `${i < 24 ? "AM " : "PM "} ${pad((Math.floor(i / 2) >= 12 ? Math.floor(i / 2) - 12 : Math.floor(i / 2)), 2)}:${pad((i % 2) * 30, 2)}`
    });
}

function pad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, undefined, { locale });
    if (DateUtils.isDate(parsed)) {
        return parsed;
    }
    return undefined;
}

function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
}

function ScheduleCreateModal({ handleEndDate, handleEndTime, handleToggleChange, handleStartDate, handleStartTime, handleOptionMoreClick, handleSubmit, isAllDay, endTimeForInput, endDateForInput, startTimeForInput, startDateForInput, handleInput, title, scheduleColorModalVisible, scheduleColor, selectScheduleColor, handleScheduleColorVisibleChange }) {
    const { t } = useTranslation();

    const scheduleColorPickerContent = (
        <ColorPalette>
            {scheduleColors.map((color, index) =>
                <ColorPicker key={`color-${index}`}>
                    <input type="checkbox" id={`checkbox-${index}`}
                        checked={(scheduleColor === index)}
                        onChange={() => selectScheduleColor(color)}
                    />
                    <label htmlFor={`checkbox-${index}`} style={{ backgroundColor: color }} />
                </ColorPicker>
            )}
        </ColorPalette>
    )

    return (
        <div>
            <div className="cardHeader">
                {/* <select onChange={this.selectScheduleColor}>{colors.map((color, index) => (<option key={index} value={color}>{color}</option>))}</select> */}
                <Popup style={{ zIndex: '999999999999' }} trigger={
                    <ScheduleCategory>
                        <input type="checkbox" id="scheduleCategory" />
                        <label htmlFor="scheduleCategory" style={{ backgroundColor: scheduleColor }} />
                    </ScheduleCategory>
                } flowing hoverable >
                    {scheduleColorPickerContent}
                </Popup>


                {/* <Popover
                    onVisibleChange={handleScheduleColorVisibleChange}
                    visible={scheduleColorModalVisible}
                    content={scheduleColorPickerContent}
                    trigger="click"
                >
                    <ScheduleCategory>
                        <input type="checkbox" id="scheduleCategory" />
                        <label htmlFor="scheduleCategory" style={{ backgroundColor: scheduleColor }} />
                    </ScheduleCategory>
                </Popover> */}

                <input
                    style={{ border: 'none' }} type="text" name="title" placeholder={`${t('Schedule.scheduleName')}`}
                    value={title} onChange={handleInput}
                />
            </div>

            <hr style={{ margin: 0, border: '1px solid #DBDBDB' }} />

            <div style={{ padding: '1rem 2rem 2rem ' }}>

                <div className="cardHeader" style={{ padding: '0' }}>
                    <span className="writerThumb" style={{ height: 'auto', fontWeight: 'bold' }}>{t('Schedule.start')}</span>

                    <DayPickerInput format={FORMAT}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder="YYYY/MM/DD"
                        value={startDateForInput}
                        onDayChange={day => handleStartDate(day)}
                        style={{ width: '100%' }}
                        dayPickerProps={{
                            selectedDays: startDateForInput,
                        }}
                    />

                    {!isAllDay &&
                        <TimePickerSelect value={startTimeForInput} onChange={e => handleStartTime(e.target.value)}>
                            {TIME_OPTIONS.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
                        </TimePickerSelect>
                    }
                    {/* <TimePicker showSecond={false} defaultValue={now}  className="xxx"  onChange={e => this.handleStartTime(e)} format={format} use12Hours   inputReadOnlys /> */}
                </div>
                <br />

                <div className="cardHeader" style={{ padding: '0' }}>
                    <span className="writerThumb" style={{ height: 'auto', fontWeight: 'bold' }}>{t('Schedule.end')}</span>
                    <DayPickerInput format={FORMAT}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder="YYYY/MM/DD"
                        value={endDateForInput}
                        onDayChange={day => handleEndDate(day)}
                        style={{ width: '100%' }}
                        dayPickerProps={{
                            selectedDays: endDateForInput,
                        }}
                    />
                    {!isAllDay &&
                        <TimePickerSelect value={endTimeForInput} onChange={e => handleEndTime(e.target.value)}>
                            {TIME_OPTIONS.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
                        </TimePickerSelect>
                    }
                    {/* <TimePicker showSecond={false} defaultValue={now}  className="xxx" onChange={e => this.handleEndTime(e)}  format={format}  use12Hours inputReadOnly   /> */}
                    <br />
                </div>
                <br />
                <ToggleButtonSelect title={`${t('Schedule.allDay')}`}
                    name="isAllDay"
                    defaultValue={isAllDay}
                    onChange={handleToggleChange}
                    style={{ padding: 0 }}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem 0rem' }}>
                    <Button onClick={handleOptionMoreClick}>{t('Schedule.moreOptions')}</Button> &nbsp;&nbsp;
            <Button onClick={handleSubmit}>{t('Schedule.save')}</Button>
                </div>
            </div>
        </div>
    )
}

export default ScheduleCreateModal

const ScheduleCategory = styled.div`
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    width: 30px;
    display: flex;
    align-items: center;
    height: 30px;
        
        span {
        padding: 0 .5rem;
        vertical-align: middle;
        }
        
        input[type="checkbox"] {
        display: none;
        }
        input[type="checkbox"] + label {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        margin: .3rem;
        border-radius: 50%;
        vertical-align: middle;
        }
`;

const TimePickerSelect = styled.select`
    background-color: white;
    font-size: 14px;
    width: 47%;
    color: black;
    border: 1px solid #d6dbdc;
    ;
    height: 30px;
    margin-left: 10px;
    select::-ms-expand {
        display: none;
        -webkit-appearance: none;
    }    
`;

const ColorPicker = styled.span`
    display: inline-block;
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      margin: .3rem;
      border-radius: 50%;
    }
    input[type="checkbox"]:checked + label {
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid;
    }
`;

const ColorPalette = styled.div`
    padding: .5rem 1rem;
`;