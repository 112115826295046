import React from 'react';
import '../../../assets/css/_modal.scss';

import { MemberList } from '../Member';

import * as BandService from '../../../services/BandService';
import * as CommonUtils from '../../../utils/CommonUtils';
import { withTranslation } from 'react-i18next';

class BandLeaderSettingModal extends React.Component {

    onClose;
    show;
    bandId;
    title;
    selectMultiple;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            members: [],
            selected: undefined,
            loading: true,
            onlyLeader: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBandMembers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandMembers = () => {
        const bandMemberRoles = [0, 1];
        let onlyLeader = false;
        BandService.getBandMembersNode(this.props.bandId, bandMemberRoles)
            .then(result => {
                if (result.length === 1) {
                    onlyLeader = true
                    this.setState({ onlyLeader: onlyLeader })
                }
                let selected;
                if (this.props.selectMultiple) {
                    selected = [];
                    result.forEach(bandMember => {
                        if (bandMember.bandMemberRole === 0) {
                            selected.push(bandMember.userId);
                        }
                    });
                } else {
                    result.forEach(bandMember => {
                        if (bandMember.userId === Number(localStorage.getItem('userId'))) {
                            selected = bandMember.userId;
                        }
                    });
                }

                if (this._isMounted) {
                    this.setState({
                        members: result,
                        selected: selected,
                        loading: false,
                    });
                }
            })
            .catch(error => console.error(error));
    };

    onSelect = (select) => {
        if (this._isMounted) {
            if (this.props.selectMultiple) {
                this.setState({
                    selected: CommonUtils.contains(this.state.selected, select)
                        ? this.state.selected.filter(id => id !== select)
                        : [...this.state.selected, select]
                });
            } else {
                this.setState({
                    selected: select,
                });
            }
        }
    };

    submit = () => {
        if (this.props.selectMultiple) {
            if (this.state.selected.length === 0) {
                if (localStorage.getItem("i18nextLng") === "english") {
                    alert("Not selected!");
                }else if (localStorage.getItem("i18nextLng") === "japanese") {
                    alert("共同リーダーを選択してください。");
                }else if (localStorage.getItem("i18nextLng") === "thai") {
                    alert("ช่วยเลือกลีดเดอร์ร่วมด้วยครับ");
                }else{
                    alert("공동 리더를 선택해주세요.");
                }

                this.props.onClose();
            } else {
                BandService.assignBandLeaderNode(this.props.bandId, this.state.selected)
                    .then(() => this.props.onClose())
                    .catch(error => {
                        console.error(error);
                        this.props.onClose();
                    });
                setTimeout(() => {
                    window.location.reload();
                }, 50);
            }
        } else {
            const selected = [];
            selected.push(this.state.selected);
            BandService.assignBandLeaderNode(this.props.bandId, selected)
                .then(() => this.props.onClose())
                .catch(error => {
                    console.error(error);
                    this.props.onClose();
                });
            setTimeout(() => {
                window.location.reload();
            }, 50);
        }

    };

    render() {
        if (!this.props.show) {
            return null;
        }

        const { loading, members, selected, onlyLeader } = this.state;

        return (
            <div>
                { loading ?
                    <div></div> :
                    <div className="modal_wrap">
                        <div className="modal">
                            <button className="close_btn" aria-label="모달창 닫기 버튼" onClick={this.props.onClose}></button>
                            <div className="modal_content view_order">
                                <h3>{this.props.title}</h3>
                                <div style={{ overflowY: 'auto' }}>
                                    <MemberList memberList={members}
                                        selectable={onlyLeader ? false : true} selectMultiple={this.props.selectMultiple}
                                        selected={selected} onSelect={this.onSelect} />
                                </div>
                                <br /><br />
                                <button className="settingSubmitBtn" onClick={this.submit}>{this.props.t('Schedule.save')}</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default withTranslation()(BandLeaderSettingModal);
