import React from 'react';
import './PostImageVideoGrid.css';
class PostImageVideoGrid extends React.Component {

    _isMounted = false;
    items;

    state = {
        directionClass: "",
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const count = this.props.items ? this.props.items.length : 0;
        const moreBtnActive = count > 4 ? true : false;
        const cols = count > 4 ? 4 : count;
        const set = this.props.items.filter((item, index) => index < 4);

        //여기는 이미지와 비디오가 둘다 있을시 비디오를 먼저 나오게 할수있게 Array 재배열을 한다.
        // let setNewArray = [];
        // set.map((item, index) => {
        //     if (item.type === "video") {
        //         item.src.split()
        //         return setNewArray.push(item);
        //     } else {
        //         return null;
        //     }
        // });

        // set.map((item, index) => {
        //     if (item.type === "image") {
        //         return setNewArray.push(item);
        //     } else {
        //         return null;
        //     }
        // });

        // //이곳에서 height이 1000이 넘는 것은 vertical 형식으로 band Grid를 준다. 
        // setNewArray.map((item, index) => {
        //     if (item.type === "image" && index === 0) {
        //         var img = new Image();
        //         img.src = item.src;
        //         img.onload = function () {
        //             if (this.width / this.height < 0.667) {
        //                 return item.direction = "-vertical";
        //             } else {
        //                 return item.direction = "-horizontal";
        //             }
        //         }
        //     } else if (item.type === "video" && index === 0) {
        //         return item.direction = "-horizontal";
        //     }
        //     return null;
        // });

        // let directionClass = "";
        // setTimeout(() => {
        //     if (count > 0) {
        //         directionClass = setNewArray[0].direction === "-vertical" ? "-vertical" : "-horizontal";
        //     }
        //     if (this._isMounted) {
        //         this.setState({ directionClass: directionClass })
        //     }
        // }, 10);


        return (
            <div>
                {count > 0 &&
                    <div className="_attachmentPhotosRegion" style={{ postion: 'relative', marginTop: -10 }}>
                        <div className="uWidget gCursorPointer">
                            <ul className={`uCollage   ${count === 2 ? "-vertical" : "-horizontal"}`} data-collage={cols}>
                                {set.map((item, index) => {
                                    //이미지일때 
                                    if (item.type === "image") {
                                        //이미지가 1개 이상일떄 
                                        const splits = item.src.split("/");
                                        // splits[splits.length - 1] = "s_" + splits[splits.length - 1];
                                        splits[splits.length - 1] = splits[splits.length - 1];
                                        let url = "";
                                        for (let i = 0; i < splits.length; i++) {
                                            if (i !== splits.length - 1) {
                                                url += (splits[i] + "/");
                                            } else {
                                                url += splits[i];
                                            }
                                        }
                                        if (cols > 1) {

                                            return <li className="collageItem " key={`img-${index}`}>
                                                <button type="button" className="collageImage _imageButton -horizontal">
                                                    {/* <img src={index === 0 ?
                                                        `${item.src}`
                                                        : `${url}`}
                                                        alt={`img-${index}`} className="Image" /> */}
                                                    <img src={url} alt={`img-${index}`} className="Image" />
                                                </button>
                                                {moreBtnActive && index === 3 && <button type="button" className="moreMedia _moreMedia">
                                                    <span className="moreText">More</span>
                                                </button>}
                                            </li>
                                                ;
                                            //이미지가 1개일때 
                                        } else {
                                            return <li className="collageItem" key={`img-${index}`}>
                                                <button type="button" className="collageImage _imageButton">
                                                    <img src={url} alt={`img-${index}`} className="Image" />
                                                </button>
                                            </li>;
                                        }
                                        //비디오일때 
                                    } else {
                                        let splits = item.src.split("/");
                                        splits[splits.length - 1] = "s_" + splits[splits.length - 1];
                                        // splits[splits.length - 1] = splits[splits.length - 1];
                                        let thumbnail = "";
                                        for (let i = 0; i < splits.length; i++) {
                                            if (i !== splits.length - 1) {
                                                thumbnail += (splits[i] + "/");
                                            } else {
                                                thumbnail += splits[i];
                                            }
                                        }
                                        splits = thumbnail.split(".");
                                        let url = "";
                                        for (let i = 0; i < splits.length - 1; i++) {
                                            url += (splits[i] + ".");
                                        }
                                        url += "png";
                                        return <li className="collageItem player -size80 bandHomeVideoHover" key={`img-${index}`}>
                                            <button type="button" className="collageImage _imageButton">
                                                <img src={url} alt={`img-${index}`} className="Image" />
                                            </button>
                                        </li>;
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default PostImageVideoGrid;