import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { COMPANY_NAME } from '../../configs';
import { Avatar } from 'antd';
import { withTranslation } from 'react-i18next';
import * as StringUtils from '../../utils/StringUtils';
const NoProfileImage = require('../../assets/images/blank-profile-picture.png');

class HomeTopMenu extends React.Component {

    bandId;
    title;
    historyProp;
    additionalButtons;
    routeToMain;
    routeBack;

    constructor(props) {
        super(props);
        this.state = {
            startScroll: false,
            transform: 0,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    back = () => {
        if (this.props.routeToMain) {
            this.props.historyProp.push("/myBand");
        } else if (this.props.routeBack) {
            this.props.historyProp.goBack();
        } else {
            this.props.historyProp.push("/band/" + this.props.bandId + "/home");
        }
    };

    home = () => {
        this.props.historyProp.push("/myBand");
    };

    route = (e) => {
        this.props.historyProp.push(e.currentTarget.attributes.getNamedItem('routes').value);
    };


    routeMyBand = () => {
        this.props.routeFunction("/myBand");
    };

    routeNewsFeed = () => {
        this.props.routeFunction("/newsFeed");
    };

    routeSearchBand = () => {
        this.props.routeFunction("/searchBand");
    };

    renderHeader = (pathname) => (
        <header className="headerWrap">
            <div className="headerContentsWrap">
                <LogoWrapper>
                    <LogoTextWrap onClick={this.routeMyBand} aria-label="홈으로 버튼">
                        <p>{COMPANY_NAME === "UC WARE" ? "MAIN" : COMPANY_NAME}</p>
                    </LogoTextWrap>
                </LogoWrapper>
                <div className="gnbWrap">
                    <button className={pathname === '/myBand' ? 'menu select' : 'menu normal'} onClick={this.routeMyBand}>{this.props.t('HomeTopMenu.myBand')}</button>
                    <button className={pathname === '/newsFeed' ? 'menu select' : 'menu normal'} onClick={this.routeNewsFeed}>{this.props.t('HomeTopMenu.newsFeed')}</button>
                    <button className={pathname === '/searchBand' ? 'menu select' : 'menu normal'} onClick={this.routeSearchBand}>{this.props.t('HomeTopMenu.search')}</button>
                </div>
                <div className="userWrap">
                    <Avatar
                        src={StringUtils.isNullOrUndefined(localStorage.getItem('profileImage'))
                            ? NoProfileImage
                            : `${localStorage.getItem('profileImage')}`}
                    />
                </div>
            </div>
        </header>
    )

    render() {
        const { pathname } = this.props;
        return (
            <>
                {this.renderHeader(pathname)}
            </>
        )
    }
}

const LogoWrapper = styled.div`
    @media (max-width: 500px) {
        display: none;
    }
`;

const LogoTextWrap = styled.button`
    flex: none;
    width: 128px;
    height: 100%;
    padding: 0.6rem;
    box-sizing: border-box;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
    font-size: 1.7rem;
    font-weight: bold;
    color: #51a5c1;
    width: 100%;
`;

export default withRouter(withTranslation()(HomeTopMenu));
