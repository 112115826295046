import React from 'react';
import styled from "styled-components";

import '../../../assets/css/_modal.scss';
import * as SettingsService from '../../../services/SettingsService';

import radioUncheckedImg from "../../../assets/images/radio-unchecked.svg";
import radioCheckedImg from "../../../assets/images/radio-checked.svg";

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;
const __ISIOS__ = navigator.userAgent.match(/iPad|iPhone|iPod/i) ? true : false;


const viewTypeOptions = [
    { value: 0, name: "view_type_card", activeName: "view_type_card_active", label: "카드형", eng: "Card type" },
    { value: 1, name: "view_type_list", activeName: "view_type_list_active", label: "목록형", eng: "List type" },
];

class ViewTypeSelectModal extends React.Component {

    onClose;
    show;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            viewTypeOption: localStorage.getItem('viewType'),
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    selectViewType = (e) => {
        if (this._isMounted) {
            this.setState({
                viewTypeOption: viewTypeOptions[e.currentTarget.value].value,
            });
        }
    };

    modalClose = () => {
        this.props.onClose();
    }

    submit = () => {
        SettingsService.updateUserViewOrderTypeNode(this.state.viewTypeOption)
            .then(() => SettingsService.userSettingsNode())
            .then(() => this.props.onClose())
            .catch(error => console.error(error));
    };

    render() {
        if (!this.props.show) {
            return null;
        }
        const currentLanguage = localStorage.getItem("i18nextLng");

        return (
            <div className="modal_wrap">

                <div className="modal">
                    <button className="close_btn" aria-label="모달창 닫기 버튼" tabIndex="0" onClick={this.modalClose}></button>
                    <div>
                        <p className="title">뷰 타입 선택</p>
                        <div className="modal_content view_type">
                            {viewTypeOptions.map((option, index) =>
                                <div className={this.state.viewTypeOption === option.value ? option.activeName : option.name} key={`radio-select-option-${index + 1}`}>
                                    <label>
                                        <div className='img'></div>
                                        <Input
                                            isMSIE={__ISMSIE__}
                                            isIOS={__ISIOS__}
                                            type="radio"
                                            value={option.value}
                                            checked={this.state.viewTypeOption === option.value}
                                            onChange={this.selectViewType}
                                        />
                                        {currentLanguage === "korean" ? option.label : option.eng}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <button className="modal_submit_btn" onClick={this.submit}>
                            확인
                        </button>
                    </div>
                </div>
                <div className="dim" onClick={this.modalClose} />
            </div>
        );
    }
}

export default ViewTypeSelectModal;

const Input = styled.input`
    width: 17px;
    -webkit-appearance: none;
    background: ${props => props.isMSIE || props.isIOS ? null : `url(${radioUncheckedImg})`}; 
    background-size: 15px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 0px 5px;
    margin-bottom: -3px;
    margin-right: 3px;
    margin-top: 5px;

    &:checked {
    background: ${props => props.isMSIE || props.isIOS ? null : `url(${radioCheckedImg})`};
    width: 17px;
    -webkit-appearance: none;
    background-size: 15px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 0px 5px;
    margin-bottom: -3px;
    margin-right: 3px;
    margin-top: 5px;
    }
`;