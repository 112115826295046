
import React from 'react';
import '../../../assets/css/_modal.scss';
import { withTranslation } from 'react-i18next';
import styled from "styled-components";
import folderIcon from '../../../assets/images/icon-folder.svg';
import { THEME_BACKGROUND_COLOR } from '../../../configs';

class ContentDirectorySelectModalOpen extends React.Component {

    onClose;
    show;
    bandId;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            members: [],
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        const { t } = this.props;

        return (
            <div className="modal_wrap">
                <div className="category">
                    <div className="close_btn" style={{ marginRight: '1rem' }} onClick={this.props.onClose}></div>
                    <div>
                        <Title>{t('ContentDirectoryChangeModal.title')}</Title>
                        <GrayWrapper>

                            <div className="modal_content view_order content_grid" >
                                <DirectorySelect>
                                    <img src={folderIcon} alt="folder-icon" />
                                    <span>ROOT</span>
                                    <input type="radio" checked={this.props.changeDirectory === undefined} 
                                    onChange={this.props.handleDirectorySelect} />
                                </DirectorySelect>
                                <Divider />

                                {this.props.directories.map((directory, index) =>
                                    <>
                                        <DirectorySelect key={`directory-${index}`}>
                                            <img src={folderIcon} alt="folder-icon" />
                                            <span>&nbsp;&nbsp;{directory.originalFileName}</span>
                                            <input type="radio"
                                                id={directory.id}
                                                checked={this.props.changeDirectory === directory.id}
                                                onChange={this.props.handleDirectorySelect}
                                            />
                                        </DirectorySelect>
                                        <Divider />
                                    </>
                                )}
                                <br />
                            </div>
                        </GrayWrapper>

                        <MoveBtn onClick={this.props.onChangeDirectory}>
                            <p>{t('ContentDirectoryChangeModal.ok')}</p>
                        </MoveBtn>

                    </div>
                </div>
            </div>
        );
    }

}



export default withTranslation()(ContentDirectorySelectModalOpen);

const Title = styled.div`
    font-size: 1.7em;
    padding-left: 1.3rem;
`;

const DirectorySelect = styled.div`
    display: flex;
    align-items: center;
    padding: 1.3rem;
    span {
      width: 90%;
      font-size: 1rem;
      font-weight: 500;
    }
    input {
      text-align: right;
    }
 
    img {
        width: 1.8rem;
    }
`;

const GrayWrapper = styled.div`
    margin: 20px 0;
    background-color: #f9f9fb;
    border-top: solid 1.5px #e5e5ea;
    border-bottom: solid 1.5px #e5e5ea;
`

const Divider = styled.div`
    border-bottom: solid 1px #e5e5ea;
    margin: 0.3rem 0;
`;

const MoveBtn = styled.div`
    background-color: ${THEME_BACKGROUND_COLOR};
    border-radius: 6px;
    width: 70%;
    height: 44px;
    font-size: 1rem;
    color: #fff;
    margin-top: 26px;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
 
    p {
       margin: auto;
       padding: 1rem 0;
       font-size: 1rem;
    }
`