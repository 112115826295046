import React from 'react';
import '../../../assets/css/_modal.scss';

import { MemberList } from '../Member';
import * as BandService from '../../../services/BandService';
import { withTranslation } from 'react-i18next';

class BandMemberManageModal extends React.Component {

    onClose;
    show;
    bandId;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            members: [],
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBandMembers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandMembers = () => {
        const bandMemberRoles = [0, 1];
        BandService.getBandMembersNode(this.props.bandId, bandMemberRoles)
            .then(result => {
                if (this._isMounted) {
                    this.setState({
                        members: result,
                        loading: false,
                    });
                }
            })
            .catch(error => console.error(error));
    };

    deportFunction = (id) => {
        BandService.deportMemberNode(this.props.bandId, id)
            .then(() => this.loadBandMembers())
            .catch(error => console.error(error));
    };

    submit = (e) => {
        e.preventDefault();
        this.props.onClose();
    }
    
    render() {
        const { t } = this.props;
        if (!this.props.show) {
            return null;
        }

        const { loading, members } = this.state;

        return (
            <div>
                {loading ?
                    <div></div> :
                    <div className="modal_wrap">
                        <div className="modal">
                            <button className="close_btn" aria-label="모달창 닫기 버튼" onClick={this.props.onClose}></button>
                            <div className="modal_content view_order">
                                <h3>{t('BandMemberManageModal.forcedMemberWithdrawal')}</h3>
                                <div style={{ overflowY: 'auto' }}>
                                    <MemberList memberList={members}
                                        isBandLeader={true}
                                        selectable={false}
                                        deportFunction={this.deportFunction}
                                        deportTitle={t('BandMemberManageModal.forcedWithdrawal')} />
                                </div>
                                <br /><br />
                                <button className="settingSubmitBtn" onClick={this.submit}>
                                    {t('BandMemberManageModal.save')}
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default withTranslation()(BandMemberManageModal);
