import * as HttpHandler from '../utils/HttpHandler';
import { SERVER_URI, BODY_FILE, BODY_JSON } from '../configs';
import * as StringUtils from '../utils/StringUtils';
import axios from 'axios';

// export function sendNotificationToinvitedAllUsers(bandId, invitedUserIds) {

//     return HttpHandler
//         .httpRequest(
//             `${SERVER_URI}:8765/ucband/member/api/v1/bands/${bandId}/users/request?userIds=${invitedUserIds}`,
//             'GET',
//             BODY_JSON,
//             undefined,
//         );
// }

export function isSystemAdminUser(ucTalkId) {
    return axios.get(`${SERVER_URI}:5000/api/user/isSystemAdminUser?ucTalkId=${ucTalkId}`).then(response => { return response.data });
}

export function getSearchedUsers(searchKeyword) {
    return axios.get(`${SERVER_URI}:5000/api/user/searchedUsers?searchKeyword=${encodeURIComponent(searchKeyword)}`)
        .then(response => { return response.data });
}

export function getUsersFromUCM() {
    return axios.get(`${SERVER_URI}:5000/api/user/fromUCM?orgGroupCode=${localStorage.getItem('orgGroupCode')}`);
}

export function userUploadFileSize() {

    let userId = localStorage.getItem('userId');

    return axios.get(`${SERVER_URI}:5000/api/user/file/size?userId=${userId}`);
}

export function getPostNotificationAllowUser(bandId, receiveUserNames, sendUserAccount, sendUserName) {

    const body = {
        bandId: bandId,
        receiveUserNames: receiveUserNames,
        sendUserAccount: sendUserAccount,
        sendUserName: sendUserName
    };

    return axios.post(`${SERVER_URI}:5000/api/notification/invitation`, body);

}

export function sendNotificationToinvitedAllUsers(bandId, receiveUserNames, sendUserAccount, sendUserName, bandName) {
    console.log('sendUserName sendUserName sendUserName', sendUserName)
    const body = {
        bandId: bandId,
        receiveUserNames: receiveUserNames,
        sendUserAccount: sendUserAccount,
        sendUserName: sendUserName,
        bandName: bandName
    };

    return axios.post(`${SERVER_URI}:5000/api/notification/invitation`, body);

}

export function sendNotificationToPostUser(bandId, postId, receiveUserId, sendUserAccount, sendUserName) {

    const body = {
        bandId: bandId,
        postId: postId,
        receiveUserId: receiveUserId,
        sendUserAccount: sendUserAccount,
        sendUserName: sendUserName
    };

    return axios.post(`${SERVER_URI}:5000/api/notification/reply/post`, body);

}

export function sendNotificationToScheduleUser(bandId, scheduleId, day, receiveUserAccount, sendUserAccount, sendUserName) {

    const body = {
        bandId: bandId,
        scheduleId: scheduleId,
        day: day,
        receiveUserAccount: receiveUserAccount,
        sendUserAccount: sendUserAccount,
        sendUserName: sendUserName
    };

    return axios.post(`${SERVER_URI}:5000/api/notification/reply/schedule`, body);

}

export function sendNotificationToChatUsers(bandId, sendUserAccount, sendUserName) {

    const body = {
        bandId: bandId,
        userId: localStorage.getItem('userId'),
        sendUserAccount: sendUserAccount,
        sendUserName: sendUserName
    };


    return axios.post(`${SERVER_URI}:5000/api/notification/chat`, body);
}

export function sendNotificationToWatingForInvitationUsers(bandId, sendUserAccount, sendUserName, selectedMembers, bandName) {

    const body = {
        bandId: bandId,
        userId: localStorage.getItem('userId'),
        sendUserAccount: sendUserAccount,
        sendUserName: sendUserName,
        selectedMembers: selectedMembers,
        bandName: bandName
    };

    return axios.post(`${SERVER_URI}:5000/api/notification/approveWatingUsers`, body);
}


export function sendNotificationToContentUsers(bandId, receiveUserNames, sendUserAccount, sendUserName, bandName) {

    const body = {
        bandId: bandId,
        receiveUserNames: receiveUserNames,
        sendUserAccount: sendUserAccount,
        sendUserName: sendUserName,
        bandName: bandName
    };

    return axios.post(`${SERVER_URI}:5000/api/notification/content`, body);
}

export function sendNotificationToPostUsers(bandId, receiveUserNames, sendUserAccount, sendUserName, bandName, createdPostId) {

    const body = {
        bandId: bandId,
        receiveUserNames: receiveUserNames,
        sendUserAccount: sendUserAccount,
        sendUserName: sendUserName,
        bandName: bandName,
        postId: createdPostId
    };

    return axios.post(`${SERVER_URI}:5000/api/notification/post`, body);
}

export function getAllUsersNode() {

    return axios.get(`${SERVER_URI}:5000/api/user/users`)
        .then(response => { return response.data });
}


export function getUserProfileNode(userId) {
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:5000/api/user/userProfile?userId=${userId}`,
            'GET',
            BODY_JSON,
            undefined,
        );
}




////////////////////////////////////안쓰는 API ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////


export function createUser(ucTalkId, password, name, nickname, organizationId, jobTitleId) {
    const body = {
        ucTalkId: ucTalkId,
        password: password,
        name: name,
        nickname: nickname,
        organizationId: organizationId,
        jobTitleId: jobTitleId,
    };

    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/user/api/v1/users/create`,
            'POST',
            BODY_JSON,
            body,
        )
        .then(response => {
            // rootStore.userStore.setUser(response);
            return response;
        });
}

export function updateUser(userId, nickname) {
    const body = {
        nickname: nickname,
    };

    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/user/api/v1/users/${userId}`,
            'PUT',
            BODY_JSON,
            body,
        )
        .then(response => {
            // rootStore.userStore.setUser(response);
            return response;
        });
}

export function uploadProfileImage(userId, profileImage) {
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8061/resource/users/${userId}/upload`,
            'POST',
            BODY_FILE,
            profileImage,
        );
}

export function getProfileImage(profileImage) {
    if (StringUtils.isNullOrUndefined(profileImage)) {
        return undefined;
    }
    return HttpHandler
        .transferFile(
            `${SERVER_URI}:8061/resource/profile/${profileImage}`,
            'GET'
        );
}
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////안쓰는 API ///////////////////////////////////////////////////////
