import React, { Component } from 'react'
import Dropzone from 'react-dropzone';
import { Input, Button, Row, message } from 'antd';
import styled from "styled-components";

import * as FileService from '../../../services/FileService';
import * as UserService from '../../../services/UserService';
import * as BandService from '../../../services/BandService';

import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip'

import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { getInitialChatMessages } from "../../../_actions/chat_actions";
import { Picker, emojiIndex } from "emoji-mart";
import Modal from 'react-modal';
import DialogComp from '../../../common/components/Shared/DialogComp';
import {
    UploadOutlined,
    MessageOutlined,
    EnterOutlined
} from '@ant-design/icons';
import './ChatInput.css';
import 'emoji-mart/css/emoji-mart.css'

const MAX_SIZE_STORAGE = localStorage.getItem("maxPersonalDiskUsage");

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0'
    }
};

class ChatInput extends Component {
    talkInput;

    state = {
        chatMessage: "",
        isTyping: false,
        isOpenFileTransferProgress: false,
        isOpenEmojiPicker: false
    };

    componentWillUnmount() {
        this.stopCheckingTyping()
    }

    componentDidUpdate() {
        if (this.talkInput) {
            this.talkInput.focus();
        };
    }

    onDrop = async (files) => {
        let fileTypeVariable = files[0].type.split('/')[0];
        let fileType = "";
        if (fileTypeVariable === "image") {
            fileType = 1
        } else if (fileTypeVariable === "video") {
            fileType = 2
        } else {
            fileType = 3
        }

        try {
            let getBandStatusResult = await BandService.getBandStatusNode(this.props.bandId)
            if (getBandStatusResult.diskUsage > localStorage.getItem('maxPersonalDiskUsage')) {
                return message.warning(`${this.props.t('New.BandDiskCapacityExceeded')}`)
            }

            let userUploadFileSizeResult = await UserService.userUploadFileSize()
            if (userUploadFileSizeResult.data.totalSize > MAX_SIZE_STORAGE) {
                return message.error(`${this.props.t('YouHaveExceeded')} ${userUploadFileSizeResult.data.totalSize}byte`)
            }
            this.setState({ isOpenFileTransferProgress: true })

            let uploadFileResult = await FileService.uploadFileNode(this.props.bandId, localStorage.getItem('userId'), files[0])
            // console.log('response ChatInput', uploadFileResult)
            let userID = localStorage.getItem('userId');
            let bandId = this.props.bandId;
            let fileName = uploadFileResult.data.results.fileName;
            let thumbnail = uploadFileResult.data.results.thumbnail;

            this.props.socket.emit("Input Chat Message", {
                type: fileType,
                bandId,
                userID,
                fileName,
                thumbnail,
                userName: localStorage.getItem('userName')
            });
            this.setState({ isOpenFileTransferProgress: false })
        } catch (error) {
            alert(error)
        }
    }

    /*
    *	Sends typing status to server.
    *	chatId {number} the id of the chat being typed in.
    *	typing {boolean} If the user is typing still or not.
    */
    parentSendTyping = (isTyping) => {
        // this.props.socket.emit("Input Typing", {
        //     userID: localStorage.getItem('userId'),
        //     userName: localStorage.getItem('userName'),
        //     bandId: this.props.bandId,
        //     isTyping: isTyping
        // })
    }

    submitChatMessage = event => {
        // messageType 0 => text
        // messageType 1 => Image
        // messageType 2 => Video
        // messageType 3 => Others
        event.preventDefault();
        let bandId = this.props.bandId;

        if (this.props.chats && this.props.chats.type === "search") {
            this.props.dispatch(getInitialChatMessages(bandId))
        }

        let chatMessage = this.state.chatMessage;
        let userID = localStorage.getItem('userId');
        let type = 0;
        this.props.socket.emit("Input Chat Message", {
            chatMessage,
            userID,
            bandId,
            type,
            userName: localStorage.getItem('userName')
        });
        this.setState({ chatMessage: "" });

        UserService.sendNotificationToChatUsers(this.props.bandId, localStorage.getItem('userAccount'), localStorage.getItem('userName'))
            .then((response) => console.log(response))
            .catch((err) => console.error(err));

    };

    handleTogglePicker = () => {
        this.setState({ isOpenEmojiPicker: !this.state.isOpenEmojiPicker });
    };

    handleAddEmoji = emoji => {
        const oldMessage = this.state.chatMessage;
        const newMessage = this.colonToUnicode(` ${oldMessage} ${emoji.colons} `);
        this.setState({ chatMessage: newMessage, emojiPicker: false });
        setTimeout(() => this.messageInputRef.focus(), 0);
    };

    closeEmojiPicker = () => {
        this.setState({ isOpenEmojiPicker: !this.state.isOpenEmojiPicker })
    }

    colonToUnicode = message => {
        return message.replace(/:[A-Za-z0-9_+-]+:/g, x => {
            x = x.replace(/:/g, "");
            let emoji = emojiIndex.emojis[x];
            if (typeof emoji !== "undefined") {
                let unicode = emoji.native;
                if (typeof unicode !== "undefined") {
                    return unicode;
                }
            }
            x = ":" + x + ":";
            return x;
        });
    };

    sendTyping = () => {
        this.lastUpdateTime = Date.now()
        if (!this.state.isTyping) {
            this.setState({ isTyping: true })
            this.parentSendTyping(true)
            this.startCheckingTyping()
        }
    }

    /*
    *	startCheckingTyping
    *	Start an interval that checks if the user is typing.
    */
    startCheckingTyping = () => {
        console.log("Typing");
        this.typingInterval = setInterval(() => {
            if ((Date.now() - this.lastUpdateTime) > 1000) {
                this.setState({ isTyping: false })
                this.stopCheckingTyping()
            }
        }, 300)
    }

    /*
    *	stopCheckingTyping
    *	Start the interval from checking if the user is typing.
    */
    stopCheckingTyping = () => {
        console.log("Stop Typing");
        if (this.typingInterval) {
            clearInterval(this.typingInterval)
            this.parentSendTyping(false)
        }
    }

    render() {
        const { chatMessage, isOpenEmojiPicker } = this.state
        return (
            <Row>
                <ChatInputWrapper layout="inline" onSubmit={this.submitChatMessage}>
                    {/* <Col span={4}>
                        <Button
                            style={{ width: '100%', height: '45px' }}
                            onClick={this.handleTogglePicker}
                        >
                            <Icon type="smile" theme="outlined" />
                        </Button>
                    </Col> */}

                    <div style={{ width: '100%' }}>
                        <Input
                            style={{ height: '45px' }}
                            id="message"
                            prefix={<MessageOutlined />}
                            placeholder={this.props.t('New.PleaseEnterMessage')}
                            type="text"
                            ref={(input) => { this.talkInput = input; }}
                            value={this.state.chatMessage}
                            onChange={({ target }) => { this.setState({ chatMessage: target.value }) }}
                            onKeyUp={e => { e.keyCode !== 13 && this.sendTyping() }}
                        />
                    </div>
                    <span style={{ width: '70px' }}>
                        <Dropzone onDrop={this.onDrop}
                            multiple={false}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <Button style={{ width: '100%', height: '45px' }} >
                                        <UploadOutlined  data-tip data-for={`uploadButton`} style={{ fontSize: '1.4em' }} />
                                        <ReactTooltip id={`uploadButton`}>
                                            <span>{this.props.t('New.UploadButton')}</span>
                                        </ReactTooltip>
                                    </Button>
                                </div>
                            )}
                        </Dropzone>
                    </span>
                    <span style={{ width: '100px' }}>
                        <Button
                            type="primary"
                            style={{ width: '100%', height: '45px' }}
                            disabled={chatMessage.length < 1}
                            htmlType="submit"
                        >
                            <EnterOutlined style={{ fontSize: '1.3em' }} />
                        </Button>
                    </span>
                </ChatInputWrapper>

                <Modal
                    isOpen={isOpenEmojiPicker}
                    onRequestClose={this.closeEmojiPicker}
                    contentLabel="Emoji Picker"
                    style={customStyles}

                >
                    <Picker
                        set="apple"
                        onSelect={this.handleAddEmoji}
                        className="emojipicker"
                        title="Pick your emoji"
                        emoji="point_up"
                    />
                </Modal>

                <DialogComp description={`${this.props.t('New.TheFileIsBeingUploaded')}`} open={this.state.isOpenFileTransferProgress} />

            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {
        chats: state.chats
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(ChatInput)));

const ChatInputWrapper = styled.form`
    width: 100%;
    flex-direction: row;
    display: flex;
    @media (max-width: 500px) {
        position: fixed;
        bottom: 55px;
        width: 100%;
    }
`;