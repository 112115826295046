/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Menu } from 'antd';
import btnHamburger from '../../../assets/images/btn_hamburger.svg';
import btnHamburger_hover from '../../../assets/images/btn_hamburger_hover.svg';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import { DB_KIND } from '../../../configs';
import { useTranslation } from 'react-i18next';

type TtoolTipProps = {
    select: (id : string) => void;
}

function BandDisplaySettingToolTip(props: TtoolTipProps) {
    const { t } = useTranslation();

    const handleSelectMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.select(event.currentTarget.id);
    };

    return (
        <Popup trigger={
            <Settings >
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <input aria-label="더보기 버튼" type="button" className="btn-hambuger" />
                </a>
            </Settings>
        } flowing hoverable>
            <Menu>
                <Menu.Item style={{ padding: 0 }}>
                    <button style={{ textAlign: 'center', width: '100%', padding: '8px' }} onClick={handleSelectMenu} id="isUserProfileSettingModalOpen">
                        <span className="ico"></span>{t('BandDisplaySettingToolTip.viewMyProfile')}
                    </button>
                </Menu.Item>
               
                <Menu.Item style={{ padding: 0 }}>
                    <button style={{ textAlign: 'center', width: '100%', padding: '8px' }} onClick={handleSelectMenu} id="isFixListSelectModalOpen">
                        <span className="ico"></span>{t('BandDisplaySettingToolTip.viewFixedList')}
                    </button>
                </Menu.Item>
                <Menu.Item style={{ padding: 0 }}>
                    <button style={{ textAlign: 'center', width: '100%', padding: '8px' }} onClick={handleSelectMenu} id="isHiddenListSelectModalOpen">
                        <span className="ico"></span>{t('BandDisplaySettingToolTip.viewHiddenList')}
                    </button>
                </Menu.Item>
                <Menu.Item style={{ padding: 0 }}>
                    <button style={{ textAlign: 'center', width: '100%', padding: '8px' }} onClick={handleSelectMenu} id="isFileSizeSelectModalOpen">
                        <span className="ico"></span>{t('BandDisplaySettingToolTip.viewFileSize')}
                    </button>
                </Menu.Item>
            </Menu>
        </Popup>
    )
}

export default BandDisplaySettingToolTip


const Settings = styled.button`
    input {
        border: none;
        cursor: pointer;
        height: 32px;
        width: 32px;
        background: url(${btnHamburger});
    }

    input:hover {
        background: url(${btnHamburger_hover});
    }
`;

