import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser, { processNodes } from 'react-html-parser';
import { withRouter } from "react-router-dom";

import PostImageVideoGrid from './PostImageVideoGrid';
import * as BandService from '../../services/BandService';
import * as UserService from '../../services/UserService';
import * as DateTimeUtils from '../../utils/DateTimeUtils';
import * as CommonUtils from '../../utils/CommonUtils';
import * as StringUtils from '../../utils/StringUtils';
import * as HtmlUtils from '../../utils/HtmlUtils';
import * as ReplyService from '../../services/ReplyService';
import { ReplyList } from '../../common/components/Reply';
import ViewState from './ViewStatePage/ViewStatePage';
import BandSkeleton from '../../common/components/Shared/Skeleton/BandSkeleton'
import Modal from 'react-awesome-modal';
import ReactModal from 'react-modal';

import { SimpleEditor } from '../../common/components/Edit';

import { UserProfileModal } from '../../common/components/Modal';
import { AiOutlineArrowRight } from "react-icons/ai";

import { Avatar, message } from 'antd';
import { expressions } from '../../configs';
import { withTranslation } from 'react-i18next';
import DialogComp from '../../common/components/Shared/DialogComp';
import { Popup } from 'semantic-ui-react'

const iconViewCount = require('../../assets/images/icon-view-count.svg');
const NoImage = require('../../assets/images/no_self_img.png');
const iconEmoticon = require('../../assets/images/icon_emotion.svg')
const iconReply = require('../../assets/images/icon_reply.svg')

class PostItem extends React.Component {

    _isMounted = false;

    bandId;
    item;
    band;
    routeFunction;
    isOpened;
    openProfileFunction;

    state = {
        bandId: undefined,
        userId: undefined,
        writer: undefined,
        loading: false,
        skeletonOut: false,
        openExpressionsPopup: false,
        expressionCount: 0,
        expressions: [],
        bandMembers: [],
        replies: [],
        openReplyLists: false
    };

    componentDidMount() {
        this._isMounted = true;
        const bandId = this.props.bandId === undefined ? this.props.band.id : this.props.bandId;
        const userId = this.props.item.createdBy;
        BandService.getSingleBandMember(bandId, userId)
            .then(member => {
                if (this._isMounted) {
                    this.setState({
                        writer: member,
                        bandId: bandId,
                        userId: userId,
                        skeletonOut: true,
                    });
                }
            })
            .catch(error => console.error(error));

        let bandMembers, replies;
        BandService.getBandMembersNode(this.props.bandId, undefined)
            .then(result => bandMembers = result)
            .then(() => ReplyService.getRepliesAtPostNode(bandId, this.props.postId))
            .then(result => replies = result)
            .then(() => ReplyService.getExpressionsAtPostNode(bandId, this.props.postId))
            .then(expression => {
                this.setState({
                    bandMembers: bandMembers,
                    expressions: expression,
                    expressionCount: expression.length,
                    replies: replies
                })
            })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    express = (value) => {
        ReplyService.createExpressionAtPostNode(this.props.bandId, this.props.item.id, value)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        openExpressionsPopup: false,
                    });
                }
            })
            .then(() => {
                ReplyService.getExpressionsAtPostNode(this.props.bandId, this.props.postId)
                    .then(expression => {
                        this.setState({
                            expressions: expression,
                            expressionCount: expression.length
                        })
                    })
            })
            .then(() => {
                message.success(`${this.props.t('PostList.expressionSuccessfullyAdded')}`);
            })
            .catch(error => console.error(error));
    };

    openExpressionsPopup = () => {
        if (this._isMounted) {
            this.setState({
                openExpressionsPopup: true,
            });
        }
    };

    closeExpressionsPopup = () => {
        if (this._isMounted) {
            this.setState({
                openExpressionsPopup: false
            });
        }
    };

    toggleReplyLists = () => {
        if (this._isMounted) {
            this.setState({
                openReplyLists: !this.state.openReplyLists
            });
        }
    }

    createReply = (reply) => {
        const bandId = this.props.bandId !== undefined ? this.props.bandId : this.props.band.id;
        const { postId } = this.props;

        if (reply.length > 230) {
            return message.warning(`${this.props.t('Post.Detail.theCharacterLimitHasBeenExceededPleaseReduceTheNumberOfCharacters')}`)
        }

        let receiveUserAccount = "";
        let sendUserAccount = "";
        let sendUserName = "";

        BandService.getSingleBandMember(bandId, this.state.writer.userId)
            .then(response => {

                UserService.getUserProfileNode(localStorage.getItem('userId'))
                    .then(response => {
                        sendUserAccount = response.ucTalkId
                    })
                    .catch(err => message.error(err))

                UserService.getUserProfileNode(localStorage.getItem('userId'))
                    .then(response => {
                        sendUserName = response.name
                    })
                    .catch(err => message.error(err))

                //받는 사람이 알림 켜놓은지 먼저 확인  replyNotificationType === 0 이 켜져있는것   
                if (response.notifyThroughUcTalk === true
                    && response.replyNotificationType === 0
                    && this.state.writer.userId !== Number(localStorage.getItem('userId'))
                ) {
                    UserService.getUserProfileNode(this.state.writer.userId)
                        .then(response => {
                            receiveUserAccount = response.ucTalkId
                        })
                        .catch(err => message.error(err))
                }

                ReplyService.createReplyAtPostNode(bandId, postId, reply, undefined)
                    .then((response) => { this.setState({ replies: [...this.state.replies, response.data[0]] }) })
                    .then(() => UserService.sendNotificationToPostUser(bandId, postId, receiveUserAccount, sendUserAccount, sendUserName))
                    .catch(error => message.error(error));
            })
    };


    selectPost = (e) => {
        const bandId = this.props.bandId !== undefined ? this.props.bandId : this.props.band.id;
        if (this.props.searchBand) {
            if (this.props.currentUserBandListIds.indexOf(bandId) > -1) {
                return this.props.routeFunction(bandId, e.currentTarget.id);
            }
        }

        if (this.props.isOpened) {
            this.props.routeFunction(bandId, e.currentTarget.id);
        } else {
            this.setState({ loading: true })

            setTimeout(() => {
                this.setState({ loading: false })
                this.props.history.push(`/band/${bandId}`)
            }, 2000);
        }
    };

    selectBand = () => {
        const bandId = this.props.bandId !== undefined ? this.props.bandId : this.props.band.id;
        this.props.routeFunction(bandId);
    };

    openUserProfileModal = () => {
        if (this.props.openProfileFunction) {
            this.props.openProfileFunction(this.state.userId, this.state.bandId);
        }
    };

    bandMemberProfile = (id) => {
        let found = this.state.bandMembers.filter(bandMember => bandMember.userId === id);
        if (found && found.length > 0) {
            // return found[0].profileImage ? `${SERVER_URI}:8061/resource/profile/${found[0].profileImage}` : NoImage;
            return found[0].profileImage ? `${found[0].profileImage}` : NoImage;
        }
        return NoImage;
    };

    render() {
        const { item, band, t, postId } = this.props;
        const { writer, expressionCount, replies, openReplyLists } = this.state;
        const bandId = this.props.bandId === undefined ? this.props.band.id : this.props.bandId;

        const avatar = writer && !StringUtils.isNullOrUndefined(writer.profileImage) ?
            `${writer.profileImage}` : NoImage;

        let content = item && item.content ? item.content : "";

        let imageAndVideos = [];
        const images = HtmlUtils.extractSrcFromImgTag(content);
        images.forEach(image => imageAndVideos.push({ type: "image", src: image }));
        const videos = HtmlUtils.extractSrcFromVideoTag(content);
        videos.forEach(video => imageAndVideos.push({ type: "video", src: video }));

        content = HtmlUtils.removeAllImgTag(content);
        content = HtmlUtils.removeAllVideoTag(content);
        content = HtmlUtils.removeEmptyParagraph(content);

        const paragraphs = HtmlUtils.extractThreeParagraphs(content);
        content = "";
        paragraphs.forEach(paragraph => {
            content += paragraph;
        });

        content = content.split('<p>').join('<p class="paragraph">')
            .split('</p>').join('</p>');

        const transform = (node, index) => {
            if (node.type === 'tag' && node.name === 'p') {
                if (node.attribs && node.attribs.class && (node.attribs.class === 'poll-inner-post')) {
                    const pollId = node.attribs.id;
                    const poll = this.props.item.polls[pollId];
                    const userId = this.props.item.createdBy;
                    let votedMember = [];
                    poll && poll.pollOptions.forEach(pollOption => {
                        pollOption.voted.forEach(vote => {
                            if (!CommonUtils.contains(votedMember, vote)) {
                                votedMember.push(vote);
                            }
                        });
                    });

                    if (poll) {
                        return <PollWrapper key={index}>
                            <PollTitle>
                                {poll.title}
                            &nbsp;&nbsp;
                            <span>{`${votedMember.length}${this.props.t('PostList.participants')}`}</span>
                            </PollTitle>
                            {poll.pollOptions.map(pollOption => {
                                const checked = CommonUtils.contains(pollOption.voted, userId) ? "checked" : "";
                                return <PollOption key={pollOption.id}>
                                    {poll.allowMultipleSelection ?
                                        <input type="checkbox" disabled={true}
                                            checked={checked}
                                        /> :
                                        <input type="radio" disabled={true}
                                            checked={checked}
                                        />}
                                &nbsp;{pollOption.content}
                                    {pollOption.voted.length > 0 ? `- ${pollOption.voted.length}${this.props.t('PostList.votes')}` : ""}
                                </PollOption>
                            })}
                        </PollWrapper>
                    } else {
                        return <div key={index}></div>
                    }

                } else if (node.attribs && node.attribs.classname && (node.attribs.classname === 'file-inner-post')) {
                    return <FileWrapper key={index}>
                        {processNodes(node.children, transform)}
                    </FileWrapper>
                }
            }

            if (node.type === 'tag' && node.name === 'p' && node.children && node.children[0] && node.children[0].data) {
                if (CommonUtils.contains(node.children[0].data, '#')) {
                    return <p className="paragraph" key={index}>
                        {node.children[0].data.split(" ").map((word, index) => {
                            if (CommonUtils.startsWith(word, "#")) {
                                return index === 0 ? <a href={word} key={index} className="rdw-hashtag-link">{word}</a> :
                                    <a href={word} key={index} className="rdw-hashtag-link"> {word}</a>;
                            } else {
                                return index === 0 ? <span key={index}>{word}</span> : <span key={index}> {word}</span>;
                            }
                        })}
                    </p>
                }
            }

            if (node.type === 'tag' && node.name === 'a' && node.attribs.className === "file-link-inner-post") {
                return <b key="converted-file">{node.children[0].children[0].data}</b>;
            }
        };

        const options = {
            decodeEntities: true,
            transform: transform
        };

        content = content.replace("<p class=\"paragraph\"></p><p class=\"paragraph\">귀하의 브라우저는 html5 video를 지원하지 않습니다.</p>", "");
        content = content.replace("<p class=\"paragraph\">귀하의 브라우저는 html5 video를 지원하지 않습니다.</p>", "");

        const rendered = content ? ReactHtmlParser(content, options) : undefined;

        const writerName = writer ? writer.name : "";
        const writerJobTitle = writer ? writer.jobTitle : "";

        const expressionContent = (
            <Expressions>
                {this.state.expressions.map((expression, index) =>
                    <ExpressionWrapper key={`expression-${index}`}>
                        <ExpressionUser src={this.bandMemberProfile(expression.userId)} alt={`user-${expression.userId}`} />
                        <Expression src={expressions[expression.expression].icon} alt={expressions[expression.expression].description} />
                    </ExpressionWrapper>
                )}
            </Expressions>
        );

        const seenContent = (
            <ViewState
                bandId={bandId}
                postId={postId}
            />
        )

        if (this.state.skeletonOut === false) {
            return (<><BandSkeleton /><BandSkeleton /></>)
        } else {
            return (
                <>
                    <div className="singleCardWrap">
                        {band &&
                            <Band onClick={this.selectBand}>
                                {band && <div>{band.name}</div>}
                            </Band>
                        }
                        <div style={{ border: '1px solid rgb(219, 219, 219)' }}>

                            <div className="cardHeader">
                                <span className="writerThumb">
                                    <Avatar src={avatar} size={50} alt={writerName}
                                    // onClick={this.openUserProfileModal}
                                    />
                                </span>
                                <div className="cardInfo" style={{ width: '100%' }}>
                                    <div className="writerNamePosition" onClick={this.openUserProfileModal}>{writerName}
                                        {!StringUtils.isNullOrUndefined(writerJobTitle) &&
                                            <span className="position">
                                                {writerJobTitle}
                                            </span>
                                        }
                                    </div>
                                    {/*
                                  // 원래는 아래처럼 해야 한다 하지만 다르게 한 이유는 이 포스트가 만들어진 시간을 나타내는게 아닌 
                                  // 이 포스트를 임의로 공개하는 시간을 나타내기 위해서 이렇게 쓰는것임
                                 <div className="date">{DateTimeUtils.dateTimeDisplay(item.createdAt)}</div> 
                                 <div className="date">{DateTimeUtils.formattedForDateTimeDisplay(item.postDateTime)}</div>
                                */}
                                    <div className="date">{DateTimeUtils.dateTimeDisplay(item.createdAt)}</div>
                                </div>
                                <button style={{ padding: '1rem' }} className="routeToPost" aria-label={`${item.id}의 글로 이동합니다.`} id={item.id} onClick={this.selectPost}>
                                    <AiOutlineArrowRight />
                                </button>
                            </div>

                            <Content id={item.id} onClick={this.selectPost}>
                                {/* {!rendered ? <p style={{ margin: '1rem' }}>표가 작성 되었습니다.</p> : rendered} */}
                                {rendered}
                                <PostImageVideoGrid items={imageAndVideos} />
                            </Content>

                            <Replies>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{marginRight:'12px'}}>
                                        {this.state.expressions.length > 0 ?
                                            <Popup
                                                trigger={<Like> {t('PostList.expression')}&nbsp;&nbsp;{expressionCount}</Like>}
                                                basic
                                            >
                                                {expressionContent}
                                            </Popup>
                                            :
                                            <Like> {t('PostList.expression')}&nbsp;&nbsp;{expressionCount}</Like>
                                        }
                                    </div>
                                    <div>
                                        <Reply>{t('PostList.comment')}&nbsp;{item.replyCount}</Reply>
                                    </div>
                                    <div style={{marginLeft:'auto'}}>
                                        {item.viewCount === 0 ?
                                            <Seen>
                                                <img src={iconViewCount} className="icon-view-count" alt="View" />
                                                &nbsp;&nbsp;{item.viewCount}
                                            </Seen>
                                            :
                                            <Popup
                                                trigger={<Seen>
                                                    <img src={iconViewCount} className="icon-view-count" alt="View" />
                                                &nbsp;&nbsp;{item.viewCount}
                                                </Seen>}
                                                flowing hoverable
                                            >
                                                {seenContent}
                                            </Popup>
                                        }
                                    </div>
                                </div>
                            </Replies>

                            <div className="cardAction">
                                <button onClick={this.openExpressionsPopup} className="addEmotion" id={item.id}>
                                    <img style={{ marginBottom: '-2px', marginRight: '3px' }} src={iconEmoticon} alt="icon-emoticon" /> {t('PostList.makeExpression')}</button>
                                <button onClick={this.toggleReplyLists} className="addReply" id={item.id}><img src={iconReply} style={{ marginBottom: '-2px', marginRight: '3px' }} alt="icon-reply" /> {t('PostList.writeAComment')}</button>
                                <div className="clearfix"></div>
                            </div>
                        </div>


                        <Modal visible={this.state.openExpressionsPopup} onClickAway={() => this.closeExpressionsPopup()}>
                            <div style={{ padding: '1rem 1rem 1rem 30px' }}>
                                {expressions.map(expression =>
                                    <span key={`express-${expression.id}`}>
                                        <img src={expression.icon} alt={expression.description} onClick={() => this.express(expression.id)} style={{display:'inline-block'}} />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                )}
                            </div>
                        </Modal>

                        <DialogComp description={t('PostList.thisIsThePostWhereYouHaveNotSignedUp')} open={this.state.loading} />
                    </div>
                    {openReplyLists && replies.length > 0 &&
                        <ReplyListSpace>
                            <ReplyList fromPostList postId={postId} bandId={bandId} replyList={replies} />
                        </ReplyListSpace>
                    }
                    {openReplyLists &&
                        <ReplyInputArea>
                            <SimpleEditor fromPostList placeholder={t('Post.Detail.writeAComment')} onSubmit={this.createReply} />
                        </ReplyInputArea>
                    }
                </>
            );
        }
    }
}

const ReplyInputArea = styled.div`
    padding: 1rem;
    border-right: 1px solid rgb(219, 219, 219);
    border-left: 1px solid rgb(219, 219, 219);
    border-bottom: 1px solid rgb(219, 219, 219);
    border-radius: 4px;
    background-color: white;
    margin-top: -20px;
    `;

const ReplyListSpace = styled.div`
    padding: 1rem;
    margin-top: -20px;
    border-right: 1px solid rgb(219, 219, 219);
    border-left: 1px solid rgb(219, 219, 219);
    border-radius: 4px;
    background-color: white;
    `;

const Expressions = styled.div`
    padding: 0.3rem 1rem;
    font-size: 0.8rem;
    color: #8e8e93;
    display: flex;
    flex-grow: 1;
`;

const ExpressionWrapper = styled.span`
    margin-left: 1rem;
`;

const ExpressionUser = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
`;

const Expression = styled.img`
    width: 1.2rem;
    border-radius: 50%;
    margin-left: -.5rem;
    height: 1.2rem;
`;

class PostList extends React.Component {

    _isMounted = false;

    bandId;
    postList;
    bandList;
    routeFunction;
    isOpened;

    state = {
        isUserProfileModalOpen: false,
        profileImage: NoImage,
        nickname: undefined,
        name: undefined,
        jobTitle: undefined,
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    openUserProfileModal = (userId, bandId = null) => {
        if (this._isMounted) {

            let userProfile, userProfileImage;
            UserService.getUserProfileNode(userId)
                .then(user => userProfile = user)
                .then(() => {
                    if (!StringUtils.isNullOrUndefined(userProfile.profileImage)) {
                        userProfileImage = `${userProfile.profileImage}`;
                    } else {
                        userProfileImage = NoImage;
                    }
                    if (this._isMounted) {

                        this.setState({
                            isUserProfileModalOpen: true,
                            profileImage: userProfileImage,
                            nickname: "닉네임",
                            name: userProfile.name,
                            jobTitle: userProfile.jobTitle,
                            employeeNumber: userProfile.employeeNumber
                        })
                    }
                })
                .catch(error => console.error(error));
        }
    };

    closeUserProfileModal = () => {
        if (this._isMounted) {
            this.setState({
                isUserProfileModalOpen: false,
                profileImage: NoImage,
                nickname: undefined,
                name: undefined,
                jobTitle: undefined,
            });
        }
    };

    render() {
        const { postList, bandList, routeFunction, isOpened, newsFeed, search, searchBand, currentUserBandListIds, t } = this.props;
        const {
            // isUserProfileModalOpen,  nickname, 
            profileImage, name, jobTitle } = this.state;

        return (
            <>
                {postList.map((item, i) => (
                    <PostItem key={i}
                        t={t}
                        postId={item.id}
                        item={item}
                        currentUserBandListIds={currentUserBandListIds}
                        bandId={item.bandId}
                        band={bandList && bandList.filter(band => band.id === item.bandId)[0]}
                        routeFunction={routeFunction}
                        //newFeed에서 오는 것은 모두다 자기가 멤버이기에 클릭 가능하게 하기
                        isOpened={newsFeed || search ? true : isOpened}
                        openProfileFunction={this.openUserProfileModal}
                        history={this.props.history}
                        searchBand={searchBand}
                    />
                ))}
                <ReactModal
                    isOpen={this.state.isUserProfileModalOpen}
                    onRequestClose={this.closeUserProfileModal}
                    style={CustomStyles}
                    contentLabel="User Profile Modal"
                >
                    <UserProfileModal
                        profileImage={profileImage}
                        name={name}
                        jobTitle={jobTitle}
                        closeUserProfileModal={this.closeUserProfileModal}
                        show={this.state.isUserProfileModalOpen}
                        onClose={this.closeUserProfileModal} />
                </ReactModal>
            </>
        );
    }
}


const Band = styled.div`
    padding: 0.6rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    border: 1px solid rgb(219, 219, 219);
    border-bottom:0;
`;

const Content = styled.div`
    font-size: 1rem;
    img {
        width: 100%;
        display: inline;
        object-fit: cover;
    }
    .paragraph {
        padding: 0 1rem 1rem 1rem;
        margin: 0;
        cursor: pointer;
    }
    .seeMore{
        font - size: 1rem;
        padding: 1rem;
        font-weight: 300;
        color: gray;
        cursor: pointer;
    }
`;

const Replies = styled.div`
    padding: 8px 16px;
    font-size: 0.8rem;
    color: #8e8e93;
    border-bottom: 1px solid #DBE5EB;
`;

const Like = styled.div`
    text-align: left;
    cursor: pointer;
`;

const Reply = styled.div`
    text-align: left;
`;

const Seen = styled.div`
    text-align: right;
    cursor: pointer;
    img {
                            width: 0.8rem;
    }
`;

const FileWrapper = styled.div`
    padding: 1rem;
    background-color: #eee;
    font-weight: normal;
`;

const PollWrapper = styled.div`
    padding: 1rem;
`;

const PollTitle = styled.div`
    background-color: #eee;
    font-weight: bold;
    padding: .5rem;
    margin-bottom: .5rem;

    span {
        font - weight: normal;
    }
`;

const PollOption = styled.div`
    padding: .2rem .5rem;
`;

export default withRouter(withTranslation()(PostList));
const CustomStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    }, overlay: { zIndex: 1000 }
};