import * as HttpHandler from '../utils/HttpHandler';
import * as StringUtils from '../utils/StringUtils';
import { SERVER_URI, BODY_JSON } from '../configs';
import axios from 'axios';

export function deletePostNode(bandId, postId) {

    return axios.delete(`${SERVER_URI}:5000/api/post?bandId=${bandId}&postId=${postId}`)

}

export function updatePostViewMember(postId) {
    const body = {
        postId,
        userId: localStorage.getItem('userId')
    }
    return axios.post(`${SERVER_URI}:5000/api/post/viewMember`, body)
}

export function createPostNode(bandId, content, polls, tags, postDateTime, isNotice, noticeEndDateTime, isImportantNotice) {
    const body = {
        content: content,
        polls: polls,
        tags: tags,
        postDateTime: postDateTime,
        isNotice: isNotice,
        noticeStartDateTime: postDateTime,
        noticeEndDateTime: noticeEndDateTime,
        isImportantNotice: isImportantNotice,
        createdBy: localStorage.getItem('userId')
    };
    return axios.post(`${SERVER_URI}:5000/api/post?bandId=${bandId}`, body)
        .then(response => { return response.data })
}

export function modifyPostNode(bandId, postId, content, polls, tags, isNotice, isImportantNotice) {
    const body = {
        content: content,
        polls: polls,
        tags: tags,
        isNotice: isNotice,
        isImportantNotice: isImportantNotice,
        createdBy: localStorage.getItem('userId')
    };
    return axios.put(`${SERVER_URI}:5000/api/post?bandId=${bandId}&postId=${postId}`, body)
}

export function voteNode(bandId, postId, pollId, pollOptionId, type) {

    let body = {
        votedBy: localStorage.getItem('userId')
    }

    return axios.post(`${SERVER_URI}:5000/api/post/vote?bandId=${bandId}&postId=${postId}&pollId=${pollId}&pollOptionId=${pollOptionId}&type=${type}`, body)

}

export function voteCancelNode(bandId, postId, pollId, pollOptionId) {

    return axios.delete(`${SERVER_URI}:5000/api/post/vote?bandId=${bandId}&postId=${postId}&pollId=${pollId}&pollOptionId=${pollOptionId}&votedBy=${localStorage.getItem('userId')}`);

}

export function setAsNoticeNode(bandId, postId, noticeStartDateTime) {
    const body = {
        noticeStartDateTime: noticeStartDateTime
    };

    return axios.post(`${SERVER_URI}:5000/api/post/notice?bandId=${bandId}&postId=${postId}`, body)
}

export function removeFromNoticeNode(bandId, postId) {

    return axios.delete(`${SERVER_URI}:5000/api/post/notice?bandId=${bandId}&postId=${postId}`)

}

export function countOfImportantNoticesNode(bandId, current) {

    return axios.get(`${SERVER_URI}:5000/api/post/importantCount?bandId=${bandId}&current=${current}`)
        .then(response => { return response.data.total })

}

export function getReadMembersNode(bandId, postId) {

    return axios.get(`${SERVER_URI}:5000/api/post/viewMember?bandId=${bandId}&postId=${postId}`)
        .then(response => { return response.data })

}

export function completePollNode(bandId, postId, pollId, dueDateTime) {
    const body = {
        dueDateTime: dueDateTime
    };
    return axios.post(`${SERVER_URI}:5000/api/post/polls/complete?bandId=${bandId}&postId=${postId}&pollId=${pollId}`, body)
}

export function getAllBandNoticeListNode(bandId) {

    return axios.get(`${SERVER_URI}:5000/api/post/notices?bandId=${bandId}`)
        .then(response => { return response.data })

}

export function getBandNoticeListNode(bandId, current) {

    return axios.get(`${SERVER_URI}:5000/api/post/notices?bandId=${bandId}&size=2&current=${current}`)
        .then(response => { return response.data })

}

export function getBandPostListNode(bandId, current, page, size) {

    return axios.get(`${SERVER_URI}:5000/api/post/posts?bandId=${bandId}&current=${current}&page=${page}&size=${size}`)
        .then(response => { return response.data })

}

export function getPostNode(bandId, postId) {

    return axios.get(`${SERVER_URI}:5000/api/post?bandId=${bandId}&postId=${postId}`)
        .then(response => { return response.data })

}

export function getUserBandsPostListNode(current, page, size, userBandIds) {

    return axios.get(`${SERVER_URI}:5000/api/post/allPostsByCurrentUser?current=${current}&page=${page}&size=${size}&userBandIds=${encodeURI(JSON.stringify(userBandIds))}`)
        .then(response => { return response.data })

}

export function searchPostByBandNode(bandId, keyword, current, page, size) {
    let url = `${SERVER_URI}:5000/api/post/posts?bandId=${bandId}&keyword=${encodeURIComponent(keyword)}&current=${current}&page=${page}&size=${size}&type=searchByBand`;
    return axios.get(url).then(response => { return response.data })
}

export function searchPostNode(bandCategoryId, keyword, current, page, size) {
    let url = `${SERVER_URI}:5000/api/post/posts?current=${current}&page=${page}&size=${size}&type=search`;
    if (!StringUtils.isNullOrUndefined(keyword)) {
        url = url + `&keyword=${encodeURIComponent(keyword)}`;
    }

    return axios.get(url).then(response => { return response.data })
}

export function copyPost(bandId, postId, targetBandId, postDateTime) {
    const body = {
        targetBandId: targetBandId,
        postDateTime: postDateTime
    };
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/posts/${postId}/copy`,
            'POST',
            BODY_JSON,
            body,
        );
}

// export function getBandTags(bandId) {
//     return HttpHandler
//         .httpRequest(
//             `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/tags`,
//             'GET',
//             BODY_JSON,
//             undefined,
//         );
// }

// 필요없는 기능인것 같아서 우선 comment 처리 
// export function searchPostByBandAndUser(bandId, userId, current, page, size) {
//     let url = `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/posts?userId=${userId}&current=${current}`;
//     if (page !== undefined && size !== undefined) {
//         url = url + `&page=${page}&size=${size}`;
//     }
//     return HttpHandler
//         .httpRequest(
//             url,
//             'GET',
//             BODY_JSON,
//             undefined,
//         );
// }