import { SERVER_URI } from '../configs';
import axios from 'axios';

export function globalSettingsNode() {

    return axios.get(`${SERVER_URI}:5000/api/setting/global`)
        .then(response => {
            if (response.data) {
                localStorage.setItem('maxBandNameLength', response.data.maxBandNameLength)
                localStorage.setItem('maxBandDescriptionLength', response.data.maxBandDescriptionLength)
                localStorage.setItem('maxBandCoverImageSize', response.data.maxBandCoverImageSize)
                localStorage.setItem('maxProfileImageSize', response.data.maxProfileImageSize)
                localStorage.setItem('maxPostContentLength', response.data.maxPostContentLength)
                localStorage.setItem('maxFileSize', response.data.maxFileSize)
                localStorage.setItem('maxDiskUsage', response.data.maxDiskUsage)
                localStorage.setItem('maxPersonalDiskUsage', response.data.maxPersonalDiskUsage)
                localStorage.setItem('maxChatMessageLength', response.data.maxChatMessageLength)
            } else {
                localStorage.setItem('maxBandNameLength', 10)
                localStorage.setItem('maxBandDescriptionLength', 255)
                localStorage.setItem('maxBandCoverImageSize', 102400)
                localStorage.setItem('maxProfileImageSize', 102400)
                localStorage.setItem('maxPostContentLength', 1000)
                localStorage.setItem('maxFileSize', 52428800)
                localStorage.setItem('maxDiskUsage', 53687091200)
                localStorage.setItem('maxPersonalDiskUsage', 2147483648)
                localStorage.setItem('maxChatMessageLength', 255)
            }
        });
}

export function updateGlobalSettingsNode(maxBandNameLength, maxBandDescriptionLength,
    maxBandCoverImageSize, maxProfileImageSize, maxPostContentLength,
    maxFileSize, maxChatMessageLength, maxDiskUsage, maxPersonalDiskUsage) {
    const body = {
        maxBandNameLength: maxBandNameLength,
        maxBandDescriptionLength: maxBandDescriptionLength,
        maxBandCoverImageSize: maxBandCoverImageSize * 1024 * 1024,
        maxProfileImageSize: maxProfileImageSize * 1024 * 1024,
        maxPostContentLength: maxPostContentLength,
        maxFileSize: maxFileSize * 1024 * 1024,
        maxChatMessageLength: maxChatMessageLength,
        maxDiskUsage: maxDiskUsage * 1024 * 1024 * 1024,
        maxPersonalDiskUsage: maxPersonalDiskUsage * 1024 * 1024 * 1024
    };

    return axios.put(`${SERVER_URI}:5000/api/setting/global`, body)
        .then(response => {
            if (response.data) {
                localStorage.setItem('maxBandNameLength', response.data.maxBandNameLength)
                localStorage.setItem('maxBandDescriptionLength', response.data.maxBandDescriptionLength)
                localStorage.setItem('maxBandCoverImageSize', response.data.maxBandCoverImageSize)
                localStorage.setItem('maxProfileImageSize', response.data.maxProfileImageSize)
                localStorage.setItem('maxPostContentLength', response.data.maxPostContentLength)
                localStorage.setItem('maxFileSize', response.data.maxFileSize)
                localStorage.setItem('maxDiskUsage', response.data.maxDiskUsage)
                localStorage.setItem('maxChatMessageLength', response.data.maxChatMessageLength)
                localStorage.setItem('maxPersonalDiskUsage', response.data.maxPersonalDiskUsage)
            }
        });
}

export function userSettingsNode() {
    const userId = localStorage.getItem('userId')

    return axios.get(`${SERVER_URI}:5000/api/setting/userSetting?userId=${userId}`)
        .then(response => {
            if (response.data) {
                localStorage.setItem('viewOrderType', response.data.viewOrderType)
                localStorage.setItem('viewType', response.data.viewType)
            } else {
                localStorage.setItem('viewOrderType', 0)
                localStorage.setItem('viewType', 0)
            }
        });
}

export function updateUserViewOrderTypeNode(viewOrderType) {
    const userId = localStorage.getItem('userId')

    const body = {
        viewOrderType,
        userId
    };

    return axios.put(`${SERVER_URI}:5000/api/setting/viewOrderType`, body)
}