import React, { Component } from "react";
import io from "socket.io-client";
import { connect } from "react-redux";
import { getInitialChatMessages, addChatMessage } from "../../_actions/chat_actions";
import { withTranslation } from 'react-i18next';

import { SOCKET_SERVER_URI } from '../../configs';
import * as BandService from '../../services/BandService';
// import DesktopHeader from "../../hoc/DesktopHeader";
import { BandTopButtons, BandMenu } from '../Menu';
import { withRouter } from 'react-router-dom';

import ChatPanel from './Sections/ChatPanel';
import ChatInput from './Sections/ChatInput';
// import ChatInfo from './Sections/ChatInfo';
import ChatSearch from './Sections/ChatSearch';
// import styled from "styled-components";

const socketUrl = SOCKET_SERVER_URI
class Chat extends Component {
    state = {
        bandId: this.props.match.params["bandId"],
        isBandMember: undefined,
        peopleWhoAreTyping: [],
        roomUsers: []
    };

    componentDidMount() {
        this.loadBandInfo();
        this.socket = io(socketUrl, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: Infinity,
            transports: ["polling", "websocket"],
            secure: true,
            rejectUnauthorized: false,
            forceNew: false,
            timeout: 60000
        });

        // this.socket.on('pong', (latency) => {
        //     console.log('latency', latency)
        // });

        let username = localStorage.getItem('ucTalkId');

        let { bandId, peopleWhoAreTyping } = this.state;

        this.socket.emit('joinRoom', { username, room: bandId });

        this.socket.on(`Output Chat Message`, messageFromBackEnd => {
            this.props.dispatch(addChatMessage(messageFromBackEnd));
        });

        this.socket.on(`roomUsers`, roomUsers => {
            this.setState({ roomUsers })
        })

        this.socket.on(`Output Typing`, typingFromBackEnd => {
            if (Number(localStorage.getItem('userId')) !== Number(typingFromBackEnd.userID)) {
                if (typingFromBackEnd.isTyping === true) {
                    let IsPersonContained = (peopleWhoAreTyping.indexOf(typingFromBackEnd.userName) > -1)
                    if (!IsPersonContained) {
                        this.setState({
                            peopleWhoAreTyping:
                                [...peopleWhoAreTyping, typingFromBackEnd.userName]
                        })
                    }
                } else {
                    const index = this.state.peopleWhoAreTyping.indexOf(typingFromBackEnd.userName)
                    let newPeopleWhoAreTyping = [...peopleWhoAreTyping];
                    if (index > -1) {
                        newPeopleWhoAreTyping.splice(0, 1);
                    }
                    this.setState({
                        peopleWhoAreTyping: newPeopleWhoAreTyping
                    })
                }
            }
        });
    }

    loadBandInfo = () => {
        BandService.getBandInfo(this.state.bandId)
            .then(result => {
                console.log('result', result)
                this.setState({
                    band: result.band,
                    isBandMember: result.isBandMember,
                    member: result.bandMember,
                    members: result.bandMembers,
                });
            }, this.props.dispatch(getInitialChatMessages(this.state.bandId)))
            .catch(error => console.error(error));
    };


    render() {
        let { bandId, isBandMember,
            // peopleWhoAreTyping, roomUsers
        } = this.state;
        const { history } = this.props;
        const bandName = this.state.band ? this.state.band.name : '';

        return (
            <div className="wrapper">
                <BandTopButtons bandId={this.state.bandId} bandName={bandName} fromPage={this.props.t('New.Conversation')} historyProp={history} />
                <div className="contentsWrapSpacer" />
                <div className="contentsWrap">

                    {isBandMember &&
                        <>
                            {/* <ChatInfo peopleWhoAreTyping={peopleWhoAreTyping} roomUsers={roomUsers} /> */}
                            <ChatSearch bandId={bandId} />
                        </>
                    }

                    <ChatPanel bandId={bandId} isBandMember={isBandMember} />

                    <ChatInput bandId={bandId} socket={this.socket} />
                </div>

                <BandMenu pathName="chat" bandId={this.state.bandId} historyProp={history} />
            </div>
        );
    }
}

export default connect()(withRouter(withTranslation()(Chat)));

// const ChatWrapper = styled.section`
//     background: #FAFAFA;
//     height: 100% !important;
//     min-height: 100vh !important;
// `;
