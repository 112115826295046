import React, { useState, useEffect } from "react";
import axios from "axios";
import { Tree } from "antd";
import { SERVER_URI } from '../../../../configs';

// attach children
const _get_new_tree = ({ list, key, new_children }) =>
    list.map((node) => {
        // first depth searching
        if (node.key === key) {
            return {
                ...node,
                child_cnt: new_children.length,
                children: new_children,
            };
            // children depth searching
        } else if (node.children) {
            return {
                ...node,
                children: _get_new_tree({
                    list: node.children,
                    key: key,
                    new_children: new_children,
                }),
            };
        }
        return node;
    });

const _get_child = async ({ class_org_group_code, class_group_code }) => {
    const {
        data: { result_set },
    } = await axios.get(
        `${SERVER_URI}:5000/api/tree/child?class_org_group_code=${class_org_group_code}&class_group_code=${class_group_code}`
    );
    //result_set은 새로 불러오는 것들의 Array 
    return result_set.map((v, i) => ({
        ...v,
        title: v.class_kind === `2` ? v.class_group_name : v.class_user_name,
        key: v.class_id,
        user_name: v.user_name ? v.user_name : null,
        isLeaf: v.child_cnt === 0 ? true : false,
    }));
};

const TreeComponent = ({ class_org_group_code, onCheck, checkedKeys }) => {
    // set initial tree
    const [tree_data, _set_tree_data] = useState(() => {
        const result = [{ title: "", key: 0 }];
        return result;
    });

    // fetching root
    useEffect(() => {

        const _get_root = async () => {
            const {
                data: {
                    result_set: [result],
                },
            } = await axios.get(
                `${SERVER_URI}:5000/api/tree/root?class_org_group_code=${class_org_group_code}`
            );
            console.log('result', result)

            const root = {
                ...result,
                title: result ? result.class_group_name : "",
                key: result ? result.class_id : 0,
                isLeaf: result && result.child_cnt === 0 ? true : false,
            };
            _set_tree_data([root]);
        };
        _get_root();
    }, [class_org_group_code]);

    // load children
    const _load_data = ({ key, children, isLeaf, class_group_code }) => {
        console.log('load func', key, children, isLeaf, class_group_code)
        return new Promise(async (resolve) => {
            // avoid duplicated axios call
            if (children) {
                resolve();
                return;
                // if the node yet to load chilren, execute axios call.
            } else if (!isLeaf) {
                const new_children = await _get_child({
                    class_org_group_code,
                    class_group_code,
                });
                // update tree
                _set_tree_data((origin) =>
                    _get_new_tree({
                        list: origin,
                        key: key,
                        new_children,
                    })
                );
            }
            resolve();
        });
    };

    const onSelect = (selectedKeys, info) => {
        console.log('onSelect', info);
    };

    return (
        <>
            {/* don't use tree caching  */}
            {tree_data.length ? (
                <Tree
                    loadData={_load_data}
                    treeData={tree_data}
                    draggable
                    blockNode
                    checkable
                    checkedKeys={checkedKeys}
                    // onDrop={_on_drop}
                    onCheck={onCheck}
                    onSelect={onSelect}
                />
            ) : (
                    "loading tree"
                )}
        </>
    );
};
export default TreeComponent;





    // ///////////////////////////Start Line For Move///////////////////////
    // /////////////////////////////////////////////////////////////////////

    // // find node (promise)
    // // list is lexically binded with treedata
    // const _find = (list, key) =>
    //     new Promise((resolve) => {
    //         for (let i = 0; i < list.length; i++) {
    //             if (list[i].key === key) {
    //                 resolve({ v: list[i], i: i, list: list });
    //             }
    //             if (list[i].children) {
    //                 _find(list[i].children, key).then((result) => {
    //                     if (result) resolve(result);
    //                 });
    //             }
    //         }
    //     });

    // // align list's order as 1 to n
    // const _align = (list) =>
    //     list.map((v, i) => ({
    //         ...v,
    //         class_order_no: i,
    //     }));

    // // syncronize order with database
    // const _sync = async (list) => {
    //     const class_list = list.map((v) => ({
    //         class_id: v.class_id,
    //         class_order_no: v.class_order_no,
    //     }));

    //     await axios.put(`${SERVER_URI}:5000/api/tree/order`, {
    //         list: class_list,
    //     });
    // };

    // const _need_to_move = (parent, child, drop_position) =>
    //     parent.class_upper_group_code !== child.class_upper_group_code ||
    //         drop_position === 0
    //         ? true
    //         : false;

    // // update child's class info moving into other parent
    // const _move = async (parent, child, drop_position) => {
    //     if (!_need_to_move(parent, child, drop_position)) return false;

    //     let obj = {
    //         class_id: child.class_id,
    //     };

    //     if (drop_position === 0) {
    //         if (child.class_kind === "2") {
    //             obj = {
    //                 ...obj,
    //                 class_group_code: child.class_group_code,
    //                 class_group_name: child.class_group_name,
    //                 class_upper_group_code: parent.class_group_code,
    //                 class_upper_class_id: parent.class_id,
    //             };
    //         } else {
    //             obj = {
    //                 ...obj,
    //                 class_group_code: parent.class_group_code,
    //                 class_group_name: parent.class_group_name,
    //                 class_upper_group_code: parent.class_group_code,
    //                 class_upper_class_id: parent.class_id,
    //             };
    //         }
    //     } else {
    //         if (parent.class_kind === "2") {
    //             if (child.class_kind === "2") {
    //                 obj = {
    //                     ...obj,
    //                     class_group_code: child.class_group_code,
    //                     class_group_name: child.class_group_name,
    //                     class_upper_group_code: parent.class_upper_group_code,
    //                     class_upper_class_id: parent.class_upper_class_id,
    //                 };
    //             } else {
    //                 obj = {
    //                     ...obj,
    //                     class_group_code: parent.class_upper_group_code,
    //                     class_group_name: parent.class_upper_group_name,
    //                     class_upper_group_code: parent.class_upper_group_code,
    //                     class_upper_class_id: parent.class_upper_class_id,
    //                 };
    //             }
    //         } else {
    //             if (child.class_kind === "2") {
    //                 obj = {
    //                     ...obj,
    //                     class_group_code: child.class_group_code,
    //                     class_group_name: child.class_group_name,
    //                     class_upper_group_code: parent.class_upper_group_code,
    //                     class_upper_class_id: parent.class_upper_class_id,
    //                 };
    //             } else {
    //                 obj = {
    //                     ...obj,
    //                     class_group_code: parent.class_upper_group_code,
    //                     class_group_name: parent.class_upper_group_name,
    //                     class_upper_group_code: parent.class_upper_group_code,
    //                     class_upper_class_id: parent.class_upper_class_id,
    //                 };
    //             }
    //         }
    //     }

    //     child.class_group_code = obj.class_group_code;
    //     child.class_group_name = obj.class_group_name;
    //     child.class_upper_group_code = obj.class_upper_group_code;
    //     child.class_upper_class_id = obj.class_upper_class_id;

    //     await axios.patch(`${SERVER_URI}:5000/api/tree/child`, obj);
    // };

    // // validation check if you drop something to user
    // const _vc_drop_to_user = async (
    //     tree_data_replica,
    //     drop_key,
    //     drop_position
    // ) => {
    //     const { v } = await _find(tree_data_replica, drop_key);
    //     return v.class_kind === "1" && drop_position === 0 ? true : false;
    // };

    // // drop event
    // const _on_drop = async (info) => {
    //     const {
    //         dropToGap,
    //         dragNode: {
    //             props: { eventKey: drag_key },
    //         },
    //         node: {
    //             props: { eventKey: drop_key, pos: drop_pos },
    //         },
    //     } = info;
    //     const drop_pos_arr = drop_pos.split("-");
    //     // drop position === -1 : drop to upper line
    //     // drop position === 0 : drop to node
    //     // drop position === 1 : drop to bottom line
    //     const drop_position =
    //         info.dropPosition - Number(drop_pos_arr[drop_pos_arr.length - 1]);
    //     const tree_data_replica = [...tree_data];
    //     // validation check if you drop something to user
    //     if (await _vc_drop_to_user(tree_data_replica, drop_key, drop_position)) {
    //         //_set_has_dropped_to_user(true);
    //         alert("사용자 하위에 추가할 수 없습니다.");
    //         return false;
    //     }
    //     const { v: drag_v, i: drag_i, list: drag_list } = await _find(
    //         tree_data_replica,
    //         drag_key
    //     );
    //     // delete drag node
    //     drag_list.splice(drag_i, 1);
    //     if (!dropToGap) {
    //         const { v, i, list } = await _find(tree_data_replica, drop_key);
    //         // if you drop something to node having children, push something to it's children
    //         if (v.children) {
    //             v.children.push(drag_v);
    //         } else {
    //             // if you drop something to node not having children(or yet to have), execute axios call
    //             let children = await _get_child({
    //                 class_org_group_code,
    //                 class_group_code: v.class_group_code,
    //             });
    //             v.children = [...children, drag_v];
    //         }
    //         _move(v, v.children[v.children.length - 1], drop_position);
    //         v.children = _align(v.children);
    //         _sync(v.children);
    //     } else {
    //         if (drop_position === -1) {
    //             let { v, i, list } = await _find(tree_data_replica, drop_key);
    //             // splice i = arr[i-1]
    //             list.splice(i, 0, drag_v);
    //             _move(v, drag_v, drop_position);
    //             list = _align(list);
    //             _sync(list);
    //         } else {
    //             let { v, i, list } = await _find(tree_data_replica, drop_key);
    //             list.splice(i + 1, 0, drag_v);
    //             _move(v, drag_v, drop_position);
    //             list = _align(list);
    //             _sync(list);
    //         }
    //     }
    //     _set_tree_data(tree_data_replica);
    // };

    // /////////////////////////////End Line For Move///////////////////////
    // /////////////////////////////////////////////////////////////////////
