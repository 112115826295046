/* eslint-disable array-callback-return */
import React from 'react';
import styled from 'styled-components';
import { BigPlayButton, Player } from 'video-react';
import ReactModal from "react-modal";

import {
    ScheduleRepeatModal,
    ScheduleColorSelectModal
} from '../../../common/components/Modal';
import { ToggleButtonSelect } from "../../../common/components/Select";
// import { LocationSearchModal } from "../../../common/components/Modal";

import * as DateTimeUtils from '../../../utils/DateTimeUtils';
import * as StringUtils from '../../../utils/StringUtils';
import * as CommonUtils from '../../../utils/CommonUtils';
import * as ScheduleService from '../../../services/ScheduleService';
import * as FileService from '../../../services/FileService';
import * as UserService from '../../../services/UserService';
import * as BandService from '../../../services/BandService';

import { message, Input } from 'antd';

import pictureImg from '../../../assets/images/btn-picture.svg';
import FileImg from '../../../assets/images/btn-file.svg';
import * as qs from "query-string";

import { THEME_BACKGROUND_COLOR } from '../../../configs'

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import DialogComp from '../../../common/components/Shared/DialogComp';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { withTranslation } from 'react-i18next';

import btnPrev from '../../../assets/images/btn_prev.svg';
import FileIcon from '../../../assets/images/icon-file.svg';
import btnBack from '../../../assets/images/btn-gotoback-blue.svg';
import { COMPANY_NAME } from '../../../configs';
const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

const MAX_SIZE_STORAGE = localStorage.getItem("maxPersonalDiskUsage");
const { TextArea } = Input;
const FORMAT = 'yyyy/MM/dd';

let TIME_OPTIONS = [];
for (let i = 0; i < 48; i++) {
    TIME_OPTIONS.push({
        value: pad(Math.floor(i / 2) * 100 + (i % 2) * 30, 4),
        label: `${i < 24 ? "AM " : "PM "} ${pad((Math.floor(i / 2) >= 12 ? Math.floor(i / 2) - 12 : Math.floor(i / 2)), 2)}:${pad((i % 2) * 30, 2)}`
    });
}

function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, undefined, { locale });
    if (DateUtils.isDate(parsed)) {
        return parsed;
    }
    return undefined;
}

function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
}

function pad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

function renderPreview(file, src) {
    if (CommonUtils.startsWith(file.type, "image")) {
        return <img src={src} alt="" />
    } else if (CommonUtils.startsWith(file.type, "video")) {
        return <Player src={src}><BigPlayButton className="big-play-button-hide" /></Player>
    } else {
        return <img src={FileIcon} alt="" />
    }
}

class Create extends React.Component {

    _isMounted = false;

    defaultState = {
        bandId: this.props.match.params["bandId"],
        isLocationSearchModalOpen: false,
        isScheduleCategorySelectModalOpen: false,
        isScheduleRepeatModalOpen: false,
        title: '',
        description: '',
        attachFiles: [],
        attachFilePreviews: [],
        location: '',
        scheduleCategory: undefined,
        startDateTime: undefined,
        endDateTime: undefined,
        isAllDay: true,
        scheduleRepeatOption: 0,
        needToConfirm: false,
        createPost: false,
        submitted: false,
        scheduleColor: `${THEME_BACKGROUND_COLOR}`,
        bandName: "",
        startDate: undefined,
        startTime: "1200",
        endDate: undefined,
        endTime: "1200",

    };

    constructor(props) {
        super(props);
        this.state = this.defaultState;

        this.inputOpenImageRef = React.createRef();
        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        const values = qs.parse(this.props.location.search);

        this.loadBandInfo();

        // 12월 5일이면 2019125 이렇게 되서  시작 하는 날이 나오지가 않는다. 그래서 20191205 이렇게 바꿔줘야 한다.
        if (values.createDate.substring(6, 15).length === 1) {
            values.createDate = values.createDate.substring(0, 6) + "0" + values.createDate.substring(6, 7)
        }

        // 특정 날짜 안찍고 들어온 사람들은 undefined 떠서 현재 날짜 나오게 해야 한다.
        if (values.createDate.substring(6, 15) === "undefined") {
            this.setState({
                startDate: DateTimeUtils.momentByFormat(DateTimeUtils.formattedCurrentDate(), "YYYYMMDD").toDate(),
                endDate: DateTimeUtils.momentByFormat(DateTimeUtils.formattedCurrentDate(), "YYYYMMDD").toDate()
            });
        } else {
            this.setState({
                startDate: DateTimeUtils.momentByDefaultFormat(values.createDate).toDate(),
                endDate: DateTimeUtils.momentByDefaultFormat(values.createDate).toDate()
            });
        }

    }

    loadBandInfo = () => {
        BandService.getBandInfo(this.props.match.params["bandId"])
            .then(result => {
                if (this._isMounted) {
                    this.setState({
                        bandName: result.band.name,
                    });
                }
            })
            .catch(error => console.error(error));
    };




    componentWillUnmount() {
        this._isMounted = false;
    }

    back = () => {
        if (this._isMounted) {
            this.setState(this.defaultState, this.props.history.goBack());
        }
    };

    routeScheduleHome = () => {
        this.back();
    };

    cancel = () => {
        this.back();
    };

    handleInput = (e) => {
        if (this.state.hasOwnProperty(e.currentTarget.name)) {
            if (this._isMounted) {
                this.setState({ [e.currentTarget.name]: e.currentTarget.value });
            }
        }
    };

    handleDescription = (e) => {
        if (this._isMounted) {
            this.setState({
                description: e.currentTarget.value,
            })
        }
    };

    handleChange = (name, value) => {
        if (this.state.hasOwnProperty(name)) {
            if (this._isMounted) {
                this.setState({
                    [name]: value,
                });
            }
        }
    };

    handleStartDate = (value) => {
        if (this._isMounted) {
            this.setState({ startDate: value });
        }
    };

    handleStartTime = (value) => {
        if (this._isMounted) {
            this.setState({ startTime: value });
        }
    };

    handleEndDate = (value) => {
        if (this._isMounted) {
            this.setState({ endDate: value });
        }
    };

    handleEndTime = (value) => {
        if (this._isMounted) {
            this.setState({ endTime: value });
        }
    };

    selectScheduleCategory = (selected) => {
        let scheduleCategory = undefined;
        if (selected) {
            scheduleCategory = selected;
        }
        this.setState({
            scheduleCategory: scheduleCategory,
            isScheduleCategorySelectModalOpen: false,
        });
    };

    selectScheduleColor = (selected) => {
        console.log(selected)
        let scheduleColor = undefined;
        if (selected) {
            scheduleColor = selected;
        }
        this.setState({
            scheduleColor: scheduleColor,
            scheduleCategory: scheduleColor,
            isScheduleCategorySelectModalOpen: false,
        });
    }

    selectScheduleRepeat = (selected) => {
        this.setState({
            scheduleRepeatOption: selected,
            isScheduleRepeatModalOpen: false,
        });
    };

    openLocationSearchModal = () => {
        if (this._isMounted) {
            this.setState({ isLocationSearchModalOpen: true });
        }
    };

    closeLocationSearchModal = () => {
        if (this._isMounted) {
            this.setState({ isLocationSearchModalOpen: false });
        }
    };

    openScheduleCategorySelectModal = () => {
        if (this._isMounted) {
            this.setState({ isScheduleCategorySelectModalOpen: true });
        }
    };

    closeScheduleRepeatModal = () => {
        if (this._isMounted) {
            this.setState({ isScheduleRepeatModalOpen: false })
        }
    };

    closeScheduleCategorySelectModal = () => {
        if (this._isMounted) {
            this.setState({ isScheduleCategorySelectModalOpen: false });
        }
    };

    openScheduleRepeatModal = () => {
        if (this._isMounted) {
            this.setState({ isScheduleRepeatModalOpen: true });
        }
    };

    openImageHandler = () => {
        this.inputOpenImageRef.current.click();
    };

    openFileHandler = () => {
        this.inputOpenFileRef.current.click();
    };

    attachImage = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let file = e.currentTarget.files[0];

        let getBandStatusResult = await BandService.getBandStatusNode(this.state.bandId)
        if (getBandStatusResult.diskUsage > localStorage.getItem('maxPersonalDiskUsage')) {
            return message.warning(`밴드 디스크 용량을 초과했습니다.`)
        }

        let userUploadFileSizeResult = await UserService.userUploadFileSize()
        if (userUploadFileSizeResult.data.totalSize > MAX_SIZE_STORAGE) {
            return message.warning(`${this.props.t('Schedule.Create.theCurrentLimitOfUploadPerPersonHasBeenExceeded')} ${userUploadFileSizeResult.data.totalSize}byte`)
        }

        if (this.state.attachFiles.length === 5) {
            return message.warning(`${this.props.t('Schedule.Create.youCanUploadUpTo5Files')}`)
        }
        if (file.size > Number(localStorage.getItem('maxFileSize'))) {
            return message.warning(`${this.props.t('Schedule.Create.imageUploadCapacityLimit')} : 10MB`)
        }

        let reader = new FileReader();
        reader.onloadend = () => {
            if (this._isMounted) {
                this.setState({
                    attachFiles: [...this.state.attachFiles, file],
                    attachFilePreviews: [...this.state.attachFilePreviews, <img src={reader.result} alt="" />],
                });
            }
        };
        reader.readAsDataURL(file);
    };

    attachFile = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let file = e.currentTarget.files[0];

        let getBandStatusResult = await BandService.getBandStatusNode(this.state.bandId)
        if (getBandStatusResult.diskUsage > localStorage.getItem('maxPersonalDiskUsage')) {
            return message.warning(`밴드 디스크 용량을 초과했습니다.`)
        }

        let userUploadFileSizeResult = await UserService.userUploadFileSize();
        if (userUploadFileSizeResult.data.totalSize > MAX_SIZE_STORAGE) {
            return message.warning(`${this.props.t('Schedule.Create.theCurrentLimitOfUploadPerPersonHasBeenExceeded')} ${userUploadFileSizeResult.data.totalSize}byte`)
        }

        if (this.state.attachFiles.length === 5) {
            return message.warning(`${this.props.t('Schedule.Create.youCanUploadUpTo5Files')}`)
        }

        let videoExist = false;
        if (this.state.attachFiles.map((file, i) => {
            if (file.type === "video/mp4") {
                return videoExist = true;
            }
        }))

            if (videoExist) {
                return message.warning(`${this.props.t('Schedule.Create.onlyOneVideoCanBeAttached')}`)
            }

        if (file.size > Number(localStorage.getItem('maxFileSize'))) {
            message.warning(`${this.props.t('Schedule.Create.fileUploadCapacityLimit')} : 300mb`)
        }

        let reader = new FileReader();
        reader.onloadend = () => {
            if (this._isMounted) {
                this.setState({
                    attachFiles: [...this.state.attachFiles, file],
                    attachFilePreviews: [...this.state.attachFilePreviews, renderPreview(file, reader.result)],
                });
            }
        };
        reader.readAsDataURL(file);

    };

    removeAttachedFile(index) {
        this.state.attachFiles.splice(index, 1)
        this.state.attachFilePreviews.splice(index, 1)

        this.setState({ attachFilePreviews: this.state.attachFilePreviews })

    }

    submit = async () => {

        if (this.state.title.length > 100) {
            return message.warning(`${this.props.t('Schedule.Create.titleLengthLimit')} : 100`);
        }

        if (this.state.description.length > 1000) {
            return message.warning(`${this.props.t('Schedule.Create.descriptionLengthLimit')} : 1000`);
        }

        if (StringUtils.isNullOrUndefined(this.state.title)) {
            message.warning(`${this.props.t('Schedule.Create.youMustSpecifytheTitle')}`);
            return;
        }

        //second! set startDate & endDate !
        let startDate = dateFnsFormat(this.state.startDate, "yyyyMMdd");
        let endDate = dateFnsFormat(this.state.endDate, "yyyyMMdd");

        //third! put startDate & startTime together and put endDate & endTime together !
        let startDateTime = startDate + this.state.startTime;
        if (this.state.isAllDay) {
            startDateTime = startDate + "0000";
        }
        let endDateTime = endDate + this.state.endTime;
        if (this.state.isAllDay) {
            endDateTime = endDate + "2359";
        }

        //여기서 startDateTime 202004080000 이런식으로 와야 된다. 
        if (startDateTime >= endDateTime) {
            message.warning(`${this.props.t('Schedule.Create.theEndTimeMustBeAfterTheStartTime')}`);
            return;
        }

        if (this._isMounted) {
            this.setState({ submitted: true }, this.createSchedule(startDateTime, endDateTime));
        }
    };

    createSchedule = (startDateTime, endDateTime) => {

        const scheduleCategoryId = this.state.scheduleCategory ? this.state.scheduleCategory.id : undefined;

        if (this.state.attachFiles && this.state.attachFiles.length > 0) {
            let attachedFileIds = [];
            const upload = async () => {
                await asyncForEach(this.state.attachFiles, async (file) => {
                    let response = await FileService.uploadFileNode(this.state.bandId, localStorage.getItem('userId'), file);
                    if (response && response.data.results.id) {
                        attachedFileIds.push(response.data.results.id);
                    }
                });
            };

            upload()
                .then(() => ScheduleService.createScheduleNode(
                    this.state.bandId, this.state.title, scheduleCategoryId, this.state.location, this.state.description,
                    this.state.isAllDay, startDateTime, endDateTime, this.state.scheduleRepeatOption,
                    this.state.needToConfirm, this.state.createPost, attachedFileIds, this.state.scheduleColor))
                .then(() => this.routeScheduleHome())
                .catch(error => {
                    console.error(error);
                    if (this._isMounted) {
                        this.setState({ submitted: false });
                    }
                });

        } else {
            ScheduleService.createScheduleNode(
                this.state.bandId, this.state.title, scheduleCategoryId, this.state.location, this.state.description,
                this.state.isAllDay, startDateTime, endDateTime, this.state.scheduleRepeatOption,
                this.state.needToConfirm, this.state.createPost, undefined, this.state.scheduleColor
            )
                .then(() => this.routeScheduleHome())
                .catch(error => {
                    console.error(error);
                    if (this._isMounted) {
                        this.setState({ submitted: false });
                    }
                });
        }
    };

    render() {
        const { t } = this.props;

        const scheduleCategory = this.state.scheduleCategory ?
            <div>
                <input type="checkbox" id="scheduleCategory" />
                <label htmlFor="scheduleCategory" style={{ backgroundColor: this.state.scheduleColor }} />
            </div> : `${this.props.t('Schedule.Create.noScheduleColorChosen')}`;

        const days = [
            t('Schedule.Modify.sun'),
            t('Schedule.Modify.mon'),
            t('Schedule.Modify.tue'),
            t('Schedule.Modify.wed'),
            t('Schedule.Modify.thu'),
            t('Schedule.Modify.fri'),
            t('Schedule.Modify.sat')
        ];

        const scheduleRepeatOption = [
            "반복 안함",
            "매일",
            "매주",
            "매월 " + DateTimeUtils.formatFromString(this.state.startDateTime, "D") + "일",
            "매월 " + Math.ceil(DateTimeUtils.momentByFormat(this.state.startDateTime, "YYYY/MM/DD") / 7) + "번째 " + days[DateTimeUtils.formatFromString(this.state.startDateTime, "d")],
            "매년",
        ];

        return (
            <div className="wrapper">

                <div className="headerWrap">
                    <div className="headerContentsWrap" style={{ alignItems: 'center' }}>

                        <div className="con-header">
                            <span className="roll-back"><img style={{ width: '12px' }}
                                onClick={this.back} src={btnPrev} alt="back" /></span> &nbsp;
                            <h6 className="page-title">{this.state.bandName}</h6>
                            <span className="page-title-sub" style={{ lineHeight: '24px' }}>{t('Schedule.Create.createASchedule')}</span>
                        </div>


                        {/* <Back>
                            <a href={`/band/${this.state.bandId}/schedule`}><img src={btnBack} alt="Back" /></a>
                            <p style={{ marginTop: '-2px', fontWeight: 'bold' }}>&nbsp;&nbsp; {t('Schedule.Create.createASchedule')}</p>
                        </Back> */}
                        <Cancel>
                            <a href={`/band/${this.state.bandId}/schedule`}>{t('Schedule.Create.cancel')}</a>
                        </Cancel>
                    </div>
                </div>
                <div className="contentsWrapSpacer" />
                <div className="contentsWrap">
                    <CreateWrapper>
                        <div>
                            <Input type="text" placeholder={t('Schedule.Create.scheduleTitle')} name="title" onChange={this.handleInput} />
                        </div>
                        <Divider />

                        <Description>
                            <TextArea placeholder={t('Schedule.Create.pleaseEnterDescription')} value={this.state.description} onChange={this.handleDescription} />

                            {COMPANY_NAME !== "협업팜" &&
                                <div>
                                    <button onClick={this.openImageHandler}><img src={pictureImg} alt="pictureImage" /></button>
                                    <button onClick={this.openFileHandler}><img src={FileImg} alt="FileImage" /></button>
                                </div>
                            }

                        </Description>
                        <Divider />

                        {this.state.attachFilePreviews && this.state.attachFilePreviews.length > 0 &&
                            <div className="band-cover-selector-grid">
                                {this.state.attachFilePreviews.map((attachFilePreview, index) => (
                                    <div style={{ display: 'flex', alignItems: 'center' }} key={index} onClick={() => { this.removeAttachedFile(index) }}>{attachFilePreview}</div>
                                ))}
                            </div>
                        }
                        {/*<Location>*/}
                        {/*    <input type="text" placeholder="위치" name="location" onChange={this.handleInput} />*/}
                        {/*    <button onClick={this.openLocationSearchModal}>검색</button>*/}
                        {/*</Location>*/}
                        <br />
                        <ButtonSelect>
                            <ScheduleCategory>{scheduleCategory}</ScheduleCategory>
                            <Select>
                                <button onClick={this.openScheduleCategorySelectModal}>{t('Schedule.Create.choose')}</button>
                            </Select>
                        </ButtonSelect>


                        <div className="cardHeader" style={{ padding: '0.5rem 0' }}>
                            <span className="writerThumb" style={{ height: 'auto', fontWeight: 'bold' }}>{t('Schedule.Create.start')}</span>

                            <DayPickerInput format={FORMAT}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder="YYYY/MM/DD"
                                value={this.state.startDate}
                                onDayChange={day => this.handleStartDate(day)}
                                style={{ width: '100%' }}
                                dayPickerProps={{
                                    selectedDays: this.state.startDate,
                                }}
                            />

                            {!this.state.isAllDay &&
                                <TimePickerSelect value={this.state.startTime} onChange={e => this.handleStartTime(e.target.value)}>
                                    {TIME_OPTIONS.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
                                </TimePickerSelect>
                            }
                        </div>

                        <Divider />

                        <div className="cardHeader" style={{ padding: '0.5rem 0' }}>
                            <span className="writerThumb" style={{ height: 'auto', fontWeight: 'bold' }}>{t('Schedule.Create.end')}</span>
                            <DayPickerInput format={FORMAT}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder="YYYY/MM/DD"
                                value={this.state.endDate}
                                onDayChange={day => this.handleEndDate(day)}
                                style={{ width: '100%' }}
                                dayPickerProps={{
                                    selectedDays: this.state.endDate,
                                }}
                            />
                            {!this.state.isAllDay &&

                                <TimePickerSelect value={this.state.endTime} onChange={e => this.handleEndTime(e.target.value)}>
                                    {TIME_OPTIONS.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
                                </TimePickerSelect>
                            }
                        </div>

                        <Divider />


                        <ToggleButtonSelect title={t('Schedule.Create.allDay')}
                            name="isAllDay"
                            defaultValue={this.state.isAllDay}
                            onChange={this.handleChange}
                        />
                        <Divider />
                        {/* <ButtonSelect>
                            <Title>반복 등록</Title>
                            <Select>
                                <button onClick={this.openScheduleRepeatModal}>
                                    {scheduleRepeatOption[this.state.scheduleRepeatOption]}
                                </button>
                            </Select>
                        </ButtonSelect>
                        <Divider /> */}
                        <ToggleButtonSelect
                            title={t('Schedule.Create.requestToParticipate')}
                            name="needToConfirm"
                            defaultValue={this.state.needToConfirm}
                            onChange={this.handleChange}
                        />
                        <Divider />
                        <ToggleButtonSelect
                            title={t('Schedule.Create.shareByPost')}
                            name="createPost"
                            defaultValue={this.state.createPost}
                            onChange={this.handleChange}
                        />
                        <Divider />
                        <br />
                        <Submit>
                            <button onClick={this.submit}>{t('Schedule.Create.submit')}</button>
                        </Submit>
                    </CreateWrapper>

                    {/* {this.state.isScheduleCategorySelectModalOpen &&
                        <div className="modal_wrap ">
                            <div className="modal">
                                <div className="close_btn" onClick={() => this.closeScheduleCategorySelectModal()}></div>
                                <ScheduleCategorySelectModal show={this.state.isScheduleCategorySelectModalOpen}
                                    bandId={this.state.bandId}
                                    onSelect={this.selectScheduleCategory}
                                    onClose={this.closeScheduleCategorySelectModal}
                                />
                            </div>
                            <div className="dim" onClose={() => this.closeScheduleCategorySelectModal()} />
                        </div>
                    } */}

                    <ReactModal
                        isOpen={this.state.isScheduleCategorySelectModalOpen}
                        contentLabel="close"
                        onRequestClose={this.closeScheduleCategorySelectModal}
                        style={CustomStyles}
                    >
                        <ScheduleColorSelectModal
                            show={this.state.isScheduleCategorySelectModalOpen}
                            bandId={this.state.bandId}
                            onSelect={this.selectScheduleColor}
                            onClose={this.closeScheduleCategorySelectModal}
                        />
                    </ReactModal>

                    <ScheduleRepeatModal show={this.state.isScheduleRepeatModalOpen}
                        onSelect={this.selectScheduleRepeat}
                        options={scheduleRepeatOption}
                        onClose={this.closeScheduleRepeatModal}
                    />

                    <input type="file" accept="image/*" ref={this.inputOpenImageRef} style={{ display: "none" }} onChange={this.attachImage} />
                    <input type="file" accept="*" ref={this.inputOpenFileRef} style={{ display: "none" }} onChange={this.attachFile} />

                    {/*<Modal width="80%" effect="fadeInUp" visible={this.state.isLocationSearchModalOpen} onClickAway={() => this.closeLocationSearchModal()}>*/}
                    {/*    <LocationSearchModal center={{lat: 18.5204, lng: 73.8567}} height='300px' zoom={15}/>*/}
                    {/*</Modal>*/}
                    <DialogComp description={t('Schedule.Create.postIsBeingUploaded')} open={this.state.submitted} />
                </div>

            </div>
        );
    }

}

const Divider = styled.div`
    border-bottom: ${props => props.bigger ? "solid 5px #e5e5ea" : "solid 1px #e5e5ea"};
    margin-bottom: 5px;
`;

const Description = styled.div`
`;

const Back = styled.div`
    width: 50%;
    text-align: left;
    display: flex;
    button {
      margin: 0;
      padding: 0;
    }
    img {
      height: 1rem;
    }
`;

const Cancel = styled.div`
    position: absolute;
    right: 10px;
    button {
      color: #51A5C1;
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
`;

const ScheduleCategory = styled.div`
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 1em;
    
    span {
      padding: 0 .5rem;
      vertical-align: middle;
    }
    
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      margin: .3rem;
      border-radius: 50%;
      vertical-align: middle;
    }
`;

const ButtonSelect = styled.div`
    display: flex;
    flex-grow: 1;
    padding: .5rem 0;
`;

const TimePickerSelect = styled.select`
    background-color: white;
    font-size: 14px;
    width: 47%;
    margin-left: 10px;
    color: black;
    border: 1px solid #d6dbdc;
    ;
    height: 30px;
    select::-ms-expand {
        display: none;
        -webkit-appearance: none;
    }    
`;

const Select = styled.div`
    width: 50%;
    text-align: right;
    button {}
`;

const CreateWrapper = styled.div`
`;

const Submit = styled.div`
    width: 100%;
    text-align: center;
    
    button {
      width: 100%;
      background-color: #51A5C1;
      border-radius: 0.5rem;
      color: #FFF;
      font-size: 1rem;
      font-weight: bold;
      border: 1px solid #aeaeae;
      line-height: 1rem;
      padding: 1rem 1.5rem;
    }
`;

export default withTranslation()(Create);

const CustomStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    }, overlay: { zIndex: 1000 }
};