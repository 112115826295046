import React from 'react';
import * as UserService from '../../../services/UserService';

import '../../../assets/css/_modal.scss';
import { Progress } from 'antd';
import { withTranslation } from 'react-i18next';
import { COMPANY_NAME } from '../../../configs';

function roundUp(num, precision) {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
}
class FileSizeSelectModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fileSize: 0
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        let userUploadFileSizeResult = await UserService.userUploadFileSize()
        console.log('userU', userUploadFileSizeResult.data.totalSize)
        this.setState({ fileSize: userUploadFileSizeResult.data.totalSize })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    modalClose = () => {
        this.props.onClose();
    }

    render() {
        const { t } = this.props;

        // if (!this.props.show) {
        //     return null;
        // }

        return (
            <div className="modal_wrap" aria-label="파일 사이즈 모달창">
                <div className="modal">
                    <button className="close_btn" aria-label="모달창 닫기 버튼" onClick={this.modalClose}></button>
                    <div>
                        <p className="title"> {t('FileSizeSelectModal.fileSize')} </p>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Progress
                                strokeColor={{
                                    '0%': '#108ee9',
                                    '100%': '#FF6347',
                                }}
                                status="active"
                                percent={Math.ceil(this.state.fileSize / Number(localStorage.getItem('maxPersonalDiskUsage')) * 100)}
                            />

                        </div>
                        <div style={{ textAlign: 'center', padding: '1rem' }}>

                            <p> {t('FileSizeSelectModal.usageUpToNow')} :{roundUp(Number(this.state.fileSize) / (1024 * 1024), 1)}MB | {roundUp(Number(this.state.fileSize) / (1024 * 1024 * 1024), 1)}GB</p>

                            {COMPANY_NAME !== "협업팜" &&
                                <p> {t('FileSizeSelectModal.availbleUsage')} : {roundUp(localStorage.getItem('maxPersonalDiskUsage') / 1024 / 1024, 1)}M | {localStorage.getItem('maxPersonalDiskUsage') / 1024 / 1024 / 1024} GB</p>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(FileSizeSelectModal);
