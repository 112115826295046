import React from 'react';
import * as StringUtils from '../../../utils/StringUtils';
import { SERVER_URI, COMPANY_NAME } from '../../../configs';
import '../../../assets/css/_home_grid.scss';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import NoImage from '../../../assets/images/no-img.svg';
import bandJoinBtn from '../../../assets/images/band_join_button.svg';

class BandGridItem extends React.Component {

    _isMounted = false;

    item;
    routeFunction;

    selectItem = (e) => {
        this.props.routeFunction(e.currentTarget.id);
    };

    render() {
        const { item } = this.props;
        let coverImage;
        let coverImageHeight;
        let bandCoverItemHeight;
        if (COMPANY_NAME !== "UC WARE") {
            bandCoverItemHeight = '145px';
            coverImageHeight = '145px';
        } else {
            bandCoverItemHeight = 'auto';
            coverImageHeight = ''
        }

        if (item && !StringUtils.isNullOrUndefined(item.coverThumbnail)) {
            if (COMPANY_NAME !== "UC WARE") {
                coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${item.coverImage}`;
            } else {
                coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${item.coverThumbnail}`;
            }
        } else {
            coverImage = NoImage;
        }

        const content = item.id === -1 ?
            <div>
                <button className="band_cover_img" >
                    <div className="linear" />
                    <img src={bandJoinBtn}
                        alt={this.props.t('New.joinCollaboration')} />
                    <div className="band_name" style={{ fontSize: '16px' }}>{this.props.t('New.joinCollaboration')}</div>
                </button>
            </div>
            :
            <div>
                <button className="band_cover_img">
                    <div className="linear" />
                    <img src={coverImage} alt={item.name} style={{ height: coverImageHeight }} />
                    <div className="band_name" style={{ fontSize: '16px' }}>{item.name}</div>
                </button>
            </div>;

        return (
            // <div className="band_item">
            <div className="band_item" style={{ height: bandCoverItemHeight }}>
                <div id={item.id}
                    onClick={this.selectItem}>
                    {content}
                </div>
            </div>
        );
    }
}

class BandGrid extends React.Component {

    bandList;
    cols;
    routeFunction;

    render() {
        const { bandList } = this.props;
        const className = this.props.aside ? "" : "band_grid";
        let bands = bandList;
        const item = {
            id: -1,
            name: "join",
        };
        bands.push(item);

        if (bands[bands.length - 2] && bands[bands.length - 2].id === -1) {
            bands.splice(-1, 1)
        }

        return (
            <div className={`${className}`}>
                <GridWrapper>
                    {bands.map((item, index) => (
                        <BandGridItem t={this.props.t} aside={this.props.aside} key={`band-grid-item-${index + 1}`} item={item} routeFunction={this.props.routeFunction} />
                    ))}
                </GridWrapper>
            </div>
        );
    }
}

export default withTranslation()(BandGrid);

const GridWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
    flex-wrap: wrap;
`;
