import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import styled from "styled-components";
import '../../../assets/css/_modal.scss';
import { message } from 'antd';
import * as BandService from '../../../services/BandService';
import { withTranslation } from 'react-i18next';

class BandTagSettingModal extends React.Component {

    onClose;
    show;
    bandId;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            tags: [],
            loading: true,
            inputValue: ""
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBandTags();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandTags = () => {
        BandService.getBandNode(this.props.bandId)
            .then(band => {
                if (this._isMounted) {
                    let tags = [];
                    band.tags.forEach((tag, index) => {
                        tags.push({ id: index, text: tag });
                    });
                    this.setState({
                        tags: tags,
                        loading: false,
                    });
                }
            })
            .catch(error => console.error(error));
    };

    handleInputChange = (e) => {

        if (e.length > 10) {
            message.warning('10자 이내로 써주세요.')
        }

        this.setState({
            inputValue: e
        });

    };

    handleDelete = (i) => {
        this.setState({
            tags: this.state.tags.filter((tag, index) => index !== i),
        });
    };

    handleAddition = (tag) => {
        let { tags } = this.state;
        this.setState({ tags: [...tags, { id: tags.length + 1, text: tag }] });
    };

    submit = () => {
        let tags = [];
        this.state.tags.forEach(tag => tags.push(tag.text));
        BandService.changeBandTagsNode(this.props.bandId, tags)
            .then(() => this.props.onClose())
            .catch(error => console.error(error));
    };

    render() {
        if (!this.props.show) {
            return null;
        }

        const { loading, tags } = this.state;
        console.log(tags)

        return (
            <div>
                {loading ?
                    <div></div> :
                    <div className="modal_wrap">
                        <div className="modal">
                             <button className="close_btn" aria-label="모달창 닫기 버튼"onClick={this.props.onClose}></button>
                            <div className="modal_content view_order">
                                <p className="title">대표 태그 설정</p>
                                <Tag>
                                    <ReactTags tags={tags}
                                        placeholder={"태그 입력"}
                                        handleDelete={this.handleDelete}
                                        handleAddition={this.handleAddition}
                                        handleInputChange={this.handleInputChange}
                                        value={this.state.inputValue}
                                        maxLength={"10"}
                                    />
                                </Tag>
                                <br /><br />
                                <button className="settingSubmitBtn" onClick={this.submit}>{this.props.t('Schedule.save')}</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

const Tag = styled.div`

input{
    border: none;
    width: 100%;
    border-bottom: solid 1px;
    padding: 0.5rem;
}

`;

export default withTranslation()(BandTagSettingModal);
