/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from "styled-components";
import { Menu } from 'antd';
import btnHamburger from '../../../assets/images/btn_hamburger.svg';
import btnHamburger_hover from '../../../assets/images/btn_hamburger_hover.svg';
import { useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react'

type TtoolTipProps = {
    select: (id: string) => void;
}

function ContentDisplaySettingToolTip(props: TtoolTipProps) {
    const { t } = useTranslation();

    const handleSelectMenu = (event: React.MouseEvent<HTMLAnchorElement>) => {
        props.select(event.currentTarget.id);
    };
    
    return (
        <Popup trigger={
            <Settings>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <input type="button" className="btn-hambuger" />
                </a>
            </Settings>
        } flowing hoverable >
            <Menu>
                <Menu.Item>
                    <a onClick={handleSelectMenu} id="isViewOrderTypeSelectModalOpen">
                        <span className="ico"></span>{t('Contents.selectDefaultSort')}
                    </a>
                </Menu.Item>
            </Menu>
        </Popup>
    )
}

export default ContentDisplaySettingToolTip

const Settings = styled.div`
    input {
        border: none;
        cursor: pointer;
        height: 32px;
        width: 32px;
        background: url(${btnHamburger});
    }

    input:hover {
        background: url(${btnHamburger_hover});
    }
`;