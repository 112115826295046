/* eslint-disable array-callback-return */
import React from 'react';
import styled from "styled-components";
import { BandMenu, BandTopButtons } from '../Menu';
import ContentGrid from './Sections/ContentGrid';
import ReactModal from "react-modal";

import { viewOrderTypeOptions } from '../../configs';
import TopFileFilterButtons from './Sections/TopFilterButtons';

import * as FileService from '../../services/FileService';
import * as CommonUtils from '../../utils/CommonUtils';
import * as BandService from '../../services/BandService';
import * as UserService from '../../services/UserService';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import DialogComp from '../../common/components/Shared/DialogComp';
import Empty from '../../common/components/Shared/Empty';
import ScrollTop from '../../common/components/Shared/ScrollToTop';
import Pagination from './Sections/Pagination';

import { ContentMenuModal, ContentViewOrderTypeSelectModal, ContentDirectoryCreateModal } from '../../common/components/Modal'
const btnPlus = require('../../assets/images/btn-plus.svg');
const MAX_SIZE_STORAGE = localStorage.getItem("maxPersonalDiskUsage");
class ContentsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contentList: [],
            menuDisplay: false,
            createMenuDisplay: false,
            contentSettingMenuTrigger: false,
            isViewTypeSelectModalOpen: false,
            isViewOrderTypeSelectModalOpen: false,
            isDirectoryCreateModalOpen: false,
            viewTypeOption: 0,
            viewOrderTypeOption: 0,
            viewType: 0,
            selectAll: true,
            selectImage: false,
            selectVideo: false,
            selectFile: false,
            newDirectoryName: '',
            currentDirectory: undefined,
            loading: false,

            bandName: "",

            width: 0,
            isBandLeader: false,
            pager: {}
        };

        this.inputOpenImageOrVideoRef = React.createRef();
        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        this.getBandLeaders();
        this.loadFileList();
        this.loadBandInfo();
    }

    loadBandInfo = () => {
        BandService.getBandInfo(this.props.match.params["bandId"])
            .then(result => {
                if (this._isMounted) {
                    this.setState({
                        bandName: result.band.name,
                    });
                }
            })
            .catch(error => console.error(error));
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.menuDisplay !== this.state.menuDisplay) {
            if (this._isMounted) {
                this.setState({ contentSettingMenuTrigger: false });
            }
        }
    }

    getBandLeaders = () => {
        BandService.getBandLeadersNode(this.props.match.params["bandId"])
            .then(bandLeaders => {
                bandLeaders.map((leader, i) => {
                    if (leader.userId === Number(localStorage.getItem('userId'))) {
                        this.setState({ isBandLeader: true })
                    }
                })
            })
    }

    loadFileList = () => {
        let fileType = undefined;
        if (this.state.selectImage) {
            fileType = 1;
        } else if (this.state.selectVideo) {
            fileType = 2;
        } else if (this.state.selectFile) {
            fileType = 0;
        }

        // get page of items from api
        const params = new URLSearchParams(this.props.location.search);
        const page = parseInt(params.get('page')) || 1;

        let contentList = [];
        let responseForPosts = [];
        FileService.getDirectoriesNode(this.props.match.params["bandId"], this.state.viewOrderTypeOption)
            .then(list => list.forEach(item => contentList.push(item)))
            .then(() => FileService.getFilesNode(this.props.match.params["bandId"], fileType, this.state.viewOrderTypeOption, page))
            .then((response) => { responseForPosts = response })
            .then(() => { responseForPosts.pageOfItems.forEach(item => contentList.push(item)) })
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        contentList: contentList,
                        loading: false,
                        pager: responseForPosts.pager
                    });
                }
            })
            .catch(error => console.error(error), this.setState({ loading: false }));
    };

    handleMenuDisplay = (isOpen) => {
        this.setState({ menuDisplay: isOpen });
    };

    selectMenu = (selected) => {
        this.setState({
            [selected]: true,
            contentSettingMenuTrigger: true,
        });
    };

    selectCreateMenu = (e) => {
        if (CommonUtils.contains(e.currentTarget.id, "uploadImageOrVideo")) {
            this.inputOpenImageOrVideoRef.current.click();
            this.setState({
                createMenuDisplay: false,
            });

        } else if (CommonUtils.contains(e.currentTarget.id, "uploadFile")) {
            this.inputOpenFileRef.current.click();
            this.setState({
                createMenuDisplay: false,
            });

        } else if (CommonUtils.contains(e.currentTarget.id, "isDirectoryCreateModalOpen")) {
            this.setState({
                isDirectoryCreateModalOpen: true,
                createMenuDisplay: false,
            });

        }
    };

    openCreateMenuDisplay = () => {
        this.setState({
            createMenuDisplay: true,
        })
    };

    closeCreateMenuDisplay = () => {
        this.setState({
            createMenuDisplay: false,
        })
    };

    selectViewOrderType = (e) => {
        this.setState({
            viewOrderTypeOption: viewOrderTypeOptions[e.currentTarget.value].value,
        });
    };

    completeSelectViewType = () => {
        this.setState({
            viewType: this.state.viewTypeOption,
            isViewTypeSelectModalOpen: false
        });
    };

    completeSelectViewOrderType = () => {
        this.setState({
            isViewOrderTypeSelectModalOpen: false
        }, this.loadFileList);
    };

    closeDirectoryCreateModal = () => {
        this.setState({
            isDirectoryCreateModalOpen: false
        });
    };

    selectContentCategory = (e) => {
        this.setState({
            selectAll: false,
            selectImage: false,
            selectVideo: false,
            selectFile: false,
            [e.currentTarget.id]: true
        }, this.loadFileList);

    };

    handleDirectoryName = (e) => {
        this.setState({
            newDirectoryName: e.currentTarget.value,
        });
    };

    createNewDirectory = () => {
        if (this.state.newDirectoryName.trim().length === 0) {

            if (localStorage.getItem("i18nextLng") === "english") {
                alert("Please enter a folder name.");
            }else if (localStorage.getItem("i18nextLng") === "japanese") {
                alert("フォルダ名を入力してください。");
            }else if (localStorage.getItem("i18nextLng") === "thai") {
                alert("โปรดป้อนชื่อโฟลเดอร์");
            }else{
                alert("폴더명을 입력해주세요.");
            }

            return;
        }
        FileService.createDirectoryNode(this.props.match.params["bandId"], this.state.newDirectoryName)
            .then(() => {
                this.setState({
                    isDirectoryCreateModalOpen: false,
                }, this.loadFileList)
            })
            .catch(error => console.error(error));
    };

    changeCurrentDirectory = (directory) => {
        if (this._isMounted) {
            this.setState({
                currentDirectory: directory,
            })
        }
    };

    uploadImageOrVideo = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        const bandId = this.props.match.params["bandId"];
        let file = e.currentTarget.files[0];

        //파일을 하나 올리고  다시 하나 더 올리려고 팝업창 띄우고 취소를 하면 저절로 이 function 이 작동 된다. 
        //하지만 그때는 file 이  undefined가 떠서 에러가 나서  그 부분을 중단시켜줘야 한다. 
        if (file === undefined) { return false; }

        if ((file.type === "image/jpeg" || file.type === "image/png") && file.size > Number(localStorage.getItem('maxFileSize'))) {
            return message.warning(`${this.props.t('Schedule.Create.imageUploadCapacityLimit')} : ${Number(localStorage.getItem('maxFileSize'))}mb`)
        }

        if (file.type === "video/mp4" && file.size > Number(localStorage.getItem('maxFileSize'))) {
            return message.warning(`${this.props.t('Schedule.Create.fileUploadCapacityLimit')} : ${Number(localStorage.getItem('maxFileSize'))}mb`)
        }

        try {

            let getBandStatusResult = await BandService.getBandStatusNode(bandId)
            if (getBandStatusResult.diskUsage > localStorage.getItem('maxPersonalDiskUsage')) {
                return message.warning(`${this.props.t('New.YouHaveExceeded')}`)
            }

            let userUploadFileSizeResult = await UserService.userUploadFileSize()
            if (userUploadFileSizeResult.data.totalSize > MAX_SIZE_STORAGE) {
                return message.warning(` ${this.props.t('Schedule.Create.theCurrentLimitOfUploadPerPersonHasBeenExceeded')} ${userUploadFileSizeResult.data.totalSize}byte`)
            }

            const bandMemberRoles = [0, 1];
            let receiveUserNames = [];
            let sendUserName = "";
            const { bandName } = this.state;
            let userProfileResult = await UserService.getUserProfileNode(localStorage.getItem('userId'))
            sendUserName = userProfileResult.name

            let getBandMembersResult = await BandService.getBandMembersNode(bandId, bandMemberRoles)

            for (let index = 0; index < getBandMembersResult.length; index++) {
                const user = getBandMembersResult[index];
                if (user.notifyThroughUcTalk && user.notifyContent && user.userId !== Number(localStorage.getItem('userId'))) {
                    receiveUserNames.push(user.name)
                }
            }

            this.setState({ loading: true })
            if (this.state.currentDirectory) {
                await FileService.uploadFileToDirectoryNode(bandId, this.state.currentDirectory, localStorage.getItem('userId'), file)
                this.loadFileList()
                await UserService.sendNotificationToContentUsers(bandId, receiveUserNames, localStorage.getItem('userAccount'), sendUserName, bandName)
            } else {
                await FileService.uploadFileNode(bandId, localStorage.getItem('userId'), file)
                this.loadFileList()
                await UserService.sendNotificationToContentUsers(bandId, receiveUserNames, localStorage.getItem('userAccount'), sendUserName, bandName)
            }
        } catch (error) {
            message.error(error)
        }
    };

    uploadFile = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        const bandId = this.props.match.params["bandId"];
        let file = e.currentTarget.files[0];
        const { bandName } = this.state;

        if (file.size > Number(localStorage.getItem('maxFileSize'))) {
            return message.warning(`${this.props.t('Schedule.Create.fileUploadCapacityLimit')} : ${Number(localStorage.getItem('maxFileSize'))}mb`)
        }

        let receiveUserNames = [];
        let sendUserName = "";
        try {
            // let getUserProfileResult = await UserService.getUserProfileNode(localStorage.getItem('userId'))
            // sendUserName = getUserProfileResult.name

            // let userUploadFileSizeResult = await UserService.userUploadFileSize()
            // if (userUploadFileSizeResult.data.totalSize > MAX_SIZE_STORAGE) {
            //     return message.warning(`${this.props.t('Schedule.Create.theCurrentLimitOfUploadPerPersonHasBeenExceeded')} ${userUploadFileSizeResult.data.totalSize}byte`)
            // }

            console.log('file', file)

            let getBandMembersResult = await BandService.getBandMembersNode(bandId)
            for (let index = 0; index < getBandMembersResult.length; index++) {
                const user = getBandMembersResult[index];
                if (user.notifyThroughUcTalk && user.notifyContent && user.userId !== Number(localStorage.getItem('userId'))) {
                    receiveUserNames.push(user.name)
                }
            }
            this.setState({ loading: true })

            if (this.state.currentDirectory) {
                await FileService.uploadFileToDirectoryNode(bandId, this.state.currentDirectory, localStorage.getItem('userId'), file)
                this.loadFileList()
                await UserService.sendNotificationToContentUsers(bandId, receiveUserNames, localStorage.getItem('userAccount'), sendUserName, bandName)
            } else {
                await FileService.uploadFileNode(bandId, localStorage.getItem('userId'), file)
                this.loadFileList()
                await UserService.sendNotificationToContentUsers(bandId, receiveUserNames, localStorage.getItem('userAccount'), sendUserName, bandName)
            }
        } catch (error) {
            message.error(error)
        }
    };

    modalClose = () => {
        this.setState({
            isViewTypeSelectModalOpen: false,
            isViewOrderTypeSelectModalOpen: false,
            isDirectoryCreateModalOpen: false,
            createMenuDisplay: false
        })
    }

    render() {
        const bandId = this.props.match.params["bandId"];
        const { contentList, selectAll, selectImage, selectVideo, bandName, selectFile, pager } = this.state;
        const { history, t } = this.props;

        let fileType = "image/*,video/*";
        if (selectImage) {
            fileType = "image/*";
        } else if (selectVideo) {
            fileType = "video/*";
        }

        return (
            <div className="wrapper">
                <BandTopButtons
                    bandId={bandId}
                    bandName={bandName}
                    fromPage={this.props.t('New.Content')} historyProp={history} />
                <div className="contentsWrapSpacer" />
                <div className="contentsWrap">
                    <ScrollTop />
                    <div>
                        <TopFileFilterButtons selectMenu={this.selectMenu} selectContentCategory={this.selectContentCategory} selectAll={selectAll} selectImage={selectImage} selectVideo={selectVideo} selectFile={selectFile} />
                        <ContentListWrapper>
                            {contentList.length === 0
                                ?
                                <>
                                    <br /><br /><br /><br /> <Empty description={t('Contents.noFilesRegisted')} />
                                </>
                                :
                                <ContentGrid
                                    bandId={bandId}
                                    contentList={contentList}
                                    isBandLeader={this.state.isBandLeader}
                                    reloadFunction={this.loadFileList}
                                    changeCurrentDirectory={this.changeCurrentDirectory}
                                />}
                            {/* {this.state.viewType === 0 && <></>} */} {/* {this.state.viewType === 1 && <ContentList bandId={bandId} contentList={contentList} />} */}
                            <Pagination pager={pager} />
                        </ContentListWrapper>
                        <Spacer />
                    </div>

                    {this.state.createMenuDisplay ||
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '65px' }}>
                            <button onClick={this.openCreateMenuDisplay}><ButtonPlus src={btnPlus} alt="btn-plus" /></button>
                        </div>
                    }

                    <DialogComp description={t('Contents.fileIsBeingUploaded')} open={this.state.loading} />

                    <BandMenu pathName="contents" bandId={bandId} historyProp={history} />

                    {this.state.createMenuDisplay &&
                        <ContentMenuModal modalClose={this.modalClose} selectCreateMenu={this.selectCreateMenu} isBandLeader={this.state.isBandLeader} currentDirectory={this.state.currentDirectory} selectAll={selectAll} selectImage={selectImage} selectVideo={selectVideo} selectFile={selectFile} />
                    }

                    <ReactModal
                        contentLabel="ViewOrderType"
                        isOpen={this.state.isViewOrderTypeSelectModalOpen}
                        onRequestClose={this.modalClose}
                        style={CustomStyles}
                    >
                        <ContentViewOrderTypeSelectModal modalClose={this.modalClose} completeSelectViewOrderType={this.completeSelectViewOrderType} selectViewOrderType={this.selectViewOrderType} viewOrderTypeOption={this.state.viewOrderTypeOption} />
                    </ReactModal>

                    <ReactModal
                        contentLabel="CreateDirectory"
                        isOpen={this.state.isDirectoryCreateModalOpen}
                        onRequestClose={this.modalClose}
                        style={CustomStyles}
                    >
                        <ContentDirectoryCreateModal modalClose={this.modalClose} handleDirectoryName={this.handleDirectoryName} createNewDirectory={this.createNewDirectory} />
                    </ReactModal>

                    <input type="file" accept={fileType} ref={this.inputOpenImageOrVideoRef} style={{ display: "none" }} onChange={this.uploadImageOrVideo} />
                    <input type="file" accept="*" ref={this.inputOpenFileRef} style={{ display: "none" }} onChange={this.uploadFile} />
                </div>
            </div>
        );
    }
}

const ContentListWrapper = styled.div`
    padding: 1rem 1rem 1rem 1rem;

    @media (max-width: 500px) {
        padding: 1rem 1rem 4rem 1rem;
    }  
`;

const Spacer = styled.div`
    width: 100%;
    height: 6%;
`;

const ButtonPlus = styled.img`
    position: fixed;
    bottom: 1rem;
    object-fit: contain;

    @media (max-width: 500px) {
        bottom: 5rem;
    }  
`;

export default withTranslation()(ContentsPage);
const CustomStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    }, overlay: { zIndex: 1000 }
};