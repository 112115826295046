import React from 'react';
import styled from 'styled-components';

import * as BandService from '../../../services/BandService';
import * as PostService from '../../../services/PostService';

import * as DateTimeUtils from '../../../utils/DateTimeUtils';

import { ToggleButtonSelect } from '../../../common/components/Select';
import { RichTextEditor } from '../../../common/components/Edit';

import radioCheckedImg from "../../../assets/images/radio-checked.svg";
import radioUncheckedImg from "../../../assets/images/radio-unchecked.svg";
import { THEME_BACKGROUND_COLOR } from '../../../configs';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';

const btnBack = require('../../../assets/images/btn-gotoback-blue.svg');
const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

// async function asyncForEach(array, callback) {
//     for (let index = 0; index < array.length; index++) {
//         await callback(array[index], index, array);
//     }
// }

class Modify extends React.Component {

    _isMounted = false;

    defaultState = {
        isBandLeader: false,
        bandId: undefined,
        post: undefined,
        data: "",
        isNotice: false,
        isImportantNotice: false,
        importantNoticeCount: 0,
        polls: [],
        files: [],
        submitted: false,
    };

    constructor(props) {
        super(props);
        this.state = this.defaultState;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadPost();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadPost = () => {
        const bandId = this.props.match.params["bandId"];
        const postId = this.props.match.params["postId"];

        let post, isBandLeader = false;
        if (bandId && postId) {
            PostService.getPostNode(bandId, postId)
                .then(result => post = result)
                .then(() => BandService.currentBandMemberNode(bandId))
                .then(bandMember => isBandLeader = bandMember.bandMemberRole === 0)
                .then(() => PostService.countOfImportantNoticesNode(bandId, DateTimeUtils.formattedCurrent()))
                .then(count => {
                    if (this._isMounted) {
                        this.setState({
                            bandId: bandId,
                            postId: postId,
                            post: post,
                            isBandLeader: isBandLeader,
                            isNotice: post.isNotice,
                            isImportantNotice: post.isImportantNotice,
                            importantNoticeCount: count,
                            disableImportantNotice: (count >= 2 && !post.isImportantNotice)
                        })

                        localStorage.setItem('modifyBandId', bandId)

                    }
                })
                .catch(error => console.error(error));
        }
    };

    back = () => {
        if (this._isMounted) {
            this.setState(this.defaultState, this.props.history.goBack());
        }
    };

    routeHome = () => {
        this.props.history.push(`/band/${this.state.bandId}/home`);
    };

    cancel = () => {
        this.back();
    };

    handleImportantNotice = () => {
        let importantNoticeCount;
        if (this.state.isImportantNotice) {
            importantNoticeCount = this.state.importantNoticeCount - 1;
        } else {
            importantNoticeCount = this.state.importantNoticeCount + 1;
        }
        if (this._isMounted) {
            this.setState({
                isImportantNotice: !this.state.isImportantNotice,
                importantNoticeCount: importantNoticeCount,
            });
        }
    };

    handleChange = (name, value) => {
        if (this.state.hasOwnProperty(name)) {
            if (this._isMounted) {
                this.setState({ [name]: value });
            }
        }
    };

    onEditorChange = (value) => {
        if (this._isMounted) {
            this.setState({
                content: value,
            })
        }
    };

    onFilesChange = (files) => {
        if (this._isMounted) {
            this.setState({
                files: files,
            })
        }
    };

    onPollsChange = (polls) => {
        if (this._isMounted) {
            this.setState({
                polls: polls,
            })
        }
    };

    submit = () => {
        if (this._isMounted) {

            if (this.state.content.trim().length === 0) {
                return message.warning(`${this.props.t('Post.Create.pleaseEnterTheContentFirst')}`)
            }

            this.setState({ submitted: true }, this.modifyPost);
        }
    };

    modifyPost = () => {
        const content = this.state.content.replace("<p data-f-id=\"pbf\" style=\"text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;\">Powered by <a href=\"https://www.froala.com/wysiwyg-editor?pb=1\" title=\"Froala Editor\">Froala Editor</a></p>", "");

        let tags = [];
        // if (this.state.files && this.state.files.length > 0) {
        //     let uploadFiles = [];
        //     const upload = async () => {
        //         await asyncForEach(this.state.files, async (file) => {
        //             let response = await FileService.uploadFile(this.state.bandId, file);
        //             if (response && response.id) {
        //                 if (!CommonUtils.startsWith(file.type, "image") && !CommonUtils.startsWith(file.type, "video")) {
        //                     uploadFiles.push({
        //                         fileName: file.name,
        //                         fileType: file.type,
        //                         url: `${SERVER_URI}:5000/_UPLOAD_FILES/${this.state.bandId}/${response.fileName}`
        //                     });
        //                 } else {
        //                     uploadFiles.push({
        //                         fileName: file.name,
        //                         fileType: file.type,
        //                         url: `${SERVER_URI}:5000/_UPLOAD_FILES/${this.state.bandId}/${response.fileName}`
        //                     });
        //                 }
        //             }
        //         });
        //     };
        //
        //     upload()
        //         .then(() => {
        //             console.log(uploadFiles);
        //             const transform = (node, index) => {
        //                 if (node.type === 'tag' && node.name === 'img') {
        //                     console.log(node);
        //                     const newUploadFile = uploadFiles.filter(uploadFile => uploadFile.fileName === node.attribs.alt);
        //                     console.log(newUploadFile);
        //                     if (newUploadFile && newUploadFile.length > 0) {
        //                         return <img key={`img-${index}`}
        //                                     src={newUploadFile[0].url}
        //                                     width={"100%"} alt={node.attribs.alt} />;
        //                     } else {
        //                         return <img key={`img-${index}`}
        //                                     src={node.attribs.src}
        //                                     width={"100%"} alt={node.attribs.alt} />;
        //                     }
        //                 }
        //
        //                 if (node.type === 'tag' && node.name === 'video') {
        //                     const newUploadFile = uploadFiles.filter(uploadFile => uploadFile.fileName === node.attribs.title);
        //                     if (newUploadFile && newUploadFile.length > 0) {
        //                         return <video key={`video-${index}`}
        //                                       src={newUploadFile[0].url}
        //                                       width={"100%"} title={node.attribs.title} controls />;
        //                     } else {
        //                         return <video key={`video-${index}`}
        //                                       src={node.attribs.src}
        //                                       width={"100%"} title={node.attribs.title} controls />;
        //                     }
        //                 }
        //
        //                 if (node.type === 'tag' && node.name === 'a') {
        //                     const newUploadFile = uploadFiles.filter(uploadFile => uploadFile.fileName === node.attribs.href);
        //                     if (newUploadFile && newUploadFile.length > 0) {
        //                         return <a key={`file-${index}`}
        //                                   href={newUploadFile[0].url}
        //                                   target="_blank"
        //                                   rel="noopener noreferrer"
        //                         ><b>{newUploadFile[0].fileName}</b></a>;
        //                     } else {
        //                         return <a key={`file-${index}`}
        //                                   href={node.attribs.href}
        //                                   target="_blank"
        //                                   rel="noopener noreferrer"
        //                         ><b>{node.children[0]}</b></a>;
        //                     }
        //                 }
        //             };
        //
        //             const options = {
        //                 decodeEntities: true,
        //                 transform: transform
        //             };
        //
        //             const content = this.state.content ? ReactHtmlParser(this.state.content, options) : undefined;
        //
        //             return PostService.modifyPost(this.state.bandId, this.state.postId, ReactDOMServer.renderToStaticMarkup(content), this.state.polls, tags,
        //                 this.state.isNotice, this.state.isImportantNotice);
        //         })
        //         .then(() => this.routeHome())
        //         .catch(error => {
        //             console.log(error);
        //             console.error(error);
        //             if (this._isMounted) {
        //                 this.setState({ submitted: false });
        //             }
        //         });
        //
        // } else {
        PostService.modifyPostNode(this.state.bandId, this.state.postId, content, this.state.polls, tags,
            this.state.isNotice, this.state.isImportantNotice)
            .then(() => this.routeHome())
            .catch(error => {
                console.error(error);
                if (this._isMounted) {
                    this.setState({ submitted: false });
                }
            });
        // }
    };

    render() {
        const {
            isNotice, isImportantNotice, importantNoticeCount, disableImportantNotice, post,
        } = this.state;
        const { t } = this.props;
        const content = post ? (post.content ? post.content : undefined) : undefined;

        return (
            <div id="section">

                <PostCreateForm>

                    <Buttons>
                        <Back>
                            <button onClick={this.back}><img src={btnBack} alt="Back" /></button>
                        </Back>
                        <Cancel>
                            <button onClick={this.cancel}>{t('Post.Create.cancel')}</button>
                        </Cancel>
                    </Buttons>

                    <EditorWrapper>
                        <div id="editor">
                            <RichTextEditor bandId={this.state.bandId}
                                onEditorChange={this.onEditorChange}
                                onFileAdded={this.onFileAdded}
                                onFileRemoved={this.onFileRemoved}
                                onPollsChange={this.onPollsChange}
                                defaultValue={content}
                                defaultPolls={post ? post.polls : undefined}
                            />
                        </div>
                        <p>&nbsp;</p>

                        {this.state.isBandLeader &&
                            <ToggleButtonSelect title={t('Post.Create.registerAsNotice')}
                                name="isNotice"
                                defaultValue={isNotice}
                                onChange={this.handleChange}
                            />
                        }

                        {isNotice &&
                            <div>
                                <NoticeSubtext>
                                    {t('Post.Create.youCanSetUpTo2ImportantAnnouncementsInTheBand')}
                                </NoticeSubtext>
                                <NoticeOption isMSIE={__ISMSIE__} isImportantNotice={isImportantNotice}>
                                    <input type="checkbox" onChange={this.handleImportantNotice}
                                        defaultChecked={isImportantNotice}
                                        disabled={disableImportantNotice}
                                    />
                                    <label>{t('Post.Create.registerAsImportantNotice')} ({importantNoticeCount} / 2)</label>
                                </NoticeOption>
                                <div>&nbsp;</div>
                            </div>
                        }

                        <Submit>
                            <button onClick={this.submit}>{t('Post.Create.register')}</button>
                        </Submit>
                    </EditorWrapper>

                </PostCreateForm>

            </div>
        );
    }

}

const NoticeSubtext = styled.div`
    margin-top: -7px;
    object-fit: contain;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #8e8e93;
`

const NoticeOption = styled.div`
    label {}
    input[type="checkbox"] {
        width: 17px;
        -webkit-appearance: none;
        background: url(${radioUncheckedImg});
        background-size: 15px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: ${props => props.isMSIE ? "0px 2px" : "0px 5px"};
        margin-bottom: -3px;
        margin-right: 3px;


        &:checked {
            background: url(${radioCheckedImg});
            width: 17px;
            -webkit-appearance: none;
            background-size: 15px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: ${props => props.isMSIE ? "0px 2px" : "0px 5px"};
            margin-bottom: -3px;
            margin-right: 3px;
            }
    }
    color: ${props => props.isImportantNotice ? `${THEME_BACKGROUND_COLOR}` : "black"};
`;


const PostCreateForm = styled.div`
    padding: 1rem;
    min-height: 100vh;
    height: auto !important;
    max-width: 700px;
    margin: 0 auto;
`;

const Buttons = styled.div`
    display: flex;
    flex-grow: 1;
`;

const Back = styled.div`
    width: 50%;
    text-align: left;
    button {
      margin: 0;
      padding: 0;
    }
    img {
      height: 1rem;
    }
`;

const Cancel = styled.div`
    width: 50%;
    text-align: right;
    button {
      color: ${THEME_BACKGROUND_COLOR};
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
`;

const EditorWrapper = styled.div`
    padding: 1rem 0;
`;

const Submit = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 2rem;
    
    button {
      width: 100%;
      background-color: ${THEME_BACKGROUND_COLOR};
      border-radius: 0.5rem;
      color: #FFF;
      font-size: 1rem;
      font-weight: bold;
      padding: 1rem 1rem;
    }
`;

export default withTranslation()(Modify);
