import React from 'react';
import styled from "styled-components";
import { withTranslation } from 'react-i18next';

import * as ScheduleService from '../../../services/ScheduleService';

class ScheduleCategorySelectModal extends React.Component {

    show;
    bandId;
    onSelect;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            scheduleCategories: [],
            selectedScheduleCategoryId: undefined,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadScheduleCategories();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadScheduleCategories = () => {
        ScheduleService.getBandScheduleCategoryListNode(this.props.bandId)
            .then(list => {
                console.log('list',list)
                if (this._isMounted) {
                    this.setState({
                        scheduleCategories: list,
                        loading: false,
                    });
                }
            })
            .catch(error => console.error(error));
    };

    select = (selected) => {
        this.props.onSelect(selected);
    };

    render() {
        if (!this.props.show) {
            return null;
        }

        const { loading, scheduleCategories } = this.state;

        return (
            <div>
                {loading ?
                    <div></div> :
                    <div className="modal_content view_order">
                        <p className="title">{this.props.t('New.ChooseCategory')}</p>
                        <div>
                            {scheduleCategories.map((scheduleCategory, index) =>
                                <ScheduleCategory key={`scheduleCategory-${index}`} onClick={() => this.select(scheduleCategory)}>
                                    <input type="checkbox" id={`scheduleCategoryCheckbox-${index}`} />
                                    <label htmlFor={`checkbox-${index}`} style={{ backgroundColor: scheduleCategory.color }} />
                                    <span>{scheduleCategory.ChooseCategory}</span>
                                </ScheduleCategory>
                            )}
                        </div>
                    </div>
                }
            </div>
        );
    }

}

const ScheduleCategory = styled.div`
    padding: .5rem 0;
    font-size: 1rem;
    font-weight: bold;
    span {
      padding: 0 .5rem;
      vertical-align: middle;
    }
    
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      margin: .3rem;
      border-radius: 50%;
      vertical-align: middle;
    }
`;

export default withTranslation()(ScheduleCategorySelectModal);
