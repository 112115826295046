import React from 'react'
import { Comment, Avatar } from 'antd';

function CheckedUser({ checkedUsersInfo }) {

    const renderCheckedUser = checkedUsersInfo =>
        checkedUsersInfo.length > 0 && checkedUsersInfo.map(user =>
            <Comment
                author={user.class_user_name}
                avatar={
                    <Avatar
                        src={`${user.user_picture_pos}`}
                        alt={`${user.class_user_name}`}
                    />
                }
                content={`${user.user_paycl_name}`}
            >
            </Comment>
        )

    return (
        <div style={{
            maxHeight: '265px',
            overflowY: 'auto'
        }}>
            {renderCheckedUser(checkedUsersInfo)}
        </div>
    )
}

export default CheckedUser
