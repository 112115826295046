import React from 'react'
import { Skeleton } from 'antd';

function ListSkeleton() {
    return (
        <div>
            <Skeleton active />
            <Skeleton active />
        </div>
    )
}

export default ListSkeleton
