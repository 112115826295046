import React from 'react';
import styled from 'styled-components';
import Datetime from 'react-datetime';
import * as qs from 'query-string';

import * as BandService from '../../../services/BandService';
import * as PostService from '../../../services/PostService';
import * as UserService from '../../../services/UserService';

import * as DateTimeUtils from '../../../utils/DateTimeUtils';

import {
    Select,
    DurationSettingToolTip,
    ToolTipController
} from '../../../common/components/ToolTip';
import { ToggleButtonSelect } from '../../../common/components/Select';
import { RichTextEditor } from '../../../common/components/Edit';
import { THEME_BACKGROUND_COLOR } from '../../../configs';

import radioCheckedImg from "../../../assets/images/radio-checked.svg";
import radioUncheckedImg from "../../../assets/images/radio-unchecked.svg";
import { withTranslation } from 'react-i18next';
import DialogComp from '../../../common/components/Shared/DialogComp';
import btnBack from '../../../assets/images/btn-gotoback-blue.svg';
import btnPrev from '../../../assets/images/btn_prev.svg';
import './PostCreatePage.css';
const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

const postDateTimeTypeOptions = [
    { value: 0, korean: "즉시 등록", english: "immediately", japanese: "直ちに" },
    { value: 1, korean: "1시간 후", english: "1 hour later", japanese: '1時間後' },
    { value: 2, korean: "1일 후", english: "1 day later", japanese: '1日後' },
    { value: 3, korean: "1주일 후", english: "1 week later", japanese: '1週間後' },
    { value: 4, korean: "1달 후", english: "1 month later", japanese: '1ヶ月後' },
    { value: 5, korean: "직접 설정", english: "Direct setting", japanese: '直接設定' },
];

const dueDateTimeTypeOptions = [
    { value: 0, korean: "미설정", english: "Not set", japanese: '設定されていません' },
    { value: 1, korean: "1시간 후", english: "1 hour later", japanese: '1時間後' },
    { value: 2, korean: "1일 후", english: "1 day later", japanese: '1日後' },
    { value: 3, korean: "1주일 후", english: "1 week later", japanese: '1週間後' },
    { value: 4, korean: "1달 후", english: "1 month later", japanese: '1ヶ月後' },
    { value: 5, korean: "직접 설정", english: "Direct setting", japanese: '直接設定' },
];

// async function asyncForEach(array, callback) {
//     for (let index = 0; index < array.length; index++) {
//         await callback(array[index], index, array);
//     }
// }

class Create extends React.Component {

    _isMounted = false;

    defaultState = {
        isBandLeader: false,
        bandId: this.props.match.params["bandId"],
        data: "",
        isNotice: false,
        isImportantNotice: false,
        importantNoticeCount: 0,
        disableImportantNotice: true,
        displayPostDateTimeMenu: false,
        displayDueDateTimeMenu: false,
        postDateTimeMenuTrigger: false,
        dueDateTimeMenuTrigger: false,
        postDateTimeType: 0,
        dueDateTimeType: 0,
        postDateTime: undefined,
        dueDateTime: undefined,
        polls: [],
        files: [],
        submitted: false,
        bandName: "",

    };

    constructor(props) {
        super(props);
        this.state = this.defaultState;
    }

    componentDidMount() {
        this._isMounted = true;

        this.loadBandInfo();

        let params = qs.parse(decodeURIComponent(this.props.location.search));
        let scheduleId = undefined;
        let scheduleTitle = undefined;
        let data = "";
        if (params && params.scheduleId && params.scheduleTitle) {
            scheduleId = params.scheduleId;
            scheduleTitle = params.scheduleTitle;
            data = `<p class="schedule-inner-post" id="${scheduleId}"><span>Schedule - </span><b>${scheduleTitle}</b></p><p><br></p>`;
        }

        const bandId = this.props.match.params["bandId"];
        let isBandLeader = false;
        BandService.currentBandMemberNode(bandId)
            .then(bandMember => isBandLeader = bandMember.bandMemberRole === 0)
            .then(() => PostService.countOfImportantNoticesNode(bandId, DateTimeUtils.formattedCurrent()))
            .then(count => {
                if (this._isMounted) {
                    this.setState({
                        isBandLeader: isBandLeader,
                        importantNoticeCount: count,
                        disableImportantNotice: (count >= 2),
                        data: data,
                    })
                }
            })
            .catch(error => console.error(error));

    }

    loadBandInfo = () => {
        BandService.getBandInfo(this.props.match.params["bandId"])
            .then(result => {
                if (this._isMounted) {
                    this.setState({
                        bandName: result.band.name,
                    });
                }
            })
            .catch(error => console.error(error));
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.displayPostDateTimeMenu !== this.state.displayPostDateTimeMenu) {
            if (this._isMounted) {
                this.setState({ postDateTimeMenuTrigger: false });
            }
        }
        if (prevState.displayDueDateTimeMenu !== this.state.displayDueDateTimeMenu) {
            if (this._isMounted) {
                this.setState({ dueDateTimeMenuTrigger: false });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    back = () => {
        if (this._isMounted) {
            this.props.history.push(`/band/${this.state.bandId}/home`);
        }
    };

    routeHome = () => {
        this.props.history.push(`/band/${this.state.bandId}/home`);
    };

    cancel = () => {
        this.back();
    };

    handleImportantNotice = () => {
        let importantNoticeCount;
        if (this.state.isImportantNotice) {
            importantNoticeCount = this.state.importantNoticeCount - 1;
        } else {
            importantNoticeCount = this.state.importantNoticeCount + 1;
        }
        if (this._isMounted) {
            this.setState({
                isImportantNotice: !this.state.isImportantNotice,
                importantNoticeCount: importantNoticeCount,
            });
        }
    };

    handleChange = (name, value) => {
        if (this.state.hasOwnProperty(name)) {
            if (this._isMounted) {
                this.setState({ [name]: value });
            }
        }
    };

    // handleChange = (event, {name, value}) => {
    //     if (this.state.hasOwnProperty(name)) {
    //         if (this._isMounted) {
    //             this.setState({ [name]: value });
    //         }
    //     }
    // };

    onEditorChange = (value) => {
        if (this._isMounted) {
            // console.log('value', value)

            this.setState({
                content: value,
            })
        }
    };

    onFileAdded = (added) => {
        if (this._isMounted) {
            // console.log('value', added)

            let files = this.state.files;
            for (let i = 0; i < added.length; i++) {
                files.push(added[i]);
            }
            this.setState({
                files: files,
            }, () => console.log(this.state.files))
        }
    };

    onFileRemoved = (removed) => {
        console.log(removed);
        if (this._isMounted) {
            this.setState({
                files: this.state.files.filter(file => file !== removed),
            }, () => console.log(this.state.files))
        }
    };

    onFilesChange = (files) => {
        if (this._isMounted) {
            console.log('files', files)

            this.setState({
                files: [...this.state.files, files],
            }, () => console.log(this.state.files))
        }
    };

    onPollsChange = (polls) => {
        if (this._isMounted) {
            this.setState({
                polls: polls,
            })
        }
    };

    handleDisplayPostDateTimeMenu = (isOpen) => {
        this.setState({ displayPostDateTimeMenu: isOpen });
    };

    selectPostDateTimeType = (value) => {
        if (this._isMounted) {
            let postDateTime;
            if (value === 0) {
                postDateTime = undefined;
            } else if (value === 1) {
                postDateTime = DateTimeUtils.current().add(1, 'hour');
            } else if (value === 2) {
                postDateTime = DateTimeUtils.current().add(1, 'day');
            } else if (value === 3) {
                postDateTime = DateTimeUtils.current().add(1, 'week');
            } else if (value === 4) {
                postDateTime = DateTimeUtils.current().add(1, 'month');
            } else if (value === 5) {
                postDateTime = DateTimeUtils.current();
            }
            this.setState({
                postDateTimeType: value,
                postDateTimeMenuTrigger: true,
                postDateTime: postDateTime,
            });
        }
    };

    handleDisplayDueDateTimeMenu = (isOpen) => {
        this.setState({ displayDueDateTimeMenu: isOpen });
    };

    selectDueDateTimeType = (value) => {
        console.log('value', value)
        let dueDateTime;
        if (value === 0) {
            dueDateTime = undefined;
        } else if (value === 1) {
            dueDateTime = DateTimeUtils.current().add(1, 'hour');
        } else if (value === 2) {
            dueDateTime = DateTimeUtils.current().add(1, 'day');
        } else if (value === 3) {
            dueDateTime = DateTimeUtils.current().add(1, 'week');
        } else if (value === 4) {
            dueDateTime = DateTimeUtils.current().add(1, 'month');
        } else if (value === 5) {
            dueDateTime = DateTimeUtils.current();
        }
        this.setState({
            dueDateTimeType: value,
            dueDateTimeMenuTrigger: true,
            dueDateTime: dueDateTime,
        });
    };

    handlePostDateTime = (value) => {
        if (this._isMounted) {
            this.setState({ postDateTime: value });
        }
    };

    handleDueDateTime = (value) => {
        if (this._isMounted) {
            this.setState({ dueDateTime: value });
        }
    };

    submit = () => {
        if (this.state.content === undefined) {
            return alert(`${this.props.t('Post.Create.pleaseEnterTheContentFirst')}`)
        }

        //    let result =  HtmlUtils.removeAllTables(this.state.content)
        //        result =  HtmlUtils.removeAllTagForOnlyTable(result)
        //     if(!result) {
        //         return alert('표만 작성 하실수는 없습니다. 다른 내용을 더 작성해주세요.')
        //     }

        if (this.state.postDateTime && this.state.dueDateTime) {
            if (this.state.postDateTime.toDate().valueOf() > this.state.dueDateTime.toDate().valueOf()) {
                alert(`${this.props.t('Post.Create.theEndTimeMustBeAfterTheStartTime')}`);
                return;
            }
        }

        if (this._isMounted) {
            this.setState({ submitted: true }, this.createPost);
        }
    };

    createPost = () => {
        const postDateTime = this.state.postDateTime ? DateTimeUtils.formatted(this.state.postDateTime) : DateTimeUtils.formattedCurrent();
        const dueDateTime = this.state.dueDateTime ? DateTimeUtils.formatted(this.state.dueDateTime) : undefined;
        // console.log('start', this.state.content)
        let content = this.state.content.replace("<p data-f-id=\"pbf\" style=\"text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;\">Powered by <a href=\"https://www.froala.com/wysiwyg-editor?pb=1\" title=\"Froala Editor\">Froala Editor</a></p>", "");
        content = content + "<p></p>"
        // console.log('end', content)

        // const tagStrings = ReactHtmlParser(this.state.content, options);
        let tags = [];
        // if (tagStrings && tagStrings.length > 0) {
        //     const tagStringList = tagStrings.filter(tag => tag !== null);
        //     tagStringList.forEach(tagString => {
        //         tagString.split(" ").filter(split => split.includes("#")).forEach(tag => {
        //             tags.push(tag.substring(1));
        //         })
        //     });
        // }

        // if (this.state.files && this.state.files.length > 0) {
        //     let uploadFiles = [];
        //     const upload = async () => {
        //         await asyncForEach(this.state.files, async (file) => {
        //             let response = await FileService.uploadFile(this.state.bandId, file);
        //             console.log(response);
        //             if (response && response.id) {
        //                 if (!CommonUtils.startsWith(file.type, "image") && !CommonUtils.startsWith(file.type, "video")) {
        //                     uploadFiles.push({
        //                         fileName: file.name,
        //                         fileType: file.type,
        //                         url: `${SERVER_URI}:5000/_UPLOAD_FILES/${this.state.bandId}/${response.fileName}`
        //                     });
        //                 } else {
        //                     uploadFiles.push({
        //                         fileName: file.name,
        //                         fileType: file.type,
        //                         url: `${SERVER_URI}:5000/_UPLOAD_FILES/${this.state.bandId}/${response.fileName}`
        //                     });
        //                 }
        //             }
        //         });
        //     };
        //
        //     upload()
        //         .then(() => {
        //             const transform = (node, index) => {
        //                 if (node.type === 'tag' && node.name === 'img') {
        //                     return <img key={`img-${index}`}
        //                         src={uploadFiles.filter(uploadFile => uploadFile.fileName === node.attribs.alt)[0].url}
        //                         width={"100%"} alt={node.attribs.alt} />;
        //                 }
        //
        //                 if (node.type === 'tag' && node.name === 'video') {
        //                     return <video key={`video-${index}`}
        //                         src={uploadFiles.filter(uploadFile => uploadFile.fileName === node.attribs.title)[0].url}
        //                         width={"100%"} title={node.attribs.title} controls />;
        //                 }
        //
        //                 if (node.type === 'tag' && node.name === 'a') {
        //                     return <a key={`file-${index}`}
        //                         href={uploadFiles.filter(uploadFile => uploadFile.fileName === node.attribs.href)[0].url}
        //                         target="_blank"
        //                         rel="noopener noreferrer"
        //                     >
        //                         <b>{uploadFiles.filter(uploadFile => uploadFile.fileName === node.attribs.href)[0].fileName}</b>
        //                     </a>;
        //                 }
        //             };
        //
        //             const options = {
        //                 decodeEntities: true,
        //                 transform: transform
        //             };
        //
        //             const content = this.state.content ? ReactHtmlParser(this.state.content, options) : undefined;
        //
        //             return PostService.createPost(this.state.bandId, ReactDOMServer.renderToStaticMarkup(content), this.state.polls, tags, postDateTime,
        //                 this.state.isNotice, dueDateTime, this.state.isImportantNotice);
        //         })
        //         .then(() => this.routeHome())
        //         .catch(error => {
        //             console.error(error);
        //             if (this._isMounted) {
        //                 this.setState({ submitted: false });
        //             }
        //         });
        //
        // } else {
        let receiveUserNames = [];
        let createdPostId = 0;
        const bandMemberRoles = [0, 1];
        PostService.createPostNode(this.state.bandId, content, this.state.polls, tags, postDateTime,
            this.state.isNotice, dueDateTime, this.state.isImportantNotice)
            .then(response => createdPostId = response.postId)
            .then(() => BandService.getBandMembersNode(this.state.bandId, bandMemberRoles))
            .then(response => {
                for (let index = 0; index < response.length; index++) {
                    const user = response[index];
                    if (user.notifyThroughUcTalk && user.notifyContent && user.userId !== Number(localStorage.getItem('userId'))) {
                        receiveUserNames.push(user.name)
                    }
                }
            })
            .then(() => UserService.sendNotificationToPostUsers(this.state.bandId, receiveUserNames, localStorage.getItem('userAccount'), localStorage.getItem('userName'), this.state.bandName, createdPostId))
            .then(() => this.routeHome())
            .catch(error => {
                console.error(error);
                if (this._isMounted) {
                    this.setState({ submitted: false });
                }
            });
        // }
    };

    render() {
        const {
            isNotice, isImportantNotice, importantNoticeCount, disableImportantNotice,
            postDateTimeType, dueDateTimeType, isBandLeader, bandName
        } = this.state;
        const { t } = this.props;
        const currentLanguage = localStorage.getItem("i18nextLng");

        let postDateTimeSelected;
        if (currentLanguage === "korean") {
            postDateTimeSelected = postDateTimeTypeOptions[this.state.postDateTimeType].korean
        } else if (currentLanguage === "english") {
            postDateTimeSelected = postDateTimeTypeOptions[this.state.postDateTimeType].english
        } else if (currentLanguage === "japanese") {
            postDateTimeSelected = postDateTimeTypeOptions[this.state.postDateTimeType].japanese
        }


        return (
            <div className="wrapper">

                <div className="headerWrap">
                    <div className="headerContentsWrap" style={{ alignItems: 'center' }}>

                        <div className="con-header">
                            <span className="roll-back"><img style={{ width: '12px' }}
                                onClick={this.back} src={btnPrev} alt="back" /></span> &nbsp;
                            <h6 className="page-title">{bandName}</h6>
                            <span className="page-title-sub" style={{ lineHeight: '24px' }}>{t('New.WritePost')}</span>
                        </div>

                        <Cancel>
                            <button onClick={this.cancel}
                                style={{ color: `${THEME_BACKGROUND_COLOR}` }} >{t('Post.Create.cancel')}</button>
                        </Cancel>
                    </div>
                </div>

                <div className="contentsWrapSpacer" />

                <div className="contentsWrap">

                    <EditorWrapper>
                        <div id="editor">
                            <RichTextEditor bandId={this.state.bandId}
                                onEditorChange={this.onEditorChange}
                                onFileAdded={this.onFileAdded}
                                onFileRemoved={this.onFileRemoved}
                                onPollsChange={this.onPollsChange}
                            />
                        </div>
                        <p>&nbsp;</p>

                        {this.state.isBandLeader &&
                            <ToggleButtonSelect title={t('Post.Create.registerAsNotice')}
                                name="isNotice"
                                defaultValue={false}
                                onChange={this.handleChange}
                            />
                        }

                        {isNotice &&
                            <div>
                                <NoticeSubtext>
                                    {t('Post.Create.youCanSetUpTo2ImportantAnnouncementsInTheBand')}
                                </NoticeSubtext>
                                <NoticeOption isMSIE={__ISMSIE__} isImportantNotice={isImportantNotice}>
                                    <input type="checkbox" onChange={this.handleImportantNotice}
                                        defaultChecked={isImportantNotice}
                                        disabled={disableImportantNotice}
                                    />
                                    <label>{t('Post.Create.registerAsImportantNotice')} ({importantNoticeCount} / 2)</label>
                                </NoticeOption>
                                <div>&nbsp;</div>
                            </div>
                        }

                        <SelectWrapper>
                            <SelectLabel>
                                {t('Post.Create.reservationTimeSetting')}
                            </SelectLabel>
                            <SelectOptions>
                                <ToolTipController
                                    detect="click"
                                    offsetX="centre"
                                    offsetY={20}
                                    closeOnClick={false}
                                    triggerClose={this.state.postDateTimeMenuTrigger}
                                    returnState={this.handleDisplayPostDateTimeMenu}>
                                    <Select>
                                        <button>
                                            {postDateTimeSelected}
                                        </button>
                                    </Select>
                                    <DurationSettingToolTip select={this.selectPostDateTimeType} options={postDateTimeTypeOptions} />
                                </ToolTipController>
                            </SelectOptions>
                        </SelectWrapper>

                        {postDateTimeType > 0 &&
                            <DateTimeWrapper>
                                <Datetime dateFormat="YYYY/MM/DD" defaultValue={this.state.postDateTime} value={this.state.postDateTime} onChange={this.handlePostDateTime} />
                            </DateTimeWrapper>
                        }

                        {isBandLeader && this.state.isNotice &&
                            <SelectWrapper>
                                <SelectLabel>
                                    {t('Post.Create.notificationEndTimeSetting')}
                                </SelectLabel>
                                <SelectOptions>
                                    <ToolTipController
                                        detect="click"
                                        offsetX="centre"
                                        offsetY={20}
                                        closeOnClick={false}
                                        triggerClose={this.state.dueDateTimeMenuTrigger}
                                        returnState={this.handleDisplayDueDateTimeMenu}>
                                        <Select>
                                            <button>
                                                {postDateTimeSelected}
                                            </button>
                                        </Select>
                                        <DurationSettingToolTip select={this.selectDueDateTimeType} options={dueDateTimeTypeOptions} />
                                    </ToolTipController>
                                </SelectOptions>
                            </SelectWrapper>
                        }

                        {dueDateTimeType > 0 && this.state.isNotice &&
                            <DateTimeWrapper>
                                <Datetime dateFormat="YYYY/MM/DD" defaultValue={this.state.dueDateTime} onChange={this.handleDueDateTime} />
                            </DateTimeWrapper>
                        }

                        <Submit>
                            <button onClick={this.submit}>{t('Post.Create.register')}</button>
                        </Submit>

                        <DialogComp description={t('Post.Create.postIsBeingUploaded')} open={this.state.submitted} />

                    </EditorWrapper>

                </div>

            </div >
        );
    }
}


const NoticeSubtext = styled.div`
    margin-top: -7px;
    object-fit: contain;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #8e8e93;
`

const NoticeOption = styled.div`
    label {}
    input[type="checkbox"] {
        width: 17px;
        -webkit-appearance: none;
        background: url(${radioUncheckedImg});
        background-size: 15px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: ${props => props.isMSIE ? "0px 2px" : "0px 5px"};
        margin-bottom: -3px;
        margin-right: 3px;


        &:checked {
            background: url(${radioCheckedImg});
            width: 17px;
            -webkit-appearance: none;
            background-size: 15px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: ${props => props.isMSIE ? "0px 2px" : "0px 5px"};
            margin-bottom: -3px;
            margin-right: 3px;
        }
    }
    color: ${props => props.isImportantNotice ? `${THEME_BACKGROUND_COLOR}` : "black"};
`;

// const Buttons = styled.div`
//     display: flex;
//     flex-grow: 1;
// `;

// const Back = styled.div`
//     width: 2%;
//     min-width: 15px;
//     text-align: left;
//     button {
//       margin: 0;
//       padding: 0;
//     }
//     img {
//       height: 1rem;
//     }
// `;

// const Title = styled.span`
//     width: 8%;
//     word-break: keep-all;
// `;

// const Cancel = styled.div`
//     width: 90%;
//     text-align: right;
//     button {
//       color: ${THEME_BACKGROUND_COLOR};
//       font-size: 1rem;
//       font-weight: bold;
//       margin: 0;
//       padding: 0;
//     }
// `;

const EditorWrapper = styled.div`
`;

const DateTimeWrapper = styled.div`
    display: flex;
    flex-grow: 1;
`;

const SelectWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    padding: .5rem 0;
`;

const SelectLabel = styled.div`
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    span {
      padding: 0 1.5rem;
      width: 2rem;
      display: inline-block;
      text-align: center;
    }
`;


const SelectOptions = styled.div`
    width: 50%;
    text-align: right;
    button {}
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: right;
    color: ${THEME_BACKGROUND_COLOR};
`;

const Submit = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 2rem;
    
    button {
      width: 100%;
      background-color: ${THEME_BACKGROUND_COLOR};
      border-radius: 0.5rem;
      color: #FFF;
      font-size: 1rem;
      font-weight: bold;
      padding: 1rem 1rem;
    }
`;


const Back = styled.div`
    width: 50%;
    text-align: left;
    button {
      margin: 0;
      padding: 0;
    }
    img {
      height: 1rem;
    }
    padding: 1rem;
`;

const Cancel = styled.div`
    position: absolute;
    right: 10px;
    button {
      color: #51A5C1;
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

`;
export default withTranslation()(Create);
