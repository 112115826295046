import React from "react";
import styled from "styled-components";

import "../../assets/css/_modal.scss";

import { BandMenu, BandTopButtons } from "../Menu";
import {
  BandSettingModal,
  BandLeaderSettingModal,
  BandMemberManageModal,
  BandDeportedMemberManageModal,
  BandTagSettingModal,
  BandDeleteConfirmModal,
  // ScheduleCategorySettingModal,
  ContentStorageManagementModal,
  BandWithdrawConfirmModal,
} from "../../common/components/Modal";
import { ToggleButtonSelect } from "../../common/components/Select";
import { COMPANY_NAME, THEME_BACKGROUND_COLOR } from "../../configs";
import Modal from "react-modal";
import ReactAwesomeModal from "react-awesome-modal";

import * as BandService from "../../services/BandService";
import btnMore from "../../assets/images/btn-more.svg";
import profileBackgroundTwo from "../../assets/images/setting-cover.png";
import ListsSkeleton from "../../common/components/Shared/Skeleton/ListsSkeleton";
import { withTranslation } from "react-i18next";
import DialogComp from "../../common/components/Shared/DialogComp";

const __ISIOS__ = navigator.userAgent.match(/iPad|iPhone|iPod/i) ? true : false;
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const NoProfileImage = require("../../assets/images/blank-profile-picture.png");

class Setting extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      bandId: this.props.match.params["bandId"],
      band: undefined,
      profileImage: undefined,
      profileName: undefined,
      isBandMember: false,
      isBandLeader: false,
      applyPublicProfile: true,
      notifyThroughUcTalk: true,
      postNotificationType: 0,
      replyNotificationType: 0,
      notifySchedule: true,
      notifyContent: true,
      notifyChat: true,
      diskUsage: "0B",
      isBandUserProfileSettingModalOpen: false,
      isBandInfoSettingModalOpen: false,
      isBandLeaderSettingModalOpen: false,
      isBandLeaderAssignModalOpen: false,
      isBandMemberDeportModalOpen: false,
      isDeportedBandMemberModalOpen: false,
      isBandTagSettingModalOpen: false,
      isScheduleCategorySettingModalOpen: false,
      isContentStorageManagementModalOpen: false,
      isBandWithdrawConfirmModalOpen: false,
      isBandDeleteConfirmModalOpen: false,
      bandOpenType: undefined,
      needToApprove: false,
      createMemberJoinPost: false,
      loading: true,
      openDialog: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadBandInfo();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadBandInfo = () => {
    BandService.getBandInfo(this.state.bandId)
      .then((result) => {
        if (this._isMounted) {
          this.setState(
            {
              band: result.band,
              isBandMember: result.isBandMember,
              isBandLeader: result.isBandLeader,
            },
            this.loadUserSettings
          );
        }
      })
      .catch((error) => console.error(error));
  };

  loadUserSettings = () => {
    if (this.state.isBandMember) {
      BandService.getSingleBandMember(
        this.state.bandId,
        localStorage.getItem("userId")
      )
        .then((bandMember) => {
          if (this._isMounted) {
            this.setState(
              {
                profileImage: bandMember.profileImage,
                profileName: bandMember.name,
                applyPublicProfile: bandMember.applyPublicProfile,
                notifyThroughUcTalk: bandMember.notifyThroughUcTalk,
                postNotificationType: bandMember.postNotificationType,
                replyNotificationType: bandMember.replyNotificationType,
                notifySchedule: bandMember.notifySchedule,
                notifyContent: bandMember.notifyContent,
                notifyChat: bandMember.notifyChat,
              },
              this.loadBandSettings
            );
          }
        })
        .catch((error) => console.error(error));
    } else {
      this.setState({ openDialog: true });

      setTimeout(() => {
        this.setState({ openDialog: false });
        this.props.history.push(`/band/${this.state.bandId}`);
      }, 3000);
    }
  };

  loadBandSettings = () => {
    if (this.state.isBandLeader) {
      if (this._isMounted) {
        this.setState({
          bandOpenType: this.state.band.openType,
          needToApprove: this.state.band.needToApprove,
          createMemberJoinPost: this.state.band.createMemberJoinPost,
          loading: false,
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  handleBandUserSettingChange = (name, value) => {
    if (this.state.hasOwnProperty(name)) {
      if (this.state.isBandUserProfileSettingModalOpen) {
        this.setState({
          isBandUserProfileSettingModalOpen: false,
        });
      }

      if (this._isMounted) {
        this.setState(
          {
            [name]: value,
          },
          this.applyBandUserSettings
        );
      }
    }
  };

  applyBandUserSettings() {
    BandService.updateBandUserSettingsNode(
      this.state.bandId,
      localStorage.getItem("userId"),
      this.state.applyPublicProfile,
      this.state.notifyThroughUcTalk,
      this.state.postNotificationType,
      this.state.replyNotificationType,
      this.state.notifySchedule,
      this.state.notifyContent,
      this.state.notifyChat
    ).catch((error) => console.error(error));
  }

  handleBandSettingChange = (e) => {
    if (this._isMounted) {
      this.setState(
        {
          bandOpenType: e.target.value,
        },
        this.applyBandSettings
      );
    }
  };

  handleNeetToApproveChange = () => {
    if (this._isMounted) {
      this.setState(
        {
          needToApprove: !this.state.needToApprove,
        },
        this.applyBandSettings
      );
    }
  };

  handleReplyNotificationTypeChange = (name, value) => {
    if (this._isMounted) {
      this.setState(
        {
          replyNotificationType: value ? '1' : '0',
        },
        this.applyBandUserSettings
      );
    }
  };

  handleNotifyThroughUcTalkChange = (name, value) => {
    if (this._isMounted) {
      this.setState(
        {
          notifyThroughUcTalk: value ? '1' : '0',
        },
        this.applyBandUserSettings
      );
    }
  };

  handleNotifyContentChange = (name, value) => {
    if (this._isMounted) {
      this.setState(
        {
          notifyContent: value ? '1' : '0',
        },
        this.applyBandUserSettings
      );
    }
  };

  applyBandSettings = () => {
    BandService.updateBandNode(
      this.state.bandId,
      undefined,
      undefined,
      undefined,
      this.state.bandOpenType,
      this.state.needToApprove,
      this.state.createMemberJoinPost,
      "two"
    ).catch((error) => console.error(error));
  };

  handleModal = (name, value) => {
    if (this.state.hasOwnProperty(name)) {
      if (this._isMounted) {
        this.setState({
          [name]: value,
        });
      }
    }
  };

  withdrawBand = () => {
    BandService.withdrawBandNode(this.state.bandId)
      .then(() => this.props.history.push("/myBand"))
      .catch((error) => {
        console.error(error);
        this.handleModal("isBandWithdrawConfirmModalOpen", false);
      });
  };

  deleteBand = () => {
    BandService.deleteBandNode(this.state.bandId)
      .then(() => this.props.history.push("/myBand"))
      .catch((error) => {
        console.error(error);
        this.handleModal("isBandDeleteConfirmModalOpen", false);
      });
  };

  profileEdit = () => {
    this.handleModal("isBandUserProfileSettingModalOpen", false);
  };

  getDiskStorageUsage = () => {
    BandService.getBandStatusNode(this.state.bandId)
      .then((response) => {
        console.log("sojfosjdfojsdfosfd", response);
        if (this._isMounted) {
          this.setState(
            {
              diskUsage: response.diskUsage,
            },
            this.handleModal("isContentStorageManagementModalOpen", true)
          );
        }
      })
      .catch((error) => {
        console.error(error);
        this.handleModal("isContentStorageManagementModalOpen", false);
      });
  };

  render() {
    const { history, t } = this.props;
    const { loading, profileImage, profileName, band } = this.state;

    return (
      <div className="wrapper">
        <DialogComp
          description={t("Setting.bandMembersOnly")}
          open={this.state.openDialog}
        />

        {loading ? (
          <div>
            <ListsSkeleton />
          </div>
        ) : (
          <div>
            <div className="contentsWrapSpacer" />
            <BandTopButtons
              setting
              bandName={band.name}
              fromPage={`${t("New.SettingPage")}`}
              bandId={this.state.bandId}
              historyProp={history}
            />
            <SettingWrapper>
              {this.state.isBandMember && (
                <div>
                  <ProfileWrapper>
                    <br />
                    {/* <img src={ !profileImage ? NoProfileImage :  userProfileImage} alt="user-profile" /> */}
                    <img
                      src={!profileImage ? NoProfileImage : profileImage}
                      alt="user-profile"
                    />

                    <p>{profileName}</p>
                    <ProfileBackground />
                  </ProfileWrapper>

                  {/* <div>
                                        <b>내 설정</b><br /><br />
                                    </div>
                                    <GrayWrapper>
                                        <ToggleButtonSelect title="공통 프로필 적용"
                                            name="applyPublicProfile"
                                            defaultValue={this.state.applyPublicProfile}
                                            onChange={this.handleBandUserSettingChange}
                                        />
                                        <Divider />
                                        <PopupHandler title="이 밴드에서의 프로필 수정하기"
                                            disabled={this.state.applyPublicProfile}
                                            onClick={() => this.handleModal("isBandUserProfileSettingModalOpen", true)}
                                            profileEdit={() => this.profileEdit()}
                                        />
                                    </GrayWrapper>

                                    {this.state.isBandUserProfileSettingModalOpen && this.state.applyPublicProfile ?
                                        alert('이 밴드에서 프로필을 수정하려면 공통 프로필 적용을 OFF로 하셔야 합니다.')
                                        :
                                        this.state.isBandUserProfileSettingModalOpen &&
                                        <div className="modal_wrap">
                                            <div className="modal">
                                                <div className="close_btn" onClick={() => this.handleModal("isBandUserProfileSettingModalOpen", false)}></div>
                                                <p className="title">내 프로필 설정</p>
                                                <BandUserProfileSettingModal bandId={this.state.bandId}
                                                    show={this.state.isBandUserProfileSettingModalOpen}
                                                    onClose={() => this.handleModal("isBandUserProfileSettingModalOpen", false)} />
                                            </div>
                                            <div className="dim" onClick={() => this.handleModal("isBandUserProfileSettingModalOpen", false)} />
                                        </div>
                                    }
                                    <br /><br /> */}
                  <div>
                    <b>{t("Setting.notificationSettings")}</b>
                    <br />
                    <br />
                  </div>

                  <GrayWrapper>
                    <ToggleButtonSelect
                      title={
                        COMPANY_NAME === "무역센터"
                          ? "채팅 알림"
                          : `${COMPANY_NAME} TALK ${t("Setting.notification")}`
                      }
                      name="notifyThroughUcTalk"
                      defaultValue={
                        this.state.notifyThroughUcTalk * 1  === 0 ? false : true
                      }
                      onChange={this.handleNotifyThroughUcTalkChange}
                    />
                    <Divider />

                    <ToggleButtonSelect
                      title={t("Setting.commentNotification")}
                      name="replyNotificationType"
                      defaultValue={
                        this.state.replyNotificationType * 1 === 0 ? false : true
                      }
                      onChange={this.handleReplyNotificationTypeChange}
                    />

                    <Divider />

                    {/* <ToggleButtonSelect title="일정 알림"
                                                name="notifySchedule"
                                                defaultValue={this.state.notifySchedule}
                                                onChange={this.handleBandUserSettingChange}
                                            />
                                            <Divider /> */}

                    <ToggleButtonSelect
                      title={t("Setting.comtentNotification")}
                      name="notifyContent"
                      defaultValue={
                        this.state.notifyContent * 1 === 0 ? false : true
                      }
                      onChange={this.handleNotifyContentChange}
                    />
                    {/* <Divider />
                                            <ToggleButtonSelect title="채팅 알림"
                                                name="notifyChat"
                                                defaultValue={this.state.notifyChat}
                                                onChange={this.handleBandUserSettingChange}
                                            /> */}
                  </GrayWrapper>

                  <br />
                  <br />
                  {this.state.isBandLeader && (
                    <div>
                      <div>
                        <b>{t("Setting.bandInformationManagement")}</b>
                        <br />
                        <br />
                      </div>

                      <GrayWrapper>
                        <PopupHandler
                          title={t("Setting.bandInformationManagement")}
                          disabled={false}
                          onClick={() =>
                            this.handleModal("isBandInfoSettingModalOpen", true)
                          }
                        />

                        <Modal
                          contentLabel="Band Setting"
                          isOpen={this.state.isBandInfoSettingModalOpen}
                          onRequestClose={() =>
                            this.handleModal(
                              "isBandInfoSettingModalOpen",
                              false
                            )
                          }
                          style={CustomStyles}
                        >
                          <BandSettingModal
                            bandId={this.state.bandId}
                            show={this.state.isBandInfoSettingModalOpen}
                            onClose={() =>
                              this.handleModal(
                                "isBandInfoSettingModalOpen",
                                false
                              )
                            }
                          />
                        </Modal>

                        <Divider />
                        <div className=" w-1/2 font-bold py-1.5 inline-block">
                          {t("Setting.bandPublicType")}
                        </div>
                        <div className=" w-1/2 text-right inline-block">
                          <select
                            className="appearance-none outline-none "
                            style={{
                              color: THEME_BACKGROUND_COLOR,
                              direction: `rtl`,
                            }}
                            defaultValue={this.state.bandOpenType}
                            onChange={this.handleBandSettingChange}
                          >
                            <option value="0">
                              {t(`Band.Create.revealAll`)}
                            </option>
                            <option value="1">
                              {t(`Band.Create.nameDisclosure`)}
                            </option>
                            <option value="2">
                              {t(`Band.Create.private`)}
                            </option>
                          </select>
                        </div>
                      </GrayWrapper>

                      {COMPANY_NAME !== "무역센터" && (
                        <>
                          <br />
                          <br />
                          <div>
                            <b>{t("Setting.membershipManagement")}</b>
                            <br />
                            <br />
                          </div>
                          <GrayWrapper>
                            <ToggleButtonSelect
                              title={t("Setting.acceptJoinApproval")}
                              name="needToApprove"
                              defaultValue={this.state.needToApprove}
                              onChange={this.handleNeetToApproveChange}
                            />
                            {/* <Divider />

                                                    <ToggleButtonSelect title="멤버 가입 알림글"
                                                        name="createMemberJoinPost"
                                                        defaultValue={this.state.createMemberJoinPost}
                                                        onChange={this.handleBandSettingChange}
                                                    /> */}
                          </GrayWrapper>
                        </>
                      )}

                      <br />
                      <br />
                      <div>
                        <b>{t("Setting.memberActivityManagement")}</b>
                        <br />
                        <br />
                      </div>
                      <GrayWrapper>
                        <PopupHandler
                          title={t("Setting.coLeaderManagement")}
                          disabled={false}
                          onClick={() =>
                            this.handleModal(
                              "isBandLeaderSettingModalOpen",
                              true
                            )
                          }
                        />
                        <Divider />

                        {__ISIOS__ ? (
                          <ReactAwesomeModal
                            width="375px"
                            effect="fadeInUp"
                            visible={this.state.isBandLeaderSettingModalOpen}
                            onClickAway={() =>
                              this.handleModal(
                                "isBandLeaderSettingModalOpen",
                                false
                              )
                            }
                          >
                            <div style={{ padding: "1rem" }}>
                              <BandLeaderSettingModal
                                bandId={this.state.bandId}
                                title={t("Setting.coLeaderManagement")}
                                selectMultiple={true}
                                show={this.state.isBandLeaderSettingModalOpen}
                                onClose={() =>
                                  this.handleModal(
                                    "isBandLeaderSettingModalOpen",
                                    false
                                  )
                                }
                              />
                            </div>
                          </ReactAwesomeModal>
                        ) : (
                          <Modal
                            isOpen={this.state.isBandLeaderSettingModalOpen}
                            onRequestClose={() =>
                              this.handleModal(
                                "isBandLeaderSettingModalOpen",
                                false
                              )
                            }
                            style={CustomStyles}
                          >
                            <BandLeaderSettingModal
                              bandId={this.state.bandId}
                              title={t("Setting.coLeaderManagement")}
                              selectMultiple={true}
                              show={this.state.isBandLeaderSettingModalOpen}
                              onClose={() =>
                                this.handleModal(
                                  "isBandLeaderSettingModalOpen",
                                  false
                                )
                              }
                            />
                          </Modal>
                        )}

                        <PopupHandler
                          title={t("Setting.leaderDelegation")}
                          disabled={false}
                          onClick={() =>
                            this.handleModal(
                              "isBandLeaderAssignModalOpen",
                              true
                            )
                          }
                        />
                        <Divider />

                        {__ISIOS__ ? (
                          <ReactAwesomeModal
                            width="375px"
                            effect="fadeInUp"
                            visible={this.state.isBandLeaderAssignModalOpen}
                            onClickAway={() =>
                              this.handleModal(
                                "isBandLeaderAssignModalOpen",
                                false
                              )
                            }
                          >
                            <div style={{ padding: "1rem" }}>
                              <BandLeaderSettingModal
                                bandId={this.state.bandId}
                                title={t("Setting.leaderDelegation")}
                                selectMultiple={false}
                                show={this.state.isBandLeaderAssignModalOpen}
                                onClose={() =>
                                  this.handleModal(
                                    "isBandLeaderAssignModalOpen",
                                    false
                                  )
                                }
                              />
                            </div>
                          </ReactAwesomeModal>
                        ) : (
                          <Modal
                            isOpen={this.state.isBandLeaderAssignModalOpen}
                            onRequestClose={() =>
                              this.handleModal(
                                "isBandLeaderAssignModalOpen",
                                false
                              )
                            }
                            style={CustomStyles}
                          >
                            <BandLeaderSettingModal
                              bandId={this.state.bandId}
                              title={t("Setting.leaderDelegation")}
                              selectMultiple={false}
                              show={this.state.isBandLeaderAssignModalOpen}
                              onClose={() =>
                                this.handleModal(
                                  "isBandLeaderAssignModalOpen",
                                  false
                                )
                              }
                            />
                          </Modal>
                        )}

                        <PopupHandler
                          title={t("Setting.forcedMemberWithdrawal")}
                          disabled={false}
                          onClick={() =>
                            this.handleModal(
                              "isBandMemberDeportModalOpen",
                              true
                            )
                          }
                        />
                        <Divider />

                        {__ISIOS__ ? (
                          <ReactAwesomeModal
                            width="375px"
                            effect="fadeInUp"
                            visible={this.state.isBandMemberDeportModalOpen}
                            onClickAway={() =>
                              this.handleModal(
                                "isBandMemberDeportModalOpen",
                                false
                              )
                            }
                          >
                            <div style={{ padding: "1rem" }}>
                              <BandMemberManageModal
                                bandId={this.state.bandId}
                                show={this.state.isBandMemberDeportModalOpen}
                                onClose={() =>
                                  this.handleModal(
                                    "isBandMemberDeportModalOpen",
                                    false
                                  )
                                }
                              />
                            </div>
                          </ReactAwesomeModal>
                        ) : (
                          <Modal
                            isOpen={this.state.isBandMemberDeportModalOpen}
                            onRequestClose={() =>
                              this.handleModal(
                                "isBandMemberDeportModalOpen",
                                false
                              )
                            }
                            style={CustomStyles}
                          >
                            <BandMemberManageModal
                              bandId={this.state.bandId}
                              show={this.state.isBandMemberDeportModalOpen}
                              onClose={() =>
                                this.handleModal(
                                  "isBandMemberDeportModalOpen",
                                  false
                                )
                              }
                            />
                          </Modal>
                        )}

                        <PopupHandler
                          title={t("Setting.blockMemberList")}
                          disabled={false}
                          onClick={() =>
                            this.handleModal(
                              "isDeportedBandMemberModalOpen",
                              true
                            )
                          }
                        />

                        {__ISIOS__ ? (
                          <ReactAwesomeModal
                            width="375px"
                            effect="fadeInUp"
                            visible={this.state.isDeportedBandMemberModalOpen}
                            onClickAway={() =>
                              this.handleModal(
                                "isDeportedBandMemberModalOpen",
                                false
                              )
                            }
                          >
                            <div style={{ padding: "1rem" }}>
                              <BandDeportedMemberManageModal
                                bandId={this.state.bandId}
                                show={this.state.isDeportedBandMemberModalOpen}
                                onClose={() =>
                                  this.handleModal(
                                    "isDeportedBandMemberModalOpen",
                                    false
                                  )
                                }
                              />
                            </div>
                          </ReactAwesomeModal>
                        ) : (
                          <Modal
                            isOpen={this.state.isDeportedBandMemberModalOpen}
                            onRequestClose={() =>
                              this.handleModal(
                                "isDeportedBandMemberModalOpen",
                                false
                              )
                            }
                            style={CustomStyles}
                          >
                            <BandDeportedMemberManageModal
                              bandId={this.state.bandId}
                              show={this.state.isDeportedBandMemberModalOpen}
                              onClose={() =>
                                this.handleModal(
                                  "isDeportedBandMemberModalOpen",
                                  false
                                )
                              }
                            />
                          </Modal>
                        )}
                      </GrayWrapper>

                      <br />
                      <br />
                      <div>
                        <b>{t("Setting.bandMenuManagement")}</b>
                        <br />
                        <br />
                      </div>
                      <GrayWrapper>
                        {/* <PopupHandler title="대표 태그 설정"
                                                    disabled={false}
                                                    onClick={() => this.handleModal("isBandTagSettingModalOpen", true)}
                                                />
                                                <Divider /> */}

                        <Modal
                          isOpen={this.state.isBandTagSettingModalOpen}
                          onRequestClose={() =>
                            this.handleModal("isBandTagSettingModalOpen", false)
                          }
                          style={CustomStyles}
                        >
                          <BandTagSettingModal
                            bandId={this.state.bandId}
                            show={this.state.isBandTagSettingModalOpen}
                            onClose={() =>
                              this.handleModal(
                                "isBandTagSettingModalOpen",
                                false
                              )
                            }
                          />
                        </Modal>

                        {/* <PopupHandler title="일정 카테고리 관리"
                                                    disabled={false}
                                                    onClick={() => this.handleModal("isScheduleCategorySettingModalOpen", true)}
                                                />
                                                <Divider />

                                                {this.state.isScheduleCategorySettingModalOpen &&
                                                    <div className="modal_wrap ">
                                                        <div className="category">
                                                            <div className="close_btn" style={{ marginRight: '1rem' }} onClick={() => this.handleModal("isScheduleCategorySettingModalOpen", false)}></div>
                                                            <ScheduleCategorySettingModal bandId={this.state.bandId}
                                                                show={this.state.isScheduleCategorySettingModalOpen}
                                                                onClose={() => this.handleModal("isScheduleCategorySettingModalOpen", false)} />
                                                        </div>
                                                        <div className="dim" onClick={() => this.handleModal("isScheduleCategorySettingModalOpen", false)} />
                                                    </div>
                                                } */}

                        <PopupHandler
                          title={t("Setting.contentStorageSpaceManagement")}
                          disabled={false}
                          onClick={() => this.getDiskStorageUsage()}
                        />

                        <Modal
                          isOpen={
                            this.state.isContentStorageManagementModalOpen
                          }
                          onRequestClose={() =>
                            this.handleModal(
                              "isContentStorageManagementModalOpen",
                              false
                            )
                          }
                          style={CustomStyles}
                        >
                          <ContentStorageManagementModal
                            diskUsage={this.state.diskUsage}
                            show={
                              this.state.isContentStorageManagementModalOpen
                            }
                            onClose={() =>
                              this.handleModal(
                                "isContentStorageManagementModalOpen",
                                false
                              )
                            }
                          />
                        </Modal>
                      </GrayWrapper>

                      <br />
                      <br />
                    </div>
                  )}
                  <div>
                    <b>{t("Setting.other")}</b>
                    <br />
                    <br />
                  </div>
                  <GrayWrapper>
                    <div style={{ marginBottom: "-10px", marginTop: "5px" }}>
                      <button
                        style={{
                          color: "red",
                          width: "100%",
                          textAlign: "left",
                        }}
                        onClick={() =>
                          this.handleModal(
                            "isBandWithdrawConfirmModalOpen",
                            true
                          )
                        }
                      >
                        {t("Setting.bandWithdrawal")}
                      </button>
                      <br />
                      <br />
                    </div>

                    <Modal
                      isOpen={this.state.isBandWithdrawConfirmModalOpen}
                      onRequestClose={() =>
                        this.handleModal(
                          "isBandWithdrawConfirmModalOpen",
                          false
                        )
                      }
                      style={CustomStyles}
                    >
                      <BandWithdrawConfirmModal
                        withdrawBand={this.withdrawBand}
                        isBandLeader={this.state.isBandLeader}
                        show={this.state.isBandWithdrawConfirmModalOpen}
                        onClose={() =>
                          this.handleModal(
                            "isBandWithdrawConfirmModalOpen",
                            false
                          )
                        }
                      />
                    </Modal>

                    {this.state.isBandLeader && (
                      <>
                        <Divider />
                        <div
                          style={{ marginTop: "18px", marginBottom: "-16px" }}
                        >
                          <button
                            style={{
                              color: "red",
                              width: "100%",
                              textAlign: "left",
                            }}
                            onClick={() =>
                              this.handleModal(
                                "isBandDeleteConfirmModalOpen",
                                true
                              )
                            }
                          >
                            {t("Setting.deleteBand")}
                          </button>
                          <br />
                          <br />
                        </div>
                      </>
                    )}

                    <Modal
                      isOpen={this.state.isBandDeleteConfirmModalOpen}
                      onRequestClose={() =>
                        this.handleModal("isBandDeleteConfirmModalOpen", false)
                      }
                      style={CustomStyles}
                    >
                      <BandDeleteConfirmModal
                        deleteBand={this.deleteBand}
                        show={this.state.isBandDeleteConfirmModalOpen}
                        onClose={() =>
                          this.handleModal(
                            "isBandDeleteConfirmModalOpen",
                            false
                          )
                        }
                      />
                    </Modal>
                  </GrayWrapper>
                </div>
              )}
              {!this.state.isBandMember && (
                <div>
                  <div>{t("Setting.other")}</div>
                  <div>
                    <button>{t("Setting.joinTheMeeting")}</button>
                    <br />
                    <br />
                  </div>
                </div>
              )}
            </SettingWrapper>
            <Spacer />
            <br />
            <br />
            <BandMenu
              pathName="setting"
              bandId={this.state.bandId}
              historyProp={history}
            />
          </div>
        )}
      </div>
    );
  }
}

class PopupHandler extends React.Component {
  title;
  disabled;
  onClick;

  render() {
    return (
      <Container>
        <Title popupHandler>{this.props.title}</Title>
        <Button isSafari={isSafari}>
          <button
            disabled={
              this.props.title === "이 밴드에서의 프로필 수정하기"
                ? false
                : this.props.disabled
            }
            onClick={this.props.onClick}
          >
            <img src={btnMore} alt="btn-more" />
          </button>
        </Button>
      </Container>
    );
  }
}

const ProfileBackground = styled.div`
  background: url(${profileBackgroundTwo}) no-repeat center center;
  background-size: cover;
  opacity: 0.6;
  display: inline-block;
  width: 100%;
  height: 189px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
`;

const ProfileWrapper = styled.div`
  width: 100%;
  height: 189px;
  position: relative;
  margin-bottom: 20px;
  img {
    display: flex;
    margin: 0 auto;
    margin-top: 7px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: 1;
    position: relative;
  }

  p {
    text-align: center;
    font-size: 2rem;
    margin-top: 10px;
    z-index: 1;
    position: relative;
  }
`;

const Divider = styled.div`
  border-bottom: solid 1px #e5e5ea;
  margin: 7px 0;
`;

const GrayWrapper = styled.div`
  background-color: #f9f9fb;
  padding: 1rem;
  border-top: solid 1.5px #e5e5ea;
  border-bottom: solid 1.5px #e5e5ea;
`;

const SettingWrapper = styled.div`
  padding: 0rem 0rem 0rem 0;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 4px;
  border-bottom: 0px;
  b {
    padding: 0 0 0 1rem;
    font-size: 1.23rem;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: 6%;
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 0;
`;

const Title = styled.div`
  margin-top: ${(props) => (props.popupHandler ? "5px" : null)};
  width: 50%;
  text-align: left;
  font-weight: bold;
`;

const Button = styled.div`
  width: 50%;
  text-align: right;
  button {
    height: ${(props) => (props.isSafari ? "35px" : null)};
    margin-top: ${(props) => (props.isSafari ? "-5px" : null)};
  }
`;

export default withTranslation()(Setting);
Modal.setAppElement("#root");

const CustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zIndex: 1000 },
};
