/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import { SimpleEditor } from '../Edit';

import * as BandService from '../../../services/BandService';
import * as FileService from '../../../services/FileService';
import * as ReplyService from '../../../services/ReplyService';

import DialogComp from '../../../common/components/Shared/DialogComp';

import * as DateTimeUtils from '../../../utils/DateTimeUtils';
import * as StringUtils from '../../../utils/StringUtils';
import { removePathFromFileName } from '../../../utils/CommonUtils';
import { Comment, Avatar, List } from 'antd';
import ListSkeleton from '../Shared/Skeleton/ListSkeleton';
import { SERVER_URI, COMPANY_NAME } from '../../../configs';
import Modal from "react-awesome-modal";
import NoImage from '../../../assets/images/no_self_img.png';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class ReplyListItem extends React.Component {

    _isMounted = false;

    bandId;
    item;
    onClick;

    constructor(props) {
        super(props);

        this.state = {
            avatar: NoImage,
            writer: undefined,
            fileInfo: undefined,
            loading: true,
            menuDisplay: false,
            replyMenuTrigger: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getUserProfile();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item.fileId !== this.props.item.fileId) {
            this.getFileInfo();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getUserProfile = () => {
        BandService.getSingleBandMember(this.props.bandId, this.props.item.createdBy)
            .then(member => {
                let avatar;
                if (!StringUtils.isNullOrUndefined(member.profileImage)) {
                    avatar = `${member.profileImage}`;
                } else {
                    avatar = NoImage;
                }
                if (this._isMounted) {
                    this.setState({
                        avatar: avatar,
                        writer: member,
                    }, this.getFileInfo);
                }
            })
            .catch(error => console.error(error));
    };

    getFileInfo = () => {
        if (this.props.item.fileId) {
            FileService.getFileInfoNode(this.props.bandId, this.props.item.fileId)
                .then(fileInfo => {
                    if (this._isMounted) {
                        this.setState({
                            fileInfo: fileInfo,
                            loading: false,
                        });
                    }
                })
                .catch(error => console.error(error));
        } else {
            if (this._isMounted) {
                this.setState({
                    fileInfo: undefined,
                    loading: false,
                })
            }
        }
    };

    handleMenuDisplay = (isOpen) => {
        this.setState({ menuDisplay: isOpen });
    };


    toDataURL = (url, fileName) => {
        return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                return window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                return URL.createObjectURL(blob);

            }
        });
    }

    download = async (url, fileName) => {
        if (__ISMSIE__) {
            console.log('테스트1');
            const link = document.createElement('a');
            link.href = await this.toDataURL(url, fileName);
            this.close();
        } else {
            console.log('테스트2');
            const link = document.createElement('a');
            link.href = await this.toDataURL(url, fileName);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    };


    renderFile = (bandId, fileInfo) => {
        let imageSRC = '';
        //fileInfo.thumbnail이 아니라 fileInfo.fileName 으로 로드해야함 
        //if (COMPANY_NAME !== "UC WARE") {
        imageSRC = `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${fileInfo.fileName}`
        //} else {
        //    imageSRC = `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${fileInfo.thumbnail}`
        //}

        console.log("밴드아이디"+bandId);
        console.log("파일아이디"+fileInfo.fileName);

        console.log('서버uri : '+`${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${fileInfo.fileName}`);

        if (fileInfo.fileType === "IMAGE") {
            return <a onClick={() => this.download(`${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${fileInfo.fileName}`, fileInfo.fileName)}>
                <img src={imageSRC}
                    style={{ width: '150px', height: '100px', marginRight: '5px' }}
                    alt={removePathFromFileName(fileInfo.originalFileName)} />
            </a>
        } else if (fileInfo.fileType === "VIDEO") {
            return <div>
                <a onClick={() => this.download(`${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${fileInfo.fileName}`, fileInfo.fileName)}>
                {removePathFromFileName(fileInfo.originalFileName)}
                <video src={`${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${fileInfo.fileName}`}
                    style={{ width: '150px', height: '100px', marginRight: '5px' }} controls />
                </a>
            </div>
        } else {
            return <div>
                <a
                    onClick={() => this.download(`${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${fileInfo.fileName}`, fileInfo.fileName)}
                >{removePathFromFileName(fileInfo.originalFileName)}</a>
            </div>
        }

    }

    render() {
        const { bandId, item, fromPostList } = this.props;
        const { loading, writer, avatar, fileInfo } = this.state;

        const writerName = writer ? writer.name : "";
        // const writerJobTitle = writer ? writer.jobTitle : "";
        const writerId = writer ? writer.userId : "";
        const content = item && item.reply && item.reply
            .split('<p>').join('<p class="paragraph">')
            .split('</p>').join('</p>');

        const actions = (writerId === Number(localStorage.getItem('userId'))) ? [<a onClick={() => this.props.onClick(item.id, "modify")}>
            <EditOutlined style={{ fontSize: '16px', color: '#08c' }} />
        </a>, <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>,
        <a onClick={() => this.props.onClick(item.id, "delete")}><DeleteOutlined style={{ fontSize: '16px', color: '#08c' }} /></a>] : [""]
        return (
            <div>
                {loading ? <div><ListSkeleton /></div> :
                    <>
                        <Comment
                            actions={!fromPostList && actions}
                            author={`${writerName}  
                             | ${DateTimeUtils.dateTimeDisplay(item.createdAt)}`}
                            avatar={<Avatar size="large" src={avatar} alt={writerName} />}
                            content={<div dangerouslySetInnerHTML={{ __html: content }} />}
                        >

                            {/* <Comment
                            actions={!fromPostList && actions}
                            author={`${writerName}  
                            ${!StringUtils.isNullOrUndefined(writerJobTitle) && `| ${writerJobTitle}`}
                             | ${DateTimeUtils.dateTimeDisplay(item.createdAt)}`}
                            avatar={<Avatar size="large" src={avatar} alt={writerName} />}
                            content={<div dangerouslySetInnerHTML={{ __html: content }} />}
                        > */}

                            {fileInfo && this.renderFile(bandId, fileInfo)}
                        </Comment>

                    </>
                }
            </div>
        );
    }

}

class ReplyList extends React.Component {

    _isMounted = false;

    bandId;
    targetId;
    replyAt;
    replyList;
    refreshFunction;

    constructor(props) {
        super(props);

        this.state = {
            selected: undefined,
            modify: false,
            delete: false,
            isOpenFileTransferProgress: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    openPopup = (selected, action) => {
        this.handlePopup(selected, action, true);
    };

    handlePopup = (selected, name, value) => {
        if (this._isMounted) {
            this.setState({
                selected: selected,
                [name]: value,
            });
        }
    };

    modify = (reply, file, fileChangeType) => {
        const bandId = this.props.bandId;
        const replyId = this.state.selected;

        this.setState({ isOpenFileTransferProgress: true })

        if (fileChangeType === "ADD") {
            FileService.uploadFileNode(bandId, localStorage.getItem('userId'), file)
                .then(response => ReplyService.updateReplyNode(bandId, replyId, reply, response.data.results.id))
                .then(() => this.props.refreshFunction())
                .then(() => this.setState({ isOpenFileTransferProgress: false }))
                .catch(error => console.error(error));
        } else if (fileChangeType === "NONE") {
            ReplyService.updateReplyNode(bandId, replyId, reply, file)
                .then(() => this.props.refreshFunction())
                .then(() => this.setState({ isOpenFileTransferProgress: false }))
                .catch(error => console.error(error));
        } else if (fileChangeType === "CHANGED") {
            FileService.uploadFileNode(bandId, localStorage.getItem('userId'), file)
                .then(response => ReplyService.updateReplyNode(bandId, replyId, reply, response.data.results.id))
                .then(() => this.props.refreshFunction())
                .then(() => this.setState({ isOpenFileTransferProgress: false }))
                .catch(error => console.error(error));
        } else if (fileChangeType === "REMOVED") {
            ReplyService.updateReplyNode(bandId, replyId, reply, undefined)
                .then(() => this.props.refreshFunction())
                .then(() => this.setState({ isOpenFileTransferProgress: false }))
                .catch(error => console.error(error));
        }
        this.handlePopup(undefined, "modify", false);
    };

    delete = () => {
        ReplyService.deleteReplyNode(this.props.bandId, this.state.selected, this.props.postId)
            .then(() => {
                this.props.refreshFunction();
                this.handlePopup(undefined, "delete", false);
            })
            .catch(error => {
                console.error(error);
                this.handlePopup(undefined, "delete", false);
            });
    };

    render() {
        const { replyList, bandId, fromPostList } = this.props;

        return (
            <List>
                {replyList.map(item =>
                    <ReplyListItem fromPostList={fromPostList} key={item.id} bandId={bandId} item={item}
                        onClick={this.openPopup} />)
                }
                <Modal style={{ zIndex: 5 }} visible={this.state.modify} onClickAway={() => this.handlePopup(undefined, "modify", false)}>
                    <SimpleEditor reply placeholder="댓글을 입력해주세요" onSubmit={this.modify}
                        bandId={bandId} defaultValue={this.state.selected} />
                </Modal>

                <div className="reply-modal">
                    <Modal footer={null} visible={this.state.delete} onCancel={() => this.handlePopup(undefined, "delete", false)}>
                        <div style={{ padding: '1rem', textAlign: 'center' }}>
                            <div style={{ fontSize: '1.4rem' }}>삭제 하시겠습니까?</div>
                            <br />
                            <div style={{ fontSize: '1.2rem' }}>
                                <button onClick={() => this.delete()}>예</button>
                                <Divider />
                                <button onClick={() => this.handlePopup(undefined, "delete", false)}>아니오</button>
                            </div>
                        </div>
                    </Modal>
                </div>


                <DialogComp description={"파일이 업로드 중입니다."} open={this.state.isOpenFileTransferProgress} />

            </List>

        );
    }
}

const Divider = styled.div`
    border-bottom:  solid 1px #e5e5ea;
    margin:  0.7rem 0
`;

export default ReplyList;


