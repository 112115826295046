import React, { useState } from 'react'
import { Layout } from 'antd';

import {
    MenuUnfoldOutlined,
    MenuFoldOutlined
} from '@ant-design/icons';
import './Layout.css';
import Logo from "./SideNav/Logo/Logo";
import Menu from "./SideNav/Menu/Menu";

const { Header, Sider, Content } = Layout;

function AdminLayout(props) {

    const [collapsed, setCollapsed] = useState(true)

    const toggle = () => {
        setCollapsed(prev => (!prev))
    };

    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <Logo />
                <Menu />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: toggle,
                    })}
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    {props.children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default AdminLayout
