import React from 'react';
import '../../../assets/css/_modal.scss';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

class ContentNameChangeModal extends React.Component {

    onClose;
    show;
    bandId;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            members: [],
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { history, t } = this.props;

        if (!this.props.show) {
            return null;
        }

        // const { t } = this.props;

        return (
            <div className="modal_wrap">
                <div className="modal">
                     <button className="close_btn" aria-label="모달창 닫기 버튼"onClick={this.props.onClose}></button>
                    <div>
                        <p className="title"> {t("ContentsNameChangeModal.title")} </p>
                        <p className="subtitle"> {t("ContentsNameChangeModal.placeholder")} </p>

                        <div className="modal_content view_order">
                            <div>
                                <ChangeName type="text" value={this.props.changeName}
                                    onChange={this.props.handleNameChange} />
                            </div>
                        </div>
                        <div>
                            <button className="modal_submit_btn" onClick={this.props.submitChangeName}>
                                {t("ContentsNameChangeModal.ok")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withTranslation()(ContentNameChangeModal);

const ChangeName = styled.input`
    border: solid 1px #eee;
    width: 100%;
    border-radius: 6px;
    background-color: #efeff4;
    height: 24px;
 
`;