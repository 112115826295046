import React from 'react';
import styled from 'styled-components';

import * as CommonUtils from '../../../utils/CommonUtils';
import * as StringUtils from '../../../utils/StringUtils';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Radio from '@material-ui/core/Radio';
import Modal from "react-awesome-modal";
import { withTranslation } from 'react-i18next';

import IconGear from '../../../assets/images/icon-edit.svg';
import IconLeader from '../../../assets/images/icon-leader.svg';
import NoImage from '../../../assets/images/no_self_img.png';
import ReactTooltip from 'react-tooltip'

class MemberListItem extends React.Component {

    _isMounted = false;

    item;
    routeFunction;
    selectable;
    selectMultiple;
    selected;
    onChange;
    settingFunction;
    deportFunction;
    deportTitle;
    isBandLeader;

    state = {
        deportModalOpen: false,
        chosenDeportedId: undefined,
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    selectItem = (e) => {
        if (this.props.routeFunction) {
            this.props.routeFunction(e.currentTarget.id);
        }
    };

    settingFunction = (e) => {
        if (this.props.settingFunction) {
            this.props.settingFunction(e.currentTarget.id);
        }
    };

    deportFunction = (e) => {
        const variable = this.props.FromDeportedMemberManagePage ? e.currentTarget.id : this.state.chosenDeportedId;

        if (this.props.deportFunction) {
            this.props.deportFunction(variable);
        }
    };

    deportModalOpen = (e) => {
        this.setState({
            chosenDeportedId: e.currentTarget.id,
            deportModalOpen: true
        })
    }

    deportModalClose = () => {
        this.setState({
            chosenDeportedId: undefined,
            deportModalOpen: false
        })
    }

    render() {
        const { item, bandLeaders, FromMembersPage, isBandLeader, t } = this.props;
        const isCurrentUser = Number(localStorage.getItem('userId')) === item.userId;

        let LeaderTrue = false;

        if (FromMembersPage) {
            bandLeaders.map((bandLeader, index) => {
                if (bandLeader === item.userId) {
                    return LeaderTrue = true;
                }
                return null;
            })
        }

        return (
            <>
                <List component="div" disablePadding >
                    <ListItem id={item.id} onClick={this.selectItem}>
                        <ListItemAvatar>
                            {/* <Avatar src={item && item.profileImage ? `${SERVER_URI}:8061/resource/profile/${item.profileImage}` : NoImage} alt="avatar" /> */}
                            <Avatar src={item && item.profileImage ? `${item.profileImage}` : NoImage} alt="avatar" />

                            {LeaderTrue && <LeaderImage src={IconLeader} alt="icon-leader" />}
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    {item.name} &nbsp;&nbsp;
                                    <span style={{ fontSize: '14px', color: 'gray' }}>
                                        {!StringUtils.isNullOrUndefined(item.departmentName) && item.departmentName}
                                        {!StringUtils.isNullOrUndefined(item.jobTitle) && item.jobTitle}
                                    </span>
                                </React.Fragment>
                            }
                        />
                        <ListItemSecondaryAction>
                            {this.props.selectable &&
                                (this.props.selectMultiple ?

                                    <Checkbox
                                        color="primary"
                                        onChange={() => this.props.onChange(item.userId)}
                                        checked={this.props.selected}
                                        inputProps={{ 'aria-labelledby': 'checkbox' }}
                                    />
                                    :
                                    <Radio
                                        color="primary"
                                        checked={this.props.selected}
                                        onChange={() => this.props.onChange(item.userId)}
                                        value="a"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />

                                )
                            }

                            {!this.props.selectable &&
                                <>
                                    {isCurrentUser ?
                                        (this.props.settingFunction &&
                                            <button id={item.id} onClick={this.settingFunction}>
                                                <img src={IconGear} alt="icon-gear" data-tip data-for={`icon-gear`} />
                                                <ReactTooltip place="left" id={`icon-gear`} >
                                                    <span>{t('New.SettingPage')}</span>
                                                </ReactTooltip>
                                            </button>) :
                                        (isBandLeader &&
                                            <DePortButton id={item.userId} FromDeportedMemberManagePage={this.props.FromDeportedMemberManagePage} onClick={this.props.FromDeportedMemberManagePage ? this.deportFunction : this.deportModalOpen}>{this.props.deportTitle}</DePortButton>
                                        )
                                    }
                                </>
                            }

                        </ListItemSecondaryAction>
                    </ListItem>
                </List>

                <Modal visible={this.state.deportModalOpen} onClickAway={() => this.deportModalClose()}>
                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                        <div style={{ fontSize: '1.4rem' }}>{t('MemberList.wouldYouLikeToKickOut')}</div>
                        <br />
                        <div style={{ fontSize: '1.2rem' }}>
                            <button style={{ color: 'red' }} onClick={() => this.deportFunction()}>{t('MemberList.yes')}</button>
                            <Divider />
                            <button onClick={() => this.deportModalClose()}>{t('MemberList.no')}</button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }

}

class MemberList extends React.Component {

    memberList;
    routeFunction;
    selectable;
    selectMultiple;
    selected;
    onSelect;
    settingFunction;
    deportFunction;
    deportTitle;
    isBandLeader;

    render() {
        return (
            <List style={{ overflowY: this.props.memberPage && 'auto' }} >
                {this.props.memberList && this.props.memberList.map(item => {
                    let selected = false;
                    if (this.props.selected) {
                        if (this.props.selected === item.userId) {
                            selected = true;
                        } else if (CommonUtils.contains(this.props.selected, item.userId)) {
                            selected = true;
                        }
                    }
                    return <MemberListItem
                        t={this.props.t}
                        key={`member-list-item-${item.id}`}
                        item={item}
                        routeFunction={this.props.routeFunction}
                        selectable={this.props.selectable}
                        selectMultiple={this.props.selectMultiple}
                        selected={selected}
                        onChange={this.props.onSelect}
                        settingFunction={this.props.settingFunction}
                        deportFunction={this.props.deportFunction}
                        deportTitle={this.props.deportTitle}
                        isBandLeader={this.props.isBandLeader}
                        bandLeaders={this.props.bandLeaders}
                        FromMembersPage={this.props.FromMembersPage}
                        FromDeportedMemberManagePage={this.props.FromDeportedMemberManagePage}
                    />
                })}
            </List>
        );
    }

}

const DePortButton = styled.button`
    color: ${props => props.FromDeportedMemberManagePage ? "blue" : "red"};
    border: 1px solid;
    border-radius: 11px;
    padding: 5px;
    font-size: 11px;
`;

const LeaderImage = styled.img`
    height: 18px;
    width: 18px;
    object-fit: contain;
    margin-top: -13px;
    margin-left: 30px;
    position: absolute;
`;

const Divider = styled.div`
    border-bottom:  solid 1px #e5e5ea;
    margin:  0.7rem 0
`;

export default withTranslation()(MemberList);
