import React from 'react';
import * as BandService from '../../../services/BandService';
import * as StringUtils from '../../../utils/StringUtils';
import btnEditPic from '../../../assets/images/btn-edit-pic.svg';
import NoImage from '../../../assets/images/no_self_img.png';

class BandUserProfileSettingModal extends React.Component {

    onClose;
    show;
    bandId;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            profileImageFile: undefined,
            profilePreview: NoImage,
            profileImageChanged: false,
            nickname: '',
            name: undefined,
            jobTitle: undefined,
        };

        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;

        console.log(this.props.bandId, localStorage.getItem('userId'))

        BandService.getSingleBandMember(this.props.bandId, localStorage.getItem('userId'))
            .then(user => {
                console.log('user',user)
                let profileImage;
                if (!StringUtils.isNullOrUndefined(user.profileImage)) {
                    // profileImage = `${SERVER_URI}:8061/resource/profile/${user.profileImage}`;
                    profileImage = `${user.profileImage}`;                
                } else {
                    profileImage = NoImage;
                }
                this.setState({
                    profilePreview: profileImage,
                    nickname: user.nickname,
                    name: user.name,
                    jobTitle: user.jobTitle,
                });
            })
            // .catch(error => alert('ㅑ머냐야머냐어'));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    selectProfileImage = (e) => {
        e.preventDefault();
        this.inputOpenFileRef.current.click();
    };

    handleImagePreview = (e) => {
        e.stopPropagation();
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            if (this._isMounted) {
                this.setState({
                    profileImageFile: file,
                    profilePreview: reader.result,
                    profileImageChanged: true,
                });
            }
        };

        reader.readAsDataURL(file);
    };

    handleNickname = (e) => {
        if (this._isMounted) {
            this.setState({
                nickname: e.currentTarget.value,
            })
        }
    };

    // submit = () => {
    //     if (this.state.profileImageChanged) {
    //         BandService.uploadBandUserProfileImage(this.props.bandId, localStorage.getItem('userId'), this.state.profileImageFile)
    //             .then(() => BandService.updateBandUserProfile(this.props.bandId, localStorage.getItem('userId'), this.state.nickname))
    //             .then(() => this.props.onClose())
    //             .then(()=> {  window.location.reload()})
    //             .catch(error => console.error(error));
    //     } else {
    //         BandService.updateBandUserProfile(this.props.bandId, localStorage.getItem('userId'), this.state.nickname)
    //             .then(() => this.props.onClose())
    //             .catch(error => console.error(error));
    //     }
    // };

    render() {
        if (!this.props.show) {
            return null;
        }

        const { profilePreview, name, jobTitle, nickname } = this.state;

        return (
            <div className="modal_content_userProfile">

                <img className="myThumb"
                    src={profilePreview === undefined ? NoImage : profilePreview } alt={nickname}
                    //onClick={this.selectProfileImage}
                />
                 <img
                    className="btn_editPic"
                    src={btnEditPic} alt="btn_editPic"
                />

                <div className="userName">{name}</div>
                <div className="userInfo">{jobTitle}</div>
                {/* <hr className="container" /> */}
                {/* <div className="label_nickName">
                    <span style={{ wordBreak: 'keep-all' }}>닉네임</span> <input className="inputBox" value={nickname} onChange={this.handleNickname} />
                </div>
                <hr className="container" /> */}
                {/* <button className="modal_submit_btn" onClick={this.submit}>
                            저장
                </button> */}

                <input type="file" accept="image/*" ref={this.inputOpenFileRef} style={{ display: "none" }} onChange={this.handleImagePreview} />

            </div>
        );
    }



}

export default BandUserProfileSettingModal;
