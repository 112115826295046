import React, { Component } from 'react';
import * as UserService from "../services/UserService";
import { withTranslation } from 'react-i18next';

export default function (ComposedClass) {
    class HOC extends Component {
        componentDidMount() {
            UserService.getUserProfileNode(localStorage.getItem('userId'))
                .then(response => {
                    localStorage.setItem('userAccount', response.ucTalkId)
                })
                .catch(err => alert(err))
        }
        render() {
            return (
                <ComposedClass {...this.props} />
            );
        }
    }

    return withTranslation()(HOC)
}


