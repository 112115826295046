import { SERVER_URI, BODY_JSON } from '../configs';
import * as HttpHandler from '../utils/HttpHandler';
import axios from 'axios';

export function getMyInfo(userId) {
    return axios.get(`${SERVER_URI}:5000/api/user?userId=${userId}`)
        .then(response => { return response.data })
}

export function getUserId() {
    return HttpHandler
        .httpRequestFirst(
            `${SERVER_URI}:8765/ucband/user/api/v1/users/me`,
            'GET',
            BODY_JSON,
            undefined,
        );
}

export function getUserIdNode() {

    const headers = { Authorization: `${localStorage.getItem('accessToken')}` };

    return axios.get(`${SERVER_URI}:5000/api/user/me`, { headers })
        .then(response => { return response.data })

}

///////////////////////////////////// 현재 안쓰는 function ///////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////

function handleLogin(response) {
    if (response.ok) {
        return response.text()
            .then(text => text && JSON.parse(text));
    } else {
        const error = `[${response.status}] ${response.statusText}`;
        return Promise.reject(error);
    }
}



export function login(ucTalkId, password) {
    const body = {
        ucTalkId: ucTalkId,
        password: password,
    };

    const headers = new Headers();
    headers.append("Content-Type", "application/json;charset=UTF-8");

    const requestOptions = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    };

    return fetch(`${SERVER_URI}:8765/ucband/user/ucband/user/api/v1/users/login`, requestOptions)
        .then(handleLogin)
        .then(response => {
            if (response) {
                console.log(response)
                // rootStore.authStore.setToken(response);
                return response;
            }
        });
}



export function refreshToken(refreshToken) {
    const body = {
        refreshToken: refreshToken,
    };

    const headers = new Headers();
    headers.append("Content-Type", "application/json;charset=UTF-8");

    const requestOptions = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    };

    return fetch(`${SERVER_URI}:8765/ucband/user/api/v1/users/token/refresh`, requestOptions)
        .then(handleLogin)
        .then(response => {
            if (response) {
                // rootStore.authStore.setToken(response);
                return response;
            }
        });
}