import React from 'react';
import styled from "styled-components";
import '../../../assets/css/_modal.scss';
import { THEME_BACKGROUND_COLOR } from '../../../configs';
import ListSkeleton from '../Shared/Skeleton/ListSkeleton';

import * as ScheduleService from '../../../services/ScheduleService';

const colors = [
    "#ff2d55",
    "#ff9500",
    "#ffcc00",
    "#4cd964",
    "#07b83c",
    "#007aff",
    "#5856d6",
    "#05d4b1",
    "#00c9ff",
    "#0037c7",
    "#db0e61",
    "#f349dd",
    "#ff8ac8",
    "#d1d1d6",
    "#8e8e93",
    "#000000",
];

class ScheduleCategorySettingModal extends React.Component {

    show;
    bandId;
    onClose;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            scheduleCategories: [],
            inputScheduleCategoryName: '',
            selected: [],
            selectedColor: 0,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadScheduleCategories();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadScheduleCategories = () => {
        ScheduleService.getBandScheduleCategoryListNode(this.props.bandId)
            .then(list => {
                if (this._isMounted) {
                    this.setState({
                        scheduleCategories: list,
                        loading: false,
                    });
                }
            })
            .catch(error => console.error(error));
    };

    onChangeName = (e) => {
        if (this._isMounted) {
            this.setState({ inputScheduleCategoryName: e.currentTarget.value });
        }
    };

    onChangeColor = (value) => {
        if (this._isMounted) {
            this.setState({ selectedColor: value });
        }
    };

    addScheduleCategory = () => {
        ScheduleService.createBandScheduleCategoryNode(
            this.props.bandId, colors[this.state.selectedColor], this.state.inputScheduleCategoryName
        )
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        inputScheduleCategoryName: '',
                        selectedColor: 0,
                    }, this.loadScheduleCategories);
                }
            })
            .catch(error => console.error(error));
    };

    close = () => {
        this.props.onClose();
    };

    onSelect = (id) => {
        if (this._isMounted) {
            if (this.state.selected.indexOf(id) >= 0) {
                this.setState({
                    selected: this.state.selected.filter(s => s !== id)
                });
            } else {
                this.setState({
                    selected: [...this.state.selected, id]
                });
            }
        }
    };

    delete = () => {
        if (this.state.selected && this.state.selected.length > 0) {
            const selected = this.state.selected;
            selected.forEach(scheduleCategoryId => {
                ScheduleService.deleteBandScheduleCategoryNode(this.props.bandId, scheduleCategoryId)
                    .then(() => {
                        this.setState({
                            selected: this.state.selected.filter(s => s !== scheduleCategoryId)
                        });
                    })
                    .catch(error => console.error(error));
            })
        }
        this.close();
    };

    render() {
        if (!this.props.show) {
            return null;
        }

        const { loading, selected } = this.state;

        return (
            <div>
                {loading ?
                    <div style={{ padding: '1rem' }}><ListSkeleton /></div> :
                    <>
                        <Title >일정 카테고리 관리</Title>
                        <GrayWrapper>
                            <div className="modal_content view_order">
                                <div style={{ overflowY: 'auto', maxHeight: '200px' }}>
                                    {this.state.scheduleCategories.map((scheduleCategory, index) =>
                                        <ScheduleCategoryItem key={`scheduleCategory-${index}`}>
                                            <input type="checkbox" style={{ marginLeft: "1rem", alignSelf: "center" }}
                                                checked={selected.indexOf(scheduleCategory.id) >= 0} onChange={() => this.onSelect(scheduleCategory.id)} />
                                            <ScheduleCategory>
                                                <input type="checkbox" id={`scheduleCategoryCheckbox-${index}`} />
                                                <label htmlFor={`checkbox-${index}`} style={{ backgroundColor: scheduleCategory.color }} />
                                                <span>{scheduleCategory.name}</span>
                                            </ScheduleCategory>
                                            <Divider />
                                        </ScheduleCategoryItem>
                                    )}
                                </div>
                                <br />
                                <div>
                                    <InputWrapper style={{ padding: '0.5rem 1rem' }}>
                                        <input placeholder={"카테고리명"} value={this.state.inputScheduleCategoryName} onChange={this.onChangeName} />
                                        <button onClick={this.addScheduleCategory}>추가하기</button>
                                    </InputWrapper>
                                    <ColorPalette>
                                        {colors.map((color, index) =>
                                            <ColorPicker key={`color-${index}`}>
                                                <input type="checkbox" id={`checkbox-${index}`}
                                                    checked={(this.state.selectedColor === index)}
                                                    onChange={() => this.onChangeColor(index)}
                                                />
                                                <label htmlFor={`checkbox-${index}`} style={{ backgroundColor: color }} />
                                            </ColorPicker>
                                        )}
                                    </ColorPalette>
                                </div>
                            </div>
                        </GrayWrapper>
                        <ButtonWrapper>
                            <DeleteListBtn onClick={this.close}><p>닫기</p></DeleteListBtn>
                            <DeleteListBtn onClick={this.delete}><p>선택항목 삭제</p></DeleteListBtn>
                        </ButtonWrapper>
                    </>
                }
            </div>
        );
    }

}

const ButtonWrapper = styled.div`
    display: flex;
`;

const DeleteListBtn = styled.div`
    background-color: ${THEME_BACKGROUND_COLOR};
    border-radius: 6px;
    width: 45%;
    height: 25px;
    font-size: 1rem;
    color: #fff;
    margin-top: 26px;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
    p {
       margin: auto;
       padding: 3px 0;
       font-size: 1rem;
    }
`;

const Title = styled.div`
    padding-left: 2rem;
    font-size: 1.25em;
`;

const InputWrapper = styled.div`
    padding: .5rem 1rem;

    input {
        border-radius: 6px;
        height: 32px;
        width: 70%;
        padding-left: 0.5rem;
    }

    button {
        border-radius: 6px;
        border: solid 1px ${THEME_BACKGROUND_COLOR};
        padding: 0.47rem;
        color: ${THEME_BACKGROUND_COLOR};
        font-weight: 700;
        width: 28%;
        margin-left: 5px;
    }
`;

const Divider = styled.div`
    border-bottom: solid 1px #e5e5ea;
`;

const GrayWrapper = styled.div`
    margin: 20px 0;
    background-color: #f9f9fb;
    border-top: solid 1.5px #e5e5ea;
    border-bottom: solid 1.5px #e5e5ea;
`;

const ScheduleCategoryItem = styled.div`
    display: flex;
`;

const ScheduleCategory = styled.div`
    padding: 1rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    
    span {
      padding: 0 .5rem;
      vertical-align: middle;
    }
    
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      margin: .3rem;
      border-radius: 50%;
      vertical-align: middle;
    }
`;

const ColorPalette = styled.div`
    padding: .5rem 1rem;
`;

const ColorPicker = styled.span`
    display: inline-block;
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      margin: .3rem;
      border-radius: 50%;
    }
    input[type="checkbox"]:checked + label {
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid;
    }
`;

export default ScheduleCategorySettingModal;
