import React from 'react';
import * as UserService from '../../../services/UserService';
import * as OrganizationService from '../../../services/OrganizationService';
import styled from 'styled-components';
import TreeSelect from 'rc-tree-select';
import 'rc-tree-select/assets/index.css';
import { message, Spin, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import { SERVER_URI } from '../../../configs';
import { SyncOutlined } from '@ant-design/icons';
import './Invitation.css';

const bubblePath = 'M632 888H392c-4.4 0-8 3.6-8 8v32c0 ' +
    '17.7 14.3 32 32 32h192c17.7 0 32-14.3 32-32v-3' +
    '2c0-4.4-3.6-8-8-8zM512 64c-181.1 0-328 146.9-3' +
    '28 328 0 121.4 66 227.4 164 284.1V792c0 17.7 1' +
    '4.3 32 32 32h264c17.7 0 32-14.3 32-32V676.1c98' +
    '-56.7 164-162.7 164-284.1 0-181.1-146.9-328-32' +
    '8-328z m127.9 549.8L604 634.6V752H420V634.6l-3' +
    '5.9-20.8C305.4 568.3 256 484.5 256 392c0-141.4' +
    ' 114.6-256 256-256s256 114.6 256 256c0 92.5-49' +
    '.4 176.3-128.1 221.8z';

const clearPath = 'M793 242H366v-74c0-6.7-7.7-10.4-12.9' +
    '-6.3l-142 112c-4.1 3.2-4.1 9.4 0 12.6l142 112c' +
    '5.2 4.1 12.9 0.4 12.9-6.3v-74h415v470H175c-4.4' +
    ' 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-' +
    '28.7 64-64V306c0-35.3-28.7-64-64-64z';

const arrowPath = 'M765.7 486.8L314.9 134.7c-5.3-4.1' +
    '-12.9-0.4-12.9 6.3v77.3c0 4.9 2.3 9.6 6.1 12.6l36' +
    '0 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6' +
    '.7 7.7 10.4 12.9 6.3l450.8-352.1c16.4-12.8 16.4-3' +
    '7.6 0-50.4z';

const getSvg = (path, iStyle = {}, style = {}) => {
    return (
        <i style={iStyle}>
            <svg
                viewBox="0 0 1024 1024"
                width="1em"
                height="1em"
                fill="currentColor"
                style={{ verticalAlign: '-.125em', ...style }}
            >
                <path d={path} />
            </svg>
        </i>
    );
}


const switcherIcon = (obj) => {
    if (obj.isLeaf) {
        return getSvg(arrowPath,
            { cursor: 'pointer', backgroundColor: 'white' },
            { transform: 'rotate(270deg)' });
    }
    return getSvg(arrowPath,
        { cursor: 'pointer', backgroundColor: 'white' },
        { transform: `rotate(${obj.expanded ? 90 : 0}deg)` });
};

const inputIcon = getSvg(bubblePath);
const clearIcon = getSvg(clearPath);
const removeIcon = getSvg(clearPath);

const iconPropsFunction = {
    inputIcon: () => inputIcon,
    clearIcon: () => clearIcon,
    removeIcon: () => removeIcon,
    switcherIcon,
};


function convertOrganization(organization) {
    return {
        key: organization.class_id,
        title: organization.class_group_name,
        value: organization.class_id,
        pId: organization.class_upper_class_id,
        organizationId: organization.class_id
    };
}

function convertUser(user) {
    return {
        title: `${user.user_name} 
        ${(user.user_payra_name !== null && user.user_payra_name !== 'null' && user.user_payra_name !== "" && user.user_payra_name !== undefined) ? ` | ${user.user_payra_name}` : ""} 
        ${(user.user_paycl_name !== null && user.user_paycl_name !== 'null' && user.user_paycl_name !== "" && user.user_paycl_name !== undefined) ? ` | ${user.user_paycl_name}` : ""} 
        ${(user.user_empno !== null && user.user_empno !== 'null' && user.user_empno !== "" && user.user_empno !== undefined) ? ` | ${user.user_empno}` : ""}`,
        value: `user-${user.user_id}`,
        userId: user.user_id
    };
}

function findUsers(users, organizationId) {
    return users.filter(user => user.class_upper_class_id === organizationId).map(user => convertUser(user));
}

function struct(organizations, users) {
    let map = [];
    for (let index = 0; index < users.organizationsInfo.length; index++) {
        const organizationElement = users.organizationsInfo[index];
        for (let index = 0; index < users.usersInfo.length; index++) {
            const userElement = users.usersInfo[index];
            if (organizationElement.class_user_id === userElement.user_id) {
                Object.assign(users.usersInfo[index],
                    { class_upper_class_id: organizationElement.class_upper_class_id });
            }
        }
    }
    for (let i = 0; i < organizations.length; i++) {
        let obj = convertOrganization(organizations[i]);
        obj.children = findUsers(users.usersInfo, obj.value);
        map[obj.value] = obj;
        let parent = obj.pId || '-';
        if (!map[parent]) {
            map[parent] = {
                children: [],
            };
        }
        map[parent].children.push(obj);
    }
    return map['-'].children;
}

class Invitation extends React.Component {

    bandId;
    _isMounted = false;

    state = {
        checked: [],
        expanded: [],
        filterText: '',
        treeData: [],
        loading: true,
        value: [],
        users: []
    };

    componentDidMount() {
        this._isMounted = true;
        // if (localStorage.getItem(`treeData-${SERVER_URI}`)) {
        //     this.setState({
        //         treeData: JSON.parse(localStorage.getItem(`treeData-${SERVER_URI}`)),
        //         loading: false
        //     })
        //     UserService.getAllUsersNode().then(response => {
        //         this.setState({ users: response })
        //     })
        // } else {
        //     this.loadOrganization();
        // }

        this.loadOrganization();

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadOrganization = async () => {
        this.setState({ loading: true })
        try {
            let usersList = await UserService.getAllUsersNode()
            let usersListFromUCM = await UserService.getUsersFromUCM()
            let organizationListFromUCM = await OrganizationService.getOrganizationFromUCM()
            let structuredData = struct(organizationListFromUCM.data, usersListFromUCM.data)
            this.setState({
                loading: false,
                treeData: structuredData,
                users: usersList
            })
            localStorage.setItem(`treeData-${SERVER_URI}`, JSON.stringify(structuredData));
        } catch (error) {
            console.error(error)
            this.setState({ loading: false })
        }
    };

    onChange = checked => {
        if (checked.length > 50) {
            alert(`${this.props.t('New.TooManyPeople')}`)
            return;
        }
        this.setState({ checked });
    };

    handleSubmit = async () => {
        // this.state.checked은  ucTalkId임 ..
        if (window.confirm(`${this.props.t('New.DoYouReallyWantToInvite')}`)) {
            var filtered = this.state.checked.filter(function (el) {
                return typeof (el) !== "number";
            });
            var arraySlicedAndNumberedLetter = filtered.map(el => el.slice(5));
            let chosenUserUIDs = []
            let bandMemberUIDs = []

            this.props.memberList.map((member, i) => {
                return bandMemberUIDs.push(member.id)
            })

            for (let index = 0; index < arraySlicedAndNumberedLetter.length; index++) {
                const chosenUserFromTblUcTalkId = arraySlicedAndNumberedLetter[index];
                for (let index = 0; index < this.state.users.length; index++) {
                    const entireUserElementFromBandUser = this.state.users[index];
                    if (chosenUserFromTblUcTalkId === entireUserElementFromBandUser.ucTalkId) {
                        chosenUserUIDs.push(entireUserElementFromBandUser.id)
                    }
                }
            }

            chosenUserUIDs = chosenUserUIDs.filter(function (val) {
                return bandMemberUIDs.indexOf(val) === -1;
            });

            UserService.sendNotificationToinvitedAllUsers(this.props.bandId, chosenUserUIDs, localStorage.getItem('userAccount'), localStorage.getItem('userName'), this.props.bandName)
                .then(() => message.success(`${this.props.t('BandInvitationModal.successfullySentNotificationsOnlyToNonBandMembers')}`))
                .catch(err => console.error(err))
            this.setState({ checked: [] })
        }
    };

    handleUpdateInvitationUser = () => {
        this.loadOrganization();
    }

    onDropdownVisibleChange = (visible, info) => {
        const { value } = this.state;
        console.log(visible, value, info);
        if (Array.isArray(value) && value.length > 1 && value.length < 3) {
            window.alert('please select more than two item or less than one item.');
            return false;
        }
        return true;
    };

    render() {
        const { t } = this.props;
        const { loading, checked, treeData,
            // users 
        } = this.state;
        return (
            <div style={{ marginBottom: '12px' }}>
                <DivWrapper>

                    <span style={{ marginRight: 10 }}> {t('BandInvitationModal.inviteMembers')}</span>
                    <div style={{ position: 'absolute', right: 20 }}>
                        <Tooltip title={`${this.props.t('New.ClickThisButtonToUpdateNewUsers')}`}>
                            <SyncOutlined spin={loading ? true : false} onClick={this.handleUpdateInvitationUser} style={{ marginTop: 3 }} />
                        </Tooltip>
                    </div>
                </DivWrapper>
                {loading ?
                    <LoadingWrapper>
                        <Spin tip={`${this.props.t('New.GetTheListOfMembers')}`}>
                        </Spin>
                    </LoadingWrapper>
                    :
                    <div>
                        <TreeSelect
                            style={{ width: '100%' }}
                            transitionName="rc-tree-select-dropdown-slide-up"
                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                            dropdownStyle={{ height: 200, overflow: 'auto' }}
                            dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
                            placeholder={<p>  {t('BandInvitationModal.clickOrSearchForThePersonYouWantToInvite')}</p>}
                            treeLine={true}
                            notFoundContent={`${t('BandInvitationModal.thereIsNoOneYouAreLookingFor')}`}
                            // treeIcon={true}
                            onDropdownVisibleChange={(visible) => { console.log(visible) }}
                            maxTagTextLength={10}
                            value={checked}
                            treeData={treeData}
                            treeNodeFilterProp="title"
                            treeCheckable
                            {...iconPropsFunction}
                            onChange={this.onChange}
                        />
                        {this.state.checked.length > 0 &&
                            <button className="inviteMemberButton" 
                                onClick={this.handleSubmit} >{t('BandInvitationModal.inviteSelectedPeople')}
                            </button>
                        }
                    </div>
                }
            </div>
        );
    }
}

export default withTranslation()(Invitation);


const LoadingWrapper = styled.div`
    padding: 1rem;
    width: 100%;
    text-align: center;
`;

const DivWrapper = styled.div`
    padding: 0.5rem 0;
    width: 100%;
    display: flex;
    vertical-align: center;
    position: relative;
    
    object-fit: contain;
    font-size: 1.3rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    img{
        width: 30px;
        height: 24.4px;
        object-fit: contain;
    }

    p { 
        margin-top: 4px;
        padding-left: 3px;
    }

`;



// function struct(organizations, users) {
//     let map = [];
//     ////////   여기서의 목적은 유저의 정보에    그룹 정보안에 있는  class_upper_class_id를 (상위 그룹 ID) 넣어 주는 것이다.   ////////   
//     //모든 그룹을 먼저 돌리고 
//     for (let index = 0; index < users.organizationsInfo.length; index++) {
//         const organizationElement = users.organizationsInfo[index];
//         //모든 유저도 돌린다.
//         for (let index = 0; index < users.usersInfo.length; index++) {
//             const userElement = users.usersInfo[index];
//             // 그룹정보안에 그에 맞는 유저 정보가 있다면 유저 정보 안에 그룹 class_id를 넣어준다.
//             if (organizationElement.class_user_id === userElement.user_id) {
//                 Object.assign(users.usersInfo[index],
//                     { class_upper_class_id: organizationElement.class_upper_class_id });
//             }
//         }
//     }

//     //모든 조직을 돌리면서 Tree에 넣을 데이터를 하나 하나 만들어 주는 과정.
//     for (let i = 0; i < organizations.length; i++) {
//         //조직의 정보를 넣는다
//         let obj = convertOrganization(organizations[i]);

//         //이런식으로 모든 조직의 정보를 갖는다.
//         //key: 168
//         // title: "유씨웨어"
//         // value: 168
//         // pId: 0
//         // organizationId: 168
//         // children: []


//         //조직의 안에 있는 유저들의 정보를 얻고 넣는다.  
//         //여기서 조직은 가장 큰 조직만이 아닌 조직 안에 있는 조직들의 뎁스의 뎁스가 들어가 있는 것들의 조직의 유저 정보도 가져온다. 
//         obj.children = findUsers(users.usersInfo, obj.value);

//         //여기서  obj 는 이걸 담고 있다.
//         // 만약 이 조직 바로 아래 유저가 있다면 

//         // 만약 이 조직 바로 아래에 유저가 없다면 ( 조직만 또 있음 )
//         // key: 168
//         // title: "유씨웨어"
//         // value: 168
//         // pId: 0
//         // organizationId: 168
//         // children: []

//         // key: 915
//         // title: "협력사"
//         // value: 915
//         // pId: 168
//         // organizationId: 915
//         // children: Array(3)
//         // 0: {title: "ノスンジン | 部長 ", value: "user-japan01", userId: "japan01"}
//         // 1: {title: "小林 | 専務 ", value: "user-japan02", userId: "japan02"}
//         // 2: {title: "木村拓哉 | 課長 ", value: "user-japan03", userId: "japan03"}
//         // 여기까지 한 것 이다.

//         //////// 이제는 모든 부서들의 인원을 채워 넣었으니 여기 부터 이제 뎁스 설정이 들어간다. 상위 조직 밑에 하위 조직을 넣는 작업이다. //////// 
//         map[obj.value] = obj;
//         // 여기 까지 오면 이제 
//         // console.log('map', map)
//         //787: {key: 787, title: "학생교육원", value: 787, pId: 778, organizationId: 787, …}
//         //788: {key: 788, title: "과학교육원", value: 788, pId: 778, organizationId: 788, …}
//         // -: {children: Array(12)}
//         //이런식으로 된다.  맨 아래  - 여기에 최상단 조직들만 들어간다. 

//         // // 그리고 여기서 그 조직의 상위 조직의 ID나 그 조직이 최상위 조직이라면 - 이걸로 parent를 정의해준다. 
//         let parent = obj.pId || '-';  // obj.pId 가 0 이면 '-' 로 해준다.   (가장 큰 단위에 조직에  -  이걸로 해준다.)

//         // 이부분이 없으면 에러가나니 의도적으로 children property를 부여한다.
//         if (!map[parent]) {
//             map[parent] = {
//                 children: [],
//             };
//         }

//         //이 부분을 통해서  
//         //1. map 마지막에 있는 (실제 사용하게될 데이터) '-' 이부분에 데이터를 채워넣는다. 
//         //2. 그리고 다른 map 안에 들어 있는 데이터에도  상위 조직 밑에 하위 조직을 넣어준다.
//         // 여기 보면  parent 는  obj 안에 들어 있는 상위 조직이다 !!!!!  그러니  상위 조직인 parent에다가  .children 해서  obj(하위 조직)을 넣어 주면 저절로 모든 조직들이 맞추어서 들어간다. 
//         map[parent].children.push(obj);
//     }
//     return map['-'].children;
// }


// handleSubmit = () => {
//     if (this.state.checked.length === 0) {
//         return message.warning(`${this.props.t('BandInvitationModal.pleaseSelectThePersonYouWantToInviteFirst')}`)
//     }

//     if (window.confirm("정말 초대하시겠습니까?")) {
//         //먼저 폴더들의 ID는 뺸다.
//         var filtered = this.state.checked.filter(function (el) {
//             return typeof (el) !== "number";
//         });

//         //그리고  stringify 되고 user 명칭이 붙은것을 다 떼어 준다.
//         var arraySlicedAndNumberedLetter = filtered.map(el => el.slice(5));

//         // let chosenUsersUID = []
//         let chosenUserNames = []
//         let sendUserName = "";
//         let bandMemberNames = []

//         UserService.getUserProfileNode(localStorage.getItem('userId'))
//             .then(response => {
//                 sendUserName = response.name
//             })
//             .catch(err => message.error(err))


//         this.props.memberList.map((member, i) => {
//             bandMemberNames.push(member.name)
//         })

//         // BandService.getBandMembersNode(bandId, bandMemberRoles)
//         //     .then(response => {
//         //         sendUserName = response.name
//         //     })
//         //     .catch(err => message.error(err))
//         for (let index = 0; index < arraySlicedAndNumberedLetter.length; index++) {
//             const chosenUserElementFromTblUser = arraySlicedAndNumberedLetter[index];
//             // console.log('chosenUserElementFromTblUser.user_id', chosenUserElementFromTblUser.user_id)

//             for (let index = 0; index < this.state.users.length; index++) {
//                 const entireUserElementFromBandUser = this.state.users[index];
//                 // console.log('hha', entireUserElementFromBandUser)

//                 if (chosenUserElementFromTblUser === entireUserElementFromBandUser.ucTalkId) {
//                     // console.log('hha', entireUserElementFromBandUser.name)
//                     // chosenUsersUID.push(Number(entireUserElementFromBandUser.id))
//                     chosenUserNames.push(entireUserElementFromBandUser.name)
//                 }

//             }

//         }

//         // 이미 밴드 멤버인 사람들에게는 초대 노노 
//         chosenUserNames = chosenUserNames.filter(function (val) {
//             return bandMemberNames.indexOf(val) === -1;
//         });
//         // console.log('chosenUserNames', chosenUserNames);
//         // Or, with the availability of ES6:
//         // array1 = array1.filter(val => !array2.includes(val));

//         // console.log('chosenUsersUID', chosenUsersUID)
//         // console.log("this.props.match || this.props.bandId", this.props.match.params.bandId || this.props.bandId)

//         UserService.sendNotificationToinvitedAllUsers((this.props.match &&
//             this.props.match.params && this.props.match.params.bandId) ||
//             this.props.bandId, chosenUserNames, localStorage.getItem('userAccount'), sendUserName)
//             .then(() => message.success(`${this.props.t('BandInvitationModal.successfullySentNotificationsOnlyToNonBandMembers')}`))
//             .catch(err => console.error(err))

//         // UserService.sendNotificationToinvitedAllUsers((this.props.match && this.props.match.params && this.props.match.params.bandId) || this.props.bandId, chosenUsersUID)
//         //     .then(() => message.success('성공적으로 알림을 보냈습니다.'))
//         //     .catch(err => console.error(err))

//         // let sendingToWho = [];

//         // arraySlicedAndNumberedLetter.map((item, i) => (
//         //     sendingToWho.push(`${(_.find(this.state.users, { 'id': item })).name}`)
//         // ))

//         // message.success(`${sendingToWho} 아이디를 가진 분들에게 초대하기 알림을 보냅니다`);

//         this.setState({ checked: [] })
//     }
// };