import React from "react";

export default function BtnJoinSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="136.647"
      height="136.647"
      viewBox="0 0 136.647 136.647"
    >
      <g id="btn_join" transform="translate(-54.646 -566.646)">
        <circle
          id="contianer"
          cx="68"
          cy="68"
          r="68"
          class="cls-1"
          fill="#efeff4"
          transform="translate(55 567)"
        />
        <path
          id="pattern"
          d="M-3981.177 369.884l-65.939 65.939zm-70.907 65.907l70.875-70.875-70.875 70.875zm70.539-60.539l-60.2 60.2zm-75.183 60.184l75.163-75.164-75.163 75.164zm-4.371-.629l78.905-78.9-78.905 78.9zm78.651-53.651l-53.4 53.4zm-82.785 52.784l82.172-82.172-82.172 82.172zm81.1-46.1l-45.03 45.03zm-85.023 45.023l85.016-85.017-85.016 85.017zm-3.733-1.268l87.481-87.481-87.481 87.481zm-3.558-1.442l89.6-89.6-89.6 89.6zm89.271-34.272l-33.947 33.947zm-92.667 32.668l91.389-91.389-91.389 91.389zm-3.244-1.756l92.876-92.877-92.876 92.877zm-3.1-1.9l94.075-94.075-94.075 94.075zm-2.96-2.039l95-95-95 95zm94.984-14.984l-14.972 14.972zm-97.81 12.81l95.648-95.648-95.648 95.648zm-2.695-2.306l96.037-96.037-96.037 96.037zm-2.564-2.435l96.166-96.167-96.166 96.167zm-2.436-2.565l96.037-96.037-96.037 96.037zm-2.305-2.694l95.648-95.648-95.648 95.648zm-2.174-2.827l95-95-95 95zm-2.04-2.96l94.075-94.075-94.075 94.075zm-1.9-3.1l92.876-92.877-92.876 92.877zm-1.756-3.244l91.388-91.389-91.388 91.389zm-1.6-3.4l89.6-89.6-89.6 89.6zm-1.442-3.558l87.481-87.481-87.481 87.481zm-1.268-3.732l85.017-85.017-85.017 85.017zm-1.078-3.923l82.172-82.172-82.172 82.172zm-.867-4.133l78.905-78.906-78.905 78.906zm-.629-4.371l75.164-75.164-75.164 75.164zm-.355-4.644l70.875-70.876-70.875 70.876zm-.033-4.968l65.94-65.94-65.94 65.94zm.368-5.368l60.2-60.2-60.2 60.2zm.9-5.9l53.4-53.4-53.4 53.4zm1.684-6.684l45.033-45.033-45.033 45.033zm3.041-8.04l33.951-33.951-33.951 33.951zm6.987-11.988l14.976-14.976-14.976 14.976z"
          class="cls-2"
          transform="translate(4172.117 267.116)"
          stroke="rgba(0,55,199,.08)"
          fill="none"
        />
        <text
          id="협업_가입하기"
          class="cls-3"
          fontSize="14px"
          fontFamily="KoPubWorldDotumBold,Sans-serif,
,KoPubWorldDotum"
          fill="#51A5C1"
          data-name="협업 가입하기"
          transform="translate(123 675)"
        >
          <tspan x="0" y="0" textAnchor="middle">
            {props.label}
          </tspan>
        </text>
        <g id="icon_join" transform="translate(-767.266 167.021)">
          <g id="그룹_451" data-name="그룹 451">
            <path
              id="패스_96"
              d="M927.312 452.145a3.425 3.425 0 0 1-1.934 3.217l-.032.013a27.462 27.462 0 0 0-3.911 2.137 49.239 49.239 0 0 0-8.83 7.622 20.235 20.235 0 0 1-4.264 3.418L883.7 482.127a3.764 3.764 0 0 0 1.223-2.761 3.806 3.806 0 0 0-.868-2.478 3.467 3.467 0 0 0-4.75-.585l-.019-.027.855-.771a4.119 4.119 0 0 0 .379-5.63 3.733 3.733 0 0 0-5.395-.4l1.389-1.255a4.094 4.094 0 0 0 .372-5.6 3.716 3.716 0 0 0-5.376-.394l-1.678 1.522-.012-.02.283-.254a4.122 4.122 0 0 0 .379-5.63 3.716 3.716 0 0 0-4.389-1.052l15.178-9.833v-.007l.167-.13 1.811-1.435 8.353-6.607.019-.013 6.3-3.7a9.128 9.128 0 0 1 5.128-1.213c4.017.192 7.479-1.626 9.365-2.437l.013-.013a3.172 3.172 0 0 1 2.99-.087c1.929.893 5.082 3.182 8.256 8.99 3.003 5.473 3.627 9.478 3.639 11.838z"
              class="cls-4"
              fill="#fff"
              data-name="패스 96"
            />
            <path
              id="패스_97"
              d="M883.7 483.122a.944.944 0 0 1-.788-.433 1.025 1.025 0 0 1 .163-1.314 2.812 2.812 0 0 0 .258-3.836 2.554 2.554 0 0 0-3.475-.427.929.929 0 0 1-1.324-.222 1.058 1.058 0 0 1 .127-1.365l.855-.771a3.1 3.1 0 0 0 .284-4.225 2.8 2.8 0 0 0-4.052-.3.928.928 0 0 1-1.345-.1 1.024 1.024 0 0 1 .1-1.4l1.388-1.255a3.068 3.068 0 0 0 .279-4.2 2.8 2.8 0 0 0-1.959-1.014 2.76 2.76 0 0 0-2.073.716l-1.677 1.522a.926.926 0 0 1-.76.235.952.952 0 0 1-.668-.445 1.049 1.049 0 0 1 .165-1.311l.282-.255a3.095 3.095 0 0 0 .285-4.225 2.807 2.807 0 0 0-3.294-.791.937.937 0 0 1-1.222-.454 1.02 1.02 0 0 1 .342-1.3l15.1-9.78.013-.01 1.973-1.563 8.355-6.607.059-.044.068-.043 6.3-3.7a10.065 10.065 0 0 1 5.643-1.339 18.051 18.051 0 0 0 8.215-2.036c.231-.1.448-.2.648-.288a4.067 4.067 0 0 1 3.852-.127c2.167 1 5.439 3.452 8.693 9.4 3.089 5.645 3.75 9.824 3.761 12.335a4.439 4.439 0 0 1-2.511 4.134l-.06.026a26.66 26.66 0 0 0-3.751 2.051 48.367 48.367 0 0 0-8.669 7.489 21.16 21.16 0 0 1-4.464 3.577l-24.666 13.589a.928.928 0 0 1-.45.106zm-1.762-8.469a4.451 4.451 0 0 1 2.836 1.582 4.828 4.828 0 0 1 1.1 3.143c0 .147-.009.294-.024.44l22.05-12.145a19.22 19.22 0 0 0 4.036-3.243 50 50 0 0 1 8.995-7.759 28.45 28.45 0 0 1 4.045-2.21l.059-.025a2.415 2.415 0 0 0 1.328-2.285c-.009-2.273-.627-6.088-3.511-11.359-3.155-5.772-6.251-7.85-7.815-8.574a2.256 2.256 0 0 0-2.087.017 1.024 1.024 0 0 1-.165.094c-.217.093-.456.2-.714.316-1.979.888-5.292 2.381-9.059 2.195a8.206 8.206 0 0 0-4.614 1.084l-6.259 3.679-10.22 8.084a.912.912 0 0 1-.141.113l-12.391 8.028a4.761 4.761 0 0 1 1.821 1.362 5.091 5.091 0 0 1 1.167 3.326v.042a4.645 4.645 0 0 1 5.237 1.406 5.069 5.069 0 0 1 1.168 3.315 5.144 5.144 0 0 1-.555 2.271 4.7 4.7 0 0 1 3.02 1.674 5.092 5.092 0 0 1 1.169 3.328 5.19 5.19 0 0 1-.476 2.101z"
              class="cls-5"
              fill="#51A5C1"
              data-name="패스 97"
            />
          </g>
          <g id="그룹_452" data-name="그룹 452">
            <path
              id="패스_98"
              d="M881.437 446.817l1.824-1.474c-.007.013-.007.026-.013.039z"
              class="cls-4"
              fill="#fff"
              data-name="패스 98"
            />
            <path
              id="패스_99"
              d="M881.437 447.811a.941.941 0 0 1-.757-.388 1.025 1.025 0 0 1 .172-1.392l1.824-1.474a.925.925 0 0 1 1.2.029 1.025 1.025 0 0 1 .275 1.1 1.412 1.412 0 0 1-.055.14.984.984 0 0 1-.276.347l-1.811 1.435a.927.927 0 0 1-.572.203z"
              class="cls-5"
              fill="#51A5C1"
              data-name="패스 99"
            />
          </g>
          <g id="그룹_457" data-name="그룹 457">
            <g id="그룹_453" data-name="그룹 453">
              <path
                id="패스_100"
                d="M907.542 468.97a4.515 4.515 0 0 1 1.646 2.785 3.962 3.962 0 0 1-.681 3.018c-1.288 1.76-3.68 1.846-5.524.575l-2.138-1.7a4.284 4.284 0 0 1 .965 5.8 3.89 3.89 0 0 1-5.623.565l-1.831-1.456a3.457 3.457 0 0 1-.077 5.614c-1.823 1.689-4.726.4-6.5-1.015l-6.037-4.8.669.532-9.364-7.444a51.346 51.346 0 0 1-6.157-6.134c-3.214-3.992-4.163-4.415-7.67-7.343a35.14 35.14 0 0 0-4.114-2.964l-.04-.022a3.853 3.853 0 0 1-1.825-3.655c.235-2.4 1.315-6.338 5.157-11.3 4.068-5.253 7.782-6.937 9.994-7.456a3.914 3.914 0 0 1 3.3.7l.018.013c2.4 1.442 3.867 4.469 8.13 5.573 0 0 4.393 1.282 10.1 6.144 7.108 6.052 5.322 4.212 9.067 7.113l13.193 10.493a4.284 4.284 0 0 1 .965 5.8 3.89 3.89 0 0 1-5.622.564z"
                class="cls-4"
                fill="#fff"
                data-name="패스 100"
              />
              <path
                id="패스_101"
                d="M892.172 485.979a8.579 8.579 0 0 1-4.973-2.029l-6.037-4.8-.015-.011-8.681-6.9a52.479 52.479 0 0 1-6.309-6.286 30.076 30.076 0 0 0-5.7-5.7c-.537-.435-1.138-.922-1.84-1.508a34.516 34.516 0 0 0-4.005-2.885 4.9 4.9 0 0 1-2.326-4.639c.25-2.551 1.384-6.678 5.363-11.816 4.173-5.387 8.027-7.215 10.527-7.8a4.821 4.821 0 0 1 4.051.85 14.706 14.706 0 0 1 2.456 2.045 11.485 11.485 0 0 0 5.378 3.394c.215.063 4.68 1.4 10.478 6.34a71.315 71.315 0 0 0 6.494 5.224c.574.395 1.224.843 2.538 1.861l13.2 10.495a5.5 5.5 0 0 1 2.009 3.4 4.976 4.976 0 0 1-.864 3.79 4.574 4.574 0 0 1-3.278 1.87 4.785 4.785 0 0 1-.676.021 5.408 5.408 0 0 1 .159.683 4.973 4.973 0 0 1-.863 3.792 4.694 4.694 0 0 1-5.775 1.376 4.924 4.924 0 0 1-.922 3.3 6.106 6.106 0 0 0-7.7 4.9 3.841 3.841 0 0 1-2.689 1.033zM883 478.113l5.352 4.256c1.287 1.022 3.848 2.4 5.289 1.062a2.773 2.773 0 0 0 1.159-2.13 2.55 2.55 0 0 0-1.021-1.947 1.021 1.021 0 0 1-.18-1.393.928.928 0 0 1 1.337-.187l1.831 1.455a2.862 2.862 0 0 0 4.786-2.621 3.5 3.5 0 0 0-1.285-2.162 1.022 1.022 0 0 1-.18-1.393.928.928 0 0 1 1.337-.189l2.111 1.677c1.316.893 3.177 1.051 4.215-.366a2.953 2.953 0 0 0 .5-2.246 3.509 3.509 0 0 0-1.268-2.149l-.017-.014a1.023 1.023 0 0 1-.179-1.394.928.928 0 0 1 1.337-.187 3.229 3.229 0 0 0 2.341.72 2.708 2.708 0 0 0 1.945-1.1 2.948 2.948 0 0 0 .5-2.245 3.5 3.5 0 0 0-1.286-2.163l-13.192-10.488a46.69 46.69 0 0 0-2.442-1.789 72.855 72.855 0 0 1-6.65-5.344c-5.461-4.649-9.717-5.945-9.76-5.958a13.213 13.213 0 0 1-6.184-3.855 12.969 12.969 0 0 0-2.162-1.812.921.921 0 0 1-.1-.066 2.979 2.979 0 0 0-2.529-.539c-2.209.518-5.639 2.17-9.466 7.111-3.686 4.758-4.727 8.486-4.951 10.775a2.829 2.829 0 0 0 1.365 2.7 36.143 36.143 0 0 1 4.264 3.065c.692.579 1.285 1.059 1.816 1.489a31.941 31.941 0 0 1 5.986 5.99 50.915 50.915 0 0 0 5.992 5.973l9.376 7.453z"
                class="cls-5"
                fill="#51A5C1"
                data-name="패스 101"
              />
            </g>
            <g id="그룹_454" data-name="그룹 454">
              <path
                id="패스_102"
                d="M907.782 470.156a.923.923 0 0 1-.575-.2l-9.6-7.577a1.021 1.021 0 0 1-.185-1.392.927.927 0 0 1 1.336-.193l9.6 7.577a1.02 1.02 0 0 1 .185 1.392.939.939 0 0 1-.761.393z"
                class="cls-5"
                fill="#51A5C1"
                data-name="패스 102"
              />
            </g>
            <g id="그룹_455" data-name="그룹 455">
              <path
                id="패스_103"
                d="M901.25 474.963a.919.919 0 0 1-.575-.2l-8.382-6.617a1.022 1.022 0 0 1-.185-1.393.927.927 0 0 1 1.336-.192l8.383 6.618a1.02 1.02 0 0 1 .184 1.392.941.941 0 0 1-.761.392z"
                class="cls-5"
                fill="#51A5C1"
                data-name="패스 103"
              />
            </g>
            <g id="그룹_456" data-name="그룹 456">
              <path
                id="패스_104"
                d="M894.906 480.008a.922.922 0 0 1-.576-.2l-7.461-5.89a1.02 1.02 0 0 1-.184-1.392.928.928 0 0 1 1.336-.193l7.461 5.89a1.022 1.022 0 0 1 .184 1.393.938.938 0 0 1-.76.392z"
                class="cls-5"
                fill="#51A5C1"
                data-name="패스 104"
              />
            </g>
          </g>
          <g id="그룹_458" data-name="그룹 458">
            <path
              id="패스_105"
              d="M873.9 459.261a4.053 4.053 0 0 1-1.314 2.966l-.283.255-3.549 3.215a3.723 3.723 0 0 1-5.4-.395 4.11 4.11 0 0 1 .379-5.629l3.832-3.471 1.013-.656a3.715 3.715 0 0 1 4.388 1.051 4.061 4.061 0 0 1 .934 2.664z"
              class="cls-4"
              fill="#fff"
              data-name="패스 105"
            />
            <path
              id="패스_106"
              d="M866.253 467.669c-.114 0-.229 0-.344-.013a4.672 4.672 0 0 1-3.277-1.7 5.138 5.138 0 0 1 .476-7.033l3.832-3.469a1.082 1.082 0 0 1 .121-.093l1.013-.656a.874.874 0 0 1 .127-.07 4.62 4.62 0 0 1 5.488 1.316 5.086 5.086 0 0 1 1.166 3.325 5.071 5.071 0 0 1-1.643 3.706l-3.83 3.469a4.625 4.625 0 0 1-3.129 1.218zm1.88-10.664l-3.775 3.418a3.012 3.012 0 0 0-.981 2.052 3.053 3.053 0 0 0 .7 2.174 2.813 2.813 0 0 0 1.97 1.024 2.777 2.777 0 0 0 2.086-.725l3.836-3.473a3.1 3.1 0 0 0 .284-4.224 2.809 2.809 0 0 0-3.233-.818zm5.768 2.256z"
              class="cls-5"
              fill="#51A5C1"
              data-name="패스 106"
            />
          </g>
          <g id="그룹_459" data-name="그룹 459">
            <path
              id="패스_107"
              d="M883.94 471.3a4.053 4.053 0 0 1-1.314 2.966l-.855.771-2.977 2.706a3.742 3.742 0 0 1-5.408-.395 4.135 4.135 0 0 1 .366-5.623l3.279-2.967.579-.523a3.734 3.734 0 0 1 5.4.4 4.061 4.061 0 0 1 .93 2.665z"
              class="cls-4"
              fill="#fff"
              data-name="패스 107"
            />
            <path
              id="패스_108"
              d="M876.283 479.706a4.7 4.7 0 0 1-3.615-1.706 5.159 5.159 0 0 1 .455-7.023l3.862-3.5a4.657 4.657 0 0 1 6.74.5 5.093 5.093 0 0 1 1.169 3.328 5.069 5.069 0 0 1-1.644 3.706l-3.828 3.475a4.673 4.673 0 0 1-3.139 1.22zm3.829-11.45a2.791 2.791 0 0 0-1.878.728l-3.856 3.489a3.1 3.1 0 0 0-.27 4.222 2.81 2.81 0 0 0 4.062.293l3.83-3.477a3.095 3.095 0 0 0 .284-4.224 2.822 2.822 0 0 0-2.172-1.031zm3.828 3.041z"
              class="cls-5"
              fill="#51A5C1"
              data-name="패스 108"
            />
          </g>
          <g id="그룹_460" data-name="그룹 460">
            <path
              id="패스_109"
              d="M880.306 464.024a4.051 4.051 0 0 1-1.306 2.953l-1.389 1.255-.579.523-3.279 2.967a3.71 3.71 0 0 1-5.376-.388 4.1 4.1 0 0 1 .379-5.6l3.562-3.228 1.678-1.523a3.716 3.716 0 0 1 5.376.395 4.038 4.038 0 0 1 .934 2.646z"
              class="cls-4"
              fill="#fff"
              data-name="패스 109"
            />
            <path
              id="패스_110"
              d="M871.26 473.689c-.113 0-.226 0-.339-.012a4.659 4.659 0 0 1-3.265-1.691 5.066 5.066 0 0 1-1.15-3.6 5.01 5.01 0 0 1 1.624-3.4l5.238-4.75a4.6 4.6 0 0 1 3.46-1.2 4.661 4.661 0 0 1 3.264 1.692 5.074 5.074 0 0 1 1.168 3.314 5.071 5.071 0 0 1-1.635 3.691l-5.247 4.746a4.607 4.607 0 0 1-3.118 1.21zm5.226-12.684a2.766 2.766 0 0 0-1.866.725l-5.239 4.751a3 3 0 0 0-.972 2.039 3.033 3.033 0 0 0 .688 2.162 2.783 2.783 0 0 0 4.029.291l5.248-4.747a3.069 3.069 0 0 0 .278-4.2 2.8 2.8 0 0 0-1.957-1.015c-.07-.003-.14-.006-.209-.006zm3.82 3.019z"
              class="cls-5"
              fill="#51A5C1"
              data-name="패스 110"
            />
          </g>
          <g id="그룹_461" data-name="그룹 461">
            <path
              id="패스_111"
              d="M887.407 478.124a3.767 3.767 0 0 1-1.222 2.762l-1.839 1.666a3.478 3.478 0 0 1-5.026-.37 3.826 3.826 0 0 1 .355-5.232l1.839-1.667a3.015 3.015 0 0 1 .276-.222 3.468 3.468 0 0 1 4.75.585 3.8 3.8 0 0 1 .867 2.478z"
              class="cls-4"
              fill="#fff"
              data-name="패스 111"
            />
            <path
              id="패스_112"
              d="M882.013 484.451a4.429 4.429 0 0 1-3.413-1.617 4.8 4.8 0 0 1-1.091-3.417 4.738 4.738 0 0 1 1.542-3.22l1.837-1.664a4.107 4.107 0 0 1 .381-.3 4.362 4.362 0 0 1 5.99.765 4.83 4.83 0 0 1 1.1 3.142 4.792 4.792 0 0 1-1.552 3.5l-1.837 1.664a4.4 4.4 0 0 1-2.957 1.147zm1.845-9.087a2.534 2.534 0 0 0-1.516.508 2.161 2.161 0 0 0-.215.172L880.3 477.7a2.733 2.733 0 0 0-.889 1.858 2.767 2.767 0 0 0 .627 1.971 2.543 2.543 0 0 0 3.683.271l1.837-1.664a2.766 2.766 0 0 0 .894-2.024 2.79 2.79 0 0 0-.635-1.815 2.551 2.551 0 0 0-1.959-.933z"
              class="cls-5"
              fill="#51A5C1"
              data-name="패스 112"
            />
          </g>
          <g id="그룹_462" data-name="그룹 462">
            <path
              id="패스_113"
              d="M895.95 454.894l-2.8-5.167.763-5.267-3.017-5.124-7.634 6.007c-.4 1.274-.174 2.815.921 4.838l4.873 9a3.859 3.859 0 0 0 5.71 1.685c1.479-.877 2.683-3.201 1.184-5.972z"
              class="cls-4"
              fill="#fff"
              data-name="패스 113"
            />
            <path
              id="패스_114"
              d="M892.535 462.519a4.924 4.924 0 0 1-4.311-2.85l-4.872-9c-1.181-2.181-1.507-4.025-1-5.638a.991.991 0 0 1 .331-.482l7.634-6.007a.929.929 0 0 1 1.336.2 1.02 1.02 0 0 1-.187 1.392l-7.375 5.8a5.733 5.733 0 0 0 .92 3.76l4.872 9A2.925 2.925 0 0 0 894.3 460c1.01-.6 2.065-2.322.823-4.616l-2.8-5.167a1.027 1.027 0 0 1-.113-.637l.763-5.267a.959.959 0 0 1 1.085-.835.988.988 0 0 1 .8 1.131l-.713 4.929 2.638 4.868c1.707 3.153.479 6.127-1.543 7.325a5.332 5.332 0 0 1-2.705.788z"
              class="cls-5"
              fill="#51A5C1"
              data-name="패스 114"
            />
          </g>
          <g id="그룹_463" data-name="그룹 463">
            <path
              id="패스_115"
              d="M898.452 451.922c-.387 0-.776-.015-1.167-.047a.983.983 0 0 1-.876-1.069.956.956 0 0 1 1.025-.913c6.068.486 11.983-3.719 12.042-3.761a.928.928 0 0 1 1.327.251 1.022 1.022 0 0 1-.241 1.384c-.249.179-5.837 4.155-12.11 4.155z"
              class="cls-5"
              fill="#51A5C1"
              data-name="패스 115"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
