import React from 'react';
import '../../../assets/css/_modal.scss';

class ScheduleRepeatModal extends React.Component {

    show;
    onSelect;
    options;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            showAdding: false,
            loaded: false,
            selectedOption: 0,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    select = (selected) => {
        if (this._isMounted) {
            this.setState({
                selectedOption: selected,
            }, () => this.props.onSelect(selected));
        }
    };

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="modal_wrap">
                <div className="modal">
                     <button className="close_btn" aria-label="모달창 닫기 버튼"onClick={this.props.onClose}></button>
                    <div>
                        <p className="title">반복 등록</p>
                        <div className="modal_content view_order">
                            {this.props.options.map((option, index) =>
                                <div key={`radio-select-option-${index}`} >
                                    <input
                                        type="radio"
                                        value={option}
                                        checked={this.state.selectedOption === index}
                                        onChange={() => this.select(index)}
                                    />
                                    <label style={{ marginLeft: '7px' }}>
                                        {option}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <button className="modal_submit_btn" onClick={this.props.onClose}>
                            확인
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScheduleRepeatModal;
