import * as StringUtils from '../utils/StringUtils';
import { SERVER_URI } from '../configs';
import axios from 'axios';
import * as DateTimeUtils from '../utils/DateTimeUtils';

export function getBandSystemStatusNode() {
    return axios.get(`${SERVER_URI}:5000/api/band/system/status`);
}

export function getBandsStatusNode() {
    return axios.get(`${SERVER_URI}:5000/api/band/status/bands/`);
}

export function getBandStatusNode(bandId) {
    return axios.get(`${SERVER_URI}:5000/api/band/status/bands/${bandId}`)
        .then(response => { return response.data });
}

export function getBandInfo(bandId, IsFromHome = false) {
    const bandMemberRoles = IsFromHome ? [0, 1, 2, 99] : [0, 1];
    const userId = Number(localStorage.getItem('userId'));


    let band, members, bandMember, isBandLeader = false, isBandMember = false, isOpened = false, bandMemberRole;
    return getBandNode(bandId)
        .then(result => band = result)
        .then(() => getBandMembersNode(bandId, bandMemberRoles))
        .then(result => members = result)
        .then(() => {
            // console.log('members ', members)
            //현재 내가 이 밴드에 멤버 인지 찾는다.
            const found = members.filter(member => member.userId === userId);
            //밴드 멤버가 맞다면 bandMember란 변수에 나의 정보를 담는다.
            if (found && found.length > 0) {
                bandMember = found[0];
            }

            let bandLeaders = [];
            members.map((member, index) => {
                if (member.bandMemberRole === 0) {
                    return bandLeaders.push(member.userId)
                }
                return null;
            });

            isBandMember = bandMember && (bandMember.bandMemberRole < 2);
            if (isBandMember) {
                isBandLeader = bandMember.bandMemberRole === 0;
                bandMemberRole = bandMember.bandMemberRole;
            } else {
                isBandLeader = false;
                if (found && found.length > 0) {
                    bandMemberRole = found[0].bandMemberRole;
                }
            }
            isOpened = isBandMember || (band && band.openType === 0);

            return {
                band: band,
                bandLeaders: bandLeaders,
                isBandLeader: isBandLeader,
                isBandMember: isBandMember,
                bandMemberRole: bandMemberRole,
                bandMember: bandMember,
                bandMembers: members,
                isOpened: isOpened
            };
        })
}

export function getAllUserBandListNode(userId, hidden) {
    // hidden은 아마  hidden 처리해 둔 밴드들 안가져오게 하려고 했던것 같다.
    // 그냥 신경 안써도 된다.
    // 자신이 속해있는 모든 밴드 목록들 가져오기.
    let url = `${SERVER_URI}:5000/api/member/bands?userId=${userId}`;
    if (hidden) {
        url = url + `?hidden=${hidden}`;
    }

    return axios.get(url).then(response => { return response.data })

}

export function getUserBandListNode(userId, fixed) {
    return axios.get(`${SERVER_URI}:5000/api/member/bands?userId=${userId}&fixed=${fixed}`)
        .then(response => {
            return response.data
        })
}

export function getUserBandListAsLeaderNode(userId) {
    return axios.get(`${SERVER_URI}:5000/api/member/bandsAsLeader?userId=${userId}`)
        .then(response => {
            return { bandListAsLeader: response.data }
        });
}

export function getBandListNode(bandIds, orderType = 0, hiddenModal = false) {
    //hiddenModal은  숨김 협업 관리에서 보여주는 것이다. 
    //orderType은 업데이트 순인지 협업 순인지.
    //orderType 0이면 그냥 업데이트 순.
    if (!bandIds || bandIds.length === 0)
        return undefined;
    return axios.get(`${SERVER_URI}:5000/api/band/bands/band_by_ids?bandIds=${bandIds}&orderType=${orderType}&userId=${localStorage.getItem('userId')}&hiddenModal=${hiddenModal}`)
        .then(
            response => { return response.data }
        );
}

export function getUserHiddenBandListNode(userId, hidden) {
    return axios.get(`${SERVER_URI}:5000/api/member/bands/hidden?userId=${userId}&hidden=${hidden}`)
        .then(
            response => { return response.data }
        );

}

export function getAllBandListNode() {
    return axios.get(`${SERVER_URI}:5000/api/band`)
        .then(
            response => { return response.data }
        );
}

export function getLatestCreatedBandListNode(bandCategoryId, limit) {
    let url = `${SERVER_URI}:5000/api/band?orderType=0`;
    if (limit) {
        url = url + `&limit=${limit}`;
    }
    if (bandCategoryId) {
        url = url + `&bandCategoryId=${bandCategoryId}`;
    }
    return axios.get(url).then(response => { return response.data; });
}

export function getPopularBandListNode(bandCategoryId, limit) {
    let url = `${SERVER_URI}:5000/api/band?orderType=2`;
    if (limit) {
        url = url + `&limit=${limit}`;
    }
    if (bandCategoryId) {
        url = url + `&bandCategoryId=${bandCategoryId}`;
    }

    return axios.get(url).then(response => { return response.data; });
}


export function searchBandNode(bandCategoryId, keyword) {
    let url = `${SERVER_URI}:5000/api/band?orderType=2`;
    if (bandCategoryId) {
        url = url + `&bandCategoryId=${bandCategoryId}`;
    }
    if (!StringUtils.isNullOrUndefined(keyword)) {
        url = url + `&keyword=${encodeURIComponent(keyword)}`;
    }

    return axios.get(url).then(response => { return response.data; });
}

export function getBandNode(bandId) {
    return axios.get(`${SERVER_URI}:5000/api/band/${bandId}`)
        .then(response => {
            return response.data;
        })
}

export function updateBandMemberCount(bandId) {

    const body = {
        bandId
    };

    return axios.put(`${SERVER_URI}:5000/api/band/member/update`, body)

}

export function getBandMembersNode(bandId, bandMemberRoles) {
    let url = `${SERVER_URI}:5000/api/member/band/users?bandId=${bandId}`;
    if (bandMemberRoles && bandMemberRoles.length > 0) {
        url = url + `&selectedBandMemberRoles=${encodeURI(JSON.stringify(bandMemberRoles))}`;
    }

    return axios.get(url)
        .then(response => {
            return response.data;
        })
}

export function getBandLeadersNode(bandId) {
    return axios.get(`${SERVER_URI}:5000/api/member/bands/leaders?bandId=${bandId}`)
        .then(response => {
            return response.data;
        })
}

export function createBandNode(bandCategoryId, name, description, coverImage, openType, needToApprove, createDateTime) {
    const body = {
        bandCategoryId: bandCategoryId,
        name: name,
        description: description,
        openType: openType,
        needToApprove: needToApprove,
        coverImage: coverImage,
        createDateTime: createDateTime,
        createdBy: localStorage.getItem('userId'),
        updatedBy: localStorage.getItem('userId')
    };

    let band;
    return axios.post(`${SERVER_URI}:5000/api/band`, body)
        .then(created => {
            band = created.data.insertId;
            return uploadCoverImageNode(created.data.insertId, coverImage);
        })
        .then(() => band);
}

export function uploadCoverImageNode(bandId, coverImage) {
    let formData = new FormData();
    const config = {
        header: { 'content-type': 'multipart/form-data' },
    }
    formData.append("file", coverImage);

    return axios.post(`${SERVER_URI}:5000/api/file/cover?bandId=${bandId}`, formData, config);
}

export function updateBandNode(bandId, bandCategoryId, name, description,
    openType, needToApprove, createMemberJoinPost, type) {

    const body = {
        bandCategoryId: bandCategoryId,
        name: name,
        description: description,
        openType: openType,
        needToApprove: needToApprove,
        createMemberJoinPost: createMemberJoinPost,
        type: type
    };

    return axios.put(`${SERVER_URI}:5000/api/band?bandId=${bandId}`, body);
}

export function joinBandNode(bandId, joinDateTime, isNeedToApprove) {
    const body = {
        bandId: bandId,
        userId: localStorage.getItem('userId'),
        createDateTime: joinDateTime,
        isNeedToApprove: isNeedToApprove
    };

    return axios.post(`${SERVER_URI}:5000/api/band/join/${bandId}`, body);
}

export function approveBandJoinNode(bandId, userIds) {

    let newOne = new Array(userIds)[0]

    const body = {
        userIds: userIds,
        currentDateTime: DateTimeUtils.formattedCurrent(),
        userLength: newOne.length
    };

    return axios.post(`${SERVER_URI}:5000/api/member/users/approve?bandId=${bandId}`, body);
}

export function rejectBandJoinNode(bandId, userIds) {
    const body = {
        userIds: userIds,
    };

    return axios.post(`${SERVER_URI}:5000/api/member/users/reject?bandId=${bandId}`, body);
}

export function deportMemberNode(bandId, userId) {
    return axios.post(`${SERVER_URI}:5000/api/member/users/deport?userId=${userId}&bandId=${bandId}`);
}

export function clearDeportMemberNode(bandId, userId) {
    return axios.post(`${SERVER_URI}:5000/api/member/users/clearDeport?userId=${userId}&bandId=${bandId}`);
}

export function setFixedBandsNode(fixed) {
    const userId = localStorage.getItem('userId');

    const body = {
        fixed: fixed
    };

    return axios.put(`${SERVER_URI}:5000/api/member/settings/fixed?userId=${userId}`, body);
}

export function setHiddenBandsNode(hidden) {
    const userId = localStorage.getItem('userId');

    const body = {
        hidden: hidden
    };

    return axios.put(`${SERVER_URI}:5000/api/member/settings/hidden?userId=${userId}`, body);
}

export function updateBandUserSettingsNode(bandId, userId,
    applyPublicProfile, notifyThroughUcTalk, postNotificationType, replyNotificationType,
    notifySchedule, notifyContent, notifyChat) {
    const body = {
        applyPublicProfile: applyPublicProfile,
        notifyThroughUcTalk: notifyThroughUcTalk,
        postNotificationType: postNotificationType,
        replyNotificationType: replyNotificationType,
        notifySchedule: notifySchedule,
        notifyContent: notifyContent,
        notifyChat: notifyChat,
    };

    return axios.put(`${SERVER_URI}:5000/api/member/users/updateSettings?bandId=${bandId}&userId=${userId}`, body);

}

export function currentBandMemberNode(bandId) {
    return axios.get(`${SERVER_URI}:5000/api/member/currentUser?bandId=${bandId}&userId=${localStorage.getItem('userId')}`)
        .then(response => {
            return response.data
        })
}

export function assignBandLeaderNode(bandId, userIds) {
    const body = {
        userIds: userIds,
    };

    return axios.post(`${SERVER_URI}:5000/api/member/users/assignLeader?bandId=${bandId}`, body);
}

export function changeBandTagsNode(bandId, tags) {
    const body = {
        tags: tags,
    };
    // tags: (7) ["777", "555", "444", "333", "2222", "111", "222"]
    return axios.put(`${SERVER_URI}:5000/api/band/tag/${bandId}`, body);
}

export function withdrawBandNode(bandId) {

    let userId = localStorage.getItem('userId');

    return axios.post(`${SERVER_URI}:5000/api/member/users/withdraw?bandId=${bandId}&userId=${userId}`);

}

export function deleteBandNode(bandId) {
    return axios.delete(`${SERVER_URI}:5000/api/band?bandId=${bandId}`);
}

export function getSingleBandMember(bandId, userId) {
    return axios.get(`${SERVER_URI}:5000/api/member/band/user?userId=${userId}&bandId=${bandId}`)
        .then(response => {
            return response.data
        });
}

export function getBandName(bandId) {
    return axios.get(`${SERVER_URI}:5000/api/band/name/${bandId}`)
        .then(response => { return response.data });
}