import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CustomToolbar from './CustomToolBar';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import moment from 'moment';
import Events from './Events';
function CalendarComponent({ events, calendarIsOpen, handleSelect, bandId, yearMonth, selected }) {
    const { t } = useTranslation();
    const localizer = momentLocalizer(moment)

    return (
        <CalendarLibraryWrapper>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                defaultDate={moment().toDate()}
                selectable
                onSelectSlot={handleSelect}
                timeevents={1}
                components={{
                    toolbar: props => (<CustomToolbar {...props} t={t} createURL={`/band/${bandId}/schedule/create?createDate=${yearMonth}${selected}`} />),
                    event: Events,
                    month: {
                        dateHeader: ({ date, label }) => {
                            let sunday = false;
                            let saturday = false;
                            if (date.toString().substring(0, 3) === "Sun") {
                                sunday = true;
                            }

                            if (date.toString().substring(0, 3) === "Sat") {
                                saturday = true;
                            }

                            return (
                                <p style={sunday ? { color: "red" } : saturday ? { color: "blue" } : null}>{label}</p>
                            );
                        }
                    }
                }}
                className={calendarIsOpen ? 'open' : ''}
            />
        </CalendarLibraryWrapper>
    )
}

export default CalendarComponent


const CalendarLibraryWrapper = styled.div`
    height: 500pt;
    @media (max-width: 500px) {
        height: 400pt;
    }
`;


// eslint-disable-next-line no-lone-blocks
{/* <Calendar
    localizer={localizer}
    events={this.state.events}
    startAccessor="start"
    endAccessor="end"
    defaultDate={moment().toDate()}
    selectable
    onSelectSlot={this.handleSelect}
    timeevents={1}
    // views={['week']}
    // onSelectEvent={event => alert(event.title)}
    components={{
        toolbar: props => (<CustomToolbar {...props} t={t} createURL={`/band/${bandId}/schedule/create?createDate=${this.state.yearMonth}${this.state.selected}`} />),
        event: Events,
        // event: props => (<Events {...props} />), //위에랑 같음 
        // dateCellWrapper: Book,
        month: {
            dateHeader: ({ date, label }) => {
                let sunday = false;
                let saturday = false;
                if (date.toString().substring(0, 3) === "Sun") {
                    sunday = true;
                }

                if (date.toString().substring(0, 3) === "Sat") {
                    saturday = true;
                }
                // console.log('data', date)
                // let highlightDate =
                //     events.find(event =>
                //         // console.log(event)
                //         moment(date).isBetween(
                //             moment(event.startDate),
                //             moment(event.endDate),
                //             null,
                //             "[]"
                //         )
                //     ) !== undefined;
                return (
                    <p style={sunday ? { color: "red" } : saturday ? { color: "blue" } : null}>{label}</p>
                );
            }
        }
    }}
    className={this.props.calendarIsOpen ? 'open' : ''}
/> */}
