import { SERVER_URI } from '../configs';
import axios from 'axios';

export function getRepliesAtPostNode(bandId, postId) {

    return axios.get(`${SERVER_URI}:5000/api/reply/replies?bandId=${bandId}&postId=${postId}&type=post`)
        .then(response => { return response.data })
}

export function getRepliesAtScheduleNode(bandId, scheduleId) {

    return axios.get(`${SERVER_URI}:5000/api/reply/replies?bandId=${bandId}&scheduleId=${scheduleId}&type=schedule`)
        .then(response => { return response.data })

}

export function getReplyNode(bandId, replyId) {

    return axios.get(`${SERVER_URI}:5000/api/reply?bandId=${bandId}&replyId=${replyId}`)
        .then(response => { return response.data })

}

export function updateReplyNode(bandId, replyId, reply, fileId) {
    const body = {
        reply: reply,
        fileId: fileId,
    };

    return axios.put(`${SERVER_URI}:5000/api/reply?bandId=${bandId}&replyId=${replyId}`, body)
        .then(response => { return response.data })
}

export function deleteReplyNode(bandId, replyId, postId = null) {

    return axios.delete(`${SERVER_URI}:5000/api/reply?bandId=${bandId}&replyId=${replyId}&postId=${postId}`)
        .then(response => { return response.data })

}

export function getExpressionsAtPostNode(bandId, postId) {

    return axios.get(`${SERVER_URI}:5000/api/reply/expression?bandId=${bandId}&postId=${postId}`)
        .then(response => { return response.data })

}

export function createReplyAtPostNode(bandId, postId, reply, fileId) {

    const body = {
        reply: reply,
        fileId: fileId,
        createdBy: localStorage.getItem('userId')
    };

    return axios.post(`${SERVER_URI}:5000/api/reply/post?bandId=${bandId}&postId=${postId}`, body)

}

export function createReplyAtScheduleNode(bandId, scheduleId, reply, fileId) {
    const body = {
        reply: reply,
        fileId: fileId,
        createdBy: localStorage.getItem('userId')
    };

    return axios.post(`${SERVER_URI}:5000/api/reply/schedule?bandId=${bandId}&scheduleId=${scheduleId}`, body)

}

export function createExpressionAtPostNode(bandId, postId, expression) {
    const body = {
        expression: expression,
        createdBy: localStorage.getItem('userId')
    };

    return axios.post(`${SERVER_URI}:5000/api/reply/expression?bandId=${bandId}&postId=${postId}`, body)

}

export function getAttendStatusNode(bandId, scheduleId, scheduleDate) {

    return axios.get(`${SERVER_URI}:5000/api/reply/attend?bandId=${bandId}&scheduleId=${scheduleId}&scheduleDate=${scheduleDate}`)
        .then(response => { return response.data })

}

export function attendNode(bandId, scheduleId, scheduleDate, status) {
    const body = {
        scheduleDate: scheduleDate,
        status: status,
        createdBy: localStorage.getItem('userId')
    };

    return axios.post(`${SERVER_URI}:5000/api/reply/attend?bandId=${bandId}&scheduleId=${scheduleId}`, body)
}

