import React from 'react';
import '../../../assets/css/_modal.scss';
import { withTranslation } from 'react-i18next';
import { roundUp } from '../../../utils/CommonUtils';

class ContentStorageManagementModal extends React.Component {

    onClose;
    show;
    bandId;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            members: [],
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        const { t } = this.props;
        let megaByteDiskUsage = Number(this.props.diskUsage) / (1028 * 1028);
        let roundUpDiskUsage = roundUp(megaByteDiskUsage, 1)
        let stringifiedDiskUsage = `${roundUpDiskUsage}M`
        return (
            <div>
                <div className="modal_wrap">
                    <div className="modal">
                        <button className="close_btn" aria-label="모달창 닫기 버튼" onClick={this.props.onClose}></button>
                        <div>
                            <p className="title">{t('Setting.contentStorageSpaceManagement')}</p>
                            <div className="modal_content view_type">
                                {t('Setting.usage')}  : {stringifiedDiskUsage} / {localStorage.getItem('maxDiskUsage') / 1024 / 1024 / 1024}GB
                            </div>
                            <div>
                                <button className="modal_submit_btn" onClick={this.props.onClose}>
                                    {t('Setting.submit')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}



export default withTranslation()(ContentStorageManagementModal);
