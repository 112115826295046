import React from 'react';
import styled from 'styled-components';
import { THEME_BACKGROUND_COLOR } from '../../../configs';

class SelectBoxSelect extends React.Component {

    _isMounted = false;

    title;
    name;
    defaultValue;
    options;
    onChange;

    constructor(props) {
        super(props);

        this.state = {
            selectValue: this.props.defaultValue,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            if (this._isMounted) {
                this.setState({ selectValue: this.props.defaultValue });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = (e) => {
        if (this._isMounted) {
            this.setState({
                selectValue: e.currentTarget.value,
            }, () => this.props.onChange(this.props.name, this.state.selectValue));
        }
    };

    render() {
        const currentLanguage = localStorage.getItem("i18nextLng");

        return (
            <Container>
                <Title poll={this.props.poll}>
                    {this.props.title}
                </Title>
                <Select poll={this.props.poll} >
                    <select value={this.state.selectValue} onChange={this.handleChange}>
                        {this.props.options.map((option, index) =>
                            <option key={`key-${index}`} value={option.value}>
                                {currentLanguage === "korean" ? option.label :
                                    currentLanguage === "english" ? option.eng : option.ja}
                            </option>
                        )}
                    </select>
                </Select>
            </Container>
        );
    }
}

const Container = styled.div`
    display: flex;
    flex-grow: 1;
    padding: .25rem 0;
`;

const Title = styled.div`
    width: 50%;
    text-align: left;
    padding: 5px 0;

    font-weight: ${props => props.poll ? "bold" : "bold"};
    font-size: ${props => props.poll ? "1rem" : null}; 
`;

const Select = styled.div`
    width: 50%;
    text-align: right;
    
    option {
    }

    select {
        margin-top:8px;
        background-color: ${props => props.poll ? "white" : "#F9F9FB"};
        direction: rtl;
        color: ${THEME_BACKGROUND_COLOR};
        border: none;
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;
    }

    select::-ms-expand {
        display: none;
        -webkit-appearance: none;
    }
`;

export default SelectBoxSelect;

