import React from 'react';
import styled from 'styled-components';
import { THEME_BACKGROUND_COLOR, SERVER_URI, COMPANY_NAME } from '../../../configs';
import * as StringUtils from '../../../utils/StringUtils';
import { MdInsertDriveFile } from "react-icons/md";
import * as DateTimeUtils from '../../../utils/DateTimeUtils';

import NoImage  from '../../../assets/images/no_self_img.png';

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class ChatMessage extends React.Component {

    _isMounted = false;

    messages;
    currentMember;
    messagesEndRef = React.createRef();

    state = {
        page: 1
    };

    componentDidMount() {
        this._isMounted = true;
        this.scrollToBottom();
        this.setState({ page: this.props.page })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (this.state.page === prevProps.page) {
            if (this.props.messages !== prevProps.messages) {
                this.scrollToBottom();
            }
        }

        if (this.props.page !== prevProps.page) {
            setTimeout(() => {
                this.setState({ page: this.props.page })
            }, 1000);
        }
    }

    scrollToBottom = () => {
        this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    };

    render() {
        const { messages } = this.props;
        return (
            <MessageList>
                {messages && messages.map((item, index) => this.renderMessage(item, index, messages))}
                <MessageEndDiv isMSIE={__ISMSIE__} ref={this.messagesEndRef} />
            </MessageList>
        );
    }

    routeDownloadOrOpenViewer = (openViewer, item) => {
        if (openViewer) {
            this.props.openFunction(item);
        } else {
            this.download(item);
        }
    };

    deleteMessage = (item) => {
        this.props.openDeleteFunction(item);
    };

    toDataURL = (url) => {
        return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            return URL.createObjectURL(blob);
        });
    }

    download = async (item) => {
        // if (__ISMSIE__ && COMPANY_NAME !== "UC WARE") {
        //     const link = document.createElement('a');
        //     link.href = `${SERVER_URI}:5000/_UPLOAD_FILES/${item.bandId}/${item.fileName}`;
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // }
        if (navigator.msSaveBlob) {
            fetch(`${SERVER_URI}:5000/_UPLOAD_FILES/${item.bandId}/${item.fileName}`)
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {
                    return navigator.msSaveBlob(blob, `${item.fileName}`);
                    // Here's where you get access to the blob
                    // And you can use it for whatever you want
                    // Like calling ref().put(blob)

                    // Here, I use it to make an image appear on the page
                    //   let objectURL = URL.createObjectURL(blob);
                    //   let myImage = new Image();
                    //   myImage.src = objectURL;
                    //   document.getElementById('myImg').appendChild(myImage)
                });
        }
        else {
            const link = document.createElement('a');
            link.href = await this.toDataURL(`${SERVER_URI}:5000/_UPLOAD_FILES/${item.bandId}/${item.fileName}`);
            link.download = `${item.fileName}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    };

    renderMessage = (item, index, messages) => {
        const noticeMessage = item.type === 5 ? true : false
        const messageFromMe = (item.userId === Number(localStorage.getItem('userId'))) ? true : false;

        if (index !== 0) {
            var IsContinuousMessage = DateTimeUtils.momentByDefaultFormat(messages[index].createdAt) - DateTimeUtils.momentByDefaultFormat(messages[index - 1].createdAt) > 7000
                ||
                messages[index].userId !== messages[index - 1].userId ? false : true
        }

        let checkImage = item.type === 1 ? true : false;
        let checkVideo = item.type === 2 ? true : false;
        let checkFile = item.type === 3 ? true : false;

        const isContinuousOtherMessageMarginLeft = '47px';

        const OthersMessage = <ListItem key={`chat-${index}`}>
            {IsContinuousMessage ? null :
                <Avatar>
                    {(StringUtils.isNullOrUndefined(item.profileImage) || item.profileImage === "no") ?
                        <img src={NoImage} alt={`avatar-${item.name}`} /> :
                        <img src={`${item.profileImage}`} alt={`avatar-${item.name}`} />
                    }
                </Avatar>
            }
            <MessageContent>
                {IsContinuousMessage ? null :
                    <div>
                        <Name>{item.name}</Name>
                    </div>
                }
                {checkFile ?
                    <>
                        <MessageFile
                            onClick={() => this.routeDownloadOrOpenViewer(false, item)}
                            style={{ marginLeft: `${IsContinuousMessage ? isContinuousOtherMessageMarginLeft : "0px"}` }}>
                            <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MdInsertDriveFile style={{ width: '100%', marginRight: '10px', height: '30px', color: 'black' }} />
                            </div>
                            <div style={{ width: '70%' }}>{item.fileName}</div>
                        </MessageFile>
                        {IsContinuousMessage ? null : <p style={{ fontSize: '10px', color: 'gray' }}>
                            {DateTimeUtils.momentByFormat(item.createdAt, 'LT')}
                        </p>}
                    </>
                    :
                    <>
                        {checkImage ?
                            <>
                                <Message
                                    onClick={() => this.routeDownloadOrOpenViewer(true, item)}
                                    className="message" IsContinuousMessage={IsContinuousMessage} checkVideoOrImage
                                    style={{ marginLeft: `${IsContinuousMessage ? isContinuousOtherMessageMarginLeft : "0px"}` }} >
                                    <img src={`${COMPANY_NAME !== "UC WARE" ? `${SERVER_URI}:5000/_UPLOAD_FILES/${item.bandId}/${item.fileName}` : `${SERVER_URI}:5000/_UPLOAD_FILES/${item.bandId}/${item.thumbnail}`}`} alt={item.fileName} />
                                </Message>
                                {IsContinuousMessage ? null : <p style={{ fontSize: '10px', color: 'gray' }}>  {DateTimeUtils.momentByLTFormat(item.createdAt)}</p>}
                            </>
                            :
                            <>
                                {checkVideo ?
                                    <>
                                        <Message
                                            onClick={() => this.routeDownloadOrOpenViewer(true, item)}
                                            className="message" IsContinuousMessage={IsContinuousMessage} checkVideoOrImage
                                            style={{ marginLeft: `${IsContinuousMessage ? isContinuousOtherMessageMarginLeft : "0px"}` }}  >
                                            <img src={`${SERVER_URI}:5000/_UPLOAD_FILES/${item.bandId}/${item.thumbnail}`} alt={item.fileName} />
                                        </Message>
                                        {IsContinuousMessage ? null : <p style={{ fontSize: '10px', color: 'gray' }}>{DateTimeUtils.momentByLTFormat(item.createdAt)}</p>}
                                    </>
                                    :
                                    <>
                                        <Message IsContinuousMessage={IsContinuousMessage}
                                            style={{ marginLeft: `${IsContinuousMessage ? isContinuousOtherMessageMarginLeft : "0px"}` }} >
                                            {item.content}
                                        </Message>
                                        {IsContinuousMessage ? null : <p style={{ fontSize: '10px', color: 'gray' }}>{DateTimeUtils.momentByLTFormat(item.createdAt)}</p>}
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </MessageContent>
        </ListItem>;

        const MyMessage = <ListItem key={`chat-${index}`} currentMember>
            <MessageContent currentMember>
                {checkFile ?
                    <>
                        <MessageFile
                            onClick={() => this.routeDownloadOrOpenViewer(false, item)}
                        ><div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MdInsertDriveFile style={{ width: '100%', marginRight: '10px', height: '30px', color: 'black' }} />
                            </div><div style={{ width: '70%' }}>{item.fileName}</div>
                        </MessageFile>
                        {IsContinuousMessage ? null : <p style={{ fontSize: '10px', color: 'gray' }}>{DateTimeUtils.momentByLTFormat(item.createdAt)}</p>}
                    </>
                    :
                    <>
                        {checkImage ?
                            <>
                                <Message
                                    onClick={() => this.routeDownloadOrOpenViewer(true, item)}
                                    className="message" currentMember IsContinuousMessage={IsContinuousMessage} checkVideoOrImage >
                                    <img onClick={this.routeOpenViewer} src={`${COMPANY_NAME !== "UC WARE" ? `${SERVER_URI}:5000/_UPLOAD_FILES/${item.bandId}/${item.fileName}` : `${SERVER_URI}:5000/_UPLOAD_FILES/${item.bandId}/${item.thumbnail}`}`} alt={item.fileName} />
                                </Message>
                                {IsContinuousMessage ? null : <p style={{ fontSize: '10px', color: 'gray' }}>{DateTimeUtils.momentByLTFormat(item.createdAt)}</p>}
                            </>
                            :
                            <>
                                {checkVideo ?
                                    <>
                                        <Message
                                            onClick={() => this.routeDownloadOrOpenViewer(true, item)}
                                            className="collageItem player -size80 bandHomeVideoHover" currentMember IsContinuousMessage={IsContinuousMessage} checkVideoOrImage >
                                            <button className="collageImage _imageButton">
                                                <img src={`${SERVER_URI}:5000/_UPLOAD_FILES/${item.bandId}/${item.thumbnail}`} alt={item.fileName} />
                                            </button>
                                        </Message>
                                        {IsContinuousMessage ? null : <p style={{ fontSize: '10px', color: 'gray' }}>{DateTimeUtils.momentByLTFormat(item.createdAt)}</p>}
                                    </>
                                    :
                                    <>
                                        <Message
                                            onClick={() => this.deleteMessage(item)}
                                            className="message" currentMember IsContinuousMessage={IsContinuousMessage}>
                                            {item.content}
                                        </Message>
                                        {IsContinuousMessage ? null : <p style={{ fontSize: '10px', color: 'gray' }}>{DateTimeUtils.momentByLTFormat(item.createdAt)}</p>}
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </MessageContent>
        </ListItem>;


        const NoticeMessage = <ListItemNotice key={`chat-${index}`}>
            <NoticeMessageContent >
                <p style={{ fontSize: '10px', color: 'gray' }}>{DateTimeUtils.momentByLTFormat(item.createdAt)}</p>
                {item.content}
            </NoticeMessageContent>
        </ListItemNotice>;

        // return (messageFromMe ? MyMessage : OthersMessage);

        return (noticeMessage ? NoticeMessage : messageFromMe ? MyMessage : OthersMessage);
    };
}

const MessageEndDiv = styled.div`
    padding: ${props => props.isMSIE ? "2rem" : ""};
`;

const MessageList = styled.div`
    padding: 0rem 1rem 2rem;
    width: 100%;
    margin: 0 auto;
    list-style: none;
    flex-grow: 1;
    overflow: auto;

    @media (max-width: 500px) {
        padding: 3rem 1rem 2rem;
    }
`;

const ListItem = styled.li`
    display: flex;
    margin-top: .5rem;
    padding-right: .5rem;
    flex-direction: ${props => props.currentMember ? "row-reverse" : "row"};
`;

const ListItemNotice = styled.li`
    display: flex;
    margin-top: .5rem;
    padding-right: .5rem;
    justify-content: center;
    text-align: center;
`

const Avatar = styled.span`{
    border-radius: 50%;
    display: inline-block;
    padding-right: .5rem;
    img {
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
    }
}
`;

const MessageContent = styled.div`
    display: inline-block;
    align-items: ${props => props.currentMember ? "flex-end" : "flex-start"};
`;

const NoticeMessageContent = styled.div`
display: inline-block;
    align-items: center;
    padding: 1rem;
`;

const Name = styled.span`
    color: black;
    font-size: .9rem;
    font-weight: bold;
`;

const MessageFile = styled.div`
    padding: .5rem 1rem;
    margin-top: .2rem;
    border-radius: 7px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    a {
        color: black;
    }
    max-width: 227px;
    word-break: break-all;
    cursor: pointer;
`;

const Message = styled.div`
    position: relative;
    display: inline-block;
    cursor: ${props => props.checkVideoOrImage ? "pointer" : ""};

    padding: ${props => props.checkVideoOrImage ? "0" : ".5rem 1rem"};
    margin-top: .2rem;
    border-radius:  7px;
    background-color:${props => props.currentMember ? (props.checkVideoOrImage ? "transparent" : `${THEME_BACKGROUND_COLOR}`) : `white`};
    color: ${props => props.currentMember ? `white` : `${THEME_BACKGROUND_COLOR}`};
    border: ${props => props.currentMember ? null : (props.checkVideoOrImage ? null : `1px solid ${THEME_BACKGROUND_COLOR}`)};
    display: inline-block;
    img {
    width: 15rem;
    }
    video {
    width: 15rem;
    }
    a {
        color: ${props => props.currentMember ? `white` : `${THEME_BACKGROUND_COLOR}`};
    }
    max-width: 227px;
    word-break: break-all;

    p {
        max-width: 227px;
    }
`;

export default ChatMessage;


