import React from 'react';
import '../../../assets/css/_modal.scss';
import { withTranslation } from 'react-i18next';
import iconNope from '../../../assets/images/icon-nope.svg';
import styled from 'styled-components';

class BandWithdrawConfirmModal extends React.Component {

    onClose;
    show;
    bandId;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            members: [],
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        const { t } = this.props;

        return (
            <div className="modal_wrap">
                <div className="modal">
                     <button className="close_btn" aria-label="모달창 닫기 버튼"onClick={this.props.onClose}></button>
                    <div>
                        {this.props.isBandLeader ?
                            <>
                                <img className="iconNope" src={iconNope} alt="icon-nope" />
                                <div className="modal_content view_type">
                                    <h4>{t('Setting.youCanLeaveTheBandAfterTransferring')}<br />{t('Setting.theLeadersAuthorityToAnotherMember')}</h4>
                                </div>
                            </>
                            :
                            <>
                                <p className="title"> {t('Setting.bandWithdrawal')}</p>
                                <div className="modal_content view_type">
                                    <GrayWrapper style={{ width: '100%' }}>
                                        <div>
                                            <button onClick={this.props.withdrawBand}>{t('Setting.yes')}</button>
                                        </div>
                                        <Divider />
                                        <div>
                                            <button onClick={this.props.onClose}>{t('Setting.no')}</button>
                                        </div>
                                    </GrayWrapper>
                                </div>
                            </>
                        }
                        <div>
                            <button className="modal_submit_btn" onClick={this.props.onClose}>
                                {t('Setting.submit')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}



export default withTranslation()(BandWithdrawConfirmModal);

const GrayWrapper = styled.div`
    background-color: #f9f9fb;
    padding: 1rem;
    border-top: solid 1.5px #e5e5ea;
    border-bottom: solid 1.5px #e5e5ea;
`;

const Divider = styled.div`
    border-bottom: solid 1px #e5e5ea;
    margin: 7px 0;
`;