/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import ReactHtmlParser, { processNodes } from 'react-html-parser';
import Modal from 'react-awesome-modal';
import { Player, BigPlayButton } from 'video-react';
import { ContentViewer } from '../../../common/components/Modal';
import { Menu } from 'antd';
import { Popup } from 'semantic-ui-react'

import { BandMenu, BandTopButtons } from '../../Menu';
import { ReplyList } from '../../../common/components/Reply';
import { SimpleEditor } from '../../../common/components/Edit';
import { List, Avatar, message } from 'antd';

import * as PostService from '../../../services/PostService';
import * as ReplyService from '../../../services/ReplyService';
import * as BandService from '../../../services/BandService';
import * as FileService from '../../../services/FileService';

import * as DateTimeUtils from '../../../utils/DateTimeUtils';
import * as CommonUtils from '../../../utils/CommonUtils';
import * as StringUtils from '../../../utils/StringUtils';
import * as UserService from '../../../services/UserService';
import DialogComp from '../../../common/components/Shared/DialogComp';
import ViewState from '../ViewStatePage/ViewStatePage';
import { expressions, SERVER_URI, COMPANY_NAME } from '../../../configs';
import { withTranslation } from 'react-i18next';

import btnAddCheck from '../../../assets/images/btn-add-check.svg';
import btnMore from '../../../assets/images/btn-more-new.svg';
import NoImage from '../../../assets/images/no_self_img.png';
import iconViewCount from '../../../assets/images/icon-view-count.svg';
import {
    isMobile
} from "react-device-detect";
const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class Detail extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            bandId: this.props.match.params["bandId"],
            postId: this.props.match.params["postId"],
            band: undefined,
            bandMembers: [],
            post: undefined,
            writer: undefined,
            isWriter: false,
            isBandLeader: false,
            replies: [],
            expressions: [],
            openPostMenu: false,
            openDeleteConfirmationPopup: false,
            openExpressionsPopup: false,
            openUserBandListSelectPopup: false,
            userBands: [],
            targetToCopyPost: undefined,
            isNotice: false,
            isContentViewerModalOpen: false,
            selectedContent: "",
            fileId: 0,
            isOpenFileTransferProgress: false,
            isViewStateModalOpen: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBandInfo();
        this.updatePostViewMember();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandInfo = () => {
        BandService.getBandInfo(this.state.bandId)
            .then(result => {
                if (this._isMounted) {
                    this.setState({
                        band: result.band,
                        isBandMember: result.isBandMember,
                        isOpened: result.isOpened,
                    }, this.loadPost);
                }
            })
            .catch(error => console.error(error));
    };

    updatePostViewMember = () => {
        PostService.updatePostViewMember(this.state.postId)

    }

    loadPost = () => {
        let bandMembers, post, writer, replies, expressions;
        PostService.getPostNode(this.state.bandId, this.state.postId)
            .then(result => post = result)
            .then(() => ReplyService.getRepliesAtPostNode(this.state.bandId, this.state.postId))
            .then(result => replies = result)
            .then(() => ReplyService.getExpressionsAtPostNode(this.state.bandId, this.state.postId))
            .then(result => expressions = result)
            .then(() => BandService.getBandMembersNode(this.state.bandId, undefined))
            .then(result => bandMembers = result)
            .then(() => {
                // console.log('replies', replies)
                const found = bandMembers.filter(member => member.userId === post.createdBy);
                const isBandLeader = bandMembers.filter(member => member.userId === Number(localStorage.getItem('userId'))).length > 0 ? true : false;
                if (found && found.length > 0) {
                    writer = found[0];
                }

                const isWriter = post.createdBy === Number(localStorage.getItem('userId'));
                if (this._isMounted) {
                    // console.log('post.isNotice', post.isNotice)
                    this.setState({
                        post: post,
                        bandMembers: bandMembers,
                        writer: writer,
                        replies: replies,
                        expressions: expressions,
                        isWriter: isWriter,
                        isNotice: post.isNotice,
                        isBandLeader: isBandLeader
                    });
                }
            })
            .catch(error => console.error(error));
    };

    closeContentViewerModal = () => {
        if (this._isMounted) {
            this.setState({
                isContentViewerModalOpen: false,
                selectedContent: "",
            });
        }
    };

    openContentViewerModal = (item, type) => {
        if (this._isMounted) {

            if (type !== "img") return;

            this.setState({
                isContentViewerModalOpen: true,
                selectedContent: item,
            });
        }
    };

    handleModal = (name, value) => {
        if (this.state.hasOwnProperty(name)) {
            if (this._isMounted) {
                this.setState({
                    [name]: value,
                });
            }
        }
    };

    createReply = (reply, file, fileChangeType) => {
        const bandId = this.props.match.params["bandId"];
        const postId = this.props.match.params["postId"];

        //여기서 코멘트에 관한 로딩 시작 
        if (file) {
            this.setState({ isOpenFileTransferProgress: true })
        }

        if (file) {
            FileService.uploadFileNode(bandId, localStorage.getItem('userId'), file)
                .then(response => { ReplyService.createReplyAtPostNode(bandId, postId, reply, response.data.results.id) })
                .then(() => this.loadPost())
                .then(() => this.setState({ isOpenFileTransferProgress: false }))
                .then(() => UserService.sendNotificationToPostUser(bandId, postId, this.state.writer.userId, localStorage.getItem('userAccount'), localStorage.getItem('userName')))
                .catch(error => message.error(error));
        } else {
            ReplyService.createReplyAtPostNode(bandId, postId, reply, undefined)
                .then(() => this.loadPost())
                .then(() => UserService.sendNotificationToPostUser(bandId, postId, this.state.writer.userId, localStorage.getItem('userAccount'), localStorage.getItem('userName')))
                .catch(error => message.error(error));
        }

    };

    vote = (e) => {
        let poll = this.state.post.polls.filter(poll => poll.id === Number(e.currentTarget.value))[0];
        //e.currentTarget.value === pollId
        //e.currentTarget.id === pollOptionId
        if (poll.multipleSelectionCount > 0) {
            let checkedChecks = document.querySelectorAll('input[type="checkbox"]:checked');
            // console.log('checkedChecks', checkedChecks)
            // console.log('checkedChecks.length', checkedChecks.length)
            if (checkedChecks.length > poll.multipleSelectionCount) {
                e.currentTarget.checked = false;
            }
        }
        // console.log('Number(e.currentTarget.value)', Number(e.currentTarget.value))
        // console.log('this.state.post.polls', this.state.post.polls)
        // console.log('e.currentTarget.checked', e.currentTarget.checked)
        // console.log('e.currentTarget.type', e.currentTarget.type)
        if (e.currentTarget.checked) {
            PostService.voteNode(this.state.bandId, this.state.postId, e.currentTarget.value, e.currentTarget.id, e.currentTarget.type)
                .then(() => this.loadPost())
                .catch(error => message.error(error));
        } else {
            if (poll.multipleSelectionCount > 0) {
                let checkedChecks = document.querySelectorAll('input[type="checkbox"]:checked');
                if (checkedChecks.length >= poll.multipleSelectionCount) {
                    return message.warning(`${this.props.t('Post.Detail.limitTheNumberOfDuplicateVotes')} : ${poll.multipleSelectionCount}`)
                }
            }
            PostService.voteCancelNode(this.state.bandId, this.state.postId, e.currentTarget.value, e.currentTarget.id)
                .then(() => this.loadPost())
                .catch(error => message.error(error));
        }
    };

    bandMemberProfile = (id) => {
        let found = this.state.bandMembers.filter(bandMember => bandMember.userId === id);
        if (found && found.length > 0) {
            // return found[0].profileImage ? `${SERVER_URI}:8061/resource/profile/${found[0].profileImage}` : NoImage;
            return found[0].profileImage ? `${found[0].profileImage}` : NoImage;
        }
        return NoImage;
    };

    express = (value) => {
        ReplyService.createExpressionAtPostNode(this.state.bandId, this.state.postId, value)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        openExpressionsPopup: false,
                    }, this.loadPost);
                }
            })
            .catch(error => message.error(error));
    };

    openViewStateModal = () => {
        if (this._isMounted) {
            this.setState({
                isViewStateModalOpen: true,
            });
        }
    }

    closeViewStateModal = () => {
        if (this._isMounted) {
            this.setState({
                isViewStateModalOpen: false,
            });
        }
    }

    openExpressionsPopup = () => {
        if (this._isMounted) {
            this.setState({
                openExpressionsPopup: true,
            });
        }
    };

    closeExpressionsPopup = () => {
        if (this._isMounted) {
            this.setState({
                openExpressionsPopup: false,
            });
        }
    };

    openPostMenu = () => {
        if (this._isMounted) {
            this.setState({ openPostMenu: true });
        }
    };

    closePostMenu = () => {
        if (this._isMounted) {
            this.setState({ openPostMenu: false });
        }
    };

    modifyPost = () => {
        if (this._isMounted) {
            this.setState({
                openPostMenu: false
            }, () => this.props.history.push("/band/" + this.state.bandId + "/post/" + this.state.postId + "/modify"));
        }
    };

    deletePost = () => {
        if (this._isMounted) {
            this.setState({
                openDeleteConfirmationPopup: true,
                openPostMenu: false
            });
        }
    };

    closeDeleteConfirmationPopup = () => {
        if (this._isMounted) {
            this.setState({ openDeleteConfirmationPopup: false });
        }
    };

    deleteConfirm = (value) => {
        if (!!value) {
            PostService.deletePostNode(this.state.bandId, this.state.postId)
                .then(() => {
                    if (this._isMounted) {
                        this.setState({
                            openDeleteConfirmationPopup: false
                        }, this.routeBandHome);
                    }
                })
                .catch(error => {
                    console.error(error);
                    if (this._isMounted) {
                        this.setState({ openDeleteConfirmationPopup: false });
                    }
                });
        } else {
            if (this._isMounted) {
                this.setState({ openDeleteConfirmationPopup: false });
            }
        }
    };

    routeBandHome = () => {
        this.props.history.push("/band/" + this.state.bandId + "/home");
    };

    copyPostContentToClipboard = () => {
        if (this._isMounted) {
            this.setState({
                openPostMenu: false
            }, () => {
                const textField = document.createElement('textarea');
                textField.innerText = this.state.post.content;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                if (__ISMSIE__) {
                    window.location.reload();
                }
                textField.remove();
            });
        }
    };

    copyUrlLinkToClipboard = () => {
        if (this._isMounted) {
            this.setState({
                openPostMenu: false
            }, () => {
                const textField = document.createElement('textarea');
                textField.innerText = `${window.location.href}?accessToken=${localStorage.getItem('accessToken')}`;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                if (__ISMSIE__) {
                    window.location.reload();
                }
                textField.remove();
            });
        }
    };

    copyPostToOtherBand = () => {
        BandService.getAllUserBandListNode(localStorage.getItem('userId'))
            .then(bandIds => BandService.getBandListNode(bandIds, 0))
            .then(bandList => {
                const userBands = bandList.content.filter(band => band.id !== Number(this.state.bandId));
                if (this._isMounted) {
                    this.setState({
                        openPostMenu: false,
                        userBands: userBands,
                        openUserBandListSelectPopup: true,
                    })
                }
            })
            .catch(error => console.error(error));
    };

    closeUserBandListSelectPopup = () => {
        if (this._isMounted) {
            this.setState({
                openUserBandListSelectPopup: false,
                targetToCopyPost: undefined,
            })
        }
    };

    selectBand = (e) => {
        if (this._isMounted) {
            this.setState({ targetToCopyPost: Number(e.currentTarget.value) });
        }
    };

    copyPost = () => {
        if (this.state.targetToCopyPost === undefined) {
            return alert(`${this.props.t('Post.Detail.pleaseSelectOneOrMoreBands')}`);
        }
        PostService.copyPost(this.state.bandId, this.state.postId, this.state.targetToCopyPost, DateTimeUtils.formattedCurrent())
            .then(() => this.closeUserBandListSelectPopup())
            .catch(error => console.error(error));
    };

    handleNotice = () => {
        if (this.state.isNotice) {
            PostService.removeFromNoticeNode(this.state.bandId, this.state.postId)
                .then(() => this.routeBandHome())
                .catch(error => console.error(error));
        } else {
            PostService.setAsNoticeNode(this.state.bandId, this.state.postId, DateTimeUtils.formattedCurrent())
                .then(() => this.routeBandHome())
                .catch(error => console.error(error));
        }
    };

    setAsNotice = () => {
        PostService.setAsNoticeNode(this.state.bandId, this.state.postId, DateTimeUtils.formattedCurrent())
            .then(() => this.routeBandHome())
            .catch(error => console.error(error));
    };

    removeFromNotice = () => {
        PostService.removeFromNoticeNode(this.state.bandId, this.state.postId)
            .then(() => this.routeBandHome())
            .catch(error => console.error(error));
    };

    routeViewState = () => {
        this.props.history.push("/band/" + this.state.bandId + "/post/" + this.state.postId + "/viewState");
    };

    routeSchedule = (scheduleId) => {
        this.props.history.push("/band/" + this.state.bandId + "/schedule/" + scheduleId);
    };

    finishPoll = (pollId, dueDateTime) => {
        PostService.completePollNode(this.state.bandId, this.state.postId, pollId, dueDateTime)
            .then(this.loadPost)
            .catch(error => console.error(error));
    };

    toDataURL = (url, fileName) => {
        return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                return window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                return URL.createObjectURL(blob);
            }
        });
    }

    download = async (url, fileName) => {

        if (__ISMSIE__) {
            if (navigator.msSaveBlob) {
                fetch(url)
                    .then(res => res.blob()) // Gets the response and returns it as a blob
                    .then(blob => {
                        return navigator.msSaveBlob(blob, fileName);
                    });
            }
        }
        else {
            const link = document.createElement('a');
            link.href = await this.toDataURL(url);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    };

    render() {
        const { bandId, post, writer, replies, postId, band } = this.state;
        const { history, t } = this.props;
        const current = DateTimeUtils.formattedCurrent();

        const content = post && post.content ? post.content
            .split('<p>').join('<p class="paragraph">')
            .split('</p>').join('</p>') : undefined
            ;


        const transform = (node, index) => {

            console.log('node', node)

            if (node.type === 'tag' && node.name === 'p') {
                if (node.attribs && node.attribs.class) {
                    if (node.attribs.class === 'poll-inner-post') {
                        const pollId = node.attribs.id;
                        const poll = this.state.post.polls[pollId];
                        const finished = poll.pollDueDateTime ? (current >= poll.pollDueDateTime) : false;
                        let votedMember = [];
                        poll.pollOptions.forEach(pollOption => {
                            pollOption.voted.forEach(vote => {
                                if (!CommonUtils.contains(votedMember, vote)) {
                                    votedMember.push(vote);
                                }
                            });
                        });
                        const displayResult = (poll.pollStatusDisplayType === 0
                            || finished
                            || (poll.pollStatusDisplayType === 1 && CommonUtils.contains(votedMember, localStorage.getItem('userId'))));
                        return <PollWrapper key={index}>
                            <PollTitle>
                                {poll.title}
                                &nbsp;&nbsp;
                                {displayResult && <span>{`${votedMember.length}${t('Post.Detail.participants')}`}</span>}
                            </PollTitle>

                            {poll.pollOptions.map(pollOption => {
                                const checked = CommonUtils.contains(pollOption.voted, Number(localStorage.getItem('userId'))) ? "checked" : "";
                                return <PollOption key={pollOption.id}>
                                    {poll.allowMultipleSelection ?
                                        <input type="checkbox" disabled={finished} id={pollOption.id} value={poll.id} onChange={this.vote}
                                            checked={checked}
                                        /> :
                                        <input type="radio" disabled={finished} id={pollOption.id} value={poll.id} onChange={this.vote}
                                            checked={checked} radiobox={true}
                                        />}
                                    &nbsp;{pollOption.content}
                                    {(pollOption.voted.length > 0 && displayResult) ? `- ${pollOption.voted.length}${t('Post.Detail.votes')}` : ""}
                                    {!poll.allowAnonymous && displayResult ?
                                        <div>{pollOption.voted.map((votedMember, index) =>
                                            <span key={index}>{this.state.bandMembers.filter(bandMember => bandMember.userId === votedMember)[0].name}&nbsp;&nbsp;</span>)}
                                        </div>
                                        : ""
                                    }
                                </PollOption>
                            })}

                            {!finished ?
                                (this.state.isWriter ? <PollFooter style={{ cursor: 'pointer' }} onClick={() => this.finishPoll(poll.id, current)}>{this.props.t('Post.Detail.endVoting')}</PollFooter> : "") :
                                <PollFooter style={{ color: 'gray' }}>{this.props.t('Post.Detail.votingEnds')}</PollFooter>
                            }
                        </PollWrapper>

                    } else if (node.attribs.class === 'file-inner-post') {
                        return <FileWrapper key={index}>
                            {processNodes(node.children, transform)}
                        </FileWrapper>

                    } else if (node.attribs.class === 'schedule-inner-post') {
                        return <ScheduleWrapper key={index}
                        //이부분은 그냥 DB에 INSERT할때 처리하였다.
                        // onClick={() => this.routeSchedule(node.attribs.id)}
                        >
                            {processNodes(node.children, transform)}
                        </ScheduleWrapper>
                    }
                }
            }
            else if (node.type === 'tag' && node.attribs.class === "fr-file") {
                let fileNameArray = node.attribs.href.split("/")
                const { length, [length - 1]: fileName } = fileNameArray
                if (isMobile) {
                    return alert("다운로드는 데스크탑에서 가능합니다.")
                    // <a href={node.attribs.href} target="_blank" rel="noopener noreferrer" download>
                    //     <span style={{ cursor: 'pointer' }} >{fileName} </span>
                    // </a>
                } else {
                    return <span style={{ cursor: 'pointer' }} onClick={() => this.download(node.attribs.href, fileName)} >{fileName} </span>
                }
            }
            else if (node.type === 'tag' && node.name === 'img') {
                let fileNameArray = node.attribs.src.split("/")
                const { length, [length - 1]: fileName } = fileNameArray
                if (isMobile) {
                    return alert("다운로드는 데스크탑에서 가능합니다.")
                    // <a href={node.attribs.src} target="_blank" rel="noopener noreferrer" download>
                    //     <span style={{ cursor: 'pointer' }} ><img src={`${node.attribs.src}`} alt="그림이당" /></span>
                    // </a>
                } else {
                    return <span style={{ cursor: 'pointer' }} onClick={() => this.download(node.attribs.src, fileName)} ><img src={`${node.attribs.src}`} alt="그림이당" /></span>
                }
            }
            if (node.type === 'tag' && node.name === 'video') {
                let downloadUrl = CommonUtils.replace(node.attribs.src, "/files/", "/download/");
                let videoUrl = CommonUtils.replace(node.attribs.src, "/files/", "/video/");
                let fileNameArray = node.attribs.src.split("/")
                const { length, [length - 1]: fileName } = fileNameArray

                return (
                    <div>
                        <Player>
                            <source src={videoUrl} />
                            <BigPlayButton position="center" />
                        </Player>
                        {/* {
                            isMobile ?
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <a href={downloadUrl} target="_blank" rel="noopener noreferrer" download>
                                            {this.props.t('Post.Detail.download')}
                                        </a>
                                    </div>
                                </>
                                :
                                <> */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span style={{ cursor: 'pointer' }} onClick={() => this.download(downloadUrl, fileName)} > {this.props.t('Post.Detail.download')}</span>
                        </div>
                        {/* </>
                        } */}
                    </div>
                );
            }

            if (node.type === 'tag' && node.name === 'p' && node.children && node.children[0] && node.children[0].data) {
                if (CommonUtils.contains(node.children[0].data, '#')) {
                    return <p className="paragraph" key={index}>
                        {node.children[0].data.split(" ").map((word, index) => {
                            if (word.startsWith("#")) {
                                return index === 0 ? <a href={word} key={index} className="rdw-hashtag-link">{word}</a> :
                                    <a href={word} key={index} className="rdw-hashtag-link"> {word}</a>;
                            } else {
                                return index === 0 ? <span key={index}>{word}</span> : <span key={index}> {word}</span>;
                            }
                        })}
                    </p>
                }
            }
        };

        const options = {
            decodeEntities: true,
            transform: transform
        };
        // console.log('content', content)
        const rendered = content ? ReactHtmlParser(content, options) : undefined;
        const writerName = writer ? writer.name : "";
        const writerJobTitle = writer ? writer.jobTitle : "";
        let avatar;
        if (writer && !StringUtils.isNullOrUndefined(writer.profileImage)) {
            avatar = `${writer.profileImage}`;
        } else {
            avatar = NoImage;
        }

        const splits = this.state.selectedContent.split("/");
        const fileNameFromDetail = splits[splits.length - 1];

        const label = this.state.isNotice ? `${t('Post.Detail.dismissFromNotice')}` : `${t('Post.Detail.setPostAsNotice')}`;

        const menu = (
            <Menu>
                <Menu.Item>
                    <a onClick={this.modifyPost}>
                        {t('Post.Detail.editPost')}
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a onClick={this.deletePost}>
                        {t('Post.Detail.deletePost')}
                    </a>
                </Menu.Item>
                {/* <Menu.Item>
                    <a onClick={this.copyPostContentToClipboard}>
                        {t('Post.Detail.copyContent')}
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a onClick={this.copyUrlLinkToClipboard}>
                        {t('Post.Detail.copyURL')}
                    </a>
                </Menu.Item> */}
                {/* <Menu.Item>
                    <a onClick={this.copyPostToOtherBand}>
                        다른 협업에 등록
                    </a>
                </Menu.Item> */}
                {this.state.isBandLeader ?
                    this.state.isNotice ?
                        <Menu.Item>
                            <a onClick={this.handleNotice}>
                                {label}
                            </a>
                        </Menu.Item>
                        :
                        <Menu.Item>
                            <a onClick={this.handleNotice}>
                                {label}
                            </a>
                        </Menu.Item>
                    : null
                }
            </Menu>
        );

        return (
            <div className="wrapper">

                <BandTopButtons bandId={bandId} bandName={band && band.name} fromPage={`${t('Post.Detail.postContent')}`} historyProp={history} routeBack={true} />

                <div className="contentsWrapSpacer" />
                <div className="contentsWrap">

                    {post &&
                        <CenterWrapper isMSIE={__ISMSIE__} id={post.id} onClick={this.selectItem}>

                            <List.Item
                                actions={[(this.state.isWriter || this.state.isBandLeader) &&
                                    <Popup trigger={
                                        <button style={{ width: '10px' }}><ProfileMoreImage src={btnMore} alt="btn-img-more" /></button>
                                    } flowing hoverable >
                                        {menu}
                                    </Popup>
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar size={64} src={avatar} alt={post.writer} />
                                    }
                                    title={<a>{writerName}
                                        {!StringUtils.isNullOrUndefined(writerJobTitle) && ` | ${writerJobTitle}`}
                                    </a>}
                                    // 원래는 아래처럼 해야 한다 하지만 다르게 한 이유는 이 포스트가 만들어진 시간을 나타내는게 아닌 
                                    // 이 포스트를 임의로 공개하는 시간을 나타내기 위해서 이렇게 쓰는것임
                                    // description={DateTimeUtils.dateTimeDisplay(post.createdAt)}
                                    // description={DateTimeUtils.formattedForDateTimeDisplay(post.postDateTime)}
                                    description={DateTimeUtils.dateTimeDisplay(post.createdAt)}
                                />
                            </List.Item>

                            <Content>
                                {rendered && rendered.map((item, index) => (
                                    <div style={{ wordBreak: 'break-all' }} key={index} >{item}</div>

                                    // <div style={{ wordBreak: 'break-all' }} key={index} onClick={() => this.openContentViewerModal(item.props.src, item.type)}>{item}</div>

                                ))}
                            </Content>

                            <Replies>
                                <Grid>
                                    <Grid.Column width={4}>
                                        <Like>{t('Post.Detail.expression')}&nbsp;&nbsp;{post.expressionCount}</Like>
                                    </Grid.Column>
                                    <Grid.Column width={9}>
                                        <Reply>{t('Post.Detail.comment')}&nbsp;{replies.length}</Reply>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <Seen onClick={this.openViewStateModal}>
                                            <img src={iconViewCount} className="icon-view-count" alt="View" />&nbsp;&nbsp;{post.viewCount}
                                        </Seen>
                                    </Grid.Column>
                                </Grid>
                            </Replies>

                            <Expressions>
                                <button onClick={this.openExpressionsPopup}><img
                                    style={{ width: '100%', maxWidth: '43px', cursor: 'pointer' }}
                                    src={btnAddCheck} alt="표정 짓기" /><p>&nbsp;</p></button>
                                {this.state.expressions.map((expression, index) =>
                                    <ExpressionWrapper key={`expression-${index}`}>
                                        <ExpressionUser src={this.bandMemberProfile(expression.userId)} alt={`user-${expression.userId}`} />
                                        <Expression src={expressions[expression.expression].icon} alt={expressions[expression.expression].description} />
                                    </ExpressionWrapper>
                                )}
                            </Expressions>

                            <Divider />

                            <ReplyList postDetail postId={postId} bandId={bandId} replyList={replies} refreshFunction={this.loadPost} />

                            <ReplyInputArea>
                                <SimpleEditor placeholder={t('Post.Detail.writeAComment')} bandId={bandId} onSubmit={this.createReply} />
                            </ReplyInputArea>

                            <Spacer />

                            <BandMenu bandId={bandId} historyProp={history} />

                        </CenterWrapper>
                    }

                    <Modal visible={this.state.isViewStateModalOpen} onClickAway={() => this.closeViewStateModal()}>
                        <ViewState
                            bandId={bandId}
                            postId={postId}
                        />
                    </Modal>

                    {fileNameFromDetail &&
                        <Modal visible={this.state.isContentViewerModalOpen} onClickAway={() => this.closeContentViewerModal()}>
                            <ContentViewer detail bandId={bandId} item={fileNameFromDetail}
                                onClose={this.closeContentViewerModal} refreshContents={this.reload}
                            />
                        </Modal>
                    }


                    {/* 
                    <Modal visible={this.state.openPostMenu} onClickAway={() => this.closePostMenu()}>
                        <div style={{ padding: '1rem', textAlign: 'center' }}>
                            <div onClick={this.modifyPost}>게시글 수정</div>
                            <Divider padding />
                            <div onClick={this.deletePost}>게시글 삭제</div>
                            <Divider padding />
                            <div onClick={this.copyPostContentToClipboard}>본문 복사</div>
                            <Divider padding />
                            <div onClick={this.copyUrlLinkToClipboard}>주소 복사</div>
                            <Divider padding />
                            <div onClick={this.copyPostToOtherBand}>다른 협업에 등록</div>

                            {this.state.isBandLeader ?
                                this.state.isNotice ?
                                    <>
                                        <Divider padding />
                                        <div onClick={this.handleNotice}>{label}</div>
                                        <Divider padding />
                                    </>
                                    :
                                    <>
                                        <Divider padding />
                                        <div onClick={this.handleNotice}>{label}</div>
                                    </>
                                : null
                            }
                        </div>
                    </Modal> */}

                    <Modal visible={this.state.openDeleteConfirmationPopup} onClickAway={() => this.closeDeleteConfirmationPopup()}>
                        <div style={{ padding: '1rem', textAlign: 'center' }}>
                            <div style={{ fontSize: '1.4rem' }}>{t('Post.Detail.areYouSureYouWantToDeleteIt')}</div>
                            <br />
                            <div style={{ fontSize: '1.2rem' }}>
                                <button onClick={() => this.deleteConfirm(true)}>{t('Post.Detail.yes')}</button>
                                <Divider padding />
                                <button onClick={() => this.deleteConfirm(false)}>{t('Post.Detail.no')}</button>
                            </div>
                        </div>
                    </Modal>

                    <Modal visible={this.state.openExpressionsPopup} onClickAway={() => this.closeExpressionsPopup()}>
                        <div style={{ padding: '1rem 1rem 0.1rem 1rem' }}>
                            {expressions.map(expression =>
                                <span key={`express-${expression.id}`}>
                                    <img src={expression.icon} alt={expression.description} onClick={() => this.express(expression.id)} style={{display:'inline-block'}}/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                            )}
                        </div>
                    </Modal>

                    <Modal visible={this.state.openUserBandListSelectPopup} width="280px" onClickAway={() => this.closeUserBandListSelectPopup()}>
                        <div style={{ width: "20rem", padding: '1rem', maxHeight: 300 }}>
                            {this.state.userBands && this.state.userBands.length > 0 ?
                                <>
                                    <div style={{ maxHeight: 230, overflowY: 'auto' }}>
                                        <Grid style={{ margin: 0 }}>
                                            {this.state.userBands.map(band =>
                                                <>
                                                    <Grid.Row key={band.id} style={{ padding: "0 0 .5rem 0", margin: 0 }}>
                                                        <Grid.Column width={13}>
                                                            {band.name}
                                                        </Grid.Column>
                                                        <Grid.Column width={3}>
                                                            <input
                                                                type="radio"
                                                                value={band.id}
                                                                checked={this.state.targetToCopyPost === band.id}
                                                                onChange={this.selectBand}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Divider padding />
                                                </>
                                            )}
                                        </Grid>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
                                        <Button onClick={this.copyPost} style={{ padding: "1rem 2rem" }}>{t('Post.Detail.selectionComplete')}</Button>
                                    </div>
                                </>
                                :
                                <div style={{ padding: 10, textAlign: 'center' }}>
                                    {t('Post.Detail.thereIsNoBandToChoose')}
                                </div>
                            }
                        </div>
                    </Modal>

                    <DialogComp description={"파일이 업로드 중입니다."} open={this.state.isOpenFileTransferProgress} />

                    <SpacerForMobileSize />

                </div>

            </div>
        );
    }
}

const Button = styled.button`
`

const ProfileMoreImage = styled.img`
    width: 5px;
`;

const Divider = styled.div`
    border-bottom:  solid 1px #e5e5ea;
    margin:  ${props => props.padding ? "0.7rem 0" : "0"};
    width: 100%;
`;

const CenterWrapper = styled.div`
    background-color: #FFF;
    padding: 1rem;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
`;

const Content = styled.div`
    font-size: 1rem;
    img {
        width: 100%;
        max-width: 500px;
        display: inline;
        max-height: 400px;
    }
    .paragraph {
        padding: 0 1rem 1rem 1rem;
        margin: 0;
    }

    table {
        margin: 1rem 2rem 1rem 1rem;
        max-width: 360px;
        overflow-x: scroll;
    }
`;

const FileWrapper = styled.div`
    padding: 1rem;
    background-color: #eee;
    font-weight: normal;
`;

const SpacerForMobileSize = styled.div`
    @media (max-width: 500px) {
        width: 100%;
        height: 27px;
    }
`;

const ScheduleWrapper = styled.div`
    padding: 1rem;
    background-color: #eee;
    font-weight: normal;
`;

const PollWrapper = styled.div`
    padding: 1rem;
`;

const PollTitle = styled.div`
    background-color: #eee;
    font-weight: bold;
    padding: .5rem;
    margin-bottom: .5rem;
    
    span {
      font-weight: normal;
    }
`;

const PollFooter = styled.div`
    background-color: #eee;
    font-weight: bold;
    padding: .5rem;
    margin-top: .5rem;
`;

const PollOption = styled.div`
    padding: .2rem .5rem;
`;

const Expressions = styled.div`
    padding: 0.3rem 1rem;
    font-size: 0.8rem;
    color: #8e8e93;
    display: flex;
    flex-grow: 1;
`;

const Replies = styled.div`
    padding: 0.6rem 1rem;
    font-size: 0.8rem;
    color: #8e8e93;
`;

const Like = styled.div`
    text-align: left;
`;

const Reply = styled.div`
    text-align: left;
    margin-left: -2.5rem;
`;

const Seen = styled.div`
    text-align: right;
    cursor: pointer;
    img {
        width: 0.8rem;
    }
`;

const ExpressionWrapper = styled.span`
    margin-left: 1rem;
`;

const ExpressionUser = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
`;

const Expression = styled.img`
    width: 1.2rem;
    border-radius: 50%;
    margin-left: -.5rem;
    height: 1.2rem;
`;

const ReplyInputArea = styled.div`
    width:  100%;
    `;
// position: ${props => props.desktop ? null : "fixed"};
// bottom:  0px;

const Spacer = styled.div`
    width: 100%;
    height: 6%;
`;

export default withTranslation()(Detail);