/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { bandCategoryList, bandCategoryListEng } from '../../../data';
import * as BandService from '../../../services/BandService';
import * as StringUtils from '../../../utils/StringUtils';
import { SERVER_URI, COMPANY_NAME } from "../../../configs";
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import NoImage  from '../../../assets/images/no-img.svg';
class BandListItem extends React.Component {

    _isMounted = false;

    item;
    routeFunction;

    constructor(props) {
        super(props);

        this.state = {
            leaders: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getBandLeaders();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getBandLeaders = () => {
        if (this.props.item && this.props.item.id !== -1) {
            BandService.getBandLeadersNode(this.props.item.id)
                .then(bandLeaders => {
                    if (this._isMounted) {
                        this.setState({
                            leaders: bandLeaders,
                        });
                    };
                })
                .catch(error => console.error(error));
        }
    };

    selectItem = (e) => {
        this.props.routeFunction(e.currentTarget.id);
    };


    renderPopularBadge = () => {
        if (this.props.popularBand) {
            return <RankingBadge>
                {this.props.index + 1}
            </RankingBadge>
        }
    }

    render() {
        const { item, t } = this.props;
        const { leaders } = this.state;

        const currentLanguage = localStorage.getItem('i18nextLng');

        let bandCategoryLists = currentLanguage === "korean" ?   bandCategoryList : bandCategoryListEng;

        const category = item && item.bandCategoryId ? bandCategoryLists.filter(category => category.id === item.bandCategoryId)[0].name : undefined;

        let coverImage = NoImage;
        // let coverImageHeight;
        // if (COMPANY_NAME !== "UC WARE") {
        //     coverImageHeight = '75px'
        // } else {
        //     coverImageHeight = 'auto'
        // }


        if (item && item.id !== -1) {
            if (!StringUtils.isNullOrUndefined(item.coverThumbnail)) {
                if (COMPANY_NAME !== "UC WARE") {
                    coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${item.coverImage}`;
                } else {
                    coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${item.coverThumbnail}`;
                }
            }
        }
        const leader = leaders ? (leaders.length > 0 ? leaders[0].name : "") : "";

        return (
            <li id={item.id} onClick={this.selectItem}>
                <a href="#" style={{ color: "black" }}>
                    <div className="bandCoverWrap">
                        <img style={{ height: "auto" }} src={coverImage} alt={item.name} className="bandCover" />
                    </div>
                    <div className="bandNameWrap">
                        <h6 className="bandName" style={{ marginBottom: '1rem' }}>{item.name}      {this.renderPopularBadge()}   </h6>
                        <p className="description">{item.description && (item.description).substring(0, 55)} {item.description && item.description.length > 55 && "..."}  </p>
                        <p className="bandInfo"> {t('Home.leader')} <span className="bandLeader">{leader}</span> | {t('Home.numberOfMembers')} <span className="bandMemNum">{item.memberCount}</span> | <span className="bandCat">{category}</span></p>
                    </div>
                </a>
            </li>
        );
    }
}

class BandList extends React.Component {

    _isMounted = false;

    bandList;
    routeFunction;

    render() {
        const { bandList, routeFunction, t } = this.props;

        return (
            <ul className="new-band-list">
                {bandList.map((item, index) =>
                    <BandListItem
                        popularBand={this.props.popularBand}
                        key={item.id}
                        item={item}
                        index={index}
                        routeFunction={routeFunction}
                        t={t}
                    />
                )}
            </ul>
        );
    }

}

export default withTranslation()(BandList);


const RankingBadge = styled.span`
    position: absolute;
    right: 16px;
    color: #DB0E61;
    border: 1px solid #DB0E61;
    font-size: 11px;
    padding: 0px 6px;
    border-radius: 11px;
    line-height: 21px;

    &:before {
        content: "BEST ";
    }

@media (max-width: 1024px) {
    line-height: 17px;
}
`;