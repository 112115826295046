import React from "react";
import styled from "styled-components";

import toggleOn from "../../../assets/images/toggle-onoff.svg";
import toggleOff from "../../../assets/images/toggle-onoff-off.svg";
import ReactTooltip from "react-tooltip";

class ToggleButtonSelect extends React.Component {
  _isMounted = false;

  title;
  name;
  defaultValue;
  onChange;

  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: this.props.defaultValue,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      if (this._isMounted) {
        this.setState({ isToggleOn: this.props.defaultValue });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClick = () => {
    console.log(this.props.name);
    if (this._isMounted) {
      this.setState(
        function(prevState) {
          return { isToggleOn: !prevState.isToggleOn };
        },
        () => this.props.onChange(this.props.name, this.state.isToggleOn)
      );
    }
  };

  render() {
    const { title } = this.props;
    return (
      <Container>
        <Title>{title}</Title>
        <Select>
          <button onClick={this.handleClick}>
            {this.state.isToggleOn ? (
              <>
                <img
                  src={toggleOn}
                  alt="toggleOn"
                  data-tip
                  data-for={`toggleOn`}
                />
              </>
            ) : (
              <>
                <img
                  src={toggleOff}
                  alt="toggleOff"
                  data-tip
                  data-for={`toggleOff`}
                />
              </>
            )}
          </button>
        </Select>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 0.5rem 0;
`;

const Title = styled.div`
  width: 50%;
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
`;

const Select = styled.div`
  width: 50%;
  text-align: right;
  button {
  }

  img {
    width: 36px;
    height: 18px;
  }
`;

// const Title = styled.div `
//     width: 50%;
//     text-align: left;
//     font-size: 1rem;
//     font-weight: bold;
//     span {
//       padding: 0 1.5rem;
//       width: 2rem;
//       display: inline-block;
//       text-align: center;
//     }
// `;

export default ToggleButtonSelect;
