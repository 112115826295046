import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import * as qs from 'query-string';
import ReactHtmlParser, { processNodes } from 'react-html-parser';
import Modal from 'react-awesome-modal';
import { ContentViewer } from '../../../common/components/Modal';
import { BandMenu, BandTopButtons } from '../../Menu';
import { ReplyList } from '../../../common/components/Reply';
import { SimpleEditor } from '../../../common/components/Edit';
import { SERVER_URI, attendStatus, COMPANY_NAME } from '../../../configs';

import * as ScheduleService from '../../../services/ScheduleService';
import * as BandService from '../../../services/BandService';
import * as ReplyService from '../../../services/ReplyService';
import * as FileService from '../../../services/FileService';
import * as UserService from '../../../services/UserService';

import * as DateTimeUtils from '../../../utils/DateTimeUtils';
import * as CommonUtils from '../../../utils/CommonUtils';
import iconWhere from '../../../assets/images/icon-where.svg';
import iconWrittenBy from '../../../assets/images/icon-writtenby.svg'
import { THEME_BACKGROUND_COLOR } from '../../../configs';
import { Row, Col, Avatar, message, Button } from 'antd';
import { withTranslation } from 'react-i18next';

import DialogComp from '../../../common/components/Shared/DialogComp';

import btnMore from '../../../assets/images/btn-more-new.svg';
import NoImage from '../../../assets/images/no-image.png';
import FileIcon from '../../../assets/images/icon-file.svg';
const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class Detail extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        const bandId = this.props.match.params["bandId"];
        const scheduleId = this.props.match.params["scheduleId"];
        const values = qs.parse(this.props.location.search);

        this.state = {
            bandId: bandId,
            scheduleId: scheduleId,
            schedule: undefined,
            duration: undefined,
            members: [],
            member: undefined,
            isWriter: false,
            today: values && values.day ? DateTimeUtils.momentByFormat(values.day, "YYYYMMDD") : undefined,
            attendStatus: [],
            attendCount: 0,
            absentCount: 0,
            undecidedCount: 0,
            notRepliedCount: 0,
            replies: [],
            openAttendStatusPopup: false,
            openUserBandListSelectPopup: false,
            openScheduleMenu: false,
            userBands: [],
            targetToCopySchedule: undefined,
            openDeleteConfirmationPopup: false,
            isContentViewerModalOpen: false,
            selectedContent: "",
            isOpenFileTransferProgress: false,
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadSchedule();
        this.loadBandInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandInfo = () => {
        BandService.getBandInfo(this.state.bandId)
            .then(result => {
                this.setState({
                    band: result.band,
                });
            })
            .catch(error => console.error(error));
    };

    routeScheduleHome = () => {
        this.props.history.push("/band/" + this.state.bandId + "/schedule");
    };

    loadSchedule = () => {
        const bandMemberRoles = [0, 1];

        let today = this.state.today;
        let scheduleDetail, member, members, attendStatus, replies;
        ScheduleService.getScheduleNode(this.state.bandId, this.state.scheduleId)
            .then(schedule => scheduleDetail = schedule)
            .then(() => BandService.getSingleBandMember(this.state.bandId, scheduleDetail.createdBy))
            .then(creator => member = creator)
            .then(() => BandService.getBandMembersNode(this.state.bandId, bandMemberRoles))
            .then(result => members = result)
            .then(() => ReplyService.getRepliesAtScheduleNode(this.state.bandId, this.state.scheduleId))
            .then(list => replies = list)
            .then(() => ReplyService.getAttendStatusNode(this.state.bandId, this.state.scheduleId, DateTimeUtils.format(this.state.today, "YYYYMMDD")))
            .then(list => attendStatus = list)
            .then(() => {
                const attendCount = attendStatus.filter(attend => attend.status === 0).length;
                const absentCount = attendStatus.filter(attend => attend.status === 1).length;
                const undecidedCount = attendStatus.filter(attend => attend.status === 2).length;
                const notRepliedCount = members.length - (attendCount + absentCount + undecidedCount);
                const isWriter = scheduleDetail.createdBy === Number(localStorage.getItem('userId'));
                if (this._isMounted) {
                    if (!today) {
                        today = DateTimeUtils.momentByDefaultFormat(scheduleDetail.startDateTime);
                    }
                    this.setState({
                        today: today,
                        schedule: scheduleDetail,
                        duration: DateTimeUtils.formattedForDateTimeDisplay(scheduleDetail.startDateTime) + " ~ "
                            + DateTimeUtils.formatFromString(scheduleDetail.endDateTime, "M월 D일 A h시 m분"),
                        member: member,
                        isWriter: isWriter,
                        members: members,
                        attendStatus: attendStatus,
                        attendCount: attendCount,
                        absentCount: absentCount,
                        undecidedCount: undecidedCount,
                        notRepliedCount: notRepliedCount,
                        replies: replies,
                        bandId: this.state.bandId,
                    });
                }
            })
            .catch(error => console.error(error));
    };

    bandMemberProfile = (id) => {
        let found = this.state.members.filter(member => member.userId === id);
        if (found && found.length > 0) {
            // return found[0].profileImage ? `${SERVER_URI}:8061/resource/profile/${found[0].profileImage}` : NoImage;
            return found[0].profileImage ? `${found[0].profileImage}` : NoImage;
        }
        return NoImage;
    };

    createReply = (reply, file, fileChangeType) => {
        const bandId = this.props.match.params["bandId"];
        const scheduleId = this.props.match.params["scheduleId"];

        if (reply.length > 230) {
            return message.warning(`${this.props.t('Schedule.Detail.theCharacterLimitHasBeenExceededPleaseReduceTheNumberOfCharacters')}`)
        }

        let receiveUserAccount = "";
        let sendUserAccount = "";
        let sendUserName = "";

        //여기서 코멘트에 관한 로딩 시작 
        if (file) {
            this.setState({ isOpenFileTransferProgress: true })
        }

        BandService.getSingleBandMember(bandId, this.state.member.userId)
            .then(response => {

                UserService.getUserProfileNode(localStorage.getItem('userId'))
                    .then(response => {
                        sendUserName = response.name
                    })
                    .catch(err => message.error(err))

                UserService.getUserProfileNode(localStorage.getItem('userId'))
                    .then(response => {
                        sendUserAccount = response.ucTalkId
                    })
                    .catch(err => message.error(err))

                //받는 사람이 알림 켜놓은지 먼저 확인      replyNotificationType === 0 이 켜져있는것   
                if (response.notifyThroughUcTalk === true && response.replyNotificationType === 0
                    && this.state.member.userId !== Number(localStorage.getItem('userId'))) {

                    UserService.getUserProfileNode(this.state.member.userId)
                        .then(response => {
                            receiveUserAccount = response.ucTalkId
                        })
                        .catch(err => message.error(err))
                }

                if (file) {
                    FileService.uploadFileNode(bandId, localStorage.getItem('userId'), file)
                        .then(response => ReplyService.createReplyAtScheduleNode(bandId, scheduleId, reply, response.data.results.id))
                        .then(() => this.loadSchedule())
                        .then(() => this.setState({ isOpenFileTransferProgress: false }))
                        .then(() => UserService.sendNotificationToScheduleUser(this.state.bandId, scheduleId, this.state.today, receiveUserAccount, sendUserAccount, sendUserName))
                        .catch(error => console.error(error));
                } else {
                    ReplyService.createReplyAtScheduleNode(bandId, scheduleId, reply, undefined)
                        .then(() => this.loadSchedule())
                        .then(() => UserService.sendNotificationToScheduleUser(this.state.bandId, scheduleId, this.state.today, receiveUserAccount, sendUserAccount, sendUserName))
                        .catch(error => console.error(error));
                }
            })
    };


    attend = (value) => {
        ReplyService.attendNode(this.state.bandId, this.state.scheduleId,
            DateTimeUtils.format(this.state.today, "YYYYMMDD"), value)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        openAttendStatusPopup: false,
                    }, this.loadSchedule);
                }
            })
            .catch(error => console.error(error));
    };

    openAttendStatusPopup = () => {
        if (this._isMounted) {
            this.setState({
                openAttendStatusPopup: true,
            });
        }
    };

    closeAttendStatusPopup = () => {
        if (this._isMounted) {
            this.setState({
                openAttendStatusPopup: false,
            });
        }
    };

    openScheduleMenu = () => {
        if (this._isMounted) {
            this.setState({ openScheduleMenu: true });
        }
    };

    openContentViewerModal = (item) => {
        if (this._isMounted) {
            this.setState({
                isContentViewerModalOpen: true,
                selectedContent: item,
            });
        }
    };

    closeContentViewerModal = () => {
        if (this._isMounted) {
            this.setState({
                isContentViewerModalOpen: false,
                selectedContent: "",
            });
        }
    };

    closeScheduleMenu = () => {
        if (this._isMounted) {
            this.setState({ openScheduleMenu: false });
        }
    };

    copyPostToOtherBand = () => {
        BandService.getAllUserBandListNode(localStorage.getItem('userId'))
            .then(bandIds => BandService.getBandListNode(bandIds, 0))
            .then(bandList => {
                const userBands = bandList.content.filter(band => band.id !== Number(this.state.bandId));
                if (this._isMounted) {
                    this.setState({
                        openScheduleMenu: false,
                        userBands: userBands,
                        openUserBandListSelectPopup: true,
                    })
                }
            })
            .catch(error => console.error(error));
    };

    closeUserBandListSelectPopup = () => {
        if (this._isMounted) {
            this.setState({
                openUserBandListSelectPopup: false,
                targetToCopySchedule: undefined,
            })
        }
    };

    selectBand = (e) => {
        if (this._isMounted) {
            this.setState({ targetToCopySchedule: Number(e.currentTarget.value) });
        }
    };

    copySchedule = () => {
        if (this.state.targetToCopySchedule === undefined) {
            return alert(`${this.props.t('Schedule.Detail.pleaseSelectOneOrMoreBands')}`);
        }
        ScheduleService.copyScheduleNode(this.state.bandId, this.state.schedule.id, this.state.targetToCopySchedule)
            .then(() => this.closeUserBandListSelectPopup())
            .catch(error => console.error(error));
    };

    deleteSchedule = () => {
        if (this._isMounted) {
            this.setState({
                openDeleteConfirmationPopup: true,
                openScheduleMenu: false
            });
        }
    };

    closeDeleteConfirmationPopup = () => {
        if (this._isMounted) {
            this.setState({ openDeleteConfirmationPopup: false });
        }
    };

    deleteConfirm = (value) => {
        if (!!value) {
            ScheduleService.deleteScheduleNode(this.state.bandId, this.state.scheduleId)
                .then(() => {
                    if (this._isMounted) {
                        this.setState({
                            openDeleteConfirmationPopup: false
                        }, this.routeScheduleHome);
                    }
                })
                .catch(error => {
                    console.error(error);
                    if (this._isMounted) {
                        this.setState({ openDeleteConfirmationPopup: false });
                    }
                });
        } else {
            if (this._isMounted) {
                this.setState({ openDeleteConfirmationPopup: false });
            }
        }
    };

    modifySchedule = () => {
        if (this._isMounted) {
            this.setState({
                openPostMenu: false
            }, () => this.props.history.push("/band/" + this.state.bandId + "/schedule/" + this.state.scheduleId + "/modify"));
        }
    };

    writePost = () => {
        this.props.history.push("/band/" + this.state.bandId + "/post/create?scheduleId=" + this.state.schedule.id + "&scheduleTitle=" + this.state.schedule.title);
    };


    toDataURL = (url, fileName) => {
        return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                return window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                return URL.createObjectURL(blob);

            }
        });
    }

    download = async (url, fileName) => {

        if (__ISMSIE__) {
            const link = document.createElement('a');
            link.href = await this.toDataURL(url, fileName);
            this.close();
        } else {
            const link = document.createElement('a');
            link.href = await this.toDataURL(url, fileName);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    };

    render() {
        const { history, t } = this.props;
        const bandId = this.props.match.params["bandId"];
        const { schedule, today, replies, duration, band } = this.state;
        const days = [`${t('Schedule.Detail.sun')}`, `${t('Schedule.Detail.mon')}`, `${t('Schedule.Detail.tue')}`,
        `${t('Schedule.Detail.wed')}`, `${t('Schedule.Detail.thu')}`, `${t('Schedule.Detail.fri')}`, `${t('Schedule.Detail.sat')}`];

        const name = this.state.member ? this.state.member.name : undefined;

        const content = schedule && schedule.description ? schedule.description
            .split('<p>').join('<p class="paragraph">')
            .split('</p>').join('</p>') : undefined
            ;

        const transform = (node, index) => {
            if (node.type === 'tag' && node.name === 'div') {
                if (node.attribs && node.attribs.classname && (node.attribs.classname === 'file-inner-post')) {
                    return <FileWrapper key={index}>
                        {processNodes(node.children, transform)}
                    </FileWrapper>
                }
            }
            if (node.type === 'tag' && node.name === 'p' && node.children && node.children[0] && node.children[0].data) {
                if (CommonUtils.contains(node.children[0].data, '#')) {
                    return <p className="paragraph" key={index}>
                        {node.children[0].data.split(" ").map((word, index) => {
                            if (word.startsWith("#")) {
                                return index === 0 ? <a href={word} key={index} className="rdw-hashtag-link">{word}</a> :
                                    <a href={word} key={index} className="rdw-hashtag-link"> {word}</a>;
                            } else {
                                return index === 0 ? <span key={index}>{word}</span> : <span key={index}> {word}</span>;
                            }
                        })}
                    </p>
                }
            }
        };

        const options = {
            decodeEntities: true,
            transform: transform
        };

        const rendered = content ? ReactHtmlParser(content, options) : undefined;

        const categoryColor = schedule && schedule.scheduleCategory && schedule.scheduleCategory.color ? schedule.scheduleCategory.color : "#eee";
        const categoryLabel = schedule && schedule.scheduleCategory && schedule.scheduleCategory.name ? schedule.scheduleCategory.name : `${t('Schedule.Detail.unspecified')}`;

        const needToConfirm = schedule && schedule.needToConfirm;

        const splits = this.state.selectedContent.split("/");
        const fileNameFromDetail = splits[splits.length - 1];


        return (
            <div className="wrapper">

                <BandTopButtons bandId={bandId} bandName={band && band.name} fromPage={`${t('Schedule.Detail.scheduleContents')}`} historyProp={history} routeBack={true} />
                <div className="contentsWrapSpacer" />

                {schedule &&
                    <div className="contentsWrap">
                        <CenterWrapper>

                            <div style={{ display: 'flex', marginBottom: '10px', padding: '1rem' }}>
                                <div style={{ marginRight: '1rem', width: '83px' }}>
                                    <DayWrap>
                                        <Date>
                                            {DateTimeUtils.momentByFormat(today, "M/D")}
                                        </Date>
                                        <p style={{ marginTop: '4px', fontWeight: 'bold' }}>{days[DateTimeUtils.momentByDefaultFormat(today).days()]}</p>
                                    </DayWrap>
                                </div>


                                <div style={{ width: '76%' }}>
                                    <Title>
                                        {schedule.title}
                                        <span>{duration}</span>
                                    </Title>


                                    <Description>
                                        <input type="checkbox" id="scheduleCategory" />
                                        <label htmlFor="scheduleCategory" style={{ backgroundColor: categoryColor }} />
                                        <span style={{ marginRight: '10px' }}>{categoryLabel}</span>
                                        {schedule.location ?
                                            <>
                                                <img style={{ width: '10px' }} src={iconWhere} alt="icon-where" />
                                                <span style={{ marginRight: '10px' }}>{schedule.location}</span>
                                            </>
                                            : null}
                                        <img style={{ width: '16.3px' }} src={iconWrittenBy} alt="icon-writtenby" />
                                        <span>{name}</span>
                                    </Description>
                                </div>
                                <div style={{ width: '12%', display: 'flex', justifyContent: 'flex-end' }}>
                                    {this.state.isWriter ?
                                        <span onClick={this.openScheduleMenu}> <button><MoreImage src={btnMore} alt="btn-img-more" /></button></span> : ""
                                    }
                                </div>

                            </div>

                            <Content>
                                {rendered}
                            </Content>

                            {schedule && schedule.attachedFiles &&
                                <AttachedFileWrapper>
                                    <Row gutter={16}>
                                        {schedule.attachedFiles.map((attachedFile, index) => {
                                            if (attachedFile.thumbnail) {
                                                let fileImage;
                                                console.log('attachedFile', attachedFile)
                                                if (COMPANY_NAME !== "UC WARE") {
                                                    fileImage = `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${attachedFile.fileName}`
                                                } else {
                                                    fileImage = `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${attachedFile.thumbnail}`
                                                    //fileImage = `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${attachedFile.fileName}`
                                                }
                                                return <Col xs={4} md={4} lg={4} key={index}>
                                                    <img style={{ width: '100%', borderRadius: 4, cursor: 'pointer' }}
                                                        onClick={() => this.openContentViewerModal(fileImage)}
                                                        src={fileImage}
                                                        alt="grid-item"
                                                    />
                                                </Col>
                                            } else {
                                                return <Col xs={4} md={4} lg={4} key={index}>
                                                    <img
                                                        onClick={() => this.download(`${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${attachedFile.fileName}`, attachedFile.fileName)}
                                                        style={{ width: '100%', borderRadius: 4, cursor: 'pointer' }}
                                                        src={FileIcon} alt="" />
                                                    <span style={{
                                                        color: 'gray', textOverflow: 'ellipsis',
                                                        display: 'block', overflow: 'hidden'
                                                    }}>{attachedFile.originalFileName}</span>
                                                </Col>
                                            }
                                        })}
                                    </Row>
                                </AttachedFileWrapper>
                            }

                            {needToConfirm &&
                                <div>
                                    <AttendStatus>
                                        <Grid>
                                            <Grid.Column width={8}>
                                                <Replied>
                                                    {t('Schedule.Detail.attend')}  &nbsp;{this.state.attendCount}
                                                    &nbsp;&nbsp;&nbsp;
                                                    {t('Schedule.Detail.nonattendance')}   &nbsp;{this.state.absentCount}
                                                    &nbsp;&nbsp;&nbsp;
                                                    {t('Schedule.Detail.undecided')}   &nbsp;{this.state.undecidedCount}
                                                </Replied>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <NotReplied>
                                                    {t('Schedule.Detail.unanswered')}&nbsp;{this.state.notRepliedCount}
                                                </NotReplied>
                                            </Grid.Column>
                                        </Grid>
                                    </AttendStatus>
                                    <AttendArea>
                                        <span style={{ fontSize: '1rem', fontWeight: '600' }} onClick={this.openAttendStatusPopup}> {t('Schedule.Detail.attend')}  <br />  {t('Schedule.Detail.attendOrNot')}<p>&nbsp;</p></span>
                                        {this.state.attendStatus.map((attend, index) =>
                                            <AttendWrapper key={`attend-status-${index}`} style={{ marginTop: 1 }}>
                                                <Avatar src={this.bandMemberProfile(attend.userId)} alt={`user-${attend.userId}`} />
                                                <AttendState src={attendStatus[attend.status].icon} alt={attendStatus[attend.status].description} />
                                            </AttendWrapper>
                                        )}
                                    </AttendArea>
                                </div>
                            }

                            <ReplyList bandId={bandId} replyList={replies} refreshFunction={this.loadSchedule} />

                            <ReplyInputArea>
                                <SimpleEditor placeholder={t('Schedule.Detail.writeAComment')} bandId={bandId} onSubmit={this.createReply} />
                            </ReplyInputArea>
                        </CenterWrapper>

                    </div>
                }

                <Spacer />
                <BandMenu  bandId={bandId} historyProp={history} />

                {fileNameFromDetail ?
                    <Modal visible={this.state.isContentViewerModalOpen} onClickAway={() => this.closeContentViewerModal()}>
                        <ContentViewer detail bandId={bandId} item={fileNameFromDetail}
                            onClose={this.closeContentViewerModal} refreshContents={this.reload}
                        />
                    </Modal>
                    :
                    null
                }

                <Modal visible={this.state.openAttendStatusPopup} onClickAway={() => this.closeAttendStatusPopup()}>
                    <div style={{ padding: '1rem' }}>
                        {attendStatus.map(status =>
                            <span key={`attendStatus-${status.id}`}>
                                <img src={status.icon} alt={status.description} onClick={() => this.attend(status.id)} />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                        )}
                    </div>
                </Modal>

                <Modal visible={this.state.openScheduleMenu} onClickAway={() => this.closeScheduleMenu()}>
                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                        <div onClick={this.modifySchedule}>{t('Schedule.Detail.scheduleModification')}</div>
                        <Divider padding />
                        <div onClick={this.deleteSchedule}>{t('Schedule.Detail.deleteSchedule')}</div>
                        {/* <Divider padding />
                            <div onClick={this.writePost}>게시판에 글 쓰기</div> */}
                        <Divider padding />
                        <div onClick={this.copyPostToOtherBand}>{t('Schedule.Detail.registerForAnotherBand')}</div>
                    </div>
                </Modal>

                <Modal visible={this.state.openUserBandListSelectPopup} onClickAway={() => this.closeUserBandListSelectPopup()}>
                    <div style={{ width: "20rem", padding: '1rem', maxHeight: 300 }}>
                        {this.state.userBands && this.state.userBands.length > 0 ?
                            <>
                                <div style={{ maxHeight: 230, overflowY: 'auto' }}>
                                    <Grid style={{ margin: 0 }}>
                                        {this.state.userBands.map(band =>
                                            <>
                                                <Grid.Row key={band.id} style={{ padding: "0 0 .5rem 0", margin: 0 }}>
                                                    <Grid.Column width={13}>
                                                        {band.name}
                                                    </Grid.Column>
                                                    <Grid.Column width={3}>
                                                        <input
                                                            type="radio"
                                                            value={band.id}
                                                            checked={this.state.targetToCopySchedule === band.id}
                                                            onChange={this.selectBand}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Divider padding />
                                            </>
                                        )}
                                    </Grid>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
                                    <Button onClick={this.copySchedule}>{t('Schedule.Detail.selectionComplete')}</Button>
                                </div>
                            </>
                            :
                            <div style={{ padding: 10, textAlign: 'center' }}>
                                {t('Schedule.Detail.thereIsNoBandToChoose')}
                            </div>
                        }
                    </div>
                </Modal>

                <Modal visible={this.state.openDeleteConfirmationPopup} onClickAway={() => this.closeDeleteConfirmationPopup()}>
                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                        <div style={{ fontSize: '1.4rem' }}> {t('Schedule.Detail.areYouSureYouWantToDeleteIt')}</div>
                        <br />
                        <div style={{ fontSize: '1.2rem' }}>
                            <button onClick={() => this.deleteConfirm(true)}> {t('Schedule.Detail.yes')}</button>
                            <Divider padding />
                            <button onClick={() => this.deleteConfirm(false)}> {t('Schedule.Detail.no')}</button>
                        </div>
                    </div>
                </Modal>


                <DialogComp description={"파일이 업로드 중입니다."} open={this.state.isOpenFileTransferProgress} />
            </div>
        );
    }
}

const CenterWrapper = styled.div`
    background-color: #FFF;
    padding: 1rem;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
`;

const Divider = styled.div`
    border-bottom:  solid 1px #e5e5ea;
    margin:  ${props => props.padding ? "0.7rem 0" : "0"};
    width: 100%;
`;


const AttachedFileWrapper = styled.div`
    padding: 1rem;
`;

const Title = styled.div`
    object-fit: contain;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    span {
        color: gray;
        font-size: 12px;
        overflow-x: hidden;
        padding-left: 0.5rem;
    }
`;

const MoreImage = styled.img`
    width: 5px;
    margin-left: 15px;
    margin-top: 10px;
`;

const Date = styled.div`
    height: 50px;
    line-height: 30px;
    padding: 10px;
    font-size: 30px;
    border-radius: 0.8rem 0.8rem 0 0;
    background-color: #edf1f2;
    `;

const DayWrap = styled.div`
    border: solid 2px ${THEME_BACKGROUND_COLOR};
    width: 80px;
    height: 80px;
    border-radius: 0.8rem;
    text-align: center;
    color: ${THEME_BACKGROUND_COLOR};
`;

const Content = styled.div`
    padding: 1rem;
    font-size: 1rem;
    img {
        width: 100%;
        display: inline;
    }
    .paragraph {
        padding-bottom: 1rem;
        margin: 0;
    }
`;

const FileWrapper = styled.div`
    padding: 1rem;
    background-color: #eee;
    font-weight: normal;
`;

const Description = styled.div`
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 1em;

    span {
      padding: 0 .5rem;
      vertical-align: middle;
    }
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      margin: .3rem;
      border-radius: 50%;
      vertical-align: middle;
    }
`;

const AttendStatus = styled.div`
    padding: 0.6rem 1rem;
    font-size: 0.8rem;
    color: #8e8e93;
`;

const Replied = styled.div`
    text-align: left;
`;

const NotReplied = styled.div`
    text-align: right;
`;

const AttendArea = styled.div`
    padding: 0.6rem 1rem;
    font-size: 0.8rem;
    color: #8e8e93;
    display: flex;
    flex-grow: 1;
`;

const AttendWrapper = styled.span`
    margin-left: 1rem;
`;

const AttendState = styled.img`
    width: 1.2rem;
    border-radius: 50%;
    margin-left: -.3rem;
    margin-bottom: -14px;
`;

const ReplyInputArea = styled.div`
    width:  100%;
`;

// position: ${props => props.desktop ? null : "fixed"};
// bottom: ${props => props.desktop ? null : "0px"};
const Spacer = styled.div`
    width: 100%;
    height: 7%;
`;

export default withTranslation()(Detail);