import React from 'react';
import { SERVER_URI, COMPANY_NAME } from '../../../configs';

import '../../../assets/css/_modal.scss';

import * as BandService from '../../../services/BandService';
import * as ArrayUtils from '../../../utils/CommonUtils';
import { withTranslation } from 'react-i18next';
import * as StringUtils from '../../../utils/StringUtils';
const NoImage = require('../../../assets/images/no-image.png');

class HiddenListSelectModal extends React.Component {

    onClose;
    show;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            initList: [],
            hiddenList: [],
            shownList: [],
            loaded: false,
        };

        this.handleEvent = this.handleEvent.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (!this.state.loaded && localStorage.getItem('userId')) {
            let hiddenList = [];
            let shownList = [];
            let initList = [];

            BandService.getUserHiddenBandListNode(localStorage.getItem('userId'), true)
                .then(bandIds => BandService.getBandListNode(bandIds, localStorage.getItem('viewOrderType'), true))
                .then(result => {
                    if (result) {
                        result.content.forEach(content => {
                            hiddenList.push(content.id);
                            initList.push(content);
                        });
                    }
                })
                .then(() => BandService.getUserHiddenBandListNode(localStorage.getItem('userId'), false))
                .then(bandIds => BandService.getBandListNode(bandIds, localStorage.getItem('viewOrderType'), true))
                .then(result => {
                    if (result) {
                        result.content.forEach(content => {
                            shownList.push(content.id);
                            initList.push(content);
                        });
                    }
                })
                .then(() => {
                    if (this._isMounted) {
                        this.setState({
                            loaded: true,
                            initList: initList,
                            hiddenList: hiddenList,
                            shownList: shownList,
                        });
                    }
                })
                .catch(error => console.error(error));
        }
    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    handleEvent(itemId) {
        const isInHiddenList = ArrayUtils.contains(this.state.hiddenList, itemId);

        this.setState({
            hiddenList: isInHiddenList
                ? this.state.hiddenList.filter(i => i !== itemId)
                : [...this.state.hiddenList, itemId],
            shownList: isInHiddenList
                ? [...this.state.shownList, itemId]
                : this.state.shownList.filter(i => i !== itemId)
        });
    }

    modalClose = () => {
        this.props.onClose();
    }

    submit = () => {
        BandService.setHiddenBandsNode(this.state.hiddenList)
            .then(() => {
                if (this._isMounted) {
                    this.setState({ loaded: false });
                }
            })
            .then(() => this.props.onClose())
            .catch(error => console.error(error));
    };

    render() {
        const { t } = this.props;
        if (!this.props.show) {
            return null;
        }

        const { initList, hiddenList, shownList } = this.state;
        console.log(initList)
        return (
            <div className="modal_wrap">
                <div className="modal">
                    <div className="close_btn" onClick={this.modalClose}></div>
                    <div>
                        <p className="title">
                            {t('HiddenListSelectModal.hiddenBandManagement')}
                        </p>
                        <div>
                            <ItemList t={t} key="hidden" title={t('HiddenListSelectModal.hiddenBand')} handleEvent={this.handleEvent} items={hiddenList} initItems={initList}
                                empty={hiddenList.length === 0 ? true : false}
                            />
                            <ItemList t={t} key="shown" title={t('HiddenListSelectModal.unhiddenBand')} handleEvent={this.handleEvent} items={shownList} initItems={initList} />
                        </div>
                        <div>
                            <button className="modal_submit_btn" onClick={this.submit}>
                                {t('HiddenListSelectModal.submit')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class ItemList extends React.Component {

    items;
    initItems;
    title;
    empty;


    render() {
        const handleEvent = this.props.handleEvent;
        const title = this.props.title;

        const itemArr = this.props.initItems;
        const myItems = this.props.items;
        const empty = this.props.empty;
        const { t } = this.props;

        const listItems = itemArr.map(itemObj => {
            if (!ArrayUtils.contains(myItems, itemObj.id)) return null;

            return <SingleItem
                t={t}
                title={title}
                key={itemObj.id}
                data={itemObj}
                onClick={() => handleEvent(itemObj.id)}
            />;
        });

        return (
            <div>
                <div className="itemListTitle">
                    {title}
                </div>
                <ul className="list_unfixed">
                    {empty === true ? <div className="placeholder"> <br /><br /><br />
                        {t('HiddenListSelectModal.selectTheBandYouWantToHide')} </div> : listItems}
                </ul>
            </div>
        );
    }

}

class SingleItem extends React.Component {

    render() {
        let data = this.props.data;
        let title = this.props.title;
        const { t } = this.props;

        let coverImage;
        if (StringUtils.isNullOrUndefined(data.coverImage)) {
            coverImage = NoImage;
        } else {
            if (COMPANY_NAME !== "UC WARE") {
                coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${data.coverImage}`;
            } else {
                coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${data.coverThumbnail}`;
            }
        }

        let btn_unfix1 = <button className="fixRound">{t('HiddenListSelectModal.unhide')}</button>
        let btn_fix1 = <button className="fixRound">{t('HiddenListSelectModal.hide')}</button>


        return (
            <li className="flex" onClick={this.props.onClick}>
                <img
                    style={{ flex: "10%" }}
                    className="thumbnail"
                    src={coverImage}
                    alt="thumbail"
                />

                <div
                    style={{ flex: "40%" }}
                > {data.name} </div>

                {title === "숨긴 협업" ? btn_unfix1 : btn_fix1}

            </li>
        );
    }

}

export default withTranslation()(HiddenListSelectModal);
