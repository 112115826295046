import { SERVER_URI } from "../configs";
import axios from 'axios';

export function getFileInfoNode(bandId, fileId) {
    return axios.get(`${SERVER_URI}:5000/api/file?bandId=${bandId}&fileId=${fileId}`)
        .then(response => { return response.data });
}

export function getDirectoriesNode(bandId, orderType) {
    return axios.get(`${SERVER_URI}:5000/api/file/directory?bandId=${bandId}&orderType=${orderType}`)
        .then(response => { return response.data });
}

export function createDirectoryNode(bandId, directoryName) {
    const body = {
        directoryName: directoryName,
        createdBy: localStorage.getItem('userId')
    };

    return axios.post(`${SERVER_URI}:5000/api/file/directory?bandId=${bandId}`, body)
        .then(response => { return response.data });
}

export function renameDirectoryNameNode(bandId, directoryId, changeName) {
    const body = {
        changeName: changeName
    };
    return axios.put(`${SERVER_URI}:5000/api/file/directory?bandId=${bandId}&directoryId=${directoryId}`, body)
}

export function removeDirectoryNode(bandId, directoryId) {
    return axios.delete(`${SERVER_URI}:5000/api/file/directory?bandId=${bandId}&directoryId=${directoryId}`)
}

export function getFilesNode(bandId, fileType, orderType, page) {
    //orderType 0 Latest 
    //orderType 1 Oldest 
    //orderType 2 Name

    //fileType 0 OTHERS 
    //fileType 1 IMAGE
    //fileType 2 VIDEO
    //fileType undefined ALL 
    let url = `${SERVER_URI}:5000/api/file/files?bandId=${bandId}&orderType=${orderType}&page=${page}`;
    if (fileType !== undefined) {
        url = `${SERVER_URI}:5000/api/file/files?bandId=${bandId}&fileType=${fileType}&orderType=${orderType}&page=${page}`;
    }
    return axios.get(url)
        .then(response => { return response.data });

}

export function uploadFileNode(bandId, userId, file) {
    let formData = new FormData();
    const config = {
        header: { 'content-type': 'multipart/form-data' },
    }
    formData.append("file", file);
    // formData.append("snapshotImage", snapshotImageRaw)

    return axios.post(`${SERVER_URI}:5000/api/file/upload?bandId=${bandId}&userId=${userId}`, formData, config);
}

export function uploadVideoThumbnailNode(bandId, fileId, fileName, snapshotImage) {
    let body = {
        bandId,
        fileId,
        fileName,
        snapshotImage
    }

    return axios.post(`${SERVER_URI}:5000/api/file/videoThumbnail/upload`, body);
}


export function uploadFileToDirectoryNode(bandId, directoryId, userId, file) {
    let formData = new FormData();
    const config = {
        header: { 'content-type': 'multipart/form-data' },
    }
    formData.append("file", file);

    return axios.post(`${SERVER_URI}:5000/api/file/upload/directory?bandId=${bandId}&directoryId=${directoryId}&userId=${userId}`, formData, config);
}

export function renameFileNameNode(bandId, fileId, changedName) {
    const body = {
        changedName: changedName
    };

    return axios.put(`${SERVER_URI}:5000/api/file?bandId=${bandId}&fileId=${fileId}`, body);
}

export function moveFileNode(bandId, fileId, directoryId) {
    const body = {
        directoryId: directoryId
    };

    return axios.put(`${SERVER_URI}:5000/api/file?bandId=${bandId}&fileId=${fileId}&type=move`, body);
}

export function removeFileNode(bandId, fileId) {
    return axios.delete(`${SERVER_URI}:5000/api/file?bandId=${bandId}&fileId=${fileId}`);
}

export function copyContentNode(bandId, fileId, targetBandId) {
    const body = {
        targetBandId: targetBandId
    };

    return axios.post(`${SERVER_URI}:5000/api/file/copy?bandId=${bandId}&fileId=${fileId}`, body);
}

export function uploadCoverImage(bandId, formData, config) {
    return axios.post(`${SERVER_URI}:5000/api/file/cover?bandId=${bandId}`, formData, config);
}

// export function createVideoThumbnailNode(bandId, directoryId, band, userId, file) {
//     let formData = new FormData();
//     const config = {
//         header: { 'content-type': 'multipart/form-data' },
//     }
//     formData.append("file", file);

//     return axios.post(`${SERVER_URI}:5000/api/file/upload/directory?bandId=${bandId}&directoryId=${directoryId}&userId=${userId}`, formData, config);
// }
