import React from 'react';
import styled from 'styled-components';

import * as HtmlUtils from '../../utils/HtmlUtils';
import { THEME_BACKGROUND_COLOR } from '../../configs';
import { withTranslation } from 'react-i18next';
const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class NoticeList extends React.Component {

    _isMounted = false;

    bandId;
    noticeList;
    routeFunction;
    isOpened;
    allNotice;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    routePost = (e) => {
        if (this.props.isOpened && this.props.routeFunction) {
            this.props.routeFunction(this.props.bandId, e.currentTarget.id);
        }
    };

    routeAllNotice = () => {
        if (this.props.isOpened && this.props.allNotice) {
            this.props.allNotice();
        }
    };

    extractNoticeTitle = (html) => {
        console.log('html', html)
        html = HtmlUtils.removeEmptyParagraph(html);
        html = HtmlUtils.removeEmptySpace(html);

        const paragraphs = HtmlUtils.extractAllParagraphs(html);

        let title = undefined;

        title = paragraphs[0];

        title = HtmlUtils.removeHtmlTags(title);

        if (!title) {
            title = `${this.props.t('NoticeList.noticeHasBeenRegistered')}`
        }
        return title;
    };


    render() {
        const { t } = this.props;
        
        return (
            <List>
                <Header>
                    <Title>{t('NoticeList.notice')}</Title>
                    {this.props.allNotice &&
                        <More> <button onClick={this.routeAllNotice}>{t('NoticeList.viewAll')} {">"}</button></More>
                    }
                </Header>
                <Body>
                    {this.props.noticeList.map((item, index) =>
                        <ListItem key={`notice-item-${index + 1}`} id={item.id} onClick={this.routePost}>
                            {item.isImportantNotice ? <TypeIcon important>{t('NoticeList.important')}</TypeIcon> : <TypeIcon>{t('NoticeList.normal')}</TypeIcon>}
                            <Subject isMSIE={__ISMSIE__}>{this.extractNoticeTitle(item.content)}</Subject>
                        </ListItem>
                    )}
                </Body>
            </List>
        );
    }

}

const List = styled.div`
    padding: 1rem;
    background-color: #FFF;
    margin : 1rem auto;
    width: 100%;
    max-width: 600px;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 4px;
`;

const Header = styled.div`
    display: flex;
    flex-grow: 1;
    padding-bottom: 1rem;
`;

const Title = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    font-weight: bold;
`;

const More = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row-reverse;
    color: #c7c7cc;
    cursor: pointer;
`;

const Body = styled.div`
`;

const ListItem = styled.div`
    display: flex;
    flex-grow: 1;
    padding-bottom: .5rem;
`;

const TypeIcon = styled.button`
  background: ${props => props.important ? "#db0e61" : "white"};
  color: ${props => props.important ? "white" : `${THEME_BACKGROUND_COLOR}`};
  border: ${props => props.important ? "solid 1px #db0e61" : `#solid 1px ${THEME_BACKGROUND_COLOR}`};
  border-radius: .75rem;
  font-size: .75rem;
  font-weight: normal;
  margin: 0 .75rem 0 0;
  padding: .25rem .5rem;
  min-width: 35px;
`;

const Subject = styled.div`
    margin-top: ${props => props.isMSIE ? '4px' : ''}
    font-size: 1rem;
    display: block;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    word-break: normal;
    word-wrap: normal;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    cursor: pointer;
`;

export default withTranslation()(NoticeList);
