import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

function ContentViewOrderTypeSelectModal({ selectMenu }) {
    const { t } = useTranslation();
    return (
        <div className="modal_wrap">
            <div className="modal">
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <div>
                            <button onClick={() => selectMenu("image")}> {t('SimpleEditor.attachImage')}</button>
                        </div>
                        <Divider padding />
                        <div>
                            <button onClick={() => selectMenu("video")}>{t('SimpleEditor.attachVideo')}</button>
                        </div>
                        <Divider padding />
                        <div>
                            <button onClick={() => selectMenu("file")}>{t('SimpleEditor.attachFile')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentViewOrderTypeSelectModal

const Divider = styled.div`
    border-bottom:  solid 1px #e5e5ea;
    margin:  ${props => props.padding ? "0.7rem 0" : "0"};
    width: 100%;
`;