import React from 'react';
import { PostList } from '../_Post';
import * as PostService from '../../services/PostService';
import * as BandService from '../../services/BandService';
import * as DateTimeUtils from '../../utils/DateTimeUtils';
import * as StringUtils from '../../utils/StringUtils';

import { withTranslation } from 'react-i18next';
import { POST_PAGE_SIZE, THEME_BACKGROUND_COLOR, SERVER_URI, COMPANY_NAME } from "../../configs";
import HomeTopMenu from '../Menu/HomeTopMenu';
import "./Sections/NewsFeed.css";
import { bandCategoryListInNewsFeed, bandCategoryListInNewsFeedEng } from '../../data';
import NoImage from '../../assets/images/no-image.png';

const btnMakeBand = require(localStorage.getItem('i18nextLng') === "english" ? '../../assets/images/btn_makeBand_rec-eng.svg' : '../../assets/images/btn_makeBand_rec.svg');

class NewsFeed extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            bandList: [],
            postList: [],
            page: 1,
            lastPage: true,
            userBandIds: [],
            pageSize: 0
        };
    }

    componentDidMount() {
        this._isMounted = true;

        let bandList = [];
        const current = DateTimeUtils.formattedCurrent();
        BandService.getAllUserBandListNode(localStorage.getItem('userId'), false)
            .then(ids => { this.setState({ userBandIds: ids }) })
            .then(() => BandService.getBandListNode(this.state.userBandIds, 1))
            .then(list => {
                if (list) {
                    bandList = list.content;
                }
            })
            .then(() => PostService.getUserBandsPostListNode(current, this.state.page, POST_PAGE_SIZE, this.state.userBandIds))
            .then(response => {
                if (this._isMounted) {
                    this.setState({
                        bandList: bandList,
                        postList: response.content,
                        pageSize: response.content.length,
                    });
                }
            })
            .catch(error => {
                alert(error);
                if (this._isMounted) {
                    this.setState({
                        bandList: [],
                        postList: [],
                    });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    routePostOrBand = (bandId, postId) => {
        if (bandId && postId) {
            this.route("/band/" + bandId + "/post/" + postId);
        } else if (bandId) {
            this.route("/band/" + bandId);
        } else {
            alert("BandId & PostId are undefined");
        }
    };

    route = (url) => {
        this.props.history.push(url);
    };

    nextPage = () => {
        if (this._isMounted) {
            const current = DateTimeUtils.formattedCurrent();
            const page = this.state.page + 1;
            let postList = this.state.postList;


            PostService.getUserBandsPostListNode(current, page, POST_PAGE_SIZE, this.state.userBandIds)
                .then(response => {
                    if (response.content) {
                        response.content.forEach(content => postList.push(content));
                    }
                    this.setState({
                        postList: postList,
                        pageSize: response.content.length,
                        page: page
                    });
                })
                .catch(error => {
                    alert(error);
                });
        }
    };

    handleRouteClick = () => {
        this.props.history.push("/searchBand");
    }

    renderBandLists = () => this.state.bandList && this.state.bandList.map((band, index) => {

        let coverImage;
        if (!StringUtils.isNullOrUndefined(band.coverThumbnail)) {
            if (COMPANY_NAME !== "UC WARE") {
                coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${band.coverImage}`;
            } else {
                coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${band.coverThumbnail}`;
            }

        } else {
            coverImage = NoImage;
        }

        return <li key={index}>
            <a href={`/band/${band.id}`} className="goToBand">
                <img src={coverImage} alt={band.name} className="bandThumb" />
                <span className="label_bandName">{band.name}</span>
            </a>
        </li>
    })

    render() {
        const { postList, bandList, pageSize } = this.state;
        const { pathname } = this.props.location;
        const { t } = this.props;
        const currentLanguage = localStorage.getItem('i18nextLng');
        let bandCategoryList;
        if (currentLanguage === "korean") {
            bandCategoryList = bandCategoryListInNewsFeed
        } else {
            bandCategoryList = bandCategoryListInNewsFeedEng
        }

        return (
            <div className="wrapper">

                <div className="headerWrap">
                    <HomeTopMenu pathname={pathname} routeFunction={this.route} />
                </div>
                <div className="contentsWrapSpacer" />
                <div className="contentsWrap">
                    <aside className="con-lft" style={{ transform: 'none' }}>
                        <div className="sticky">
                            <a href="/band/create" style={{ marginBottom: '16px' }} className="makeBand_rec">
                                <img src={btnMakeBand} alt="makeBandButton" />
                            </a>
                            <div className="myBandList">
                                <h5>{t('NewsFeed.myBand')}
                                    <span className="count">{bandList.length}</span></h5>
                                <ul>
                                    {this.renderBandLists()}
                                </ul>
                            </div>
                        </div>
                    </aside>
                    <main className="con-center">
                        {postList.length === 0 ?
                            <div style={{ height: '85vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: '#FAFAFA' }}>
                                <p>{t('NewsFeed.thereMayNotBeRegisteredBandsYet')}</p>
                                <p>{t('NewsFeed.thereMayNotBePostsInRegisteredBands')}</p>
                            </div>
                            :
                            <PostList newsFeed postList={postList} bandList={bandList} routeFunction={this.routePostOrBand} />
                        }

                        {pageSize === 20 &&
                            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem', marginTop: '1rem' }}>
                                <button style={{
                                    padding: '1rem', borderRadius: '5px',
                                    background: `${THEME_BACKGROUND_COLOR}`, color: 'white', width: '100px', fontWeight: 'bold'
                                }} onClick={this.nextPage}>{t('NewsFeed.loadMore')}</button>
                            </div>
                        }
                    </main>

                    <aside className="con-rht" style={{ transform: 'none' }}>
                        <div className="sticky">
                            <h5>{t('NewsFeed.findByTopic')}</h5>
                            <ul className="explore-cat-news">
                                {bandCategoryList.map((item, index) => (
                                    <li key={index}><img
                                        style={{
                                            boxShadow: '2px 2px 4px rgba(0,165,193,.2)',
                                            borderRadius: '6px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={this.handleRouteClick} src={item.icon} alt="category" /></li>
                                ))}
                            </ul>
                        </div>
                    </aside>
                </div>
                <div className="clearfix"></div>
            </div >
        );
    }
}

export default withTranslation()(NewsFeed);
