import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

class BandCategoryGrid extends React.Component {

    _isMounted = false;

    bandCategoryList;
    routeFunction;

    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            gridItemSize: window.innerWidth * 0.29,
        });
    }

    selectItem = (e) => {
        this.props.routeFunction(e.currentTarget.id);
    };

    render() {
        const { bandCategoryList } = this.props;

        return (
            <GridWrapper>
                <Row>
                    {bandCategoryList.map((item, index) => (
                        <Col xs={8} sm={8} md={8} key={index}>
                            <GridItem tabIndex="0" aria-label={item.name} key={`band-category-grid-item-${index + 1}`}
                                id={item.id}
                                onClick={this.selectItem}>
                                <img src={item.icon} alt={item.name} />
                            </GridItem>
                        </Col>
                    ))}
                </Row>
            </GridWrapper>
        );
    }
}

const GridWrapper = styled.div`
    padding: 0 1em;
    cursor:pointer;
`;

const GridItem = styled.button`
    width: 100%;
    margin-right: 16px;
    margin-bottom: 16px;
    img {
        width: 90%;
        height: auto;
        border-radius: 6px;
        box-shadow: 2px 2px 4px rgba(0,165,193,0.2);
    }
`;

export default BandCategoryGrid;
