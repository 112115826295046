/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Menu } from "antd";
import btnAdd from "../../../assets/images/btn-add.svg";
import { useTranslation } from "react-i18next";
import { Popup } from "semantic-ui-react";

type TtoolTipProps = {
  selectMenu: (type: string) => void;
};

function SimpleEditorFileToolTip(props: TtoolTipProps) {
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item>
        <a onClick={() => props.selectMenu("image")}>
          {t("SimpleEditorFileToolTip.attachImage")}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => props.selectMenu("video")}>
          {t("SimpleEditorFileToolTip.attachVideo")}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => props.selectMenu("file")}>
          {t("SimpleEditorFileToolTip.attachFile")}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Popup
      flowing
      hoverable
      trigger={
        <button style={{ padding: "0.5rem" }}>
          <img
            style={{ width: "1.6rem", height: "1.6rem" }}
            src={btnAdd}
            alt="btn-emoticon"
          />
        </button>
      }
    >
      {menu}
    </Popup>
  );
}

export default SimpleEditorFileToolTip;
