/* eslint-disable array-callback-return */
import React from "react";
import styled from "styled-components";
import { HomeMenuButtons } from "../Menu";
import BandGrid from "./Sections/BandGrid";
import {
  UserProfileSettingModal,
  ViewOrderTypeSelectModal,
  FixListSelectModal,
  HiddenListSelectModal,
  FileSizeSelectModal,
} from "../../common/components/Modal";

import * as UserService from "../../services/UserService";
import * as SettingsService from "../../services/SettingsService";
import * as BandService from "../../services/BandService";

import "../../assets/css/_home_grid.scss";
import { COMPANY_NAME } from "../../configs";

import slide from "../../assets/images/slide1.jpg";
import slideMid from "../../assets/images/slide1_768w.jpg";
import slideWide from "../../assets/images/slide1_wide.jpg";

import slideG from "../../assets/images/slide1_g.jpg";
import slideMidG from "../../assets/images/slide1_768w_g.jpg";
import slideWideG from "../../assets/images/slide1_wide_g.jpg";

import slideBusan from "../../assets/images/slide_busan.jpg";
import slideMidBusan from "../../assets/images/slide_busan_768w.jpg";
import slideWideBusan from "../../assets/images/slide_busan_wide.jpg";

import ListSkeleton from "../../common/components/Shared/Skeleton/ListSkeleton";

import Modal from "react-modal";
import ReactTooltip from "react-tooltip";

import { withTranslation } from "react-i18next";
import _ from "lodash";
import "./LandingPage.css";
import BtnJoinSVG from "../../assets/images/BtnJoinSVG";
import BtnMakeSVG from "../../assets/images/BtnMakeSVG";

const btnPlus = require("../../assets/images/btn-plus.svg");
const btnPlusBlack = require("../../assets/images/btn-plus-black.svg");

class MyBand extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      bandList: [],
      bandListAsLeader: [],
      isUserProfileSettingModalOpen: false,
      isViewTypeSelectModalOpen: false,
      isViewOrderTypeSelectModalOpen: false,
      isFixListSelectModalOpen: false,
      isHiddenListSelectModalOpen: false,
      isFileSizeSelectModalOpen: false,
      isDarkMode: false,
      isMyBandListAsLeader: false,
      isSystemAdminUser: false,
    };
  }

  // downloadSnapshot = () => {
  //     const { videoPreview } = this.state;
  //     const link = document.createElement('a');
  //     link.href = videoPreview;
  //     link.download = `video_preview.jpg`;
  //     document.body.appendChild(link);
  //     console.log('link', link)
  //     // link.click();
  // }

  componentDidMount() {
    this._isMounted = true;

    if (localStorage.getItem("userId")) {
      UserService.isSystemAdminUser(localStorage.getItem("ucTalkId")).then(
        (isSystemAdminUser) => {
          if (isSystemAdminUser === true) {
            this.setState({ isSystemAdminUser });
          }
          console.log("ucTalkId", localStorage.getItem("ucTalkId"));
          console.log(
            "isSystemAdminUser",
            localStorage.getItem("isSystemAdminUser")
          );
        }
      );

      let bandList = [];
      let bandListAsLeader = [];

      SettingsService.globalSettingsNode()
        .then(() => SettingsService.userSettingsNode())
        // 여기서 고정된 밴드 리스트들을 먼저 가져와야지 맨드를 고정했을때 위로 온다.
        // 고정된 밴드 리스트들을 가져온다.   getUserBandListNode 두번째 arg는 fixed or unfixed
        .then(() =>
          BandService.getUserBandListNode(localStorage.getItem("userId"), true)
        )
        .then((bandIds) => BandService.getBandListNode(bandIds, 0))
        .then((result) => {
          if (
            localStorage.getItem("bandFixedListUserId") &&
            localStorage.getItem("bandFixedList") &&
            localStorage.getItem("bandFixedListUserId") ===
              localStorage.getItem("userId")
          ) {
            let returnArray = localStorage
              .getItem("bandFixedList")
              .split(",")
              .map(function(item) {
                return parseInt(item, 10);
              });

            returnArray.map((i) => {
              bandList.push(_.find(result.content, { id: i }));
            });
          } else {
            if (result) {
              result.content.forEach((content) => bandList.push(content));
            }
          }
        })
        // 고정되지 않은 밴드 리스트들을 가져온다.
        .then(() =>
          BandService.getUserBandListNode(localStorage.getItem("userId"), false)
        )
        .then((bandIds) =>
          BandService.getBandListNode(
            bandIds,
            localStorage.getItem("viewOrderType")
          )
        )
        .then((result) => {
          // console.log(`getBandListNode Type ${localStorage.getItem('viewOrderType')} :`, result)
          if (result) {
            result.content.forEach((content) => bandList.push(content));
          }
        })
        .then(() =>
          BandService.getUserBandListAsLeaderNode(
            localStorage.getItem("userId")
          )
        )
        .then((result) =>
          BandService.getBandListNode(
            result.bandListAsLeader,
            localStorage.getItem("viewOrderType")
          )
        )
        .then((result) => {
          if (result) {
            result.content.forEach((content) => bandListAsLeader.push(content));
          }

          if (this._isMounted) {
            this.setState({
              loading: false,
              bandList: bandList,
              bandListAsLeader: bandListAsLeader,
            });
          }
        })
        .catch((error) => {
          alert(error);
          if (this._isMounted) {
            this.setState({
              loading: false,
              bandList: [],
            });
          }
        });
    } else {
      if (this._isMounted) {
        this.setState({
          loading: false,
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  MyBandListAsLeaderHanlder = () => {
    this.setState({
      isMyBandListAsLeader: !this.state.isMyBandListAsLeader,
    });
  };

  routeBandHome = (bandId) => {
    if (bandId === "-1") {
      this.route("/searchBand");
    } else {
      this.route("/band/" + bandId);
    }
  };

  routeAdminPage = () => {
    this.route("/admin/settings");
  };

  routeBandCreate = () => {
    this.route("/band/create");
  };

  routeSearchBand = () => {
    this.route("/searchBand");
  };

  route = (url) => {
    this.props.history.push(url);
  };

  handleModalPopup = (selected) => {
    if (this._isMounted) {
      this.setState({ [selected]: true });
    }
  };

  closeUserProfileSettingModal = () => {
    this.setState({
      isUserProfileSettingModalOpen: false,
    });
  };

  closeViewTypeSelectModal = () => {
    this.setState(
      {
        isViewTypeSelectModalOpen: false,
      },
      this.refresh
    );
  };

  closeViewOrderTypeSelectModal = () => {
    this.setState(
      {
        isViewOrderTypeSelectModalOpen: false,
      },
      this.refresh
    );
  };

  closeFixListSelectModal = () => {
    this.setState(
      {
        isFixListSelectModalOpen: false,
      },
      this.refresh
    );
  };

  closeHiddenListSelectModal = () => {
    this.setState(
      {
        isHiddenListSelectModalOpen: false,
      },
      this.refresh
    );
  };

  closeFileSizeSelectModal = () => {
    this.setState(
      {
        isFileSizeSelectModalOpen: false,
      },
      this.refresh
    );
  };

  refresh = () => {
    if (localStorage.getItem("userId")) {
      let bandList = [];
      BandService.getUserBandListNode(localStorage.getItem("userId"), true)
        .then((bandIds) => BandService.getBandListNode(bandIds, 0))
        .then((result) => {
          if (
            localStorage.getItem("bandFixedListUserId") &&
            localStorage.getItem("bandFixedList") &&
            localStorage.getItem("bandFixedListUserId") ===
              localStorage.getItem("userId")
          ) {
            let returnArray = localStorage
              .getItem("bandFixedList")
              .split(",")
              .map(function(item) {
                return parseInt(item, 10);
              });

            returnArray.map((i) => {
              bandList.push(_.find(result.content, { id: i }));
            });
          } else {
            if (result) {
              result.content.forEach((content) => bandList.push(content));
            }
          }
        })
        .then(() =>
          BandService.getUserBandListNode(localStorage.getItem("userId"), false)
        )
        .then((bandIds) =>
          BandService.getBandListNode(
            bandIds,
            localStorage.getItem("viewOrderType")
          )
        )
        .then((result) => {
          if (result) {
            result.content.forEach((content) => bandList.push(content));
          }
        })
        .then(() => {
          if (this._isMounted) {
            this.setState({
              loading: false,
              bandList: bandList,
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  onSearchBandRouteClick = () => {
    this.props.history.push("/searchBand");
  };

  render() {
    const {
      loading,
      bandList,
      bandListAsLeader,
      isMyBandListAsLeader,
    } = this.state;
    const { pathname } = this.props.location;
    const { t } = this.props;
    const currentLanguage = localStorage.getItem("i18nextLng");

    let slideSmall;
    if (COMPANY_NAME === "HOME") {
      slideSmall = slideG;
    } else if (COMPANY_NAME === "협업팜") {
      slideSmall = slideBusan;
    } else {
      slideSmall = slide;
    }

    let slideMiddle;
    if (COMPANY_NAME === "HOME") {
      slideMiddle = slideMidG;
    } else if (COMPANY_NAME === "협업팜") {
      slideMiddle = slideMidBusan;
    } else {
      slideMiddle = slideMid;
    }

    let slideWideVar;
    if (COMPANY_NAME === "HOME") {
      slideWideVar = slideWideG;
    } else if (COMPANY_NAME === "협업팜") {
      slideWideVar = slideWideBusan;
    } else {
      slideWideVar = slideWide;
    }

    return (
      <div id="section">
        <div>
          <div className="image-gallery " aria-live="polite">
            <div className="image-gallery-content">
              <div className="image-gallery-slide-wrapper bottom ">
                <div className="image-gallery-slides">
                  <div
                    className="image-gallery-slide center slide1"
                    style={{
                      transform: "translate3d(0%, 0px, 0px)",
                      transition: "all 450ms ease-out 0s",
                    }}
                  >
                    <div className="image-gallery-image">
                      <picture>
                        <img
                          className="slider_s"
                          src={slideSmall}
                          alt="작은 슬라이드"
                        />
                        <img
                          className="slider_m"
                          src={slideMiddle}
                          alt="중간 슬라이드 "
                        />
                        <img
                          className="slider_l"
                          src={slideWideVar}
                          alt="큰 슬라이드"
                        />
                      </picture>
                      <div className="image-gallery-description-wrap">
                        <span
                          className={`image-gallery-description ${COMPANY_NAME ===
                            "협업팜" && "busan"}`}
                        >
                          {t("MyBand.ifYouNeedNewBand")}
                          <br />
                          {t("MyBand.howAboutThisBand")}
                          <br />
                          {COMPANY_NAME === "협업팜" ? (
                            <SeachBandRouteButtonBusan
                              busan={true}
                              onClick={this.onSearchBandRouteClick}
                            >
                              {t("MyBand.searchForMoreBands")}{" "}
                            </SeachBandRouteButtonBusan>
                          ) : (
                            <SeachBandRouteButton
                              onClick={this.onSearchBandRouteClick}
                            >
                              {t("MyBand.searchForMoreBands")}{" "}
                            </SeachBandRouteButton>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content_wrap">
            <HomeMenuButtons
              routeFunction={this.route}
              MyBandListAsLeaderHanlder={this.MyBandListAsLeaderHanlder}
              isMyBandListAsLeader={isMyBandListAsLeader}
              selectPopup={this.handleModalPopup}
              pathname={pathname}
            />
            {loading ? (
              <div style={{ width: "80%", margin: "1rem auto" }}>
                <ListSkeleton />
              </div>
            ) : isMyBandListAsLeader ? (
              bandListAsLeader &&
              bandListAsLeader.length > 0 && (
                <BandGrid
                  bandList={bandListAsLeader}
                  cols={2}
                  routeFunction={this.routeBandHome}
                />
              )
            ) : (
              bandList &&
              bandList.length > 0 && (
                <BandGrid
                  bandList={bandList}
                  cols={2}
                  routeFunction={this.routeBandHome}
                />
              )
            )}

            {loading ? (
              <div></div>
            ) : (
              (!bandList || bandList.length === 0) && (
                <div className="band-guide-area">
                  <div
                    className=""
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      fontStretch: "normal",
                      lineHeight: "1",
                    }}
                  >
                    {t("MyBand.youDidNotJoinTheBandYet")}
                  </div>
                  <br />
                  <div
                    className=""
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      fontStretch: "normal",
                      lineHeight: "1.33",
                    }}
                  >
                    {t("MyBand.whyDontYouCreateOrJoinTheBand")}
                  </div>
                  <div className="" style={{ marginTop: "1rem" }}>
                    <button onClick={this.routeSearchBand} className=" p-4">
                      <BtnJoinSVG label={this.props.t(`MyBand.joinTheBand`)} />
                    </button>
                    <button onClick={this.routeBandCreate} className=" p-4">
                      <BtnMakeSVG
                        label={this.props.t(`MyBand.createTheBand`)}
                      />
                    </button>
                  </div>
                </div>
              )
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "0",
                position: "fixed",
                bottom: "16px",
                right: "16px",
              }}
            >
              {this.state.isSystemAdminUser && (
                <>
                  <button
                    data-tip
                    data-for={`go_to_admin`}
                    onClick={this.routeAdminPage}
                  >
                    <ButtonPlus
                      style={{ zIndex: 9999 }}
                      src={btnPlusBlack}
                      alt="어드민 페이지로"
                    />
                  </button>
                  <ReactTooltip id={`go_to_admin`}>
                    <span>{t("New.RouteToAdmin")}</span>
                  </ReactTooltip>
                </>
              )}
              <button
                data-tip
                data-for={`make_band`}
                style={{ marginLeft: "1rem" }}
                onClick={this.routeBandCreate}
              >
                <ButtonPlus
                  style={{ zIndex: 9999 }}
                  src={btnPlus}
                  alt="밴드 만들기 버튼"
                />
              </button>
              <ReactTooltip id={`make_band`}>
                <span>{t("New.createCollaboration")}</span>
              </ReactTooltip>
            </div>

            <Modal
              contentLabel="User Profile"
              isOpen={this.state.isUserProfileSettingModalOpen}
              onRequestClose={this.closeUserProfileSettingModal}
              style={CustomStyles}
            >
              <UserProfileSettingModal
                show={this.state.isUserProfileSettingModalOpen}
                onClose={this.closeUserProfileSettingModal}
              />
            </Modal>

            <Modal
              contentLabel="View Order Type"
              isOpen={this.state.isViewOrderTypeSelectModalOpen}
              onRequestClose={this.closeViewOrderTypeSelectModal}
              style={CustomStyles}
            >
              <ViewOrderTypeSelectModal
                show={this.state.isViewOrderTypeSelectModalOpen}
                onClose={this.closeViewOrderTypeSelectModal}
              />
            </Modal>

            <Modal
              contentLabel="Fix List"
              isOpen={this.state.isFixListSelectModalOpen}
              onRequestClose={this.closeFixListSelectModal}
              style={CustomStyles}
            >
              <FixListSelectModal
                show={this.state.isFixListSelectModalOpen}
                onClose={this.closeFixListSelectModal}
              />
            </Modal>

            <Modal
              contentLabel="Hidden List"
              isOpen={this.state.isHiddenListSelectModalOpen}
              onRequestClose={this.closeHiddenListSelectModal}
              style={CustomStyles}
            >
              <HiddenListSelectModal
                show={this.state.isHiddenListSelectModalOpen}
                onClose={this.closeHiddenListSelectModal}
              />
            </Modal>

            <Modal
              contentLabel="File Size"
              isOpen={this.state.isFileSizeSelectModalOpen}
              onRequestClose={this.closeFileSizeSelectModal}
              style={CustomStyles}
            >
              <FileSizeSelectModal
                show={this.state.isFileSizeSelectModalOpen}
                onClose={this.closeFileSizeSelectModal}
              />
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const ButtonPlus = styled.img`
  @media (max-width: 500px) {
    bottom: 5rem;
  }
  button:hover {
    background: #e9f8fb;
    transition: all 0.6s;
  }
`;

const SeachBandRouteButton = styled.button`
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid;
  border-radius: 4px;
  font-size: 16px;
`;

const SeachBandRouteButtonBusan = styled.button`
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
`;

export default withTranslation()(MyBand);

Modal.setAppElement("#root");

const CustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zIndex: 1000 },
};
