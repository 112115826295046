import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

function DialogComp({ open, description }) {
    return (
        <Dialog open={open}>
            <DialogWrapper >
                <div> {description} </div>
                <br />
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </div>
            </DialogWrapper>
        </Dialog>
    )
}

const DialogWrapper = styled.div`
    width: 100%;
    padding: 3rem;
    vertical-align: middle;
    text-align: center;
`;

export default DialogComp


