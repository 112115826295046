import React from 'react'
import { Empty } from 'antd';

export default function ({ description }) {
    return (
        <div>
            <Empty
                description={description}
            />
        </div>
    )
}

