import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import Modal from 'react-awesome-modal';

import * as HtmlUtils from '../../../../utils/HtmlUtils';
import * as DateTimeUtils from '../../../../utils/DateTimeUtils';
import { THEME_BACKGROUND_COLOR } from '../../../../configs';
import { withTranslation } from 'react-i18next';

import btnHamburger from '../../../../assets/images/btn_hamburger.svg';
import btnHamburger_hover from '../../../../assets/images/btn_hamburger_hover.svg';
const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class NoticeItem extends React.Component {

    _isMounted = false;

    bandId;
    item;
    routeFunction;
    modifyNotice;
    removeFromNotice;
    isOpened;
    isBandLeader;

    constructor(props) {
        super(props);

        this.state = {
            openMenu: false,
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    selectPost = (e) => {
        if (this.props.isOpened) {
            this.props.routeFunction(this.props.bandId, e.currentTarget.id);
        }
    };

    openMenu = () => {
        if (this._isMounted) {
            this.setState({ openMenu: true });
        }
    };

    closeMenu = () => {
        if (this._isMounted) {
            this.setState({ openMenu: false });
        }
    };

    modifyNotice = () => {
        if (this._isMounted) {
            this.setState({
                openMenu: false
            }, this.props.modifyNotice(this.props.item.id));
        }
    };

    removeFromNotice = () => {
        if (this._isMounted) {
            this.setState({
                openMenu: false
            }, this.props.removeFromNotice(this.props.item.id));
        }
    };

    render() {
        const { item, t } = this.props;

        let content = item && item.content ? item.content : "";
        let startDateTime, endDateTime;

        console.log(item.postDateTime)

        startDateTime = DateTimeUtils.formattedForDateTimeDisplay(item.postDateTime);
        if (!!item.noticeEndDateTime && item.noticeEndDateTime !== null) {
            endDateTime = DateTimeUtils.formattedForDateTimeDisplay(item.noticeEndDateTime);
        } else {
            endDateTime = "";
        }

        const images = HtmlUtils.extractSrcFromImgTag(content);

        content = HtmlUtils.removeAllImgTag(content);
        content = HtmlUtils.removeAllVideoTag(content);
        content = HtmlUtils.removeEmptyParagraph(content);

        const paragraphs = HtmlUtils.extractThreeParagraphs(content);
        content = "";
        paragraphs.forEach(paragraph => {
            content += paragraph;
        });

        content = content.split('<p>').join('<p class="paragraph">')
            .split('</p>').join('</p>');

        return (
            <ListItem>

                <Grid>
                    <Grid.Row style={{ padding: ".3rem", marginLeft: '-7px' }}>
                        <Grid.Column width={14}>
                            <ListItemHeader>
                                {console.log('item.isImportantNotice', item.isImportantNotice)}
                                {console.log('item', item)}
                                {item.isImportantNotice ? <TypeIcon important>{t('NoticeList.important')}</TypeIcon> : <TypeIcon>{t('NoticeList.normal')}</TypeIcon>}
                                <Title isMSIE={__ISMSIE__} >{`${startDateTime} ~ ${endDateTime}`}</Title>
                            </ListItemHeader>
                        </Grid.Column>
                        <Grid.Column width={2} style={{ padding: 0 }}>
                            {!!this.props.isBandLeader &&
                                <Settings style={{ marginTop: '-3px', float: 'right' }} onClick={this.openMenu}> <input type="button" className="btn-hambuger" /></Settings>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    {images && images.length > 0 ?
                        <Grid.Row style={{ padding: ".3rem .3rem .5rem .3rem" }} id={item.id} onClick={this.selectPost}>
                            <Grid.Column width={12} style={{ wordBreak: 'break-word' }}>
                                {ReactHtmlParser(content)}
                            </Grid.Column>
                            <Grid.Column width={4} style={{ padding: "0 1rem 0 0" }}>
                                <img src={images[0]} alt={item.name} />
                            </Grid.Column>
                        </Grid.Row> :
                        <Grid.Row style={{ padding: ".3rem .3rem .8rem .3rem" }} id={item.id} onClick={this.selectPost}>
                            <Grid.Column width={16} style={{ wordBreak: 'break-word' }}>
                                {ReactHtmlParser(content)}
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>

                <Modal visible={this.state.openMenu} onClickAway={() => this.closeMenu()}>
                    <div style={{ padding: '1rem' }}>
                        <div onClick={() => this.modifyNotice()}>{t('Post.Detail.editPost')}</div>
                        <Divider />
                        <div onClick={() => this.removeFromNotice()}>{t('Post.Detail.dismissFromNotice')}</div>
                    </div>
                </Modal>

            </ListItem>
        );
    }

}

class NoticeAllList extends React.Component {

    _isMounted = false;
    bandId;
    noticeList;
    routeFunction;
    modifyNotice;
    removeFromNotice;
    isOpened;
    isBandLeader;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { bandId, noticeList, routeFunction, modifyNotice, removeFromNotice, isOpened, isBandLeader, t } = this.props;

        return (
            <List>
                {noticeList.map(item =>
                    <NoticeItem key={item.id}
                        bandId={bandId}
                        item={item}
                        routeFunction={routeFunction}
                        modifyNotice={modifyNotice}
                        removeFromNotice={removeFromNotice}
                        isOpened={isOpened}
                        isBandLeader={isBandLeader}
                        t={t}
                    />
                )}
            </List>
        );
    }

}

const Divider = styled.div`
    border-bottom:  solid 1px #e5e5ea;
    margin:  0.7rem 0;
`;

const List = styled.div`
    padding: 0.5rem 1rem .5rem 1rem;
    background-color: #FFF;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
`;

const ListItem = styled.div`
    margin: 1rem 0 1rem 0;
    background-color: #FFF;
    border: 1px solid #e6e6e6;
    padding: 0.5rem;
    border-radius: 4px;
`;

const ListItemHeader = styled.div`
    display: flex;
    flex-grow: 1;
    padding-bottom: .5rem;
    align-items: flex-end;
`;

const TypeIcon = styled.button`
  background: ${props => props.important ? "#db0e61" : "white"};
  color: ${props => props.important ? "white" : `${THEME_BACKGROUND_COLOR}`};
  border: ${props => props.important ? "solid 1px #db0e61" : `#solid 1px ${THEME_BACKGROUND_COLOR}`};
  border-radius: .75rem;
  font-size: .75rem;
  font-weight: normal;
  margin: 0 .75rem 0 0;
  padding: .25rem .5rem;
`;

const Title = styled.div`
    margin-top: ${props => props.isMSIE ? "5px" : "3px"};
    font-size: 1rem;
`;


const Settings = styled.div`
    input {
        border: none;
        cursor: pointer;
        height: 32px;
        width: 32px;
        background: url(${btnHamburger});
    }

    input:hover {
        background: url(${btnHamburger_hover});
    }
`;

export default withTranslation()(NoticeAllList);
