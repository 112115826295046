import React from 'react'
import { SERVER_URI, COMPANY_NAME } from '../../../../configs'

function Events({ event }) {
    const routeFunction = () => {
        if (COMPANY_NAME === "협업팜") {
            window.location.href = `${SERVER_URI}:3200/band/${event.bandId}/schedule/${event.id}?day=${event.startDateQuery}${event.dayNumber}`;
        } else {
            window.location.href = `${SERVER_URI}/band/${event.bandId}/schedule/${event.id}?day=${event.startDateQuery}${event.dayNumber}`;
            // window.location.href = `${SERVER_URI}:3200/band/${event.bandId}/schedule/${event.id}?day=${event.startDateQuery}${event.dayNumber}`;
        }
    }
    return (
        <div style={{ backgroundColor: `${event.scheduleColor}`, paddingLeft: '4px' }} onClick={routeFunction}>
            <strong>{event.title}</strong>
            {event.desc && ':  ' + event.desc}
        </div>
    )
}

export default Events

// function Book({ event }) {
//     // console.log('event', event)
//     return (
//         <div className="rbc-day-bg">
//             {/* <button>Book Class</button> */}
//         </div>
//     )
// }