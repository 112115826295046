export function contains(target, item) {
    return target && target.length > 0 && target.indexOf(item) > -1;
}

export function replace(str, target, replace) {
    return str.split(target).join(replace);
}

export function startsWith(target, str) {
    return target && target.indexOf(str) === 0;
}

export function removePathFromFileName(filename) {
    if (contains(filename, "\\")) {
        const split = filename.split("\\");
        return split[split.length - 1];
    }
    return filename;
}


export function isValidFileName(filename, ext) {
    const pattern = "([가-힣a-zA-Z0-9\\s_\\\\.\\-\\(\\):])+(" + ext + ")$";
    const regex = new RegExp(pattern);
    return regex.test(filename);
}

export function extractFileExtension(filename) {
    const split = filename.split(".");
    if (split && split.length > 0) {
        return "." + split[split.length - 1];
    }
    return "";
}

/**   
  @param num The number to round
  @param precision The number of decimal places to preserve
**/

export function roundUp(num, precision) {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
}


/*
**String into array
*/
// "433","417","322" 이렇게 온 것을  ["433","417","322"]로 바꾸기 
// var returnArray = OriginalArray.split(',')

/*
**String into array with number
*/
// "433","417","322" 이렇게 온 것을  [433,417,322]로 바꾸기 
// var returnArray = OriginalArray.split(',').map(function (item) {
//     return parseInt(item, 10);
// });