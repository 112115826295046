import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as UserService from "../../../services/UserService";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import * as StringUtils from '../../../utils/StringUtils';
import { THEME_BACKGROUND_COLOR } from '../../../configs';
import { message } from 'antd';
import { Spin } from 'antd';

import "./SearchMembers.css";

import {
    CheckOutlined
} from '@ant-design/icons';

import { Input } from 'antd';
const { Search } = Input;
const NoProfileImage = require('../../../assets/images/blank-profile-picture.png');

function SearchMembers(props) {
    const { t } = useTranslation();
    const [searchKeyword, setSearchKeyword] = useState("")
    const [searchedUsers, setSearchedUsers] = useState([])
    const [checkedUsers, setCheckedUsers] = useState([])
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const userList = async () => {
            const response = await UserService.getAllUsersNode()
            setUsers(response)
        }
        userList();
    }, [])

    const handleChange = (e) => {
        let inputValue = e.target.value
        setSearchKeyword(inputValue)
    };

    const handleSearch = () => {
        if (!searchKeyword.trim()) {
            setSearchedUsers([]);
        } else {
            setLoading(true)
            setTimeout(() => {
                UserService.getSearchedUsers(searchKeyword)
                    .then(searchedUsers => {
                        setSearchedUsers(searchedUsers);
                        setLoading(false)
                    })
            }, 1000);

        }
    }

    const handleClick = (user) => {
        if (checkedUsers.find(u => u.id === user.id)) {
            setCheckedUsers(checkedUsers.filter(u => u.id !== user.id))
        } else {
            setCheckedUsers(prev => prev.concat(user))
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const handleSubmit = async () => {

        let checkedUsersUcTalkId = [];

        for (let index = 0; index < checkedUsers.length; index++) {
            const ucTalkId = checkedUsers[index].ucTalkId;
            checkedUsersUcTalkId.push(ucTalkId)
        }

        if (window.confirm("정말 초대하시겠습니까?")) {
            let chosenUserUIDs = []
            let bandMemberUIDs = []

            props.memberList.map((member, i) => {
                return bandMemberUIDs.push(member.id)
            })

            for (let index = 0; index < checkedUsersUcTalkId.length; index++) {
                const chosenUserFromTblUcTalkId = checkedUsersUcTalkId[index];
                for (let index = 0; index < users.length; index++) {
                    const entireUserElementFromBandUser = users[index];
                    if (chosenUserFromTblUcTalkId === entireUserElementFromBandUser.ucTalkId) {
                        chosenUserUIDs.push(entireUserElementFromBandUser.id)
                    }
                }
            }

            chosenUserUIDs = chosenUserUIDs.filter(function (val) {
                return bandMemberUIDs.indexOf(val) === -1;
            });


            UserService.sendNotificationToinvitedAllUsers(props.bandId, chosenUserUIDs, localStorage.getItem('userAccount'), localStorage.getItem('userName'), props.bandName)
                .then(() => message.success(`알림을 보냈습니다.`))
                // .then(() => message.success(`${t('BandInvitationModal.successfullySentNotificationsOnlyToNonBandMembers')}`))
                .catch(err => console.error(err))
            setSearchKeyword('');
            setSearchedUsers([])
            setCheckedUsers([])
        }
    };

    const renderSearchedUsers = searchedUsers =>
        searchedUsers.length > 0 && searchedUsers.map(user => (
            <List key={user.id} component="div" disablePadding className="list-item" onClick={() => handleClick(user)}>
                <ListItem id={user.id} >
                    <ListItemAvatar>
                        <Avatar
                            src={StringUtils.isNullOrUndefined(user.profileImage)
                                ? NoProfileImage
                                : user.profileImage}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                {user.name} &nbsp;&nbsp;
                                    <span style={{ fontSize: '14px', color: 'gray' }}>
                                    {!StringUtils.isNullOrUndefined(user.departmentName) && user.departmentName}
                                    {!StringUtils.isNullOrUndefined(user.jobTitle) && ` ${user.jobTitle}`}
                                </span>
                            </React.Fragment>
                        }
                    />
                    <ListItemSecondaryAction>
                        {checkedUsers.find(u => u.id === user.id) ? <CheckOutlined /> : ''}
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        ))

    const handleRemoveUserClick = (userId) => {
        setCheckedUsers(checkedUsers.filter(u => u.id !== userId))
    }

    const renderCheckedUsers = checkedUsers =>
        checkedUsers.length > 0 && checkedUsers.map(user => (
            <CheckedMember key={user.id} onClick={() => handleRemoveUserClick(user.id)} >
                <AvatarImage
                    src={StringUtils.isNullOrUndefined(user.profileImage)
                        ? NoProfileImage
                        : `${localStorage.getItem('profileImage')}`}
                    alt="avatar" />
                    &nbsp; {user.name}
            </CheckedMember>
        ))

    return (
        <div>
            <DivWrapper>
                <span style={{ marginRight: 10 }}> {t('BandInvitationModal.inviteMembers')}</span>
            </DivWrapper>

            {/* <input
                className="searchUserInput"
                value={searchKeyword}
                onKeyPress={handleKeyPress}
                placeholder="검색 후 엔터를 눌러주세요"
                onChange={handleChange}
                style={{ width: '100%', height: 32, border: '1px solid #d9d9d9', padding: '10px', fontSize: '16px' }}
            />

            <button
                style={{ display: 'none' }}
                onClick={handleSearch}
            > 클릭
            </button>
             */}
            <Search
                value={searchKeyword}
                placeholder={t('SearchBand.findBySearch')}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                style={{ width: '100%' }}
            />

            {loading &&
                <div style={{ width: '100%', textAlign: 'center', margin: '1rem 0 0.5rem' }}>
                    <Spin />
                </div>}

            {searchedUsers.length > 0 &&
                <SearchedMembers>
                    {renderSearchedUsers(searchedUsers)}
                </SearchedMembers>
            }
            {checkedUsers.length > 0 &&
                <button className="inviteMemberButton"
                    onClick={handleSubmit}>{t('BandInvitationModal.inviteSelectedPeople')}
                </button>

            }
            {checkedUsers.length > 0 &&
                <CheckedMemberWrapper>
                    {renderCheckedUsers(checkedUsers)}
                </CheckedMemberWrapper>
            }
        </div>
    )
}

export default SearchMembers

const CheckedMemberWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    padding: 1rem 0;
    width: 100%;
    flex-wrap: wrap;
`;

const SearchedMembers = styled.div`
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin-top:10px;
`;

const DivWrapper = styled.div`
    padding: 0.5rem 0;
    width: 100%;
    display: flex;
    vertical-align: center;
    position: relative;
    
    object-fit: contain;
    font-size: 1.3rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    img{
        width: 30px;
        height: 24.4px;
        object-fit: contain;
    }

    p { 
        margin-top: 4px;
        padding-left: 3px;
    }
`;

const CheckedMember = styled.div`
    object-fit: contain;
    border-radius: 14px;
    border: solid 1px ${THEME_BACKGROUND_COLOR};
    padding: 0.2rem 1rem 0.4rem;
    margin: 0 0.5rem;
    word-break: keep-all;
    margin-bottom: 5px;
    cursor: pointer;
`;

const AvatarImage = styled.img`
    margin-bottom: -5px;
    width: 20px;
    border-radius: 50%;
    height: 100%;
`;
