import React from 'react';
import '../../../assets/css/_modal.scss';
import { withTranslation } from 'react-i18next';

class BandDeleteConfirmModal extends React.Component {

    onClose;
    show;
    bandId;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            members: [],
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        const { t } = this.props;

        return (
            <div className="modal_wrap">
                <div className="modal">
                     <button className="close_btn" aria-label="모달창 닫기 버튼"onClick={this.props.onClose}></button>
                    <div>
                        <p className="title">{t('Setting.deleteBand')}</p>
                        <div style={{ fontWeight: 'bold' }}>
                            <div>
                                <p>{t('Setting.areYouSureYouWantToDeleteTheBand')}</p>
                                <p>{t('Setting.onceDeletedItCannotBeRecovered')}</p>
                            </div>
                            <br />
                            <div style={{ textAlign: 'right' }}>
                                <span tabIndex="0" style={{ cursor: 'pointer' }} onClick={this.props.onClose}>{t('Setting.no')}</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span tabIndex="0" style={{ color: 'red', cursor: 'pointer' }} onClick={this.props.deleteBand}>{t('Setting.yes')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}



export default withTranslation()(BandDeleteConfirmModal);
