import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import btnPrev from '../../assets/images/btn_prev.svg';
import './BandTopButton.css';
import ReactTooltip from 'react-tooltip'

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class BandTopButtons extends React.Component {

    bandId;
    title;
    historyProp;
    additionalButtons;
    routeToMain;
    routeBack;

    constructor(props) {
        super(props);

        this.state = {
            startScroll: false,
            transform: 0,
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        if (this.props.home || this.props.setting) {
            let scrollTop = window.scrollY;
            if (scrollTop > 100) {
                this.setState({
                    startScroll: true
                });
            } else {
                this.setState({
                    startScroll: false
                })
            }
        }
    }

    back = () => {
        if (this.props.routeToMain) {
            this.props.historyProp.push("/myBand");
        } else if (this.props.routeBack) {
            this.props.historyProp.goBack();
        } else {
            this.props.historyProp.push("/band/" + this.props.bandId + "/home");
        }
    };

    home = () => {
        this.props.historyProp.push("/myBand");
    };

    route = (e) => {
        this.props.historyProp.push(e.currentTarget.attributes.getNamedItem('routes').value);
    };

    renderHeader = ({ additionalButtons, bandName, fromPage, home }, changeHeaderColor, imgStyle = "") =>
        <>
            <div className="con-header-top" style={{ padding: '1rem' }}>
                <span className="roll-back-top"><img style={{ width: '12px', marginTop: __ISMSIE__ && "-8px" }}
                    onClick={this.back} src={btnPrev} alt="back" /></span> &nbsp;
                {home ?
                    <>
                        {changeHeaderColor ?
                            <>
                                <h6 className="page-title-top-home-scroll">{bandName}</h6>
                                <span className="page-title-sub-top-home-scroll" style={{ lineHeight: '24px' }}>{fromPage}</span>
                            </>
                            :
                            <>
                                <h6 className="page-title-top-home">{bandName}</h6>
                                <span className="page-title-sub-top-home" style={{ lineHeight: '24px' }}>{fromPage}</span>
                            </>
                        }
                    </>
                    :
                    <>
                        <h6 className="page-title-top">{bandName}</h6>
                        <span className="page-title-sub-top" style={{ lineHeight: '24px' }}>{fromPage}</span>
                    </>
                }
            </div>

            {additionalButtons && additionalButtons.map((additional, index) =>
                <React.Fragment key={index}>
                    {additional.buttonImage &&
                        <img
                            style={{ top: `${imgStyle}` }}
                            onClick={this.route}
                            routes={additional.routePath}
                            src={additional.buttonImage}
                            className={`button-additional-img-${index}`}
                            alt={`button-additional-img-${index}`}
                            data-tip data-for={`${localStorage.getItem("i18nextLng") === "korean" ? `${additional.nameKo}` : `${additional.nameEng}`} `}
                        />}
                    <ReactTooltip id={`${localStorage.getItem("i18nextLng") === "korean" ? `${additional.nameKo}` : `${additional.nameEng}`} `} >
                        <span>{`${localStorage.getItem("i18nextLng") === "korean" ? `${additional.nameKo}` : `${additional.nameEng}`} `}</span>
                    </ReactTooltip>
                </React.Fragment>
            )}
        </>

    render() {
        const { home, setting } = this.props;
        const imgStyle = "10px";
        var w = window.pageYOffset;
        const changeHeaderColor = w > 75 ? true : false

        return (
            <Header isMSIE={__ISMSIE__} setting={setting} home={home} changeHeaderColor={changeHeaderColor}>
                {__ISMSIE__ ? this.renderHeader(this.props, changeHeaderColor, imgStyle) : this.renderHeader(this.props, changeHeaderColor)}
            </Header>
        )
    }
}

const Header = styled.header`
    background-color: ${props => props.home || props.setting ? (props.changeHeaderColor ? "white" : "transparent") : "white"}
    color: ${props => props.home ? (props.changeHeaderColor ? "#52A5C1 !important" : "white") : "black"}
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;

    display: flex;
    align-items: center;
    z-index: 5;

    * {
        display: inline;
    }

    li {
        margin: 5px;
        font-weight: bold;
    }
`;

export default withRouter(withTranslation()(BandTopButtons));

