import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import ChatMessages from "./ChatMessage";
import styled from "styled-components";
import {
    getMoreChatMessages,
    deleteChatMessage,
    getInitialChatMessages
} from "../../../_actions/chat_actions";
import Modal from 'react-awesome-modal';
import { ContentViewer } from '../../../common/components/Modal';
import { MdCancel } from "react-icons/md";
import Empty from '../../../common/components/Shared/Empty';
import { withTranslation } from 'react-i18next';

class ChatPanel extends Component {
    state = {
        page: 1,
        selectedContent: undefined,
        isDeleteMessageModalOpen: false,
    };

    loadMoreHandler = () => {
        let nextPage = this.state.page + 1;
        this.setState({ page: nextPage })
        this.props.dispatch(getMoreChatMessages(this.props.bandId, nextPage));
    }

    cancelResultHandler = () => {
        this.props.dispatch(getInitialChatMessages(this.props.bandId))
    }

    openContentViewerModal = (item) => {
        this.setState({
            isContentViewerModalOpen: true,
            selectedContent: item,
        });
    };

    closeContentViewerModal = () => {
        this.setState({
            isContentViewerModalOpen: false,
            selectedContent: undefined,
        });
    };

    openDeleteMessageModal = (item) => {
        this.setState({
            isDeleteMessageModalOpen: true,
            selectedContentForDelete: item,
        });
    }

    closeDeleteMessageModal = () => {
        this.setState({
            isDeleteMessageModalOpen: false,
            selectedContentForDelete: undefined,
        });
    };

    deleteMessage = (item) => {
        this.props.dispatch(deleteChatMessage(item))
            .then(() => {
                this.setState({ isDeleteMessageModalOpen: false })
            })
    }

    render() {
        let { bandId, t } = this.props;

        return (
            <ChatWrapper>
                {this.props.chats && this.props.chats.type !== "search" && this.props.chats.chatLength === 20 &&
                    <LoadMore onClick={this.loadMoreHandler}> {t('ChatPanel.loadMore')}  </LoadMore>
                }

                {this.props.chats && this.props.chats.type === "search" &&
                    <SearchResult onClick={this.cancelResultHandler}> {t('ChatPanel.searchResults')}  &nbsp; <MdCancel /> </SearchResult>
                }

                {this.props.isBandMember &&
                    this.props.chats && this.props.chats.chats && this.props.chats.chats.length > 0 ?
                    <ChatMessages
                        messages={this.props.chats.chats}
                        openFunction={this.openContentViewerModal}
                        openDeleteFunction={this.openDeleteMessageModal}
                        page={this.state.page}
                    />
                    :
                    <>
                        {this.props.chats.type !== "search" ?
                            <EmptyChat>
                                <Empty description={t('ChatPanel.startChattingForTheFirstTime')} />
                            </EmptyChat>
                            :
                            <EmptyChat>
                                <Empty description={t('ChatPanel.thereAreNoChatsMatchingYourSearchTerms')} />
                            </EmptyChat>
                        }
                    </>
                }

                {!this.props.isBandMember &&
                    <EmptyWrapper>
                        {t('ChatPanel.bandMembersOnly')}
                        <br /><br />
                        {t('ChatPanel.pleaseJoinTheBandFirst')}
                    </EmptyWrapper>
                }

                <Modal width="320px" visible={this.state.isContentViewerModalOpen} onClickAway={() => this.closeContentViewerModal()}>
                    <ContentViewer fromChat bandId={bandId} item={this.state.selectedContent}
                        onClose={this.closeContentViewerModal} refreshContents={this.reload}
                    />
                </Modal>

                <Modal width="320px" visible={this.state.isDeleteMessageModalOpen} onClickAway={() => this.closeDeleteMessageModal()}>
                    <div style={{ padding: '1rem', textAlign: 'center' }}>
                        {t('ChatPanel.areYouSureYouWantToDeleteThisMessage')}
                        <br /><br />
                        <div>
                            <button onClick={() => this.deleteMessage(this.state.selectedContentForDelete)}> {t('ChatPanel.yes')}</button>
                        </div>
                        <Divider />
                        <div>
                            <button onClick={() => this.closeDeleteMessageModal()}> {t('ChatPanel.no')}</button>
                        </div>
                    </div>
                </Modal>
            </ChatWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        chats: state.chats
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(ChatPanel)));

const SearchResult = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const LoadMore = styled.button`
    width: 100%;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
`;

const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 62vh;
    background: white;
    border: 1px solid rgb(219, 219, 219);
    border-bottom: 0px;
    margin-top: 1rem;
    border-radius: 4px;
    @media (max-width: 500px) {
        padding-bottom:1rem;
    }
`;

const EmptyChat = styled.div`
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;
`;

const EmptyWrapper = styled.div`
    padding: 7rem 1rem 1rem 1rem;
    text-align: center;
    font-size: 1.5rem;
`;

const Divider = styled.div`
    border-bottom: solid 1px #e5e5ea;
    margin: 7px 0;
`;
