import moment from 'moment';
require('moment-timezone');
// moment.tz.setDefault(`${moment.tz.guess()}`);

export function formattedCurrent() {
    return moment().format("YYYYMMDDHHmm");
}

export function formattedCurrentDate() {
    return moment().format("YYYYMMDD");
}

export function formattedCurrentTime() {
    return moment().format("HHmm");
}

export function current() {
    return moment();
}

export function formatted(moment) {
    return moment.format("YYYYMMDDHHmm");
}

export function momentByDefaultFormat(value) {
    return moment(value, 'YYYYMMDDHHmm');
}

export function momentByFormat(value, format) {
    return moment(value, 'YYYYMMDDHHmm').format(format);
}

export function formattedForDateDisplay(value) {
    if (localStorage.getItem('i18nextLng') === "english") {
        return momentByDefaultFormat(value).tz(`${moment.tz.guess()}`).format("ll");
    } else {
        return momentByDefaultFormat(value).tz(`${moment.tz.guess()}`).format("YYYY년 M월 D일");
    }
}

export function formattedForDateTimeDisplay(value) {
    if (localStorage.getItem('i18nextLng') === "english") {
        return momentByDefaultFormat(value).tz(`${moment.tz.guess()}`).format("llll");
    } else {
        return momentByDefaultFormat(value).tz(`${moment.tz.guess()}`).format("YYYY년 M월 D일 A h시 m분");
    }
}

//위로 했을때 한국 시간이 아닌 다른 시간이 나와서 이렇게 해줬다.
export function dateTimeDisplay(value) {
    if (localStorage.getItem('i18nextLng') === "korean") {
        return moment(value).tz(`${moment.tz.guess()}`).format('YYYY년 M월 D일 A h시 m분');
    } else {
        return moment(value).tz(`${moment.tz.guess()}`).format('llll');
    }
}

export function format(value, format) {
    return moment(value, format);
}

export function formatFromString(value, format) {
    return moment(value, 'YYYYMMDDHHmm').format(format);
}

export function momentByLTFormat(value) {
    return moment(value).tz(`${moment.tz.guess()}`).format('LT')
}