import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import btnPlusWhite from "../../../assets/images/btn-plus.svg"

function ContentMenuModal({ modalClose, selectCreateMenu, selectImage, selectVideo, selectAll, isBandLeader, selectFile, currentDirectory }) {
    const { t } = useTranslation();

    let label = `${t('Contents.picture')} / ${t('Contents.videoUpload')}`;
    if (selectImage) {
        label = `${t('Contents.pictureUpload')}`;
    } else if (selectVideo) {
        label = `${t('Contents.videoUpload')}`;
    }

    return (
        <div className="modal_wrap_menu_display">
            <div className="modal">
                <div>
                    {!currentDirectory && isBandLeader &&
                        <div>
                            <button onClick={selectCreateMenu} id="isDirectoryCreateModalOpen">{t('Contents.createFolder')}</button>
                        </div>
                    }
                    {(selectAll || selectImage || selectVideo) &&
                        <div>
                            <button onClick={selectCreateMenu} id="uploadImageOrVideo">{label}</button>
                        </div>
                    }
                    {(selectAll || selectFile) &&
                        <div>
                            <button onClick={selectCreateMenu} id="uploadFile">{t('Contents.fileUpload')}</button>
                        </div>
                    }
                    <PlusMenu>
                        <button onClick={modalClose}><ButtonPlusWhite src={btnPlusWhite} alt="btn-plus" /></button>
                    </PlusMenu>
                </div>
            </div>
            <div className="dim" onClick={modalClose} />
        </div>
    )
}

export default ContentMenuModal

const PlusMenu = styled.div`
    text-align: right;
    button {
    }
`;

const ButtonPlusWhite = styled.img`
    width: 3rem;
    height: 3rem;
    margin-top: 1rem;
`;