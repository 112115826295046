import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-awesome-modal';
import ReactModal from "react-modal";
import { withTranslation } from 'react-i18next';

import { ContentViewer, ContentNameChangeModal, ContentDirectorySelectModalOpen } from '../../../common/components/Modal';
import * as FileService from '../../../services/FileService';
import * as BandService from '../../../services/BandService';
import * as StringUtils from '../../../utils/StringUtils';
import { contains, removePathFromFileName, isValidFileName, extractFileExtension } from '../../../utils/CommonUtils';
import { SERVER_URI, THEME_BACKGROUND_COLOR, COMPANY_NAME } from '../../../configs';
import { Row, Col, message } from 'antd';

import FileIcon from '../../../assets/images/icon-file.svg';
import VideoIcon from '../../../assets/images/icon_video.svg';
import ImgIcon from '../../../assets/images/icon-img.svg';
import bgImage from '../../../assets/images/bg.png';
import folderIcon from '../../../assets/images/icon-folder.svg';
import NoImage from '../../../assets/images/no-image.png';
import {
    isMobile
} from "react-device-detect";
const __ISIOS__ = navigator.userAgent.match(/iPad|iPhone|iPod/i) ? true : false;

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class ContentGridItem extends React.Component {

    _isMounted = false;

    bandId;
    item;
    collect;
    routeDirectory;
    openFunction;

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.item === nextProps.item) {
            return false;
        } else {
            return true;
        }
    }

    collect = (props) => {
        if (this.props.collect) {
            this.props.collect(props.name, props.isDirectory);
        }
    };

    routeDirectoryOrOpenViewer = (openViewer) => {
        if (__ISIOS__) { return; }
        if (openViewer) {
            this.props.openFunction(this.props.item);
        } else if (this.props.item.isDirectory && this.props.routeDirectory) {
            this.props.routeDirectory(this.props.item.id);
        } else {
            this.download();
        }
    };

    toDataURL = async (url, fileName) => {
        return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                return window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                return URL.createObjectURL(blob);
            }
        });
    }

    download = async () => {
        if (__ISMSIE__) {
            const link = document.createElement('a');
            link.href = await this.toDataURL(`${SERVER_URI}:5000/_UPLOAD_FILES/${this.props.bandId}/${this.props.detail ? this.props.item : this.props.item.fileName}`, `${this.props.detail ? this.props.item : this.props.item.fileName}`);
        }
        else {
            const link = document.createElement('a');
            link.href = await this.toDataURL(`${SERVER_URI}:5000/_UPLOAD_FILES/${this.props.bandId}/${this.props.detail ? this.props.item : this.props.item.fileName}`);
            link.download = `${this.props.detail ? this.props.item : this.props.item.fileName}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    render() {
        const { bandId, item } = this.props;
        const { t } = this.props;
        

        let thumbnail = "";
        if (COMPANY_NAME !== "UC WARE") {
            if (item.contentType === "video/mp4") {
                thumbnail = item && !StringUtils.isNullOrUndefined(item.thumbnail) ?
                    `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${item.thumbnail}` : NoImage;
            } else {
                thumbnail = item && !StringUtils.isNullOrUndefined(item.thumbnail) ?
                    `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${item.fileName}` : NoImage;
            }
        } else {
            thumbnail = item && !StringUtils.isNullOrUndefined(item.thumbnail) ?
                `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${item.thumbnail}` : NoImage;
        }

        const { fileType } = item;
        const ImageWrapper = styled.div`
            width: 101%;
            height: ${props => props.isDirectory ? "10.7rem" : "8.7rem"};
            border-radius: 2px 2px 0 0 ;
            background: ${props => props.isDirectory ? `url("${bgImage}") center center` : (props.typeIsFile ? `url("${FileIcon}") center center` : `url("${thumbnail}") center center`)};
            background-size: ${props => props.typeIsFile ? '35%' : (props.isDirectory ? '100%' : '100%')};
            background-repeat: no-repeat; 
            text-align: center;
            p {
                font-size: 1rem;
                color: ${THEME_BACKGROUND_COLOR};
                font-weight: bold;
                margin-right: 3px;
            }
           
            @media (max-width: 330px) {
                width: 10rem;
                height: ${props => props.isDirectory ? "9.7rem" : "8rem"};
            }   
        `;

        let fileTypeIcon;
        if (fileType === "IMAGE") {
            fileTypeIcon = ImgIcon
        } else if (fileType === "VIDEO") {
            fileTypeIcon = VideoIcon
        } else if (fileType === "OTHERS") {
            fileTypeIcon = FileIcon
        }

        let openViewer = false;
        if (fileType === "IMAGE" || fileType === "VIDEO") {
            openViewer = true;
        }
        return (
            <Col xs={12} sm={8} md={6} lg={4}>
                <ContextMenuTrigger id="contextMenu" name={item.id} isDirectory={item.isDirectory} collect={this.collect}>
                    {
                        isMobile ?
                            <>
                                {
                                    item.isDirectory ?
                                        <GridItem onClick={() => this.routeDirectoryOrOpenViewer(openViewer)} >
                                            < ImageWrapper isDirectory={item.isDirectory} typeIsFile={fileType === "OTHERS"}>
                                                {item.isDirectory &&
                                                    <>
                                                        <DirectoryImg src={folderIcon} alt={item.originalFileName} />
                                                        <p className="band-grid-item-name" style={{ wordBreak: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', padding: '0.5rem' }}>{item.originalFileName}</p>
                                                    </>
                                                }
                                            </ImageWrapper>
                                            <ParagraphWrapper>
                                                {fileTypeIcon && <img src={fileTypeIcon} alt="file-type" />}
                                                {!item.isDirectory && <p className="band-grid-item-name">{removePathFromFileName(item.originalFileName)}</p>}
                                            </ParagraphWrapper>
                                        </GridItem>
                                        :
                                        <GridItemAtag href={`${SERVER_URI}:5000/_UPLOAD_FILES/${this.props.bandId}/${this.props.detail ? this.props.item : this.props.item.fileName}?kind=mobile&accessToken=${localStorage.getItem('accessToken')}`} target="_blank" >
                                            < ImageWrapper isDirectory={item.isDirectory} typeIsFile={fileType === "OTHERS"}>
                                                {item.isDirectory &&
                                                    <>
                                                        <DirectoryImg src={folderIcon} alt={item.originalFileName} />
                                                        <p className="band-grid-item-name" style={{ wordBreak: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', padding: '0.5rem' }}>{item.originalFileName}</p>
                                                    </>
                                                }
                                            </ImageWrapper>
                                            <ParagraphWrapper>
                                                {fileTypeIcon && <img src={fileTypeIcon} alt="file-type" />}
                                                {!item.isDirectory && <p className="band-grid-item-name">{removePathFromFileName(item.originalFileName)}</p>}
                                            </ParagraphWrapper>
                                        </GridItemAtag>

                                }
                            </>
                            :
                            <GridItem onClick={() => this.routeDirectoryOrOpenViewer(openViewer)} data-tip data-for={item.originalFileName}>
                                < ImageWrapper isDirectory={item.isDirectory} typeIsFile={fileType === "OTHERS"}>
                                    {item.isDirectory &&
                                        <>
                                            <DirectoryImg src={folderIcon} alt={item.originalFileName} />
                                            <p className="band-grid-item-name" style={{ wordBreak: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', padding: '0.5rem' }}>{item.originalFileName}</p>
                                        </>
                                    }
                                </ImageWrapper>
                                <ReactTooltip id={item.originalFileName} place="bottom" effect="solid">{item.originalFileName}</ReactTooltip>
                                <ParagraphWrapper>
                                    {fileTypeIcon && <img src={fileTypeIcon} alt="file-type" />}
                                    {!item.isDirectory && <p className="band-grid-item-name">{removePathFromFileName(item.originalFileName)}</p>}
                                </ParagraphWrapper>
                            </GridItem>
                    }
                </ContextMenuTrigger>
            </Col >
        );
    }

}

class ContentGrid extends React.Component {

    _isMounted = false;

    bandId;
    contentList;
    cols;
    routeFunction;
    reloadFunction;
    openFunction;

    state = {
        currentDirectory: undefined,
        selected: undefined,
        isDirectory: false,
        changeName: undefined,
        changeDirectory: undefined,
        isNameChangeModalOpen: false,
        isDirectorySelectModalOpen: false,
        isContentViewerModalOpen: false,
        selectedContent: undefined,
        openUserBandListSelectPopup: false,
        userBands: [],
        copyContentId: undefined,
        targetToCopy: undefined,
        openCopyAddress: false,
        copyURL: "",
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    collectItem = (id, isDirectory) => {
        if (this._isMounted) {
            this.setState({
                selected: id,
                isDirectory: isDirectory
            });
        }
    };

    toDataURL = (url) => {
        return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            return URL.createObjectURL(blob);
        });
    }

    reload = () => {
        if (this.props.reloadFunction) {
            this.setState({
                selected: undefined,
                isDirectory: false,
                changeName: undefined,
                changeDirectory: undefined,
                isNameChangeModalOpen: false,
                isDirectorySelectModalOpen: false,
                isContentViewerModalOpen: false,
                selectedContent: undefined,
                openUserBandListSelectPopup: false,
                userBands: [],
                copyContentId: undefined,
                targetToCopy: undefined,
            }, this.props.reloadFunction());
        }
    };

    handleClick = async (e, data) => {
        e.preventDefault();

        const bandId = this.props.bandId;
        const selected = this.getSelected();

        if (contains(data.action, "renameDirectoryName")) {
            if (this._isMounted) {
                this.setState({
                    changeName: selected.originalFileName,
                }, this.openNameChangeModal);
            }
        } else if (contains(data.action, "removeDirectory")) {
            FileService.removeDirectoryNode(bandId, selected.id)
                .then(() => this.reload())
                .catch(error => console.error(error));
        } else if (contains(data.action, "renameFileName")) {
            if (this._isMounted) {
                this.setState({
                    changeName: removePathFromFileName(selected.originalFileName),
                }, this.openNameChangeModal);
            }
        } else if (contains(data.action, "copyFileLocation")) {
            this.copyToClipboard();
        } else if (contains(data.action, "copyToOtherBand")) {
            this.copyToOtherBand(selected.id);
        } else if (contains(data.action, "downloadFile")) {
            if (isMobile) {
                if (__ISIOS__) {
                    window.location.href = `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${selected.fileName}`
                } else {
                    window.open(
                        `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${selected.fileName}`,
                        '_blank' // <- This is what makes it open in a new window.
                    );
                }
            } else {
                const link = document.createElement('a');
                link.href = await this.toDataURL(`${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${selected.fileName}`);
                link.download = `${selected.fileName}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else if (contains(data.action, "removeFile")) {
            var result = "";
            if (localStorage.getItem("i18nextLng") === "english") {
                 result = window.confirm('Are you sure you want to delete it?')

            }else if (localStorage.getItem("i18nextLng") === "japanese") {
                 result = window.confirm('本当に削除しますか？')

            }else if (localStorage.getItem("i18nextLng") === "thai") {
                 result = window.confirm('คุณแน่ใจหรือว่าต้องการจะลบมัน?')

            }else{
                 result = window.confirm('정말로 지우시겠습니까?')
            }

            if (result) {
                FileService.removeFileNode(bandId, selected.id)
                    .then(() => this.reload())
                    .catch(error => console.error(error));
            }
        } else if (contains(data.action, "moveFile")) {
            if (this._isMounted) {
                this.setState({
                    changeDirectory: (selected.directoryId === null ? undefined : selected.directoryId),
                }, this.openDirectorySelectModal);
            }
        }
    };

    getSelected = () => {
        if (this.state.selected) {
            const selected = this.props.contentList.filter(content => content.id === Number(this.state.selected));
            if (selected && selected.length > 0) {
                return selected[0];
            }
        }
        return undefined;
    };

    copyToClipboard = () => {
        const bandId = this.props.bandId;
        const selected = this.getSelected();
        const url = `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${selected.fileName}`;
        // const textField = document.createElement('textarea');
        this.setState({ copyURL: url })

        this.setState({ openCopyAddress: true })

        // textField.innerText = url;
        // document.body.appendChild(textField);
        // textField.select();
        // document.execCommand('copy');
        // textField.remove();
    };

    copyToOtherBand = (contentId) => {
        BandService.getAllUserBandListNode(localStorage.getItem('userId'))
            .then(bandIds => BandService.getBandListNode(bandIds, 0))
            .then(bandList => {
                const userBands = bandList.content.filter(band => band.id !== Number(this.props.bandId));
                if (this._isMounted) {
                    this.setState({
                        userBands: userBands,
                        copyContentId: contentId,
                        openUserBandListSelectPopup: true,
                    })
                }
            })
            .catch(error => console.error(error));
    };

    closeUserBandListSelectPopup = () => {
        if (this._isMounted) {
            this.setState({
                openUserBandListSelectPopup: false,
                targetToCopy: undefined,
            })
        }
    };

    selectBand = (e) => {
        if (this._isMounted) {
            this.setState({ targetToCopy: Number(e.currentTarget.value) });
        }
    };

    copyContent = () => {
        if (this.state.targetToCopy === undefined) {
            return message.warning('하나 이상의 밴드를 선택해 주세요');
        }
        FileService.copyContentNode(this.props.bandId, this.state.copyContentId, this.state.targetToCopy)
            .then(() => this.closeUserBandListSelectPopup())
            .catch(error => console.error(error));
    };

    routeRoot = () => {
        if (this._isMounted) {
            this.setState({ currentDirectory: undefined },
                () => this.props.changeCurrentDirectory(this.state.currentDirectory));
        }
    };

    routeDirectory = (directoryId) => {
        if (this._isMounted) {
            this.setState({ currentDirectory: directoryId },
                () => this.props.changeCurrentDirectory(this.state.currentDirectory));
        }
    };

    handleNameChange = (e) => {
        if (this._isMounted) {
            this.setState({ changeName: e.currentTarget.value });
        }
    };

    changeName = () => {
        const bandId = this.props.bandId;
        const selected = this.getSelected();

        if (selected.isDirectory) {
            FileService.renameDirectoryNameNode(bandId, selected.id, this.state.changeName)
                .then(() => this.reload())
                .catch(error => console.error(error));
        } else {
            if (isValidFileName(this.state.changeName, extractFileExtension(selected.originalFileName))) {
                FileService.renameFileNameNode(bandId, selected.id, this.state.changeName)
                    .then(() => this.reload())
                    .catch(error => console.error(error));
            } else {
                if (localStorage.getItem("i18nextLng") === "english") {
                    alert("Only English, and numbers are available for the name, and please change the name according to the extension name.");
                }else if (localStorage.getItem("i18nextLng") === "japanese") {
                    alert("タイトルは英語、数字のみ利用でき、拡張子名を適切にして名前を変えてください");
                }else if (localStorage.getItem("i18nextLng") === "thai") {
                    alert(".มีเพียงตัวอักษรเกาหลี ภาษาอังกฤษและตัวเลขเท่านั้นที่สามารถใช้ได้กับชื่อและโปรดเปลี่ยนชื่อตามชื่อส่วนขยาย");
                }else{
                    alert("이름은 한글, 영어 알파벳, 숫자만 이용할수 있으며, 확장자 명을 알맞게 해서 이름을 바꿔 주세요.");
                }
            }
        }
    };

    handleDirectorySelect = (e) => {
        const directoryId = e.currentTarget.id ? Number(e.currentTarget.id) : undefined;
        if (this._isMounted) {
            this.setState({
                changeDirectory: directoryId
            });
        }
    };

    changeDirectory = () => {
        const bandId = this.props.bandId;
        const selected = this.getSelected();
        FileService.moveFileNode(bandId, selected.id, this.state.changeDirectory)
            .then(() => this.reload())
            .catch(error => console.error(error));
    };

    openNameChangeModal = () => {
        if (this._isMounted) {
            this.setState({
                isNameChangeModalOpen: true
            });
        }
    };

    closeNameChangeModal = () => {
        if (this._isMounted) {
            this.setState({
                isNameChangeModalOpen: false
            });
        }
    };

    openDirectorySelectModal = () => {
        if (this._isMounted) {
            this.setState({
                isDirectorySelectModalOpen: true
            });
        }
    };

    closeDirectorySelectModal = () => {
        if (this._isMounted) {
            this.setState({
                isDirectorySelectModalOpen: false,
            });
        }
    };

    openContentViewerModal = (item) => {
        if (this._isMounted) {
            this.setState({
                isContentViewerModalOpen: true,
                selectedContent: item,
            });
        }
    };

    closeContentViewerModal = () => {
        if (this._isMounted) {
            this.setState({
                isContentViewerModalOpen: false,
                selectedContent: undefined,
            });
        }
    };

    closeCopyAddressModalPopup = () => {
        if (this._isMounted) {
            this.setState({
                openCopyAddress: false,
                copyURL: "",
            });
        }
    }

    render() {
        const { bandId, contentList, t } = this.props;
        const { isDirectory, currentDirectory } = this.state;

        let contents;
        if (currentDirectory) {
            contents = contentList.filter(content => content.directoryId === currentDirectory);
        } else {
            contents = contentList.filter(content => content.directoryId === null);
        }

        let directories = contentList.filter(content => content.isDirectory);
        directories = directories ? directories : [];
        const currentDirectoryName = currentDirectory ? directories.filter(directory => directory.id === currentDirectory)[0].originalFileName : "";

        return (
            <GridWrapper>

                <Modal visible={this.state.openCopyAddress} onClickAway={() => this.closeCopyAddressModalPopup()}>
                    <div style={{ padding: '2rem', wordBreak: 'break-all' }}>
                    <h3 style={{ marginBottom: '10px' }}>{t('New.CopyAddress')}</h3>
                        {this.state.copyURL}
                    </div>
                </Modal>
                {currentDirectory &&
                    <div>
                        <span onClick={this.routeRoot}>ROOT</span> {'>'} {currentDirectoryName}
                        <p>&nbsp;</p>
                    </div>
                }
                <Row >
                    {currentDirectory &&
                        <Col xs={8} md={8} lg={8}>
                            <ContextMenuTrigger id="contextMenu">
                                <GridItem onClick={this.routeRoot}>
                                    <ParentFolder>
                                        <DirectoryImg src={folderIcon} alt="...부모 폴더로" />
                                        <p className="band-grid-item-name" style={{ fontSize: '2rem' }}>...</p>
                                    </ParentFolder>
                                </GridItem>
                            </ContextMenuTrigger>
                        </Col>
                    }


                    {contents && contents.map((item, index) => (
                        <ContentGridItem key={`content-grid-item-${index + 1}`}
                            bandId={bandId}
                            item={item}
                            collect={this.collectItem}
                            routeDirectory={this.routeDirectory}
                            openFunction={this.openContentViewerModal}
                        />
                    ))}
                </Row>

                <ContextMenu id="contextMenu" className="contextMenuWrapper" style={{ zIndex: '2' }}>
                    {isDirectory && this.props.isBandLeader &&
                        <div>
                            <MenuItem style={{ cursor: 'pointer' }} data={{ action: "renameDirectoryName" }} onClick={this.handleClick}>
                                {t('New.EditFolderName')}
                            </MenuItem>
                            <MenuItem divider />
                            <Divider />
                            <MenuItem style={{ cursor: 'pointer' }} data={{ action: "removeDirectory" }} onClick={this.handleClick}>
                                {t('New.DeleteFolder')}
                            </MenuItem>
                        </div>
                    }

                    {!isDirectory && this.props.isBandLeader &&
                        <div>
                            <MenuItem style={{ cursor: 'pointer' }} data={{ action: "renameFileName" }} onClick={this.handleClick}>
                                {t('New.ModifyFileName')}
                            </MenuItem>
                            <MenuItem divider />
                            <Divider />
                            <MenuItem style={{ cursor: 'pointer' }} data={{ action: "copyFileLocation" }} onClick={this.handleClick}>
                                {t('New.CopyAddress')}
                            </MenuItem>
                            <MenuItem divider />
                            {/*<Divider />
                             <MenuItem data={{ action: "copyToOtherBand" }} onClick={this.handleClick}>
                                다른 협업에 올리기
                            </MenuItem> */}
                            <Divider />
                            <MenuItem divider />
                            <MenuItem style={{ cursor: 'pointer' }} data={{ action: "downloadFile" }} onClick={this.handleClick}>
                                {t('New.Save')}
                            </MenuItem>
                            <Divider />
                            <MenuItem divider />
                            <MenuItem style={{ cursor: 'pointer' }} data={{ action: "removeFile" }} onClick={this.handleClick}>
                                {t('New.Delete')}
                            </MenuItem>
                            <MenuItem divider />
                            <Divider />
                            <MenuItem style={{ cursor: 'pointer' }} data={{ action: "moveFile" }} onClick={this.handleClick}>
                                {t('New.MovePosition')}
                            </MenuItem>
                        </div>
                    }

                    {!isDirectory && !this.props.isBandLeader &&
                        <div>
                            <MenuItem style={{ cursor: 'pointer' }} data={{ action: "downloadFile" }} onClick={this.handleClick}>
                                {t('New.Save')}
                            </MenuItem>
                        </div>
                    }

                </ContextMenu>

                <ReactModal
                    isOpen={this.state.isNameChangeModalOpen}
                    contentLabel="Change"
                    onRequestClose={this.closeNameChangeModal}
                    style={CustomStyles}
                >
                    <ContentNameChangeModal
                        changeName={this.state.changeName}
                        show={this.state.isNameChangeModalOpen}
                        submitChangeName={this.changeName}
                        handleNameChange={this.handleNameChange}
                        onClose={this.closeNameChangeModal} />
                </ReactModal>

                <ReactModal
                    isOpen={this.state.isDirectorySelectModalOpen}
                    contentLabel="Close"
                    onRequestClose={this.closeDirectorySelectModal}
                    style={CustomStyles}
                >
                    <ContentDirectorySelectModalOpen
                        changeDirectory={this.state.changeDirectory}
                        show={this.state.isDirectorySelectModalOpen}
                        directories={directories}
                        onChangeDirectory={this.changeDirectory}
                        handleDirectorySelect={this.handleDirectorySelect}
                        onClose={this.closeDirectorySelectModal} />
                </ReactModal>

                <Modal visible={this.state.openUserBandListSelectPopup} onClickAway={() => this.closeUserBandListSelectPopup()}>
                    <div style={{ width: "20rem", padding: '1rem', maxHeight: 300, overflowY: 'auto' }}>
                        {this.state.userBands && this.state.userBands.length > 0 ?
                            <div style={{ padding: 10 }}>
                                <Grid style={{ margin: 0 }}>
                                    {this.state.userBands.map(band =>
                                        <>
                                            <Grid.Row key={band.id} style={{ padding: "0 0 .5rem 0", margin: 0 }}>
                                                <Grid.Column width={13}>
                                                    {band.name}
                                                </Grid.Column>
                                                <Grid.Column width={3}>
                                                    <input
                                                        type="radio"
                                                        value={band.id}
                                                        checked={this.state.targetToCopy === band.id}
                                                        onChange={this.selectBand}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Divider padding />
                                        </>
                                    )}
                                </Grid>
                                <button onClick={this.copyContent} style={{ padding: "1rem 2rem" }}>선택 완료</button>
                            </div> :
                            <div style={{ padding: 10, textAlign: 'center' }}>
                                {t('New.ThereIsNoBandToChooseFrom')}
                            </div>
                        }
                    </div>
                </Modal>

                <Modal width="320px" visible={this.state.isContentViewerModalOpen} onClickAway={() => this.closeContentViewerModal()}>
                    <ContentViewer t={t} bandId={bandId} item={this.state.selectedContent}
                        onClose={this.closeContentViewerModal} refreshContents={this.reload}
                    />
                </Modal>

            </GridWrapper>
        );
    }
}

const ParentFolder = styled.div`
    background: url(${bgImage}) center center; 
    background-size: 100%; 
    height: 10.7rem;
    text-align: center;
`;

const ParagraphWrapper = styled.div`
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 1.8rem;
    margin-right: 7px;
 
    img {
        width: 20px;
        height: 17px;
        margin-top: 4px;
        margin-left: 5px;
        margin-right: 5px;
        min-width: 20px;
    }
 
    p {
        text-align: left;
        margin-top: 3px;
        font-size: 1rem;
        color: ${THEME_BACKGROUND_COLOR};
        font-weight: bold;
        margin-right: 3px;
    }
`;

const GridWrapper = styled.div`
    padding: 0;
`;

const GridItem = styled.button`
    border-radius: 6px;
    border: solid 4px ${THEME_BACKGROUND_COLOR};
    border-width: 1px;
    width: 11.3rem;
    height: 11rem;
    margin: 0.5rem 0;
    
    @media (max-width: 330px) {
        width: 10.3rem;
        height: 10rem;
        margin: 2px;
    }   
 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;


const GridItemAtag = styled.a`
    border-radius: 6px;
    border: solid 4px ${THEME_BACKGROUND_COLOR};
    border-width: 1px;
    width: 11.3rem;
    height: 11rem;
    margin: 0.5rem 0;
    
    @media (max-width: 330px) {
        width: 10.3rem;
        height: 10rem;
        margin: 2px;
    }   
 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DirectoryImg = styled.img`
    width: 5rem;
    margin-top: 3rem;  
`;

const Divider = styled.div`
    border-bottom: solid 1px #e5e5ea;
    margin: 0.3rem 0;
`;

export default withTranslation()(ContentGrid);

const CustomStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    }, overlay: { zIndex: 1000 }
};