import React from 'react';
import styled from "styled-components";
import { scheduleColors } from '../../../configs';
import { withTranslation } from 'react-i18next';

class ScheduleColorSelectModal extends React.Component {

    show;
    bandId;
    onSelect;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            selected: [],
            selectedColor: "#51A5C1",
        };
    }

    select = (id, color) => {
        if (this.state.selected.indexOf(id) >= 0) {
            this.setState({
                selected: this.state.selected.filter(s => s !== id)
            });
            this.props.onSelect(color);

        } else {
            this.setState({
                selected: [...this.state.selected, id]
            });
            this.props.onSelect(color);
        }
    };

    render() {
        let { t } = this.props;
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="modal_wrap">
                <div className="modal">
                    <div className="modal_content view_order">
                        <p className="title">{t('ScheduleColorSelectModal.chooseScheduleColor')} </p>
                        <ColorPalette>
                            {scheduleColors.map((color, index) =>
                                <ColorPicker onClick={() => this.select(index, color)} key={`color-${index}`}>
                                    <input  type="checkbox" id={`checkbox-${index}`}
                                        checked={(this.state.selectedColor === index)}
                                        
                                    />
                                    <label htmlFor={`checkbox-${index}`} style={{ backgroundColor: color }} />
                                </ColorPicker>
                            )}
                        </ColorPalette>
                    </div>
                </div>
            </div>
        );
    }

}

const ColorPicker = styled.button`
    display: inline-block;
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      margin: .3rem;
      border-radius: 50%;
    }
    input[type="checkbox"]:checked + label {
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid;
    }
`;

const ColorPalette = styled.div`
    padding: .5rem 1rem;
`;

export default withTranslation()(ScheduleColorSelectModal);
