/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from "react-router-dom";
import * as UserService from '../../services/UserService';
import * as BandService from '../../services/BandService';
import * as StringUtils from '../../utils/StringUtils';
import { Avatar } from 'antd';
import { COMPANY_NAME } from '../../configs';
import './BandMenu.css'
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip'

const btnHome = require('../../assets/images/bottom-tap-band-home.svg');
const btnHomeActive = require('../../assets/images/bottom-tap-band-home-active.svg');

const btnChat = require('../../assets/images/bottom-tap-chat.svg');
const btnChatActive = require('../../assets/images/bottom-tap-chat-active.svg');

const btnSchedule = require('../../assets/images/bottom-tap-schedule.svg');
const btnScheduleActive = require('../../assets/images/bottom-tap-schedule-active.svg');

const btnContents = require('../../assets/images/bottom-tap-contents.svg');
const btnContentsActive = require('../../assets/images/bottom-tap-contents-active.svg');

const btnMember = require('../../assets/images/bottom-tap-member.svg');
const btnMemberActive = require('../../assets/images/bottom-tap-member-active.svg');

const btnSetting = require('../../assets/images/bottom-tap-setting.svg');
const btnSettingActive = require('../../assets/images/bottom-tap-setting-active.svg');
const NoProfileImage = require('../../assets/images/blank-profile-picture.png');
class BandMenu extends React.Component {

    bandId;
    historyProp;
    isBandMember;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isBandMember: false,
            userData: {},
        };
    }

    componentDidMount() {
        this._isMounted = true;

        UserService.getUserProfileNode(localStorage.getItem('userId'))
            .then((results) => {
                if (this._isMounted) {
                    this.setState({ userData: results });
                }
            })

        this.loadBandInfo()
    }



    loadBandInfo = () => {
        BandService.getSingleBandMember(this.props.bandId, localStorage.getItem('userId'))
            .then(response => {
                // console.log('getSingleBandMember', response)
                if ((response.bandMemberRole === 0) ||
                    (response.bandMemberRole === 1)) {
                    this.setState({ isBandMember: true })
                }
            })
            .catch(error => console.error(error));
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    routeHome = () => {
        this.route("/band/" + this.props.bandId + "/home")
    };

    routeChat = () => {
        window.location.href = "/band/" + this.props.bandId + "/chat";
    };

    routeSchedule = () => {
        this.route("/band/" + this.props.bandId + "/schedule")
    };

    routeContents = () => {
        this.route("/band/" + this.props.bandId + "/contents")
    };

    routeMembers = () => {
        this.route("/band/" + this.props.bandId + "/members")
    };

    routeSetting = () => {
        this.route("/band/" + this.props.bandId + "/setting")
    };

    route = (url) => {
        if (url === window.location.pathname) {
            window.location.reload();
        } else {
            this.props.historyProp.push(url);
        }
    };

    render() {
        const { pathName, t } = this.props;
        const { userData } = this.state;
        const userImage = StringUtils.isNullOrUndefined(userData.profileImage) ? NoProfileImage : `${userData.profileImage}`
        return (
            <>
                <header className="headerWrap-band">
                    <div className="headerContentsWrap-band">
                        {/* <Link className="logoWrap" to="/myBand" >
                                <img alt="band_menu_bg" style={{ width: '145px', height: '32px' }} src={ImgEWPBAND} />
                            </Link> */}
                        <Link to="/myBand" >
                            <LogoText>{COMPANY_NAME === "UC WARE" ? "MAIN" : COMPANY_NAME}</LogoText>
                        </Link>
                        <div className="gnbWrap-band"></div>
                        <ul className="lnbWrap-band">
                            <li className={pathName === 'home' ? 'menuLnb-band selectedLnb' : 'menuLnb-band normalLnb'}><Link to={"/band/" + this.props.bandId + "/home"} >{t('BandMenu.home')}  </Link></li>
                            {this.state.isBandMember &&
                                <>
                                    <li className={pathName === 'chat' ? 'menuLnb-band selectedLnb' : 'menuLnb-band normalLnb'}><a href={"/band/" + this.props.bandId + "/chat"} > {t('BandMenu.chatting')} </a></li>
                                    <li className={pathName === 'schedule' ? 'menuLnb-band selectedLnb' : 'menuLnb-band normalLnb'}><Link to={"/band/" + this.props.bandId + "/schedule"} > {t('BandMenu.schedule')}  </Link></li>
                                    <li className={pathName === 'contents' ? 'menuLnb-band selectedLnb' : 'menuLnb-band normalLnb'}><Link to={"/band/" + this.props.bandId + "/contents"} >{t('BandMenu.content')}   </Link></li>
                                    <li className={pathName === 'member' ? 'menuLnb-band selectedLnb' : 'menuLnb-band normalLnb'}><Link to={"/band/" + this.props.bandId + "/members"} >{t('BandMenu.member')}   </Link></li>
                                </>
                            }
                        </ul>
                        <ReactTooltip id="settingPage">
                            <span>{t('New.SettingPage')}</span>
                        </ReactTooltip>
                        <Link data-tip data-for={`settingPage`} className="userWrap-band" to={"/band/" + this.props.bandId + "/setting"} ><Avatar src={userImage} alt="profileImage" /></Link>
                    </div>
                </header>

                {this.state.isBandMember &&
                    <Menu>
                        <button onClick={this.routeHome}>
                            <img src={pathName === "home" ? btnHomeActive : btnHome} alt="button_home"
                                data-tip data-for={`button_home`} />
                            <ReactTooltip id={`button_home`} >
                                <span>{t('New.Home')}</span>
                            </ReactTooltip>
                        </button>
                        <button onClick={this.routeChat}>
                            <img src={pathName === "chat" ? btnChatActive : btnChat} alt="button_chat"
                                data-tip data-for={`button_chat`} />
                            <ReactTooltip id={`button_chat`} >
                                <span>{t('New.Conversation')}</span>
                            </ReactTooltip>
                        </button>
                        <button onClick={this.routeSchedule}>
                            <img src={pathName === "schedule" ? btnScheduleActive : btnSchedule} alt="button_schedule"
                                data-tip data-for={`button_schedule`} />
                            <ReactTooltip id={`button_schedule`} >
                                <span>{t('New.Schedule')}</span>
                            </ReactTooltip>
                        </button>
                        <button onClick={this.routeContents}>
                            <img src={pathName === "contents" ? btnContentsActive : btnContents} alt="button_contents"
                                data-tip data-for={`button_contents`} />
                            <ReactTooltip id={`button_contents`} >
                                <span>{t('New.Content')}</span>
                            </ReactTooltip>
                        </button>
                        <button onClick={this.routeMembers}>
                            <img src={pathName === "member" ? btnMemberActive : btnMember} alt="button_member"
                                data-tip data-for={`button_member`} />
                            <ReactTooltip id={`button_member`} >
                                <span>{t('New.Member')}</span>
                            </ReactTooltip>
                        </button>
                        <button onClick={this.routeSetting}>
                            <img src={pathName === "setting" ? btnSettingActive : btnSetting} alt="button_setting"
                                data-tip data-for={`button_setting`} />
                            <ReactTooltip id={`button_setting`} >
                                <span>{t('New.SettingPage')}</span>
                            </ReactTooltip>
                        </button>
                    </Menu>
                }
            </>
        )
    }
}

const Menu = styled.div`
    box-shadow: 0px 0px 7px 0px #5a838f82 !important;
    left: 0;
    z-index:5;
    position: fixed;
    bottom: -4px;
    max-width: 500px;
    width: 100%;
    height: auto !important;
    background-color: #FFF;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    button {
      display: block;
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
    }

    @media (min-width: 500px) {
        display: none;
      }
`;

const LogoText = styled.p`
    flex: none;
    width: 128px;
    height: 100%;
    padding: 0.6rem;
    box-sizing: border-box;
    display: inline-block;
    margin-right: 5px;
    margin-top: 2px;
    cursor: pointer;
    font-size: 1.7rem;
    font-weight: bold;
    color: #51a5c1;
    width: 100%;
`;

export default withRouter(withTranslation()(BandMenu));
