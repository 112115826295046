import React from 'react';

import FroalaEditor from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';

import 'froala-editor/js/languages/ko.js';
import 'froala-editor/js/languages/ja.js';
import 'froala-editor/js/languages/th.js';

import 'froala-editor/js/froala_editor.min.js';

import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';

import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/line_height.min.js';

import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';

import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/edit_in_popup.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/forms.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.min.css';

import 'froala-editor/css/plugins/colors.css';
import 'froala-editor/css/plugins/draggable.css';
import 'froala-editor/css/plugins/emoticons.css';
import 'froala-editor/css/plugins/file.css';
import 'froala-editor/css/plugins/image.css';
import 'froala-editor/css/plugins/image_manager.css';
import 'froala-editor/css/plugins/table.css';
import 'froala-editor/css/plugins/video.css';
import 'froala-editor/css/third_party/embedly.css';

import Modal from 'react-awesome-modal';
import { PollSettingModal } from '../Modal';
import { SERVER_URI } from "../../../configs";
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import './RichTextEditor.css';
import '../../../assets/css/_modal.scss';
import btnVote from '../../../assets/images/btn-vote.svg';
import { DB_KIND } from '../../../configs';

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class RichTextEditor extends React.Component {

    bandId;
    defaultValue;
    defaultPolls;
    onEditorChange;
    onPollsChange;
    _isMounted;

    constructor(props) {
        super(props);

        this.handleModelChange = this.handleModelChange.bind(this);

        Froalaeditor.DefineIconTemplate('font_awesome', `<img class="pollIcon" src=${btnVote} style=""></img>`);
        Froalaeditor.DefineIcon('poll', { NAME: 'vote-yea', template: 'font_awesome' });
        Froalaeditor.RegisterCommand('poll', {
            title: `${this.props.t('RichTextEditor.poll')}`,
            icon: 'poll',
            refreshAfterCallback: false,
            callback: () => {
                this.openPollSettingModal();
            }
        });

        this.config = {
            key: 'Lc2C1qD2E2E4E4C3B12hBd1a1c1Zd1IXBc1a1THTBPLIIWBORpI1A1A1C7A1A5E5A1I4I4==',
            placeholderText: `${this.props.t('RichTextEditor.pleaseEnterTheText')}`,
            charCounterCount: false,
            toolbarButtons: {
                'moreText': {
                    'buttons': ['paragraphFormat', 'bold', 'italic', 'underline', 'strikeThrough', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
                    'buttonsVisible': 5
                },
                // 'moreParagraph': {
                //     'buttons': ['formatOLSimple', 'quote', 'paragraphStyle', 'formatOL', 'formatUL', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'lineHeight', 'outdent', 'indent'],
                //     'buttonsVisible': 3
                // },
                'moreRich': {
                    'buttons': DB_KIND === "ORACLE" ? ['insertImage', 'insertFile'] : ['insertImage', 'insertVideo', 'insertFile', 'poll'],
                    // 'buttons': ['insertImage', 'insertVideo', 'insertFile', 'poll', 'embedly', 'insertLink', 'insertTable', 'specialCharacters', 'insertHR'],

                    'buttonsVisible': 4
                },
                'moreMisc': {
                    'buttons': ['undo', 'redo'],
                    'align': 'right',
                    'buttonsVisible': 2
                }
            },
            fontFamilySelection: true,
            fontSizeSelection: true,
            paragraphFormatSelection: true,
            imageMaxSize: Number(localStorage.getItem('maxFileSize')),
            videoMaxSize: Number(localStorage.getItem('maxFileSize')),
            fileMaxSize: Number(localStorage.getItem('maxFileSize')),
            fileAllowedTypes: ['*'],
            language: localStorage.getItem("i18nextLng") === "korean"  ? "ko" 
                                                                       : localStorage.getItem("i18nextLng") === "english" ? "en"
                                                                                                                          : localStorage.getItem("i18nextLng") === "japanese" ? "ja"
                                                                                                                                                                              : "th",
            events: {
                'file.beforeUpload': function (file) {
                    if (Number(localStorage.getItem('personalDiskUsage')) > Number(localStorage.getItem('maxPersonalDiskUsage')) ||
                        Number(localStorage.getItem('bandDiskUsage')) > Number(localStorage.getItem('maxDiskUsage'))) {
                        message.error(`파일 업로드 용량 제한`)
                        // message.error(`${this.props.t('RichTextEditor.fileUploadCapacityLimit')}`)
                        return false
                    }
                },
                'image.beforeUpload': function (image) {
                    if (Number(localStorage.getItem('personalDiskUsage')) > Number(localStorage.getItem('maxPersonalDiskUsage')) ||
                        Number(localStorage.getItem('bandDiskUsage')) > Number(localStorage.getItem('maxDiskUsage'))
                    ) {
                        message.error(`이미지 업로드 용량 제한`)
                        // message.error(`${this.props.t('RichTextEditor.imageUploadCapacityLimit')}`)
                        return false
                    }
                    // UserService.userUploadFileSize().then(response => { if (response.data.totalSize > MAX_SIZE_STORAGE) { return false } })
                },
                'video.beforeUpload': function (video) {
                    if (Number(localStorage.getItem('personalDiskUsage')) > Number(localStorage.getItem('maxPersonalDiskUsage'))
                        || Number(localStorage.getItem('bandDiskUsage')) > Number(localStorage.getItem('maxDiskUsage'))) {
                        message.error(`비디오 업로드 용량 제한`)
                        // message.error(`${this.props.t('RichTextEditor.videoUploadCapacityLimit')}`)
                        return false
                    }
                    // if (__ISMSIE__) {
                    //     return null;
                    // } else {
                    //     // Image too text-large.
                    //     const snapshoter = new VideoSnapshot(video[0]);
                    //     const previewSrc = await snapshoter.takeSnapshot();
                    //     localStorage.setItem('videoThumbnail', previewSrc)
                    // }
                },
                'video.uploaded': function (response) {
                    // 업로드 한후에 백엔드에서 주는 res 가 일로 온다.
                    // if (!__ISMSIE__) {
                    //     let splits = response.split(",");
                    //     // console.log(splits)
                    //     // url(http://49.236.136.36:5000/_UPLOAD_FILES/307/s_"a3f2a1b0-c0ad-449f-a8df-a0e5175ee8f8.png") center center

                    //     console.log(splits[0].substring(0, 10))

                    //     let bandId = splits[0].substring(10);
                    //     let fileId = splits[1].substring(9);
                    //     let fileName = splits[2].substring(12);

                    //     fileName = fileName.replace('"', "")

                    //     FileService.uploadVideoThumbnailNode(bandId, fileId, fileName, localStorage.getItem('videoThumbnail'))
                    //         .then(() => { localStorage.removeItem('videoThumbnail') })
                    //         .catch(error => console.error(error));
                    // }

                },
                'image.error': function (error, response) {
                    // Image too text-large.
                    if (error.code === 5) {
                        message.error(`${this.props.t('RichTextEditor.imageUploadCapacityLimit')}`)
                    }
                },
                'video.error': function (error, response) {
                    // Image too text-large.
                    if (error.code === 5) {
                        message.error(`${this.props.t('RichTextEditor.videoUploadCapacityLimit')}`)
                    }
                },
                'file.error': function (error, response) {
                    // Image too text-large.
                    if (error.code === 5) {
                        message.error(`${this.props.t('RichTextEditor.fileUploadCapacityLimit')}`)
                    }
                }
            },
            imageUploadURL: `${SERVER_URI}:5000/api/file/uploadForEditor?bandId=${this.props.bandId || localStorage.getItem("modifyBandId")}&userId=${localStorage.getItem('userId')}`,
            fileUploadURL: `${SERVER_URI}:5000/api/file/uploadForEditor?bandId=${this.props.bandId || localStorage.getItem("modifyBandId")}&userId=${localStorage.getItem('userId')}`,
            videoUploadURL: `${SERVER_URI}:5000/api/file/uploadForEditor?bandId=${this.props.bandId || localStorage.getItem("modifyBandId")}&userId=${localStorage.getItem('userId')}`,
        };

        this.state = {
            model: "",
            polls: [],
            pollIndex: 0,
            selectedPoll: undefined,
            isPollSettingModalOpen: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        __ISMSIE__ ? window.addEventListener('click', this.clickHandler) : window.addEventListener('click', this.clickHandler);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.defaultValue && !prevProps.defaultValue) {
            let polls = [];
            let pollIndex = 0;
            if (this.props.defaultPolls) {
                this.props.defaultPolls.forEach(poll => {
                    let pollOptions = [];
                    poll.pollOptions.forEach(pollOption => pollOptions.push(pollOption.content));
                    let convertedPoll = {
                        pollIndex: pollIndex,
                        title: poll.title,
                        allowAnonymous: poll.allowAnonymous,
                        pollStatusDisplayType: poll.pollStatusDisplayType,
                        allowMultipleSelection: poll.allowMultipleSelection,
                        multipleSelectionCount: poll.multipleSelectionCount,
                        dueDateTime: `${poll.pollDueDateTime}`,
                        pollOptionOrderType: poll.pollOptionOrderType,
                        pollOptions: pollOptions,
                    };
                    polls.push(convertedPoll);
                    pollIndex++;
                });
            }
            if (this._isMounted) {
                this.setState({
                    model: this.props.defaultValue,
                    polls: polls,
                    pollIndex: pollIndex,
                })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    openPollSettingModal = () => {
        this.setState({
            isPollSettingModalOpen: true,
        });
    };

    closePollSettingModal = () => {
        this.setState({
            isPollSettingModalOpen: false,
            selectedPoll: undefined,
        });
    };

    insertPoll = (poll) => {
        const pollIndex = this.state.pollIndex;

        if (this._isMounted) {
            this.setState({
                isPollSettingModalOpen: false,
                pollIndex: pollIndex + 1,
                polls: [...this.state.polls, poll],
                model: this.state.model + `<p class="poll-inner-post" id="${pollIndex}">${this.props.t('RichTextEditor.poll')} - ${poll.title}</p><p></p>`,
            }, () => { this.props.onPollsChange(this.state.polls) });
        }
    };

    updatePoll = (poll) => {
        let polls = [];
        this.state.polls.forEach(item => {
            if (Number(item.pollIndex) === Number(poll.pollIndex)) {
                polls.push(poll);
            } else {
                polls.push(item);
            }
        });

        if (this._isMounted) {
            this.setState({
                isPollSettingModalOpen: false,
                polls: polls
            }, () => { this.props.onPollsChange(this.state.polls) });
        }
    };

    clickHandler = (e) => {
        if (e && e.path) {
            const filtered = e.path.filter(pathItem => pathItem.className && pathItem.className === "poll-inner-post");
            if (filtered && filtered.length > 0) {
                // this.updatePoll(filtered[0].id);
                const selectedPollId = filtered[0].id;
                console.log(`selected poll --> ${selectedPollId}`);
                if (this._isMounted) {
                    const selectedPoll = this.state.polls.filter(poll => Number(poll.pollIndex) === Number(selectedPollId));
                    if (selectedPoll && selectedPoll.length > 0) {
                        this.setState({
                            selectedPoll: selectedPoll[0],
                            isPollSettingModalOpen: true,
                        })
                    }
                }
            }
        } else if (e && e.srcElement && e.srcElement.id) {
            const selectedPollId = e.srcElement.id;
            console.log(`selected poll --> ${selectedPollId}`);
            if (this._isMounted) {
                const selectedPoll = this.state.polls.filter(poll => Number(poll.pollIndex) === Number(selectedPollId));
                if (selectedPoll && selectedPoll.length > 0) {
                    this.setState({
                        selectedPoll: selectedPoll[0],
                        isPollSettingModalOpen: true,
                    })
                }
            }
        }
    };

    handleModelChange = (model) => {
        this.setState({
            model: model
        }, () => {
            this.props.onEditorChange(this.state.model);
        });
    };

    render() {
        return (
            <div>
                <div id="editor">
                    <FroalaEditor
                        config={this.config}
                        model={this.state.model}
                        onModelChange={this.handleModelChange}
                    />
                </div>
                <Modal width="80%" effect="fadeInUp" visible={this.state.isPollSettingModalOpen}>
                    <PollSettingModal pollIndex={this.state.pollIndex}
                        defaultValue={this.state.selectedPoll}
                        show={this.state.isPollSettingModalOpen}
                        onSubmit={this.insertPoll}
                        onUpdate={this.updatePoll}
                        onClose={this.closePollSettingModal}
                    />
                </Modal>
            </div>
        );
    }

}

export default withTranslation()(RichTextEditor);
