import React from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import btnUploadPic from '../../../../assets/images/btn-upload-pic.png';

class BandCoverSelector extends React.Component {

    _isMounted = false;

    cols;
    rows;
    onSelect;

    constructor(props) {
        super(props);

        this.state = {
            current: 1,
            file: undefined,
            imagePreviewUrl: undefined,
            files: [],
        };

        this.inputOpenFileRef = React.createRef();
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //Preview를 클릭해서 file의 state을 변경해서 Create.js Componets로 보낼때 이걸 해줘야 클릭할때마다 보내진다.
        if (prevState.file !== this.state.file) {
            this.selected();
        }
    }

    updateWindowDimensions() {
        if (this._isMounted) {
            this.setState({
                gridSize: window.innerWidth * 0.35 / this.props.cols,
            });
        }
    }

    prev = () => {
        if (this.state.current > 1) {
            let preCoverPage = this.state.current - 1;
            if (this._isMounted) {
                this.setState({
                    current: preCoverPage,
                });
            }
        }
    };

    next = () => {
        if (this.state.current < this.state.total) {
            let nextCoverPage = this.state.current + 1;
            if (this._isMounted) {
                this.setState({
                    current: nextCoverPage,
                });
            }
        }
    };

    openFileDialog = (e) => {
        e.preventDefault();
        this.inputOpenFileRef.current.click();
    };

    handleImagePreview = (e) => {
        e.stopPropagation();
        e.preventDefault();

        let file = e.currentTarget.files[0];

        //이것 없으면 파일 하나 올리고 다시 하나 올릴려고 팝업 창 띄운후에 취소 하면 에러남.
        if (!file) { return null; }

        if (file.type !== "image/png" && file.type !== "image/jpeg") {
            return message.warning(`${this.props.t('BandCoverSelector.uploadableImageFileType')} : jpeg, png`)
        }

        if (file.size > Number(localStorage.getItem('maxBandCoverImageSize'))) {
            message.warning(`${this.props.t('BaBandCoverSelectornd.imageUploadCapacityLimit')} : ${localStorage.getItem('maxBandCoverImageSize')}MB`);
            e.currentTarget.files = undefined;
            if (this._isMounted) {
                this.setState({
                    file: undefined,
                    imagePreviewUrl: undefined,
                });
            }
            return;
        }

        let reader = new FileReader();
        reader.onloadend = () => {
            if (this._isMounted) {

                this.setState({
                    //files를 넣는 이유는 이걸로 handle Click할때 부모 components에 file을 보내야 하기 때문이다. 
                    files: [...this.state.files, file],
                    file: file,
                    imagePreviewUrl: reader.result
                }, this.selected);
            }
        };

        reader.readAsDataURL(file);
    };

    handleFilePick = (e) => {

        let index = Number(e.currentTarget.alt.substr(6));

        if (this._isMounted) {
            this.setState({
                file: this.state.files[`${index - 1}`],
                imagePreviewUrl: e.currentTarget.src,
            });
        }
    };

    selected = () => {
        this.props.onSelect(this.state.file);
    };

    render() {
        const { imagePreviewUrl } = this.state;
        const { t } = this.props;

        let imagePreview = null;
        if (imagePreviewUrl) {
            imagePreview = (<img src={imagePreviewUrl} alt="_Preview" style={{ width: '100%', maxHeight: '148px', height: '100%', objectFit: "cover", borderRadius: "0.5em" }} />);
        } else {
            imagePreview = (<div></div>);
        }

        return (
            <Selector>
                <Header>
                    <div style={{ width: '50%', float: 'left' }}>
                        <Title>{t('BandCoverSelector.BandCoverSettings')}</Title>
                    </div>
                </Header>

                <Body>
                    <div style={{ width: '35%' }}>
                        {imagePreview}
                    </div>
                    <div style={{ width: '30%' }} />
                    <div style={{ width: '35%' }}>
                        <img style={{
                            width: '100%',
                            cursor: 'pointer'
                        }} onClick={this.openFileDialog}
                            src={btnUploadPic} alt="btnUploadPic" />
                    </div>
                </Body>

                <input type="file" accept="image/*" ref={this.inputOpenFileRef} style={{ display: "none" }} onChange={this.handleImagePreview} />

            </Selector>
        );
    }

}

const Selector = styled.div`
    padding: 0.6rem 0;
`;

const Header = styled.div`
    padding-bottom: 1rem;
    display: flex;
    flex-grow: 1;
`;

const Body = styled.div`
    overflow-y: hidden;
    max-height: 100%;
    padding: 0 0rem 1rem;
    display: flex;
`;

const Title = styled.div`
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    span {
      width: 2rem;
      display: inline-block;
      text-align: center;
    }
`;

export default withTranslation()(BandCoverSelector);
