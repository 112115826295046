import React from 'react';
import styled from 'styled-components';

import { PostList } from '../../_Post';
// import { TagList } from '../../../common/components/Tag';
// import { MemberList } from '../../../common/components/Member';
import { BandMenu, BandTopButtons } from '../../Menu';

import * as BandService from '../../../services/BandService';
import * as PostService from '../../../services/PostService';
import * as DateTimeUtils from '../../../utils/DateTimeUtils';
import * as CommonUtils from '../../../utils/CommonUtils';
import { POST_PAGE_SIZE, THEME_BACKGROUND_COLOR } from "../../../configs";
import { Input } from 'antd';
import { withTranslation } from 'react-i18next';
// import Spinner from '../../../common/components/Shared/Spinner';
import Empty from '../../../common/components/Shared/Empty';

const imgNotInput = require('../../../assets/images/img-no-input.svg');
const { Search } = Input;

class SearchInBand extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            searchType: 0,
            searchKeyword: '',
            searchMember: undefined,
            postList: [],
            page: 0,
            lastPage: true,
            allMemberList: [],
            memberList: [],
            selectedMember: undefined,
            allTagList: [],
            tagList: [],
            pageSize: 0,
            hasTyped: false
        };
    }

    componentDidMount() {
        this._isMounted = true;

        this.loadBandInfo();

        const bandId = this.props.match.params["bandId"];
        const bandMemberRoles = [0, 1];
        let members, tags;
        BandService.getBandMembersNode(bandId, bandMemberRoles)
            .then(memberList => members = memberList)
            // .then(() => PostService.getBandTags(bandId))
            // .then(tagList => tags = tagList.content)
            .then(() => {

                if (this._isMounted) {
                    this.setState({
                        memberList: members,
                        allMemberList: members,
                        tagList: tags,
                        allTagList: tags,
                    });
                }
            })
            .catch(error => console.error(error));
    }

    loadBandInfo = () => {
        BandService.getBandInfo(this.props.match.params["bandId"])
            .then(result => {
                if (this._isMounted) {
                    this.setState({
                        bandName: result.band.name,
                    });
                }
            })
            .catch(error => console.error(error));
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    searchInput = (e) => {
        const text = e.currentTarget.value;

        this.changeSearchType(0, text);

        // if (CommonUtils.startsWith(text, '#')) {
        //     this.changeSearchType(1, text);
        //     this.searchTag(text.substring(1));
        // } else if (CommonUtils.startsWith(text, '@')) {
        //     this.changeSearchType(2, text);
        //     this.searchMember(text.substring(1));
        // } else {
        //     this.changeSearchType(0, text);
        // }
    };

    changeSearchType = (value, text) => {
        if (this._isMounted) {
            this.setState({
                searchType: value,
                searchKeyword: text,
                selectedMember: undefined,
            });
        }
    };

    searchMember = (text) => {
        let found;
        if (text && text.trim().length > 0) {
            found = this.state.allMemberList.filter(member => CommonUtils.contains(member.name, text));
        } else {
            found = this.state.allMemberList;
        }
        if (this._isMounted) {
            this.setState({
                memberList: found,
            });
        }
    };

    searchTag = (text) => {
        let found;
        if (text && text.trim().length > 0) {
            found = this.state.allTagList.filter(tag => CommonUtils.contains(tag.tag, text));
        } else {
            found = this.state.allTagList;
        }
        if (this._isMounted) {
            this.setState({
                tagList: found,
            });
        }
    };

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (!this.state.searchKeyword.trim()) return alert('먼저 검색어를 입력해주세요.')
            this.setState({ hasTyped: true })
            PostService.searchPostByBandNode(this.props.match.params["bandId"], this.state.searchKeyword, DateTimeUtils.formattedCurrent(), 1, POST_PAGE_SIZE)
                .then(response => this.setState({
                    postList: response.content,
                    lastPage: response.last,
                    pageSize: response.content.length
                }))
                .catch(error => console.error(error));
        }
    };

    onSearch = () => {
        if (!this.state.searchKeyword.trim()) return alert('먼저 검색어를 입력해주세요.')
        this.setState({ hasTyped: true })
        PostService.searchPostByBandNode(this.props.match.params["bandId"], this.state.searchKeyword, DateTimeUtils.formattedCurrent(), 1, POST_PAGE_SIZE)
            .then(response => this.setState({
                postList: response.content,
                lastPage: response.last,
                pageSize: response.content.length
            }))
            .catch(error => console.error(error));
    }

    back = () => {
        this.props.history.goBack();
    };

    route = (url) => {
        this.props.history.push(url);
    };

    routePostOrBand = (bandId, postId) => {
        if (bandId && postId) {
            this.route("/band/" + bandId + "/post/" + postId);
        } else if (bandId) {
            this.route("/band/" + bandId);
        } else {
            alert("BandId & PostId are undefined");
        }
    };

    // searchPostByMember = (memberId) => {
    //     if (this._isMounted) {
    //         PostService.searchPostByBandAndUser(this.props.match.params["bandId"], memberId, DateTimeUtils.formattedCurrent(), 0, POST_PAGE_SIZE)
    //             .then(response => this.setState({
    //                 selectedMember: memberId,
    //                 postList: response.content,
    //                 lastPage: response.last,
    //             }))
    //     }
    // };

    nextPage = () => {
        if (this._isMounted) {
            const current = DateTimeUtils.formattedCurrent();
            const page = this.state.page + 1;
            let postList = this.state.postList;

            PostService.searchPostByBandNode(this.props.match.params["bandId"], this.state.searchKeyword, current, page, POST_PAGE_SIZE)
                .then(response => {
                    if (response.content) {
                        response.content.forEach(content => postList.push(content));
                    }
                    this.setState({
                        postList: postList,
                        pageSize: response.content.length,
                        page: page
                    });
                })
                .catch(error => {
                    alert(`Failed to load list. - ${error}`);
                });


            // if (this.state.searchType === 1) {
            //     // TODO
            // } else if (this.state.searchType === 2) {
            //     PostService.searchPostByBandAndUser(this.props.match.params["bandId"], this.state.selectedMember, current, page, POST_PAGE_SIZE)
            //         .then(response => {
            //             if (response.content) {
            //                 response.content.forEach(content => postList.push(content));
            //             }
            //             this.setState({
            //                 postList: postList,
            //                 lastPage: response.last,
            //                 page: page
            //             });
            //         })
            //         .catch(error => {
            //             alert(`Failed to load list. - ${error}`);
            //         });
            // } else {
            //     PostService.searchPostByBand(this.props.match.params["bandId"], this.state.searchKeyword, current, page, POST_PAGE_SIZE)
            //         .then(response => {
            //             if (response.content) {
            //                 response.content.forEach(content => postList.push(content));
            //             }
            //             this.setState({
            //                 postList: postList,
            //                 lastPage: response.last,
            //                 page: page
            //             });
            //         })
            //         .catch(error => {
            //             alert(`Failed to load list. - ${error}`);
            //         });
            // }
        }
    };

    render() {
        const { history, t } = this.props;
        const { searchType, postList, hasTyped, bandName
            // memberList, tagList, lastPage,  
        } = this.state;

        return (
            <div className="wrapper">

                <BandTopButtons bandId={this.props.match.params["bandId"]} bandName={bandName} fromPage={`${t('HomeMenuBottons.search')}`} historyProp={history} />

                <div className="contentsWrap">

                    <TitleWrapper>
                        <Title>
                            {t('SearchInBand.search')}
                        </Title>
                        <ButtonWrapper>
                            <button onClick={this.back} style={{ padding: '0.5rem' }}>
                                {t('SearchInBand.cancel')}
                            </button>
                        </ButtonWrapper>
                    </TitleWrapper>

                    <div style={{ padding: '1rem' }} >
                        <Search
                            style={{ width: '100%' }}
                            type="text"
                            onSearch={this.onSearch}
                            placeholder={t('SearchInBand.fullSearch')}
                            onChange={this.searchInput}
                            onKeyDown={this.onKeyDown}
                        />
                    </div>

                    <ResultArea>
                        <ResultWrapper>
                            {!hasTyped ?
                                <NoSearchStart t={t} />
                                :
                                <>
                                    {searchType === 0 &&
                                        <div>
                                            {postList && postList.length > 0 ?
                                                <div>
                                                    <PostList search bandId={this.props.match.params["bandId"]} postList={postList} routeFunction={this.routePostOrBand} />
                                                    {this.state.pageSize === 20 &&
                                                        <>
                                                            <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
                                                                <button style={{ padding: '1rem', borderRadius: '5px', background: `${THEME_BACKGROUND_COLOR}`, color: 'white', width: '100px', fontWeight: 'bold' }} onClick={this.nextPage}>
                                                                    {t('SearchInBand.loadMore')}
                                                                </button>
                                                            </div>
                                                        </>
                                                    }
                                                </div> :
                                                <Empty description={"No band found"} />
                                            }
                                        </div>
                                    }
                                    {/* {searchType === 1 &&
                                <TagListWrapper>
                                    <TagListTitle>이 협업에서 많이 쓰는 태크</TagListTitle>
                                    <TagList tagList={tagList} />
                                </TagListWrapper>
                            } */}
                                    {/* {searchType === 2 &&
                                <div>
                                    <MemberListWrapper>
                                        <MemberList memberList={memberList} routeFunction={this.searchPostByMember} />
                                    </MemberListWrapper>
                                    {memberList && memberList.length > 0 ?
                                        <div>
                                            <PostList search bandId={this.props.match.params["bandId"]} postList={postList} routeFunction={this.routePostOrBand} />
                                            {!lastPage &&
                                                <button onClick={this.nextPage}>더보기</button>
                                            }
                                        </div> :
                                        <NoResult />
                                    }
                                </div>
                            } */}
                                </>
                            }
                        </ResultWrapper>
                    </ResultArea>
                    <BandMenu pathName="search" bandId={this.props.match.params["bandId"]} historyProp={history} />
                </div>
            </div>
        );
    }

}

class NoSearchStart extends React.Component {

    render() {
        const { t } = this.props;

        const NoResultContainer = styled.div`
            text-align: center;
            width: 100%;
            height: 100%;
            padding-top: 7rem;
        `;

        const NoResultTitle = styled.div`
            width: 100%;
            font-size: 2rem;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.27;
            letter-spacing: normal;
            color: ${THEME_BACKGROUND_COLOR};
            padding: 0 0 .5rem 0;
            display: inline-block;
            text-align: center;
            span {
              width: fit-content;
            }
        `;

        const NoResultDescription = styled.div`
            width: 100%;
            font-size: 1.2rem;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: ${THEME_BACKGROUND_COLOR};
            padding: 0 0 2rem 0;
        `;

        return (
            <NoResultContainer>
                <NoResultTitle>
                    <span>{t('SearchInBand.youHaventEntered')}<br />{t('SearchInBand.yourSearchTermsYet')}</span>
                </NoResultTitle>
                <NoResultDescription>{t('SearchInBand.pleaseEnterWhatYouAreLookingForInTheTopColumn')}</NoResultDescription>
                <div>
                    <img src={imgNotInput} alt="No Input" />
                </div>
            </NoResultContainer>
        );
    }

}

const TitleWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
    padding: 1rem;
`;

const Title = styled.div`
    width: 50%;
    font-size: 2rem;
`;

const ButtonWrapper = styled.div`
    width: 50%;
    button {
      float: right;
    }
`;

const ResultArea = styled.div`
    position: relative;
    display: table;
    height: auto !important;
    width: 98%;
    margin: 0 auto;
`;

const ResultWrapper = styled.div`
    display: table-cell;
    vertical-align: middle;
`;

export default withTranslation()(SearchInBand);
