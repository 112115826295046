import {
    SET_DRAG_BAND_LIST
} from '../_actions/types';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_DRAG_BAND_LIST:
            return { ...state, drags: action.payload }
        default:
            return state;
    }
}



