import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { ContentDisplaySettingToolTip } from '../../../common/components/ToolTip';
import { Grid } from 'semantic-ui-react';
import { THEME_BACKGROUND_COLOR } from '../../../configs';

function TopFilterButtons({ selectImage, selectVideo, selectAll, selectFile, selectContentCategory, selectMenu }) {
    const { t } = useTranslation();
    return (
        <TopFileFilterButtons>
            <Grid>
                <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column width={14} style={{ display: 'flex', alignItems: 'center' }}>
                        <Buttons>
                            <Button active={selectAll} id="selectAll" onClick={selectContentCategory}>{t('Contents.All')}</Button>
                            <Button active={selectImage} id="selectImage" onClick={selectContentCategory}>{t('Contents.picture')}</Button>
                            <Button active={selectVideo} id="selectVideo" onClick={selectContentCategory}>{t('Contents.video')}</Button>
                            <Button active={selectFile} id="selectFile" onClick={selectContentCategory}>{t('Contents.file')}</Button>
                        </Buttons>
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <div style={{ float: 'right' }}>
                            <ContentDisplaySettingToolTip select={selectMenu} />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </TopFileFilterButtons>
    )
}

export default TopFilterButtons

const TopFileFilterButtons = styled.div`
    padding: 1rem 1rem 0 1rem;
`;


const Buttons = styled.div`
`;

const Button = styled.button`
        padding: 0.4rem;
        margin-right: 0.6rem;
        font-size: 1.2rem;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: 0.1rem;
        text-align: left;
        color: ${props => props.active ? "#FFF" : `${THEME_BACKGROUND_COLOR}`} ;
        border: solid 1px;
        border-radius: 0.3rem;
        background-color: ${props => props.active ? `${THEME_BACKGROUND_COLOR}` : "#FFF"};

        @media (max-width: 400px) {
            padding: 0.3rem;
            margin: 0.2;
            font-size: 1rem;
        }  
`;
