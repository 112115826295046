import React from 'react'
import { useTranslation } from 'react-i18next';
import { viewOrderTypeOptions } from '../../../configs';

import styled from "styled-components";
import radioUncheckedImg from "../../../assets/images/radio-unchecked.svg";
import radioCheckedImg from "../../../assets/images/radio-checked.svg";

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;
const __ISIOS__ = navigator.userAgent.match(/iPad|iPhone|iPod/i) ? true : false;

function ContentViewOrderTypeSelectModal({ viewOrderTypeOption, selectViewOrderType, completeSelectViewOrderType, modalClose }) {
    const { t } = useTranslation();
    const currentLanguage = localStorage.getItem("i18nextLng");

    return (
        <div className="modal_wrap">
            <div className="modal">
                <button className="close_btn" aria-label="모달창 닫기 버튼" onClick={modalClose}></button>
                <div>
                    <p className="title">{t('Contents.selectDefaultSort')}</p>
                    <div className="modal_content view_order">
                        {viewOrderTypeOptions.map((option, index) =>
                            <div key={`radio-select-option-${index + 1}`}>
                                <label>
                                    <InputRadio
                                        isIOS={__ISIOS__}
                                        isMSIE={__ISMSIE__}
                                        type="radio"
                                        value={option.value}
                                        checked={viewOrderTypeOption === option.value}
                                        onChange={selectViewOrderType}
                                    />
                                    {currentLanguage === "korean" ? option.label : currentLanguage === "english" ? option.eng : option.ja}
                                </label>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <button className="modal_submit_btn" onClick={completeSelectViewOrderType}>
                        {t('Contents.submit')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ContentViewOrderTypeSelectModal

const InputRadio = styled.input`
    width: 17px;
    -webkit-appearance: none;
    background: ${props => props.isMSIE || props.isIOS ? null : `url(${radioUncheckedImg})`}; 
    background-size: 15px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 0px 5px;
    margin-bottom: -3px;
    margin-right: 3px;
    margin-top: 5px;

    &:checked {
    background: ${props => props.isMSIE || props.isIOS ? null : `url(${radioCheckedImg})`};
    width: 17px;
    -webkit-appearance: none;
    background-size: 15px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 0px 5px;
    margin-bottom: -3px;
    margin-right: 3px;
    margin-top: 5px;
    }
`;
