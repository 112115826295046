import React from "react";
import BandCategoryGrid from "./Sections/BandCategoryGrid";
import BandSingleCoverSelector from "./Sections/BandSingleCoverSelector";
import ReactTooltip from "react-tooltip";
import {
  RadioButtonSelect,
  ToggleButtonSelect,
} from "../../../common/components/Select";

import * as BandService from "../../../services/BandService";
import * as DateTimeUtils from "../../../utils/DateTimeUtils";

import { bandCategoryList, bandCategoryListEng, bandCategoryListJap, bandCategoryListThai } from "../../../data";
import { Input, Button } from "antd";
import { withTranslation } from "react-i18next";
import DialogComp from "../../../common/components/Shared/DialogComp";
import "./BandCreatePage.css";
import { COMPANY_NAME } from "../../../configs";

import btnPrev from "../../../assets/images/btn_prev.svg";

const { TextArea } = Input;
const currentLanguage = localStorage.getItem("i18nextLng");

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;
const __ISIOS__ = navigator.userAgent.match(/iPad|iPhone|iPod/i) ? true : false;
class BandCreate extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      bandCategory: undefined,
      bandName: "",
      bandDescription: "",
      bandCoverImageFile: undefined,
      bandOpenType: 0,
      needToApprove: false,
      submitted: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  back = () => {
    let prevStep = this.state.currentStep - 1;

    if (prevStep === 0) {
      this.cancel();
    } else {
      if (this._isMounted) {
        this.setState({
          currentStep: prevStep,
        });
      }
    }
  };

  cancel = () => {
    if (this._isMounted) {
      this.setState({
        currentStep: 1,
        bandCategory: undefined,
      });
    }

    this.props.history.push("/myBand");
  };

  selectBandCategory = (bandCategoryId) => {
    if (this._isMounted) {
      this.setState({
        currentStep: 2,
        bandCategory: bandCategoryId,
      });
    }
  };

  inputName = (e) => {
    if (this._isMounted) {
      if (
        e.currentTarget.value.length <=
        Number(localStorage.getItem("maxBandNameLength"))
      ) {
        this.setState({
          bandName: e.currentTarget.value,
        });
      } else {
        e.currentTarget.value = this.state.bandName;
      }
    }
  };

  inputDescription = (e) => {
    if (
      e.currentTarget.value.length <=
      Number(localStorage.getItem("maxBandDescriptionLength"))
    ) {
      if (this._isMounted) {
        this.setState({
          bandDescription: e.currentTarget.value,
        });
      }
    } else {
      e.currentTarget.value = this.state.bandDescription;
    }
  };

  selectBandCoverImage = (file) => {
    if (this._isMounted) {
      this.setState({
        bandCoverImageFile: file,
      });
    }
  };

  handleChange = (e) => {
    console.log(`value: `, e.target.value);
    if (this._isMounted) {
      this.setState({
        bandOpenType: Number(e.target.value),
      });
    }
  };

  handleToggleChange = (e) => {
    if (this._isMounted) {
      this.setState({
        needToApprove: !this.state.needToApprove,
      });
    }
  };

  routeBandHome = (bandId) => {
    this.props.history.push("/band/" + bandId + "/home");
  };

  submit = () => {
    const { bandName } = this.state;
    if (!bandName) {
      return alert(
        `${`${this.props.t("Band.Create.pleaseEnterTheBandNameFirst")}`}`
      );
    }
    if (this._isMounted) {
      this.setState({ submitted: true }, this.requestCreateBand);
    }
  };

  requestCreateBand = () => {
    const {
      bandCategory,
      bandName,
      bandDescription,
      bandCoverImageFile,
      bandOpenType,
      needToApprove,
    } = this.state;

    BandService.createBandNode(
      bandCategory,
      bandName,
      bandDescription,
      bandCoverImageFile,
      bandOpenType,
      needToApprove,
      DateTimeUtils.formattedCurrent()
    )
      .then((response) => setTimeout(() => this.routeBandHome(response), 1000))
      .catch((error) => {
        alert(error);
        if (this._isMounted) {
          this.setState({ submitted: false });
        }
      });
  };

  render() {
    const { currentStep } = this.state;
    const { t } = this.props;
    if (currentStep === 1) {
      return (
        <div className="band-create-wrapper">
          <div
            className="band-create-contentsWrap make-band set-cat"
            style={{ maxWidth: "500px" }}
          >
            <div className="con-header">
              <span className="roll-back">
                <img onClick={this.back} src={btnPrev} alt="back" />
              </span>
              <h6 className="page-title">{t("Band.Create.createBand")}</h6>
              <span className="page-title-sub">{t("New.ChooseCategory")}</span>
            </div>
            <BandCategoryGrid
              // bandCategoryList={bandCategoryList}
              bandCategoryList={
                currentLanguage === "korean"
                  ? bandCategoryList
                  : bandCategoryListEng
              }
              routeFunction={this.selectBandCategory}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="band-create-wrapper">
          <div
            className="band-create-contentsWrap make-band set-cat"
            style={{ maxWidth: "500px" }}
          >
            <div className="con-header">
              <span className="roll-back">
                <img onClick={this.cancel} src={btnPrev} alt="back" />
              </span>
              <h6 className="page-title">{t("Band.Create.createBand")}</h6>
              <span className="page-title-sub">{t("New.DetailedSetting")}</span>
            </div>
            <Input
              placeholder={t("Band.Create.pleaseEnterTheBandName")}
              onChange={this.inputName}
            />
            <br />
            <br />
            <TextArea
              placeholder={t("Band.Create.pleaseEnterTheBandDescription")}
              onChange={this.inputDescription}
            />
            <br />
            <br />
            <BandSingleCoverSelector onSelect={this.selectBandCoverImage} />

            <div className="text-left pb-2 text-base font-bold">
              {t("Band.Create.meetingDisclosureSettings")}
            </div>
            <div className="my-2">
              <label className="flex align-baseline">
                <input
                  type="radio"
                  value={0}
                  onChange={this.handleChange}
                  checked={this.state.bandOpenType === 0}
                  className={`${!__ISIOS__ &&
                    !__ISMSIE__ &&
                    `uc-radio`} w-5 h-6 mr-1`}
                />
                <span data-tip data-for={`happyface-1`}>
                  {this.props.t(`Band.Create.revealAll`)}
                </span>
                <ReactTooltip id={`happyface-1`} place="right" type="error">
                  <span>{this.props.t(`Band.Create.revealAllTooltip`)}</span>
                </ReactTooltip>
              </label>
            </div>
            <div className="my-2">
              <label className="flex align-baseline">
                <input
                  type="radio"
                  value={1}
                  onChange={this.handleChange}
                  checked={this.state.bandOpenType === 1}
                  className={`${!__ISIOS__ &&
                    !__ISMSIE__ &&
                    `uc-radio`} w-5 h-6 mr-1`}
                />
                <span data-tip data-for={`happyface-2`}>
                  {t(`Band.Create.nameDisclosure`)}
                </span>
                <ReactTooltip id={`happyface-2`} place="right" type="success">
                  <span>{t(`Band.Create.revealNameTooltip`)}</span>
                </ReactTooltip>
              </label>
            </div>
            <div className="my-2">
              <label className="flex align-baseline">
                <input
                  type="radio"
                  value={2}
                  onChange={this.handleChange}
                  checked={this.state.bandOpenType === 2}
                  className={`${!__ISIOS__ &&
                    !__ISMSIE__ &&
                    `uc-radio`} w-5 h-6 mr-1`}
                />
                <span data-tip data-for={`happyface-3`}>
                  {this.props.t(`Band.Create.private`)}
                </span>
                <ReactTooltip id={`happyface-3`} place="right" type="info">
                  <span>{this.props.t(`Band.Create.privateTooltip`)}</span>
                </ReactTooltip>
              </label>
            </div>
            <br />
            {COMPANY_NAME !== "무역센터" && (
              <>
                <ToggleButtonSelect
                  title={t("Band.Create.joinBandSettings")}
                  name="needToApprove"
                  defaultValue={false}
                  onChange={this.handleToggleChange}
                />
                <br />
              </>
            )}
            <Button
              size="large"
              style={{ width: "100%" }}
              disabled={this.state.submitted}
              onClick={this.submit}
            >
              {t("Band.Create.createBand")}
            </Button>
            <DialogComp
              description={t("Band.Create.bandIsBeingCreated")}
              open={this.state.submitted}
            />
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(BandCreate);
