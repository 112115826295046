import React from 'react'
import { Link } from 'react-router-dom'
import { FiChevronsLeft } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';
import { FiChevronsRight } from 'react-icons/fi';
import { FiChevronRight } from 'react-icons/fi';
import "./Pagination.css"
function Pagination({pager}) {
    return (
        <div className="pb-0 pt-3">
            {pager.pages && pager.pages.length > 0 &&
                <ul className="pagination">

                    <li className={`page-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                        <Link to={{ search: `?page=1#top` }} className="page-link"><FiChevronsLeft /></Link>
                    </li>

                    <li className={`page-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                        <Link to={{ search: `?page=${pager.currentPage - 1}#top` }} className="page-link"><FiChevronLeft /></Link>
                    </li>

                    {pager.pages.map(page =>
                        <li key={page} className={`page-item ${pager.currentPage === page ? 'active' : ''}`}>
                            <Link to={{ search: `?page=${page}#top` }} className="page-link">{page}</Link>
                        </li>
                    )}

                    <li className={`page-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                        <Link to={{ search: `?page=${pager.currentPage + 1}#top` }} className="page-link"><FiChevronRight /></Link>
                    </li>

                    <li className={`page-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                        <Link to={{ search: `?page=${pager.totalPages}#top` }} className="page-link"><FiChevronsRight /></Link>
                    </li>

                </ul>
            }
        </div>
    )
}

export default Pagination
