import React from 'react';
import * as FileService from '../../../services/FileService';
import { removePathFromFileName } from '../../../utils/CommonUtils';
import { SERVER_URI, COMPANY_NAME } from '../../../configs';
import { withTranslation } from 'react-i18next';

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class ContentViewer extends React.Component {

    _isMounted = false;
    bandId;
    item;
    onClose;
    refreshContents;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    delete = () => {
        const result = window.confirm(`${this.props.t('ContentViewer.areYouSureYouWantToDelete')}`);
        if (result) {
            FileService.removeFileNode(this.props.bandId, this.props.item.id)
                .then(() => this.props.refreshContents())
                .catch(error =>
                    alert(`${this.props.t('ContentViewer.youCanOnlyDeleteFilesYouUpload')}`),
                    this.props.onClose()
                );
        }
    };

    toDataURL = (url, fileName) => {
        return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                return window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                return URL.createObjectURL(blob);

            }
        });
    }

    download = async () => {

        if (__ISMSIE__ && COMPANY_NAME !== "EWP") {
            const link = document.createElement('a');
            link.href = await this.toDataURL(`${SERVER_URI}:5000/_UPLOAD_FILES/${this.props.bandId}/${this.props.detail ? this.props.item : this.props.item.fileName}`, `${this.props.detail ? this.props.item : this.props.item.fileName}`);
            this.close();
        }
        else if (__ISMSIE__ && COMPANY_NAME !== "UC WARE") {
            if (navigator.msSaveBlob) {

                fetch(`${SERVER_URI}:5000/_UPLOAD_FILES/${this.props.bandId}/${this.props.detail ? this.props.item : this.props.item.fileName}`)
                    .then(res => res.blob()) // Gets the response and returns it as a blob
                    .then(blob => {
                        return navigator.msSaveBlob(blob, `${this.props.detail ? this.props.item : this.props.item.fileName}`);
                        // Here's where you get access to the blob
                        // And you can use it for whatever you want
                        // Like calling ref().put(blob)

                        // Here, I use it to make an image appear on the page
                        //   let objectURL = URL.createObjectURL(blob);
                        //   let myImage = new Image();
                        //   myImage.src = objectURL;
                        //   document.getElementById('myImg').appendChild(myImage)
                    });
            }
        }
        else {
            const link = document.createElement('a');
            link.href = await this.toDataURL(`${SERVER_URI}:5000/_UPLOAD_FILES/${this.props.bandId}/${this.props.detail ? this.props.item : this.props.item.fileName}`, `${this.props.detail ? this.props.item : this.props.item.fileName}`);
            link.download = `${this.props.detail ? this.props.item : this.props.item.fileName}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    };

    close = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const { bandId, item, t } = this.props;

        if (!item) {
            return null;
        }

        const url = `${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${this.props.detail ? this.props.item : item.fileName}`;
        const name = removePathFromFileName(item.originalFileName);
        var type = item.fileType;
        if (this.props.detail) {
            type = "IMAGE"
        }

        if (this.props.detail) {
            return (
                this.props.item !== undefined ?
                    <div>
                        <div style={{ textAlign: 'center', padding: '1rem' }}>
                            <button onClick={this.close}>{t('ContentViewer.close')}</button>&nbsp;&nbsp; |
                            &nbsp;&nbsp;

                                <button onClick={this.download}>{t('ContentViewer.download')}</button>

                        </div>
                        {this.props.item ?
                            <img src={url} style={{ width: "100%", objectFit: "cover", maxHeight: '350px', maxWidth: 350 }} alt="haha" /> :
                            null
                        }
                    </div> : null
            )
        } else if (this.props.fromChat) {
            return (
                <div>
                    <div style={{ textAlign: 'center', padding: '1rem' }}>
                        <button onClick={this.close}>{t('ContentViewer.close')}</button>&nbsp;&nbsp; |
                    &nbsp;&nbsp;
                    {/*  <button onClick={this.delete}>삭제</button>&nbsp;&nbsp; |
                    &nbsp;&nbsp; */}
                        <button onClick={this.download}>{t('ContentViewer.download')}</button>
                    </div>
                    {item.type === 1 ?
                        <img src={url} style={{ width: "100%", objectFit: "cover", maxHeight: '350px' }} alt={name} /> :
                        <video webkit-playsinline="true" src={url} style={{ width: "100%", maxHeight: '350px', maxWidth: 350 }} controls />
                    }
                </div>
            );
        } else {
            return (
                <div>
                    <div style={{ textAlign: 'center', padding: '1rem' }}>
                        <button onClick={this.close}>{t('ContentViewer.close')}</button>&nbsp;&nbsp; |
                        &nbsp;&nbsp;
                        {/*  <button onClick={this.delete}>삭제</button>&nbsp;&nbsp; |
                        &nbsp;&nbsp; */}
                        <button onClick={this.download}>{t('ContentViewer.download')}</button>
                    </div>
                    {type === "IMAGE" ?
                        <img src={url} style={{ width: "100%", objectFit: "cover", maxHeight: '350px', maxWidth: 350 }} alt={name} /> :
                        <video webkit-playsinline="true" src={url} style={{ width: "100%", maxHeight: '350px' }} controls />
                    }
                </div>
            );
        }
    }

}

export default withTranslation()(ContentViewer);
