import React from 'react';
import styled from 'styled-components';

import NoticeAllList from './Sections/NoticeAllList';
import { BandTopButtons, BandMenu } from '../../Menu';
import { withTranslation } from 'react-i18next';

import * as PostService from '../../../services/PostService';
import * as BandService from "../../../services/BandService";
const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

class Notice extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        const bandId = this.props.match.params["bandId"];
        this.state = {
            bandId: bandId,
            band: undefined,
            isBandLeader: false,
            isBandMember: false,
            isOpened: false,
            noticeList: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBandInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandInfo = () => {
        BandService.getBandInfo(this.state.bandId)
            .then(result => {
                if (this._isMounted) {
                    this.setState({
                        band: result.band,
                        isBandLeader: result.isBandLeader,
                        isBandMember: result.isBandMember,
                        isOpened: result.isOpened,
                    }, this.loadNoticeList);
                }
            })
            .catch(error => console.error(error));
    };

    loadNoticeList = () => {
        if (this.state.isOpened) {
            PostService.getAllBandNoticeListNode(this.state.bandId)
                .then(response => {
                    console.log('notice response', response)
                    if (this._isMounted) {
                        this.setState({
                            noticeList: response.content,
                        });
                    }
                })
                .catch(error => console.error(error));
        }
    };

    route = (url) => {
        this.props.history.push(url);
    };

    routePostOrBand = (bandId, postId) => {
        if (bandId && postId) {
            this.route("/band/" + bandId + "/post/" + postId);
        } else if (bandId) {
            this.route("/band/" + bandId);
        } else {
            alert("BandId & PostId are undefined");
        }
    };

    modifyNotice = (noticeId) => {
        const bandId = this.props.match.params["bandId"];
        this.route("/band/" + bandId + "/post/" + noticeId + "/modify");
    };

    removeFromNotice = (noticeId) => {
        const bandId = this.props.match.params["bandId"];
        PostService.removeFromNoticeNode(bandId, noticeId)
            .then(() => this.loadNoticeList())
            .catch(error => console.error(error));
    };

    render() {
        const { bandId, noticeList, band } = this.state;

        return (
            <div className="wrapper">

                <BandTopButtons bandName={band && band.name} fromPage={this.props.t('NoticeList.notice')} routeBack={true} historyProp={this.props.history} />
                <div className="contentsWrapSpacer" />

                <div className="contentsWrap">

                    <NoticeTitle isMSIS={__ISMSIE__}>{this.props.t('NoticeList.notice')}</NoticeTitle>

                    {this.state.isOpened &&
                        <div>
                            <NoticeAllList bandId={bandId}
                                noticeList={noticeList}
                                isOpened={this.state.isBandMember}
                                isBandLeader={this.state.isBandLeader}
                                routeFunction={this.routePostOrBand}
                                modifyNotice={this.modifyNotice}
                                removeFromNotice={this.removeFromNotice}
                            />
                        </div>
                    }

                    <Spacer />
                    <BandMenu pathName="notice" bandId={bandId} historyProp={this.props.history} />
                </div>
            </div>
        );
    }

}

const NoticeTitle = styled.h2`
`;

const Spacer = styled.div`
    width: 100%;
    height: 6%;
`;

export default withTranslation()(Notice);
