import React from 'react';
import '../../../assets/css/_modal.scss';
import { Avatar } from 'antd'
import * as UserService from '../../../services/UserService';
import * as StringUtils from '../../../utils/StringUtils';
import { withTranslation } from 'react-i18next';

const NoProfileImage = require('../../../assets/images/blank-profile-picture.png');
const NoImage = require('../../../assets/images/no_self_img.png');

class UserProfileSettingModal extends React.Component {

    onClose;
    show;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            profileImageFile: undefined,
            profilePreview: NoProfileImage,
            profileImageChanged: false,
            nickname: '',
            name: undefined,
            organization: undefined,
            jobTitle: undefined,
            employeeNumber: undefined
        };

        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;

        let userProfile, userProfileImage;
        UserService.getUserProfileNode(localStorage.getItem('userId'))
            .then(user => userProfile = user)
            .then(() => {
                if (!StringUtils.isNullOrUndefined(userProfile.profileImage)) {
                    userProfileImage = `${userProfile.profileImage}`;
                }
                if (this._isMounted) {
                    this.setState({
                        profilePreview: userProfileImage,
                        nickname: "닉네임",
                        name: userProfile.name,
                        jobTitle: userProfile.jobTitle,
                        employeeNumber: userProfile.employeeNumber
                    })
                }
            })
            .catch(error => console.error(error));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    modalClose = () => {
        this.props.onClose();
    };

    render() {
        if (!this.props.show) {
            return null;
        }

        const { profilePreview, name, jobTitle, nickname, employeeNumber } = this.state;

        return (
            <div className="modal_wrap">
                <div className="modal">
                    <button className="close_btn" aria-label="모달창 닫기 버튼" onClick={this.modalClose}></button>
                    <div>
                        <p className="title">{this.props.t('BandDisplaySettingToolTip.viewMyProfile')}</p>
                        <div className="modal_content_userProfile">
                            <Avatar size={64} src={profilePreview === undefined ? NoImage : profilePreview} alt={nickname} />
                            <div className="userName">{name}</div>
                            <div className="userInfo">{(jobTitle !== null && jobTitle !== "null") && jobTitle} {(employeeNumber !== null && employeeNumber !== "null") && employeeNumber}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withTranslation()(UserProfileSettingModal);
