import React from 'react';
import styled from 'styled-components';
import { List, Avatar } from 'antd';

import * as BandService from '../../../services/BandService';
import * as PostService from '../../../services/PostService';
import * as CommonUtils from '../../../utils/CommonUtils';
import * as StringUtils from '../../../utils/StringUtils';
import { withTranslation } from 'react-i18next';

import NoImage from '../../../assets/images/no_self_img.png';
import { COMPANY_NAME } from '../../../configs';

class ViewState extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            selected: "read",
            bandId: this.props.bandId,
            postId: this.props.postId,
            readMembers: [],
            unreadMembers: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    init = () => {
        const bandMemberRoles = [0, 1];

        let post, members, read, readMembers = [], unreadMembers = [];
        PostService.getPostNode(this.state.bandId, this.state.postId)
            .then(result => post = result)
            .then(() => BandService.getBandMembersNode(this.state.bandId, bandMemberRoles))
            .then(result => members = result)
            .then(() => PostService.getReadMembersNode(this.state.bandId, this.state.postId))
            .then(result => read = result)
            .then(() => {
                members.forEach(member => {
                    if (CommonUtils.contains(read, member.userId)) {
                        readMembers.push(member);
                    } else {
                        if (member.userId !== post.createdBy) {
                            unreadMembers.push(member);
                        }
                    }
                });
                if (this._isMounted) {
                    this.setState({
                        readMembers: readMembers,
                        unreadMembers: unreadMembers,
                    });
                }
            })
            .catch(error => console.error(error));
    };

    back = () => {
        this.props.history.goBack();
    };

    select = (value) => {
        if (this._isMounted) {
            this.setState({
                selected: value,
            })
        }
    };

    render() {
        const { selected, readMembers, unreadMembers } = this.state;
        console.log('readMembers', readMembers)
        return (
            <div style={{ margin: '0 auto', width: '100%', maxWidth: '500px', minWidth: '200px' }}>
                <Header>
                    <HeaderLeft onClick={() => this.select("read")}>{this.props.t('New.readMember')} </HeaderLeft> |
                    <HeaderRight onClick={() => this.select("unread")}>{this.props.t('New.unreadMember')}</HeaderRight>
                </Header>

                {selected === "read" ?
                    <Members>
                        {readMembers.map(member =>
                            <List.Item key={member.userId}>
                                <List.Item.Meta
                                    avatar={<Avatar size={48} src={member.profileImage ? `${member.profileImage}` : NoImage} />}
                                    title={member.name}
                                    description={`${COMPANY_NAME !== "협업팜" ? `${!StringUtils.isNullOrUndefined(member.jobTitle) && member.jobTitle}` : ""}`}
                                />
                            </List.Item>
                        )}
                    </Members>
                    :
                    <Members>
                        {unreadMembers.map(member =>
                            <List.Item key={member.userId}>
                                <List.Item.Meta
                                    avatar={<Avatar size={48} src={member.profileImage ? `${member.profileImage}` : NoImage} />}
                                    title={member.name}
                                    description={`${COMPANY_NAME !== "협업팜" ? `${!StringUtils.isNullOrUndefined(member.jobTitle) && member.jobTitle}` : ""}`}
                                />
                            </List.Item>
                        )}
                    </Members>
                }
            </div>
        );
    }
}


const Header = styled.div`
    padding: 1rem;
    display: flex;
    flex-grow: 1;
`;

const HeaderLeft = styled.button`
    width: 50%;
    text-align: center;
    cursor: pointer;
`;

const HeaderRight = styled.button`
    width: 50%;
    text-align: center;
    cursor: pointer;
`;

const Members = styled.div`
    padding: 1rem 1rem;
    max-height: 200px;
    overflow-y: auto;
`;

export default withTranslation()(ViewState);
