import * as CommonUtils from './CommonUtils';

export function removeAllTagForOnlyTable(html) {
    return CommonUtils.replace(html, /<p>|<\/p>|<br>|<br\/>|&nbsp;/g, "");
}

export function removeAllTables(html) {
    let m,
        paragraphs = [],
        rex = /<(table).*?>\s*(.+?)\s*<\/(table).*?>/g;

    // eslint-disable-next-line no-cond-assign
    while (m = rex.exec(html)) {
        paragraphs.push(m[0]);
    }

    console.log('paragraphs', paragraphs)

    return CommonUtils.replace(html, paragraphs, "");
}


export function extractAllParagraphs(html) {
    let m,
        paragraphs = [],
        rex = /<(p|h).*?>\s*(.+?)\s*<\/(p|h).*?>/g;

    // eslint-disable-next-line no-cond-assign
    while (m = rex.exec(html)) {
        paragraphs.push(m[0]);
    }

    return paragraphs;
}

export function extractThreeParagraphs(html) {
    let m,
        c,
        paragraphs = [],
        rex = /<(p|h).*?>\s*(.+?)\s*<\/(p|h1)>/g,
        rex2 = /<(span).*?>|<\/(span)>/g,
        totalTextLength = 0;

    c = 0;

    let loadMore;

    if (localStorage.getItem('i18nextLng') === "english") {
        loadMore = "Load More..."
    } else if (localStorage.getItem('i18nextLng') === "korean") {
        loadMore = "더보기..."
    }

    // eslint-disable-next-line no-cond-assign
    while (m = rex.exec(html)) {

        totalTextLength = totalTextLength + m[2].length

        m[2] = m[2].replace(rex2, "")

        if (totalTextLength > 300) {
            if (c < 3) {
                paragraphs.push("<p>" + m[2].substring(0, 150) + "</p>");
                paragraphs.push(`<p class='seeMore'>${loadMore}</p>`)
            }
            break;
        } else {
            if (c < 3) {
                paragraphs.push("<p>" + m[2] + "</p>");
            }
        }
        c++;
    }

    if (c > 3) {
        paragraphs.push(`<p class='seeMore'>${loadMore}</p>`);
    }

    return paragraphs;
}

export function extractSrcFromImgTag(html) {
    let m,
        urls = [],
        rex = /<img.*?src="([^">]*\/([^">]*?))".*?>/g;

    // eslint-disable-next-line no-cond-assign
    while (m = rex.exec(html)) {
        urls.push(m[1]);
    }

    return urls;
}

export function extractSrcFromVideoTag(html) {
    let m,
        urls = [],
        rex = /<video.*?src="([^">]*\/([^">]*?))".*?>/g;

    // eslint-disable-next-line no-cond-assign
    while (m = rex.exec(html)) {
        urls.push(m[1]);
    }

    return urls;
}

export function removeEmptyParagraph(html) {
    return CommonUtils.replace(CommonUtils.replace(html, /<p><br\/?><\/p>/g, ""), /<p>&nbsp;<\/p>/g, "");
}

export function removeAllImgTag(html) {
    return CommonUtils.replace(html, /<img .*?>/g, "");
}

export function removeAllVideoTag(html) {
    return CommonUtils.replace(CommonUtils.replace(html, /<video .*?>/g, ""), "</video>", "");
}

export function removeHtmlTags(html) {
    return html && html.replace(/<[^>]+>/g, '').replace(/\s/g, " ");
}


export function removeEmptySpace(html) {
    return CommonUtils.replace(html, /&nbsp;/g, "");
}