import React from 'react'
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

function ContentDirectoryCreateModal({ modalClose, handleDirectoryName, createNewDirectory }) {
    const { t } = useTranslation();

    return (
        <div className="modal_wrap">
            <div className="modal">
                 <button className="close_btn" aria-label="모달창 닫기 버튼"onClick={modalClose}></button>
                <div>
                    <p className="title">{t('Contents.createFolder')}</p>
                    <div className="modal_content view_order">
                        <div>{t('Contents.pleaseEnterTheFolderNameToBeCreatedInTheCurrentLocation')}</div>
                        <div>
                            <Input onChange={handleDirectoryName} />
                        </div>
                    </div>
                </div>
                <div>
                    <button className="modal_submit_btn" onClick={createNewDirectory}>
                        {t('Contents.submit')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ContentDirectoryCreateModal

const Input = styled.input`
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-bottom: solid 1px black;
`;
