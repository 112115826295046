import React from 'react';
import styled from "styled-components";
import { THEME_BACKGROUND_COLOR } from '../../../configs';
import { useTranslation } from 'react-i18next';

type TtoolTipProps = {
    select: (id: number) => void;
    options: any[]
}

function DurationSettingToolTip(props: TtoolTipProps) {
    const { t } = useTranslation();
    const currentLanguage = localStorage.getItem("i18nextLng");
    const { options } = props;

    const handleSelectMenu = (event: React.MouseEvent<HTMLLIElement>) => {
        props.select(Number(event.currentTarget.id));
    };

    return (
        <Container>
            <ul className="arrow_box">
                {options.map((option, index) =>
                    <li className="option" key={index} onClick={handleSelectMenu} id={option.value}>
                        {currentLanguage === "korean" ? option.korean :
                            currentLanguage === "english" ? option.english :
                                option.japanese}
                    </li>
                )}
            </ul>
        </Container>
    )
}

export default DurationSettingToolTip

const Container = styled.div`
    display: inline-grid;
    width: 8rem;

    .arrow_box {
        position: relative;
        display: inline-block;
        min-width: max-content;
        right: 30px;
        border: 2px solid ${THEME_BACKGROUND_COLOR};
        border-radius: 6px;
        background: #ffffff;
        box-shadow: 0px 2px 6px rgba(0,0,0,0.4);
        cursor : pointer;
    }

    .arrow_box:after, .arrow_box:before {
        position: absolute;
        left: 86%;
        bottom: 100%;
        width: 0;
        height: 0;
        content: "";
        border: solid transparent;
        pointer-events: none;
    }
    
    .arrow_box:after {
        margin-left: -9px;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 9px;
    }

    .arrow_box:before {
        margin-left: -12px;
        border-color: rgba(0, 55, 199, 0);
        border-bottom-color: ${THEME_BACKGROUND_COLOR};
        border-width: 12px;
    }

    .option{
        padding:8px 16px;
        border-bottom:1px solid #EFEFF4;
        font-size:0.9rem;
        font-weight : bold;
        line-height : 1.2rem;
        color:${THEME_BACKGROUND_COLOR};
    }

    .option:last-child{
        border-bottom:0px;
    }
`;

