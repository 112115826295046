import React from 'react';
import styled from "styled-components";

import { BandMenu, BandTopButtons } from '../Menu';
import { MemberList } from '../../common/components/Member';

import * as UserService from '../../services/UserService';
import * as BandService from '../../services/BandService';
import * as CommonUtils from '../../utils/CommonUtils';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DB_KIND, THEME_BACKGROUND_COLOR } from '../../configs';
import Invitation from './Sections/Invitation';

import SearchMembers from './SearchMembers/SearchMembers';

import { Input } from 'antd';
const { Search } = Input;

class Members extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        const bandId = this.props.match.params["bandId"];
        this.state = {
            bandId: bandId,
            memberList: [],
            entireMemberList: [],
            isBandMember: false,
            isBandLeader: false,
            isOpened: false,
            waitingForJoin: [],
            selectedMembers: [],
            step: 1,
            loading: false,
            bandName: ""
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBandInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandInfo = () => {
        BandService.getBandInfo(this.state.bandId)
            .then(result => {
                if (this._isMounted) {
                    this.setState({
                        bandName: result.band.name,
                        band: result.band,
                        bandLeaders: result.bandLeaders,
                        isBandLeader: result.isBandLeader,
                        isBandMember: result.isBandMember,
                        isOpened: result.isOpened,
                    }, () => this.loadMembers(result.bandMembers));
                }
            })
            .catch(error => console.error(error));
    };

    loadMembers = (bandMembers) => {
        if (this.state.isOpened) {
            const notApprovedMemberRole = [2];

            let waitingForJoin = [], selectedMembers = [];
            BandService.getBandMembersNode(this.state.bandId, notApprovedMemberRole)
                .then(result => waitingForJoin = result)
                .then(() => {
                    // console.log('waitingForJoin',waitingForJoin)
                    waitingForJoin.forEach(member => selectedMembers.push(member.userId));
                    if (this._isMounted) {
                        this.setState({
                            memberList: bandMembers,
                            entireMemberList: bandMembers,
                            waitingForJoin: waitingForJoin,
                            selectedMembers: selectedMembers,
                        });
                    }
                })
                .catch(error => console.error(error));
        }
    };

    searchInput = (e) => {
        this.setState({ loading: true })
        const text = e.currentTarget.value;
        const memberList = this.state.entireMemberList

        let found = memberList;
        if (text && text.trim().length > 0) {
            found = memberList.filter(member => CommonUtils.contains(member.name, text));
        }

        if (this._isMounted) {
            this.setState({
                memberList: found,
            });
        }

        if (found.length !== 0) {
            setTimeout(() => {
                this.setState({ loading: false });
            }, 1000);
        }

    };

    next = () => {
        if (this._isMounted) {
            this.setState({ step: 2 });
        }
    };

    cancel = () => {
        if (this._isMounted) {
            this.setState({ step: 1 });
        }
    };

    selectMember = (selectedMember) => {
        this.setState({
            selectedMembers: CommonUtils.contains(this.state.selectedMembers, selectedMember)
                ? this.state.selectedMembers.filter(id => id !== selectedMember)
                : [...this.state.selectedMembers, selectedMember]
        });
    };

    approveToJoin = () => {
        
        BandService.approveBandJoinNode(this.state.bandId, this.state.selectedMembers)
            .then(() => {
                if (this._isMounted) {
                    UserService.sendNotificationToWatingForInvitationUsers(this.state.bandId, localStorage.getItem('userAccount'), localStorage.getItem('userName'), this.state.selectedMembers, this.state.bandName)
                        .then((response) => console.log(response))
                        .catch((err) => console.error(err));

                    this.setState({
                        step: 1,
                    }, this.loadBandInfo);
                }
            })
            .catch(error => console.error(error));
    };

    rejectToJoin = () => {
        BandService.rejectBandJoinNode(this.state.bandId, this.state.selectedMembers)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        step: 1,
                    }, this.loadBandInfo);
                }
            })
            .catch(error => console.error(error));
    };

    settingFunction = (id) => {
        this.props.history.push("/band/" + this.state.bandId + "/setting");
    };

    deportFunction = (id) => {
        BandService.deportMemberNode(this.state.bandId, id)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        step: 1,
                    }, this.loadBandInfo);
                }
            })
            .catch(error => console.error(error));
    };

    render() {
        const { bandId, memberList, waitingForJoin, selectedMembers, step, bandLeaders, bandName } = this.state;
        const { history, t } = this.props;

        return (
            <div className="wrapper">
                {step === 1 &&
                    <>
                        <BandTopButtons bandId={bandId} bandName={bandName} fromPage={`${t('Members.member')}`} historyProp={history} />

                        <div className="contentsWrapSpacer" />

                        {this.state.isOpened &&
                            <div className="contentsWrap">
                                <PageWrapper>

                                    {/* UC WARE에서는 Invitation 하는 걸 3개 다 보여주기, 
                                    그 외에는 ORACLE 버전에서는 SearchMembers를 보여주고 
                                    그 외 버전에서는 한번에 조직도 다 가져오는 컴포넌트 사용 */}
                                    {DB_KIND === "ORACLE" ?
                                        <SearchMembers bandName={bandName} memberList={memberList} bandId={bandId} />
                                        :
                                        <Invitation bandName={bandName} memberList={memberList} bandId={bandId} />
                                    }

                                    {/* {COMPANY_NAME === "UC WARE"
                                        &&
                                        <>
                                            <OrgComponent bandName={bandName} memberList={memberList} bandId={bandId} />
                                            <SearchMembers bandName={bandName} memberList={memberList} bandId={bandId} />
                                        </>
                                    } */}

                                    {this.state.isBandLeader && waitingForJoin && waitingForJoin.length > 0 &&
                                        <div style={{ marginBottom: '16px' }}>
                                            <DivWrapper>
                                                <span onClick={this.next} style={{ cursor: 'pointer' }}>{t('Members.watingForApproval')} ({waitingForJoin.length})</span>
                                            </DivWrapper>
                                            <MemberListWrapper>
                                                <MemberList
                                                    memberPage
                                                    memberList={waitingForJoin}
                                                    isBandLeader={this.state.isBandLeader}
                                                    selectMultiple
                                                    selectable={true} selected={selectedMembers} onSelect={this.selectMember} />
                                            </MemberListWrapper>
                                            <SubmitButton>
                                                <button className="refuseButton" onClick={this.rejectToJoin}>
                                                    {t('Members.declineToJoin')}
                                                </button>

                                                <button className="approveButton" onClick={this.approveToJoin}>
                                                    {t('Members.approveOfJoining')}
                                                </button>
                                            </SubmitButton>
                                        </div>
                                    }

                                    <MemberListWrapper>
                                        <MemberLabel>{t('Members.memberList')}</MemberLabel>

                                        <Search style={{ margin: 0 }}
                                            type="text"
                                            placeholder={`${t('Members.pleaseEnterYourMemberName')}`}
                                            onChange={this.searchInput} />

                                        {this.state.loading ?
                                            <div style={{ textAlign: 'center' }}>
                                                <br />
                                                <h3>{t('Members.searching')}</h3>
                                                <br />
                                                <CircularProgress />
                                            </div>
                                            :
                                            <MemberList
                                                memberPage
                                                title={`${t('Members.memberList')}`}
                                                memberList={memberList}
                                                bandLeaders={bandLeaders}
                                                FromMembersPage
                                                isBandLeader={this.state.isBandLeader}
                                                selectable={false}
                                                settingFunction={this.settingFunction}
                                                deportFunction={this.deportFunction}
                                                deportTitle={`${t('Members.deport')}`} />
                                        }

                                    </MemberListWrapper>

                                </PageWrapper>
                                <SpacerForMobileSize />
                                <BandMenu pathName="member" bandId={bandId} historyProp={history} />
                            </div>
                        }
                    </>
                }

                {step === 2 &&
                    <div className="contentsWrap">
                        <PageWrapper>
                            <Buttons>
                                <Title>{t('Members.watingForApproval')}</Title>
                                <Cancel>
                                    <button onClick={this.cancel}>{t('Members.cancel')}</button>
                                </Cancel>
                            </Buttons>

                            <MemberListWrapper>
                                <MemberList
                                    memberPage
                                    memberList={waitingForJoin}
                                    isBandLeader={this.state.isBandLeader}
                                    selectMultiple
                                    selectable={true} selected={selectedMembers} onSelect={this.selectMember} />
                            </MemberListWrapper>
                            <SubmitButton>
                                <button className="refuseButton" onClick={this.rejectToJoin}>
                                    {t('Members.declineToJoin')}
                                </button>

                                <button className="approveButton" onClick={this.approveToJoin}>
                                    {t('Members.approveOfJoining')}
                                </button>
                            </SubmitButton>
                        </PageWrapper>
                    </div>
                }

            </div>
        )
    }

}

const SubmitButton = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: center;
    height: 40px;
    vertical-align: middle;
    margin-top: 10px;

    button {
        background-color: ${THEME_BACKGROUND_COLOR};
        width: 35%;
        height: 100%;
        margin: 0 10px;
        color: white;
        border-radius: 7px;
    }

    .refuseButton {
        background-color: white
        color: ${THEME_BACKGROUND_COLOR};
        border: 1px solid ${THEME_BACKGROUND_COLOR};
    }

    .approveButton {
        background-color: ${THEME_BACKGROUND_COLOR};
        color: white;
    }
`;

const MemberLabel = styled.div`
    object-fit: contain;
    font-size: 1.3rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #232323;
    padding: 0.5rem 0;

    display: flex;
    vertical-align: center;

    img{
        width: 30px;
        height: 24.4px;
        object-fit: contain;      
    }

    p { 
        margin-top: 4px;
        padding-left: 3px;
    }
`;

const PageWrapper = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid #DBDBDB;
    padding: 1rem;
    background: #FFFFFF;
    border-radius: 4px;
`;

const DivWrapper = styled.div`
    padding: 0.5rem 0;
    width: 100%;
    display: flex;
    vertical-align: center;

    object-fit: contain;
    font-size: 1.3rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    img{
        width: 30px;
        height: 24.4px;
        object-fit: contain;
    }

    p { 
        margin-top: 4px;
        padding-left: 3px;
    }
`;

const MemberListWrapper = styled.div`
    padding: 0 0;
`;

const SpacerForMobileSize = styled.div`
    @media (max-width: 500px) {
        width: 100%;
        height: 27px;
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-grow: 1;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
`;

const Title = styled.span`
    width: 85%;
    word-break: keep-all;
    font-size: 2rem;
    font-weight: bold;
`;

const Cancel = styled.div`
    width: 10%;
    text-align: right;
    button {
      color: black;
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
`;

export default Members;
