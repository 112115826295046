import React from 'react';
import styled from 'styled-components';

import BandList from './Sections/BandList';
import { PostList } from '../_Post';
import { bandCategoryList, bandCategoryListEng, bandCategoryListJap, bandCategoryListThai } from '../../data';

import * as BandService from "../../services/BandService";
import * as PostService from "../../services/PostService";
import * as DateTimeUtils from "../../utils/DateTimeUtils";

import { POST_PAGE_SIZE, THEME_BACKGROUND_COLOR } from "../../configs";
import HomeTopMenu from '../Menu/HomeTopMenu';
import ListsSkeleton from '../../common/components/Shared/Skeleton/ListsSkeleton';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { Input } from 'antd';
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { withTranslation } from 'react-i18next';

import btnNext from '../../assets/images/btn_next.svg';
import btnPrev from '../../assets/images/btn_prev.svg';
import './SearchBandPage.css';

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

const { Search } = Input;

class SearchBand extends React.Component {

    _isMounted = false;
    timeout = null;

    constructor(props) {
        super(props);
        this.state = {
            bandCategoryList: bandCategoryList,
            selectedBandCategory: undefined,
            loading: true,
            latestBandList: [],
            popularBandList: [],
            allBandList: [],
            bandList: [],
            postList: [],
            page: 1,
            lastPage: true,
            currentUserBandListIds: [],
            searchKeyword: '',
            openSelectBandCategoryPopup: false,
            pageSize: 0
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBandList();
        this.loadUserBandList();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandList() {
        let latestBandList, popularBandList;
        let bandCategoryId, count;
        if (this.state.selectedBandCategory) {
            bandCategoryId = Number(this.state.selectedBandCategory);
        } else {
            count = 3;
        }

        BandService.getLatestCreatedBandListNode(bandCategoryId, count)
            .then(response => latestBandList = response)
            .then(() => BandService.getPopularBandListNode(bandCategoryId, count))
            .then(response => popularBandList = response)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        latestBandList: latestBandList,
                        popularBandList: popularBandList,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                alert(`Failed to load list. - ${error}`);
                if (this._isMounted) {
                    this.setState({
                        loading: false,
                        allBandList: [],
                        bandList: [],
                        postList: []
                    });
                }
            });
    }

    loadUserBandList = () => {
        if (localStorage.getItem('userId')) {
            let currentUserBandListIds = [];
            BandService.getUserBandListNode(localStorage.getItem('userId'), true)
                .then(bandIds => bandIds.forEach(bandId => currentUserBandListIds.push(bandId)))
                .then(() => BandService.getUserBandListNode(localStorage.getItem('userId'), false))
                .then(bandIds => bandIds.forEach(bandId => currentUserBandListIds.push(bandId)))
                .then(() => {
                    if (this._isMounted) {
                        this.setState({
                            currentUserBandListIds: currentUserBandListIds,
                        });
                    }
                })
                .catch(error => {
                    alert(`Failed to load list. - ${error}`);
                });
        }
    };

    routeBandHome = (bandId) => {
        this.route("/band/" + bandId);
    };

    routePostOrBand = (bandId, postId) => {
        if (bandId && postId) {
            this.route("/band/" + bandId + "/post/" + postId);
        } else if (bandId) {
            this.routeBandHome(bandId);
        } else {
            alert("BandId & PostId are undefined");
        }
    };

    route = (url) => {
        this.props.history.push(url);
    };

    selectBandCategory = (selected) => {
        if (this._isMounted) {
            this.setState({
                selectedBandCategory: selected,
                openSelectBandCategoryPopup: false
            }, this.loadBandList);
        }
    };

    unselectBandCategory = () => {
        if (this._isMounted) {
            this.setState({
                selectedBandCategory: undefined
            }, this.loadBandList);
        }
    };

    openSelectBandCategoryPopup = () => {
        if (this._isMounted) {
            this.setState({
                openSelectBandCategoryPopup: true
            });
        }
    };

    closeSelectBandCategoryPopup = () => {
        if (this._isMounted) {
            this.setState({
                openSelectBandCategoryPopup: false
            });
        }
    };

    searchInput = (e) => {
        if (this._isMounted) {
            this.setState({
                searchKeyword: e.currentTarget.value,
            });
        }
    };

    onKeyDown = (e) => {
        //e.key ==='Enter'

        clearTimeout(this.timeout);
        // Set a timeout to wait for the user to stop writing
        // So we don´t have to make unnessesary calls
        this.timeout = setTimeout(() => {
            let allBandList, bandList, postList, last;
            BandService.getAllBandListNode()
                .then(response => allBandList = response)
                .then(() => BandService.searchBandNode(this.state.selectedBandCategory, this.state.searchKeyword))
                .then(response => bandList = response)
                .then(() => PostService.searchPostNode(this.state.selectedBandCategory, this.state.searchKeyword, DateTimeUtils.formattedCurrent(), 1, POST_PAGE_SIZE))
                .then(response => {
                    postList = response.content;
                    last = response.last;
                })
                .then(() => {
                    if (this._isMounted) {
                        this.setState({
                            pageSize: postList.length,
                            allBandList: allBandList,
                            bandList: bandList,
                            postList: postList,
                            lastPage: last,
                        });
                    }
                })
                .catch(error => {
                    alert(`Failed to load list. - ${error}`);
                    if (this._isMounted) {
                        this.setState({
                            allBandList: [],
                            bandList: [],
                            postList: []
                        });
                    }
                });
        }, 500);

    };

    nextPage = () => {
        if (this._isMounted) {
            const current = DateTimeUtils.formattedCurrent();
            const page = this.state.page + 1;
            let postList = this.state.postList;
            PostService.searchPostNode(this.state.selectedBandCategory, this.state.searchKeyword, current, page, POST_PAGE_SIZE)
                .then(response => {
                    if (response.content) {
                        response.content.forEach(content => postList.push(content));
                    }
                    this.setState({
                        pageSize: postList.length,
                        postList: postList,
                        lastPage: response.last,
                        page: page
                    });
                })
                .catch(error => {
                    alert(`Failed to load list. - ${error}`);
                });
        }
    };

    render() {
        const { loading, latestBandList, popularBandList, currentUserBandListIds } = this.state;
        const { pathname } = this.props.location;
        const selectedCategory = this.state.selectedBandCategory ?
            bandCategoryList.filter(bandCategory => bandCategory.id === Number(this.state.selectedBandCategory))[0].name : undefined;
        const { t } = this.props;
        const currentLanguage = localStorage.getItem('i18nextLng');
        let bandCategoryListVariable;
        if (currentLanguage === "english") {
            bandCategoryListVariable = bandCategoryListEng
        } else if(currentLanguage === "japanese"){
            bandCategoryListVariable = bandCategoryListJap
        } else if(currentLanguage === "thai"){
            bandCategoryListVariable = bandCategoryListThai
        }else {
            bandCategoryListVariable = bandCategoryList
        }

        return (
            <div className="wrapper">

                <div className="headerWrap">
                    <HomeTopMenu pathname={pathname} routeFunction={this.route} />
                </div>
                <div className="contentsWrapSpacer" />
                <div className="contentsWrap">
                    <div className="search-noInput">

                        {loading ?
                            <div><ListsSkeleton /></div>
                            :
                            <>
                                <div className="searchBoxWrap">
                                    {/* <div className="band-search-area">
                                            <input className="band-search-input"
                                                type="text" placeholder="검색어를 입력해주세요."
                                                onChange={this.searchInput}
                                                onKeyDown={this.onKeyDown}
                                            />
                                        </div> */}
                                    <Search
                                        onKeyDown={this.onKeyDown}
                                        placeholder={t('SearchBand.findBySearch')}
                                        onChange={this.searchInput}
                                        style={{ width: '100%' }}
                                    />
                                </div>

                                {this.state.selectedBandCategory ?
                                    <NavigationWrapper>
                                        <div style={{ flexGrow: 1 }}>
                                            <span style={{ cursor: 'pointer', fontSize: '1.7rem', fontWeight: 400 }} onClick={this.unselectBandCategory}>{`<`}</span>
                                        &nbsp;&nbsp;{selectedCategory}
                                        </div>

                                        <div style={{ cursor: 'pointer' }} onClick={this.openSelectBandCategoryPopup}>{t('SearchBand.findBySearch')}</div>
                                    </NavigationWrapper>
                                    :
                                    <section className="explore-cat-wrap" style={{ backgroundColor: '#EDF1F2' }}>
                                        <h5>{t('SearchBand.findBySearch')}</h5>
                                        <MultipleItems
                                            selectBandCategoryClick={this.selectBandCategory}
                                        />
                                    </section>
                                }

                                {this.state.searchKeyword &&
                                    <div className="band-search-result-area">
                                        <div className="band-search-result-area title">
                                            {t('SearchBand.bands')} {this.state.bandList.length}
                                        </div>
                                        <BandList
                                            bandList={this.state.bandList}
                                            routeFunction={this.routeBandHome} />
                                    </div>
                                }

                                {this.state.searchKeyword &&
                                    <div className="band-search-result-area">
                                        <div className="band-search-result-area title">
                                            {t('SearchBand.posts')} {this.state.postList.length}
                                        </div>
                                        <PostList
                                            currentUserBandListIds={currentUserBandListIds}
                                            searchBand postList={this.state.postList} bandList={this.state.allBandList} routeFunction={this.routePostOrBand} />

                                        {this.state.pageSize === 20 &&
                                            <>
                                                <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
                                                    <button style={{ padding: '1rem', borderRadius: '5px', background: `${THEME_BACKGROUND_COLOR}`, color: 'white', width: '100px', fontWeight: 'bold' }} onClick={this.nextPage}>
                                                        {t('SearchBand.loadMore')}</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                }

                                {!this.state.searchKeyword &&
                                    <>
                                        <section className="new-band-wrap" style={{ backgroundColor: '#EDF1F2' }}>
                                            <h5 style={{ color: '#808080', lineHeight: '24px', fontWeight: 'bold' }}>
                                                {t('SearchBand.recentlyCreatedBands')}</h5>
                                            <BandList
                                                bandList={latestBandList}
                                                routeFunction={this.routeBandHome} />
                                        </section>

                                        <section className="popular-band-wrap" style={{ backgroundColor: '#EDF1F2' }}>
                                            <h5 style={{ color: '#808080', lineHeight: '24px', fontWeight: 'bold' }}>{t('SearchBand.popularBands')}
                                            </h5>
                                            <BandList
                                                popularBand
                                                bandList={popularBandList}
                                                routeFunction={this.routeBandHome} />
                                        </section>
                                    </>
                                }

                                {this.state.openSelectBandCategoryPopup &&
                                    <div className="modal_wrap" style={{ position: 'fixed' }}>
                                        <div className="category" >
                                            <div>
                                                <div className="close_btn" style={{ marginRight: '1rem' }} onClick={() => this.closeSelectBandCategoryPopup()}></div>
                                                <Title >{t('SearchBand.chooseAnotherTopic')}</Title>
                                                <GrayWrapper>
                                                    <div className="modal_content view_order" style={{ marginBottom: '-20px' }}>
                                                        {bandCategoryListVariable.map((bandCategory, index) => {
                                                            if (bandCategory.id !== Number(this.state.selectedBandCategory)) {
                                                                return <div key={bandCategory.id}>
                                                                    <ThemeList onClick={() => this.selectBandCategory(bandCategory.id)}>
                                                                        {bandCategory.name}
                                                                    </ThemeList>
                                                                    <Divider />
                                                                </div>
                                                            } else {
                                                                return "";
                                                            }
                                                        })}
                                                        <br />
                                                        <div>
                                                        </div>
                                                    </div>
                                                </GrayWrapper>
                                                <div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="dim" onClick={() => this.closeSelectBandCategoryPopup()} />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SearchBand);

class MultipleItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            slidesShow: 7
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.width !== this.state.width) {
            if (this.state.width < 400) {
                this.setState({ slidesShow: 4 })
            }
            if (this.state.width > 400) {
                this.setState({ slidesShow: 5 })
            }
            if (this.state.width > 525) {
                this.setState({ slidesShow: 7 })
            }
            if (this.state.width > 768) {
                this.setState({ slidesShow: 8 })
            }
            if (this.state.width > 1200) {
                this.setState({ slidesShow: 9 })
            }
        }
    }

    render() {
        const currentLanguage = localStorage.getItem('i18nextLng');
        let bandCategoryListVariable;

        if (currentLanguage === "english") {
            bandCategoryListVariable = bandCategoryListEng
        } else if(currentLanguage === "japanese"){
            bandCategoryListVariable = bandCategoryListJap
        } else if(currentLanguage === "thai"){
            bandCategoryListVariable = bandCategoryListThai
        }else {
            bandCategoryListVariable = bandCategoryList
        }
        
        const arrowWidth = __ISMSIE__ ? "22px" : "10px"
        return (
            <div style={{ marginTop: '5px', marginLeft: '14px', marginBottom: '16px' }}>
                <Carousel
                    arrowLeft={<ArrowLeft><img className="search-btnPrev" style={{ width: `${arrowWidth}` }} src={btnPrev} alt="btnPrev" /></ArrowLeft>}
                    arrowLeftDisabled={<AiOutlineLeft />}
                    arrowRight={<ArrowRight><img className="search-btnNext" style={{ width: `${arrowWidth}` }} src={btnNext} alt="btnNext" /></ArrowRight>}
                    arrowRightDisabled={<AiOutlineRight />}
                    addArrowClickHandler
                    centered
                    infinite
                    arrows
                    slidesPerPage={this.state.slidesShow}
                >
                    {bandCategoryListVariable.map((category, index) =>
                        <img
                            style={{ width: '80%', cursor: 'pointer', boxShadow:'2px 2px 4px rgba(0,165,193,0.2)', borderRadius:'6px'}}
                            src={category.icon}
                            alt={category.id}
                            key={category.id}
                            id={category.id}
                            onClick={(e) => this.props.selectBandCategoryClick(category.id)}
                        />
                    )}
                </Carousel>
            </div>
        )
    }
}

const ArrowLeft = styled.div`
    width: 60px;
    height: 85%;
    position: absolute;
    top: 24px;
    left: 0;
    padding: 4.3% 4% 4.3% 0%;
    background: linear-gradient(270deg, rgba(237,241,242,0) 0%, rgba(237,241,242,1) 50%);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    z-index: 1;
`;

const ArrowRight = styled.div`
    width: 60px;
    height: 85%;
    position: absolute;
    top: 24px;
    right: 0;
    padding: 4.3% 0% 4.3% 4%;
    background: linear-gradient(90deg, rgba(237,241,242,0) 0%, rgba(237,241,242,1) 50%);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
`;

const ThemeList = styled.div`
    padding: 1rem 2rem;
    cursor: pointer;
`;

const Divider = styled.div`
    border-bottom: solid 1px #e5e5ea;
`;

const Title = styled.div`
    padding-left: 2rem;
    font-size: 1.25em;
`;

const GrayWrapper = styled.div`
    margin: 20px 0;
    background-color: #f9f9fb;
    border-top: solid 1.5px #e5e5ea;
    border-bottom: solid 1.5px #e5e5ea;
`;

const NavigationWrapper = styled.div`
    display: flex;

    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.6rem 1rem;
`;



// {/* <Modal
// contentLabel="Select Band Category"
// isOpen={this.state.openSelectBandCategoryPopup}
// onRequestClose={() => this.closeSelectBandCategoryPopup()}
// style={CustomStyles}
// >
// <BandCategorySelectModal
//     bandCategoryListVariable={bandCategoryListVariable}
//     selectedBandCategory={this.state.selectedBandCategory}
//     selectBandCategory={(bandCategoryId) => this.selectBandCategory(bandCategoryId)}
//     show={this.state.openSelectBandCategoryPopup}
//     onClose={() => this.closeSelectBandCategoryPopup()}
// />
// </Modal> */}