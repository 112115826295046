import * as HttpHandler from '../utils/HttpHandler';
import { SERVER_URI, BODY_JSON } from '../configs';
import * as DateTimeUtils from '../utils/DateTimeUtils';
import moment from 'moment';
import axios from 'axios';


export function getBandScheduleCategoryListNode(bandId) {

    return axios.get(`${SERVER_URI}:5000/api/schedule/categories?bandId=${bandId}`)
        .then(
            response => { return response.data }
        );

}

export function createBandScheduleCategoryNode(bandId, color, name) {
    const body = {
        color: color,
        name: name,
        createdBy: localStorage.getItem('userId')
    };

    return axios.post(`${SERVER_URI}:5000/api/schedule/categories?bandId=${bandId}`, body)

}

export function deleteBandScheduleCategoryNode(bandId, bandCategoryId) {

    return axios.delete(`${SERVER_URI}:5000/api/schedule/categories?bandId=${bandId}&bandCategoryId=${bandCategoryId}`)
        .then(
            response => { return response.data }
        );

}

export function getBandScheduleListNode(bandId, month) {

    return axios.get(`${SERVER_URI}:5000/api/schedule?bandId=${bandId}&month=${month}`)
        .then(
            response => { return response.data }
        );

}

export function createScheduleNode(bandId, title, scheduleCategoryId, location, description,
    isAllDay, startDateTime, endDateTime, scheduleRepeatType,
    needToConfirm, createPost, attachedIds, scheduleColor) {
    const body = {
        title: title,
        scheduleCategoryId: scheduleCategoryId,
        location: location,
        description: description,
        isAllDay: isAllDay,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        scheduleRepeatType: 0,
        // scheduleRepeatType: scheduleRepeatType,
        needToConfirm: needToConfirm,
        createPost: createPost,
        attachedIds: attachedIds,
        createdBy: localStorage.getItem('userId'),
        currentDateTime: DateTimeUtils.formattedCurrent(),
        scheduleColor
    };

    console.log('body', body)

    return axios.post(`${SERVER_URI}:5000/api/schedule?bandId=${bandId}`, body)

}

export function getScheduleNode(bandId, scheduleId) {

    return axios.get(`${SERVER_URI}:5000/api/schedule/one?bandId=${bandId}&schedules=${scheduleId}`)
        .then(
            response => { return response.data }
        );

}

export function copyScheduleNode(bandId, scheduleId, targetBandId) {
    const body = {
        targetBandId: targetBandId
    };

    return axios.post(`${SERVER_URI}:5000/api/schedule/copy?bandId=${bandId}&scheduleId=${scheduleId}`, body)

}

export function deleteScheduleNode(bandId, scheduleId) {

    return axios.delete(`${SERVER_URI}:5000/api/schedule?bandId=${bandId}&scheduleId=${scheduleId}`)

}

export function modifyScheduleNode(bandId, scheduleId, title, scheduleCategoryId, location, description,
    isAllDay, startDateTime, endDateTime, scheduleRepeatType,
    needToConfirm, attachedIds, scheduleColor) {
    const body = {
        title: title,
        scheduleCategoryId: scheduleCategoryId,
        location: location,
        description: description,
        isAllDay: isAllDay,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        scheduleRepeatType: scheduleRepeatType,
        needToConfirm: needToConfirm,
        attachedIds: attachedIds,
        updatedBy: localStorage.getItem('userId'),
        currentDateTime: DateTimeUtils.formattedCurrent(),
        scheduleColor
    };

    return axios.put(`${SERVER_URI}:5000/api/schedule?bandId=${bandId}&scheduleId=${scheduleId}`, body)

}

export function listToMap(scheduleList, startDate, endDate) {
    const map = [];

    if (scheduleList) {
        scheduleList.forEach(schedule => {
            const scheduleStartMoment = DateTimeUtils.momentByDefaultFormat(schedule.startDateTime);
            const scheduleEndMoment = schedule.endDateTime ? DateTimeUtils.momentByDefaultFormat(schedule.endDateTime) : endDate;
            const duration = scheduleEndMoment.diff(scheduleStartMoment);

            if (schedule.scheduleRepeatType === 0) {
                map.push({
                    id: schedule.id,
                    dayNumber: scheduleStartMoment.date(),
                    dayOfWeek: scheduleStartMoment.days(),
                    startDateTime: scheduleStartMoment,
                    endDateTime: scheduleEndMoment,
                    duration: duration,
                    title: schedule.title,
                    scheduleCategory: schedule.scheduleCategory,
                    location: schedule.location,
                    createdBy: schedule.createdBy,
                    needToConfirm: schedule.needToConfirm,
                });

            } else if (schedule.scheduleRepeatType === 1) {
                const start = scheduleStartMoment.toDate().valueOf() > startDate.toDate().valueOf() ? scheduleStartMoment.date() : startDate.date();
                const end = endDate.date();
                const date = moment([startDate.year(), startDate.month(), start]);
                for (let i = 0; i <= (end - start); i++) {
                    map.push({
                        id: schedule.id,
                        dayNumber: date.clone().add(i, 'days').date(),
                        dayOfWeek: date.clone().add(i, 'days').days(),
                        startDateTime: scheduleStartMoment,
                        endDateTime: scheduleEndMoment,
                        duration: duration,
                        title: schedule.title,
                        scheduleCategory: schedule.scheduleCategory,
                        location: schedule.location,
                        createdBy: schedule.createdBy,
                        needToConfirm: schedule.needToConfirm,
                    });
                }

            } else if (schedule.scheduleRepeatType === 2) {
                const start = scheduleStartMoment.toDate().valueOf() > startDate.toDate().valueOf() ? scheduleStartMoment.date() : startDate.date();
                const end = endDate.date();
                const date = moment([startDate.year(), startDate.month(), start]);
                const baseDays = DateTimeUtils.momentByDefaultFormat(schedule.startDateTime).days();
                for (let i = 0; i <= (end - start); i++) {
                    const cursor = date.clone().add(i, 'days');
                    if (cursor.days() === baseDays) {
                        map.push({
                            id: schedule.id,
                            dayNumber: date.clone().add(i, 'days').date(),
                            dayOfWeek: date.clone().add(i, 'days').days(),
                            startDateTime: scheduleStartMoment,
                            endDateTime: scheduleEndMoment,
                            duration: duration,
                            title: schedule.title,
                            scheduleCategory: schedule.scheduleCategory,
                            location: schedule.location,
                            createdBy: schedule.createdBy,
                            needToConfirm: schedule.needToConfirm,
                        });
                    }
                }

            } else if (schedule.scheduleRepeatType === 3) {
                const start = scheduleStartMoment.toDate().valueOf() > startDate.toDate().valueOf() ? scheduleStartMoment.date() : startDate.date();
                const end = endDate.date();
                const date = moment([startDate.year(), startDate.month(), start]);
                const baseDate = DateTimeUtils.momentByDefaultFormat(schedule.startDateTime).date()
                for (let i = 0; i <= (end - start); i++) {
                    const cursor = date.clone().add(i, 'days');
                    if (cursor.date() === baseDate) {
                        map.push({
                            id: schedule.id,
                            dayNumber: date.clone().add(i, 'days').date(),
                            dayOfWeek: date.clone().add(i, 'days').days(),
                            startDateTime: scheduleStartMoment,
                            endDateTime: scheduleEndMoment,
                            duration: duration,
                            title: schedule.title,
                            scheduleCategory: schedule.scheduleCategory,
                            location: schedule.location,
                            createdBy: schedule.createdBy,
                            needToConfirm: schedule.needToConfirm,
                        });
                    }
                }

            } else if (schedule.scheduleRepeatType === 4) {
                const start = scheduleStartMoment.toDate().valueOf() > startDate.toDate().valueOf() ? scheduleStartMoment.date() : startDate.date();
                const end = endDate.date();
                const date = moment([startDate.year(), startDate.month(), start]);
                const baseWeeks = Math.ceil(DateTimeUtils.momentByDefaultFormat(schedule.startDateTime).date() / 7);
                const baseDays = DateTimeUtils.momentByDefaultFormat(schedule.startDateTime).days();
                for (let i = 0; i <= (end - start); i++) {
                    const cursor = date.clone().add(i, 'days');
                    const cursorWeeks = Math.ceil(cursor.date() / 7);
                    if (cursorWeeks === baseWeeks && cursor.days() === baseDays) {
                        map.push({
                            id: schedule.id,
                            dayNumber: date.clone().add(i, 'days').date(),
                            dayOfWeek: date.clone().add(i, 'days').days(),
                            startDateTime: scheduleStartMoment,
                            endDateTime: scheduleEndMoment,
                            duration: duration,
                            title: schedule.title,
                            scheduleCategory: schedule.scheduleCategory,
                            location: schedule.location,
                            createdBy: schedule.createdBy,
                            needToConfirm: schedule.needToConfirm,
                        });
                    }
                }

            } else if (schedule.scheduleRepeatType === 5) {
                const start = scheduleStartMoment.toDate().valueOf() > startDate.toDate().valueOf() ? scheduleStartMoment.date() : startDate.date();
                const end = endDate.date();
                const date = moment([startDate.year(), startDate.month(), start]);
                const baseMonth = DateTimeUtils.momentByDefaultFormat(schedule.startDateTime).month();
                const baseDays = DateTimeUtils.momentByDefaultFormat(schedule.startDateTime).days();
                for (let i = 0; i <= (end - start); i++) {
                    const cursor = date.clone().add(i, 'days');
                    if (cursor.month() === baseMonth && cursor.days() === baseDays) {
                        map.push({
                            id: schedule.id,
                            dayNumber: date.clone().add(i, 'days').date(),
                            dayOfWeek: date.clone().add(i, 'days').days(),
                            startDateTime: scheduleStartMoment,
                            endDateTime: scheduleEndMoment,
                            duration: duration,
                            title: schedule.title,
                            scheduleCategory: schedule.scheduleCategory,
                            location: schedule.location,
                            createdBy: schedule.createdBy,
                            needToConfirm: schedule.needToConfirm,
                        });
                    }
                }
            }
        });
    }

    return map.sort((a, b) => a.dayNumber - b.dayNumber);
}






























//////////////////////////////// 안쓰는 API들 //////////////////////////////////////////////// 
///////////////////////////////////////////////////////////////////////////////////////////

export function getBandScheduleCategoryList(bandId) {
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/scheduleCategories`,
            'GET',
            BODY_JSON,
            undefined,
        );
}

export function createBandScheduleCategory(bandId, color, name) {
    const body = {
        color: color,
        name: name,
    };

    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/scheduleCategories/create`,
            'POST',
            BODY_JSON,
            body,
        );
}

export function deleteBandScheduleCategory(bandId, bandCategoryId) {
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/scheduleCategories/${bandCategoryId}`,
            'DELETE',
            BODY_JSON,
            undefined,
        );
}

export function getBandScheduleList(bandId, month) {

    console.log('month', month)
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/schedules?month=${month}`,
            'GET',
            BODY_JSON,
            undefined,
        );
}

export function getSchedule(bandId, scheduleId) {
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/schedules/${scheduleId}`,
            'GET',
            BODY_JSON,
            undefined,
        );
}

export function copySchedule(bandId, scheduleId, targetBandId) {
    const body = {
        targetBandId: targetBandId
    };
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/schedules/${scheduleId}/copy`,
            'POST',
            BODY_JSON,
            body,
        );
}

export function deleteSchedule(bandId, scheduleId) {
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/schedules/${scheduleId}`,
            'DELETE',
            BODY_JSON,
            undefined,
        );
}

export function modifySchedule(bandId, scheduleId, title, scheduleCategoryId, location, description,
    isAllDay, startDateTime, endDateTime, scheduleRepeatType,
    needToConfirm, attachedIds) {
    const body = {
        title: title,
        scheduleCategoryId: scheduleCategoryId,
        location: location,
        description: description,
        isAllDay: isAllDay,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        scheduleRepeatType: scheduleRepeatType,
        needToConfirm: needToConfirm,
        attachedIds: attachedIds,
    };

    console.log('body', body)

    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/post/api/v1/bands/${bandId}/schedules/${scheduleId}`,
            'PUT',
            BODY_JSON,
            body,
        );
}