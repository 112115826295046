import React from "react";
import styled from "styled-components";
import {
  COMPANY_NAME,
  THEME_BACKGROUND_COLOR,
  THEME_COLOR,
} from "../../configs";
import { BandDisplaySettingToolTip } from "../../common/components/ToolTip";
import "./HomeTopMenu.css";
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class HomeMenuButtons extends React.Component {
  _isMounted = false;

  routeFunction;
  selectPopup;

  state = {
    menuDisplay: false,
    trigger: false,
    currentLanguage: window.localStorage.i18nextLng,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate = (prevProp, prevState) => {
    if (prevState.menuDisplay !== this.state.menuDisplay) {
      if (this._isMounted) {
        this.setState({ trigger: false });
      }
    }
  };

  routeMyBand = () => {
    this.props.routeFunction("/myBand");
  };

  routeNewsFeed = () => {
    this.props.routeFunction("/newsFeed");
  };

  routeSearchBand = () => {
    this.props.routeFunction("/searchBand");
  };

  handleMenuDisplay = (data) => {
    this.setState({ menuDisplay: data });
  };

  selectMenu = (value) => {
    this.props.selectPopup(value);
    if (this._isMounted) {
      this.setState({ trigger: true });
    }
  };

  handleLangugageClick = (e) => {
    i18next.changeLanguage(e.target.value);
    this.setState({ currentLanguage: e.target.value });
  };

  render() {
    const {
      pathname,
      t,
      MyBandListAsLeaderHanlder,
      isMyBandListAsLeader,
    } = this.props;
    const { currentLanguage } = this.state;
    return (
      <div
        className={
          pathname === "/newsFeed"
            ? "homeMenuButtonWrapperNewsFeed"
            : "homeMenuButtonWrapper"
        }
      >
        <Buttons>
          <button
            className={pathname === "/myBand" ? "select" : "normal"}
            onClick={this.routeMyBand}
          >
            {t("HomeMenuBottons.myBand")}
          </button>
          <button
            className={
              pathname === "/newsFeed" ? "selectButton" : "normalButton"
            }
            onClick={this.routeNewsFeed}
          >
            {t("HomeMenuBottons.newsFeed")}
          </button>
          <button
            className={
              pathname === "/searchBand" ? "selectButton" : "normalButton"
            }
            onClick={this.routeSearchBand}
          >
            {t("HomeMenuBottons.search")}
          </button>
          <button onClick={MyBandListAsLeaderHanlder}>
            {isMyBandListAsLeader
              ? `${t("MyBand.bandsThatICreated")}`
              : `${t("MyBand.myBand")}`}{" "}
          </button>
          <select
            className="language-selector"
            value={currentLanguage}
            onChange={this.handleLangugageClick}
          >
            <option value="english">En</option>
            <option value="korean">Ko</option>
            {COMPANY_NAME === "UC WARE" && <option value="japanese">Ja</option>}
            <option value="thai">Thai</option>
          </select>
        </Buttons>
        {pathname === "/myBand" && (
          <BandDisplaySettingToolTip select={this.selectMenu} />
        )}
      </div>
    );
  }
}

const Buttons = styled.div`
  display: inline-block;
  button {
    border: 1px solid rgb(128, 128, 128);
    font-size: 13px;
    color: rgb(128, 128, 128);
    line-height: 20px;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 13px 4px 13px 0px;
    height: 32px;
  }

  select {
    width: 57px;
    border: 1px solid rgb(128, 128, 128);
    font-size: 16px;
    color: rgb(128, 128, 128);
    line-height: 20px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 13px 4px 13px 0px;
    height: 32px;
  }

  button:not(:last-child) {
    margin-right: 10px;
  }

  .normalButton {
    color: ${THEME_COLOR};
    border: solid 1px;
    background-color: #fff;
  }

  .normalButton:hover {
    background-color: #e4e6e8;
    transition: all 0.6s;
  }

  .normal:hover {
    background-color: #808080a3;
  }

  .selectButton {
    color: #fff;
    border: solid 1px ${THEME_COLOR};
    background-color: ${THEME_COLOR};
  }

  .selectButton:hover {
    color: #fff;
    border: solid 1px #808080a3;
    background-color: #808080a3;
  }

  .normal {
    color: ${THEME_BACKGROUND_COLOR};
    border: solid 1px;
    background-color: #fff;
  }

  .normal:hover {
    background-color: #51a5c19c;
  }

  .select {
    color: #fff;
    border: solid 1px ${THEME_BACKGROUND_COLOR};
    background-color: ${THEME_BACKGROUND_COLOR};
  }

  .select:hover {
    background: #0087aa;
    border: 1px solid #0087aa;
    transition: all 0.6s;
  }
`;

export default withTranslation()(HomeMenuButtons);
