import * as HttpHandler from '../utils/HttpHandler';
import { SERVER_URI, BODY_JSON } from '../configs';
import axios from 'axios';

export function organizationNode(organizationId) {

    return axios.get(`${SERVER_URI}:5000/api/organization/organizations?organizationId=${organizationId}`);

}

export function jobTitleNode(jobTitleId) {

    return axios.get(`${SERVER_URI}:5000/api/organization/jobTitles?jobTitleId=${jobTitleId}`);

}

export function getOrganizationFromUCM() {

    return axios.get(`${SERVER_URI}:5000/api/organization/fromUCM?orgGroupCode=${localStorage.getItem('orgGroupCode')}`);

}



////////////////////////////////////안쓰는 API ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

export function organization(id) {
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/organization/api/v1/organizations/${id}`,
            'GET',
            BODY_JSON,
            undefined,
        );
}

export function jobTitle(id) {
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/organization/api/v1/jobTitles/${id}`,
            'GET',
            BODY_JSON,
            undefined,
        );
}

export function organizations() {
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/organization/api/v1/organizations`,
            'GET',
            BODY_JSON,
            undefined,
        );
}

export function jobTitles() {
    return HttpHandler
        .httpRequest(
            `${SERVER_URI}:8765/ucband/organization/api/v1/jobTitles`,
            'GET',
            BODY_JSON,
            undefined,
        );
}

////////////////////////////////////안쓰는 API ///////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

