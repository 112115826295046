import React, { useState, useEffect } from "react";
import TreeComponent from "./TreeComponent";
import axios from "axios";
import { SERVER_URI } from '../../../../configs';
import CheckedUser from './CheckedUser';
import { message, Button } from 'antd';
import * as UserService from '../../../../services/UserService';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import iconInvite from '../../../../assets/images/icon-invite.svg';

const OrgComponent = (props) => {
    const { t } = useTranslation();
    const [
        // org_list
        , _set_org_list] = useState([]);
    const [select_org, _set_select_org] = useState({
        org_group_name: "",
        org_group_code: "",
    });
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [checkedUsersInfo, setCheckedUsersInfo] = useState([]);
    const [checkedUsersUcTalkId, setCheckedUsersUcTalkId] = useState([]);
    const [users, setUsers] = useState([])

    const onCheck = (checkedKeys, info) => {
        setCheckedKeys(checkedKeys);
        let checkedUsersInfo = [];
        let checkedUsersUcTalkId = [];
        // eslint-disable-next-line array-callback-return
        info.checkedNodes.map(item => {
            if (item.class_kind === "1") {
                checkedUsersInfo.push(item)
                checkedUsersUcTalkId.push(item.class_user_id)
            }
        })
        setCheckedUsersUcTalkId(checkedUsersUcTalkId)
        setCheckedUsersInfo(checkedUsersInfo)
    };

    useEffect(() => {
        const userList = async () => {
            const response = await UserService.getAllUsersNode()
            setUsers(response)
        }
        userList();

        const _get_org_list = async () => {
            const {
                data: { result_set },
            } = await axios.get(`${SERVER_URI}:5000/api/tree/org/list`);
            //1. 모든 그룹 리스트를 가져오기  property  org_group_code, org_group_name, org_caption, domain_name
            _set_org_list(result_set);
            const [org] = result_set;
            console.log('org', org)
            //2. 모든 그룹 리스트 중 첫번째 그룹 선택 
            _set_select_org(org);
        };
        _get_org_list();
    }, []);

    const handleSubmit = async () => {
        if (checkedUsersUcTalkId.length === 0) {
            return message.warning(`${t('BandInvitationModal.pleaseSelectThePersonYouWantToInviteFirst')}`)
        }

        if (window.confirm("정말 초대하시겠습니까?")) {
            let chosenUserUIDs = []
            let bandMemberUIDs = []

            props.memberList.map((member, i) => {
                return bandMemberUIDs.push(member.id)
            })

            for (let index = 0; index < checkedUsersUcTalkId.length; index++) {
                const chosenUserFromTblUcTalkId = checkedUsersUcTalkId[index];
                for (let index = 0; index < users.length; index++) {
                    const entireUserElementFromBandUser = users[index];
                    if (chosenUserFromTblUcTalkId === entireUserElementFromBandUser.ucTalkId) {
                        chosenUserUIDs.push(entireUserElementFromBandUser.name)
                    }
                }
            }

            chosenUserUIDs = chosenUserUIDs.filter(function (val) {
                return bandMemberUIDs.indexOf(val) === -1;
            });

            UserService.sendNotificationToinvitedAllUsers(props.bandId, chosenUserUIDs, localStorage.getItem('userAccount'), localStorage.getItem('userName'), props.bandName)
                .then(() => message.success(`${t('BandInvitationModal.successfullySentNotificationsOnlyToNonBandMembers')}`))
                .catch(err => console.error(err))
            setCheckedKeys(['']);
            setCheckedUsersUcTalkId([])
            setCheckedUsersInfo([])
        }
    };
    return (
        <>
            <DivWrapper>
                <img src={iconInvite} alt="icon-invite" />&nbsp;
                            {t('BandInvitationModal.inviteMembers')}
            </DivWrapper>

            <div className="site-layout" >
                <div style={{ display: 'flex' }}>
                    <div style={{
                        margin: "0px 5px 20px 0px",
                        overflow: "auto",
                        padding: 16,
                        border: '1px solid lightgray',
                        borderRadius: '4px',
                        width: "40%",
                        maxHeight: '300px'
                    }}>
                        <TreeComponent
                            class_org_group_code={select_org.org_group_code}
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                        />
                    </div>
                    <div style={{
                        margin: "0px 0px 20px 5px",
                        overflow: "auto",
                        border: '1px solid lightgray',
                        borderRadius: '4px',
                        padding: 16,
                        width: "60%",
                    }}>
                        <CheckedUser checkedUsersInfo={checkedUsersInfo} />
                    </div>
                </div>
                {checkedUsersUcTalkId.length > 0 &&
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
                        <Button style={{
                            marginBottom: '1rem', width: '100%', height: '45px'
                        }}
                            onClick={handleSubmit}>{t('BandInvitationModal.inviteSelectedPeople')}
                        </Button>
                    </div>
                }
            </div>
        </>
    );
};

export default OrgComponent;

const DivWrapper = styled.div`
    padding: 0.5rem 0;
    width: 100%;
    display: flex;
    vertical-align: center;

    object-fit: contain;
    font-size: 1.3rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    img{
        width: 30px;
        height: 24.4px;
        object-fit: contain;
    }

    p { 
        margin-top: 4px;
        padding-left: 3px;
    }

`;
