import React from 'react';
import styled from 'styled-components';
import Datetime from 'react-datetime';

import { ScheduleRepeatModal, ScheduleColorSelectModal } from '../../../common/components/Modal';
import { ToggleButtonSelect } from "../../../common/components/Select";

import * as DateTimeUtils from '../../../utils/DateTimeUtils';
import * as StringUtils from '../../../utils/StringUtils';
import * as ScheduleService from '../../../services/ScheduleService';
import * as FileService from '../../../services/FileService';
import ReactModal from "react-modal";

import { SERVER_URI } from '../../../configs';
import { message, Input } from 'antd';
import pictureImg from '../../../assets/images/btn-picture.svg';
import FileImg from '../../../assets/images/btn-file.svg';
import * as BandService from "../../../services/BandService";
import { THEME_BACKGROUND_COLOR } from '../../../configs';
import { withTranslation } from 'react-i18next';
import DialogComp from '../../../common/components/Shared/DialogComp';
import { COMPANY_NAME } from '../../../configs';

import btnBack from '../../../assets/images/btn-gotoback-blue.svg';
import NoImage from '../../../assets/images/no-image.png';
import "react-datetime/css/react-datetime.css";

const { TextArea } = Input;

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

class Modify extends React.Component {

    _isMounted = false;

    defaultState = {
        bandId: this.props.match.params["bandId"],
        scheduleId: this.props.match.params["scheduleId"],
        isScheduleCategorySelectModalOpen: false,
        isScheduleRepeatModalOpen: false,
        title: '',
        description: '',
        defaultFileIds: [],
        attachFiles: [],
        attachFilePreviews: [],
        location: '',
        scheduleCategory: undefined,
        startDateTime: undefined,
        endDateTime: undefined,
        isAllDay: false,
        scheduleRepeatOption: 0,
        needToConfirm: false,
        submitted: false,
        scheduleColor: `${THEME_BACKGROUND_COLOR}`,
    };

    constructor(props) {
        super(props);
        this.state = this.defaultState;

        this.inputOpenImageRef = React.createRef();
        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            startDateTime: DateTimeUtils.current(),
        }, this.loadSchedule);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadSchedule = () => {
        const bandId = this.props.match.params["bandId"];
        const scheduleId = this.props.match.params["scheduleId"];

        let schedule, isBandLeader = false;
        if (bandId && scheduleId) {
            ScheduleService.getScheduleNode(bandId, scheduleId)
                .then(result => schedule = result)
                .then(() => BandService.currentBandMemberNode(bandId))
                .then(bandMember => isBandLeader = bandMember.bandMemberRole === 0)
                .then(() => {
                    console.log('schedule', schedule)
                    let defaultFileIds = [], attachFilePreviews = [];
                    if (schedule && schedule.attachedFiles) {
                        schedule.attachedFiles.forEach(file => {
                            defaultFileIds.push(file.id);
                            if ("IMAGE" === file.fileType) {
                                attachFilePreviews.push(`${SERVER_URI}:5000/_UPLOAD_FILES/${bandId}/${file.fileName}`);
                            } else {
                                attachFilePreviews.push(NoImage);
                            }
                        });
                    }
                    if (this._isMounted) {
                        this.setState({
                            bandId: bandId,
                            scheduleId: scheduleId,
                            schedule: schedule,
                            isBandLeader: isBandLeader,
                            title: schedule.title,
                            description: schedule.description,
                            location: schedule.location,
                            isAllDay: schedule.isAllDay,
                            startDateTime: DateTimeUtils.momentByDefaultFormat(schedule.startDateTime),
                            endDateTime: DateTimeUtils.momentByDefaultFormat(schedule.endDateTime),
                            needToConfirm: schedule.needToConfirm,
                            scheduleRepeatOption: schedule.scheduleRepeatType,
                            attachFilePreviews: attachFilePreviews,
                            defaultFileIds: defaultFileIds,
                            scheduleColor: schedule.scheduleColor
                        })
                    }
                })
                .catch(error => console.error(error));
        }
    };

    back = () => {
        if (this._isMounted) {
            this.setState(this.defaultState, this.props.history.goBack());
        }
    };

    routeScheduleHome = () => {
        if (this._isMounted) {
            this.setState(this.defaultState, this.props.history.push("/band/" + this.state.bandId + "/schedule"));
        }
    };

    cancel = () => {
        this.back();
    };

    handleInput = (e) => {
        if (this.state.hasOwnProperty(e.currentTarget.name)) {
            if (this._isMounted) {
                this.setState({ [e.currentTarget.name]: e.currentTarget.value });
            }
        }
    };

    handleDescription = (e) => {
        if (this._isMounted) {
            this.setState({
                description: e.currentTarget.value,
            })
        }
    };

    handleChange = (name, value) => {
        if (this.state.hasOwnProperty(name)) {
            if (this._isMounted) {
                this.setState({
                    [name]: value,
                });
            }
        }
    };

    selectScheduleColor = (selected) => {
        console.log(selected)
        let scheduleColor = undefined;
        if (selected) {
            scheduleColor = selected;
        }
        this.setState({
            scheduleColor: scheduleColor,
            scheduleCategory: scheduleColor,
            isScheduleCategorySelectModalOpen: false,
        });
    }

    handleStartDateTime = (value) => {
        if (this._isMounted) {
            this.setState({ startDateTime: value });
        }
    };

    handleEndDateTime = (value) => {
        if (this._isMounted) {
            this.setState({ endDateTime: value });
        }
    };

    selectScheduleCategory = (selected) => {
        let scheduleCategory = undefined;
        if (selected) {
            scheduleCategory = selected;
        }
        this.setState({
            scheduleCategory: scheduleCategory,
            isScheduleCategorySelectModalOpen: false,
        });
    };

    selectScheduleRepeat = (selected) => {
        this.setState({
            scheduleRepeatOption: selected,
            isScheduleRepeatModalOpen: false,
        });
    };

    openScheduleCategorySelectModal = () => {
        if (this._isMounted) {
            this.setState({ isScheduleCategorySelectModalOpen: true });
        }
    };

    closeScheduleRepeatModal = () => {
        if (this._isMounted) {
            this.setState({ isScheduleRepeatModalOpen: false })
        }
    };

    closeScheduleCategorySelectModal = () => {
        if (this._isMounted) {
            this.setState({ isScheduleCategorySelectModalOpen: false });
        }
    };

    openScheduleRepeatModal = () => {
        if (this._isMounted) {
            this.setState({ isScheduleRepeatModalOpen: true });
        }
    };

    openImageHandler = () => {
        this.inputOpenImageRef.current.click();
    };

    openFileHandler = () => {
        this.inputOpenFileRef.current.click();
    };

    attachImage = (e) => {
        e.stopPropagation();
        e.preventDefault();

        let file = e.currentTarget.files[0];
        if (file.size > Number(localStorage.getItem('maxFileSize'))) {
            return message.warning(`${this.props.t('Schedule.Create.theCurrentLimitOfUploadPerPersonHasBeenExceeded')}`)
        } else {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (this._isMounted) {
                    this.setState({
                        attachFiles: [...this.state.attachFiles, file],
                        attachFilePreviews: [...this.state.attachFilePreviews, reader.result],
                    });
                }
            };

            reader.readAsDataURL(file);
        }
    };

    attachFile = (e) => {
        e.stopPropagation();
        e.preventDefault();

        let file = e.currentTarget.files[0];
        if (file.size > Number(localStorage.getItem('maxFileSize'))) {
            message.warning(`${this.props.t('Schedule.Create.fileUploadCapacityLimit')} : 300mb`)
        } else {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (this._isMounted) {
                    this.setState({
                        attachFiles: [...this.state.attachFiles, file],
                        attachFilePreviews: [...this.state.attachFilePreviews, NoImage],
                    });
                }
            };

            reader.readAsDataURL(file);
        }
    };

    deleteFile = (index) => {
        if (this.state.attachFiles.length !== this.state.attachFilePreviews.length) {
            const diff = this.state.attachFilePreviews.length - this.state.attachFiles.length;
            if (index < diff) {
                console.log(this.state.defaultFileIds[index]);
                this.setState({
                    defaultFileIds: this.state.defaultFileIds.filter(item => item !== this.state.defaultFileIds[index]),
                    attachFilePreviews: this.state.attachFilePreviews.filter((item, i) => i !== index),
                }, () => console.log(this.state));
            } else {
                this.setState({
                    attachFiles: this.state.attachFiles.filter((item, i) => i !== (index - diff)),
                    attachFilePreviews: this.state.attachFilePreviews.filter((item, i) => i !== index),
                }, () => console.log(this.state));
            }
        } else {
            if (this._isMounted) {
                this.setState({
                    attachFiles: this.state.attachFiles.filter((item, i) => i !== index),
                    attachFilePreviews: this.state.attachFilePreviews.filter((item, i) => i !== index),
                }, () => console.log(this.state));
            }
        }
    };

    submit = async () => {
        if (StringUtils.isNullOrUndefined(this.state.title) && this.state.startDateTime) {
            message.warning(`${this.props.t('Schedule.Create.youMustSpecifytheTitle')}`);
            return;
        }

        if (!this.state.isAllDay && !this.state.endDateTime) {
            message.warning("요구되는 모든 항목을 입력해 주세요");
            return;
        }

        let startDateTime = DateTimeUtils.formatted(this.state.startDateTime);
        if (this.state.isAllDay) {
            startDateTime = this.state.startDateTime.format("YYYYMMDD") + "0000";
        }
        let endDateTime = this.state.endDateTime ? DateTimeUtils.formatted(this.state.endDateTime) : DateTimeUtils.formattedCurrent();
        if (this.state.isAllDay) {
            endDateTime = this.state.startDateTime.format("YYYYMMDD") + "2359";
        }

        if (startDateTime > endDateTime) {
            message.warning(`${this.props.t('Schedule.Create.theEndTimeMustBeAfterTheStartTime')}`);
            return;
        }

        if (this._isMounted) {
            this.setState({ submitted: true }, this.modifySchedule);
        }
    };

    modifySchedule = () => {
        let startDateTime = DateTimeUtils.formatted(this.state.startDateTime);
        if (this.state.isAllDay) {
            startDateTime = this.state.startDateTime.format("YYYYMMDD") + "0000";
        }
        let endDateTime = this.state.endDateTime ? DateTimeUtils.formatted(this.state.endDateTime) : DateTimeUtils.formattedCurrent();
        if (this.state.isAllDay) {
            endDateTime = this.state.startDateTime.format("YYYYMMDD") + "2359";
        }

        const scheduleCategoryId = this.state.scheduleCategory ? this.state.scheduleCategory.id : undefined;

        let attachedFileIds = undefined;
        if (this.state.defaultFileIds && this.state.defaultFileIds.length > 0) {
            attachedFileIds = [];
            this.state.defaultFileIds.forEach(id => {
                attachedFileIds.push(id);
            });
        }

        if (this.state.attachFiles && this.state.attachFiles.length > 0) {
            if (!attachedFileIds) {
                attachedFileIds = [];
            }


            const upload = async () => {
                await asyncForEach(this.state.attachFiles, async (file) => {
                    let response = await FileService.uploadFileNode(this.state.bandId, localStorage.getItem('userId'), file);
                    if (response && response.data.results.id) {
                        attachedFileIds.push(response.data.results.id);
                    }
                });
            };


            upload()
                .then(() => ScheduleService.modifyScheduleNode(
                    this.state.bandId, this.state.scheduleId, this.state.title, scheduleCategoryId, this.state.location, this.state.description,
                    this.state.isAllDay, startDateTime, endDateTime, this.state.scheduleRepeatOption,
                    this.state.needToConfirm, attachedFileIds, this.state.scheduleColor))
                .then(() => this.routeScheduleHome())
                .catch(error => {
                    console.error(error);
                    if (this._isMounted) {
                        this.setState({ submitted: false });
                    }
                });

        } else {
            ScheduleService.modifyScheduleNode(
                this.state.bandId, this.state.scheduleId, this.state.title, scheduleCategoryId, this.state.location, this.state.description,
                this.state.isAllDay, startDateTime, endDateTime, this.state.scheduleRepeatOption,
                this.state.needToConfirm, attachedFileIds, this.state.scheduleColor)
                .then(() => this.routeScheduleHome())
                .catch(error => {
                    console.error(error);
                    if (this._isMounted) {
                        this.setState({ submitted: false });
                    }
                });
        }
    };

    render() {
        const { t } = this.props;

        const scheduleCategory = (
            <div>
                <input type="checkbox" id="scheduleCategory" />
                <label htmlFor="scheduleCategory" style={{ backgroundColor: this.state.scheduleColor }} />
            </div>);


        const days = [
            t('Schedule.Modify.sun'),
            t('Schedule.Modify.mon'),
            t('Schedule.Modify.tue'),
            t('Schedule.Modify.wed'),
            t('Schedule.Modify.thu'),
            t('Schedule.Modify.fri'),
            t('Schedule.Modify.sat')
        ];

        const scheduleRepeatOptions = [
            "반복 안함",
            "매일",
            "매주",
            "매월 " + DateTimeUtils.formatFromString(this.state.startDateTime, "D") + "일",
            "매월 " + Math.ceil(DateTimeUtils.momentByFormat(this.state.startDateTime, "YYYY/MM/DD") / 7) + "번째 " + days[DateTimeUtils.formatFromString(this.state.startDateTime, "d")],
            "매년",
        ];

        return (
            <div className="wrapper">


                <div className="headerWrap">
                    <div className="headerContentsWrap" style={{ alignItems: 'center' }}>
                        <Back>
                            <a href={`/band/${this.state.bandId}/schedule`}><img src={btnBack} alt="Back" /></a>
                            <p style={{ marginTop: '-2px', fontWeight: 'bold' }}>&nbsp;&nbsp; {t('Schedule.Create.createASchedule')}</p>
                        </Back>
                        <Cancel>
                            <a href={`/band/${this.state.bandId}/schedule`}>{t('Schedule.Create.cancel')}</a>
                        </Cancel>
                    </div>
                </div>
                <div className="contentsWrapSpacer" />


                <div className="contentsWrap">

                    <CreateWrapper>
                        <div>
                            <Input type="text" placeholder={t('Schedule.Create.scheduleTitle')} name="title" value={this.state.title} onChange={this.handleInput} />
                        </div>
                        <Divider />

                        <Description>
                            <TextArea placeholder={t('Schedule.Create.pleaseEnterDescription')} value={this.state.description} onChange={this.handleDescription} />
                            {COMPANY_NAME !== "협업팜" &&
                                <div>
                                    <button onClick={this.openImageHandler}><img src={pictureImg} alt="pictureImage" /></button>
                                    <button onClick={this.openFileHandler}><img src={FileImg} alt="FileImage" /></button>
                                </div>
                            }
                        </Description>
                        <Divider />

                        {this.state.attachFilePreviews && this.state.attachFilePreviews.length > 0 &&
                            <div className="band-cover-selector-grid">
                                {this.state.attachFilePreviews.map((attachFilePreview, index) => (
                                    <div key={index}>
                                        <img src={attachFilePreview} alt="" />
                                        <button onClick={() => this.deleteFile(index)}>삭제</button>
                                    </div>
                                ))}
                            </div>
                        }
                        {/* <div>
                            <Input type="text" placeholder="위치" name="location" value={this.state.location} onChange={this.handleInput} />
                        </div> */}
                        <Divider />
                        <br />

                        <ButtonSelect>
                            <ScheduleCategory>{scheduleCategory}</ScheduleCategory>
                            <Select>
                                <button onClick={this.openScheduleCategorySelectModal}>선택</button>
                            </Select>
                        </ButtonSelect>

                        <ToggleButtonSelect title={t('Schedule.Modify.allDay')}
                            name="isAllDay"
                            defaultValue={this.state.isAllDay}
                            onChange={this.handleChange}
                        />
                        <DateTimeWrapper>
                            <div style={{ width: '20%' }}><p>{t('Schedule.Modify.start')}</p></div><Datetime className="dataTimeInput" dateFormat="YYYY/MM/DD" timeFormat={!this.state.isAllDay} value={this.state.startDateTime} onChange={this.handleStartDateTime} />
                        </DateTimeWrapper>

                        <Divider />
                        {!!!this.state.isAllDay &&
                            <>
                                <DateTimeWrapper>
                                    <div style={{ width: '20%' }}><p>{t('Schedule.Modify.end')}</p></div><Datetime className="dataTimeInput" dateFormat="YYYY/MM/DD" timeFormat={!this.state.isAllDay} value={this.state.endDateTime} onChange={this.handleEndDateTime} />
                                </DateTimeWrapper>
                                <Divider />
                            </>
                        }
                        {/* <ButtonSelect>
                            <Title>반복 등록</Title>
                            <Select>
                                <button onClick={this.openScheduleRepeatModal}>
                                    {scheduleRepeatOptions[this.state.scheduleRepeatOption]}
                                </button>
                            </Select>
                        </ButtonSelect>
                        <Divider /> */}
                        <ToggleButtonSelect title={t('Schedule.Create.requestToParticipate')}
                            name="needToConfirm"
                            defaultValue={this.state.needToConfirm}
                            onChange={this.handleChange}
                        />
                        <Divider />
                        <br />
                        <Submit>
                            <button onClick={this.submit}>{t('Schedule.Create.submit')}</button>
                        </Submit>
                    </CreateWrapper>


                    <ReactModal
                        isOpen={this.state.isScheduleCategorySelectModalOpen}
                        onRequestClose={this.closeScheduleCategorySelectModal}
                        style={CustomStyles}
                    >
                        <ScheduleColorSelectModal
                            show={this.state.isScheduleCategorySelectModalOpen}
                            bandId={this.state.bandId}
                            onSelect={this.selectScheduleColor}
                            onClose={this.closeScheduleCategorySelectModal}
                        />
                    </ReactModal>


                    <ScheduleRepeatModal show={this.state.isScheduleRepeatModalOpen}
                        onSelect={this.selectScheduleRepeat}
                        options={scheduleRepeatOptions}
                        onClose={this.closeScheduleRepeatModal}
                    />

                    <DialogComp description={t('Schedule.Create.postIsBeingUploaded')} open={this.state.submitted} />

                    <input type="file" accept="image/*" ref={this.inputOpenImageRef} style={{ display: "none" }} onChange={this.attachImage} />
                    <input type="file" accept="*" ref={this.inputOpenFileRef} style={{ display: "none" }} onChange={this.attachFile} />

                </div>
            </div>
        );
    }

}

const DateTimeWrapper = styled.div`
    padding: 0.5rem 0;
    display: flex;
    flexWrap: nowrap;

    p {
        font-size: 1rem;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        margin-top: 14px !important;
    }
`;

const Divider = styled.div`
    border-bottom: ${props => props.bigger ? "solid 5px #e5e5ea" : "solid 1px #e5e5ea"};
    margin-bottom: 5px;
`;

const Description = styled.div`
`;

const Back = styled.div`
    width: 50%;
    text-align: left;
    display: flex;
    button {
      margin: 0;
      padding: 0;
    }
    img {
      height: 1rem;
    }
`;

const Cancel = styled.div`
    width: 50%;
    text-align: right;
    button {
      color: #51A5C1;
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
`;

const ScheduleCategory = styled.div`
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 1em;
    
    span {
      padding: 0 .5rem;
      vertical-align: middle;
    }
    
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      margin: .3rem;
      border-radius: 50%;
      vertical-align: middle;
    }
`;

const ButtonSelect = styled.div`
    display: flex;
    flex-grow: 1;
    padding: .5rem 0;
`;

const Select = styled.div`
    width: 50%;
    text-align: right;
    button {}
`;

const CreateWrapper = styled.div`
`;

const Submit = styled.div`
    width: 100%;
    text-align: center;
    
    button {
      width: 100%;
      background-color: ${THEME_BACKGROUND_COLOR};
      border-radius: 0.5rem;
      color: #FFF;
      font-size: 1rem;
      font-weight: bold;
      border: 1px solid #aeaeae;
      line-height: 1rem;
      padding: 1rem 1.5rem;
    }
`;

export default withTranslation()(Modify);
const CustomStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    }, overlay: { zIndex: 1000 }
};