import React from "react";

import {
    TableOutlined,
    SettingOutlined,
    RollbackOutlined
} from '@ant-design/icons';

const menuList = [
    {
        title: "상태",
        path: "/admin/status",
        icon: <TableOutlined />,
        roles: ["admin", "editor", "guest"]
    },
    {
        title: "설정",
        path: "/admin/settings",
        icon: <SettingOutlined />,
        roles: ["admin", "editor", "guest"]
    },
    {
        title: "돌아가기",
        path: "/myBand",
        icon: <RollbackOutlined />,
        roles: ["admin", "editor", "guest"]
    },
    // {
    //   title: "路由嵌套",
    //   path: "/nested",
    //   icon: "cluster",
    //   roles:["admin","editor"],
    //   children: [
    //     {
    //       title: "菜单1",
    //       path: "/nested/menu1",
    //       children: [
    //         {
    //           title: "菜单1-1",
    //           path: "/nested/menu1/menu1-1",
    //           roles:["admin","editor"],
    //         },
    //         {
    //           title: "菜单1-2",
    //           path: "/nested/menu1/menu1-2",
    //           children: [
    //             {
    //               title: "菜单1-2-1",
    //               path: "/nested/menu1/menu1-2/menu1-2-1",
    //               roles:["admin","editor"],
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },
];
export default menuList;
