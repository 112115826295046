import React, { Component } from 'react'
import styled from 'styled-components';
import * as BandService from '../services/BandService';
import * as StringUtils from '../utils/StringUtils';

import { SERVER_URI, THEME_BACKGROUND_COLOR, COMPANY_NAME } from '../configs';
import { BandMenu } from '../components/Menu';
import { Row, Col, Avatar } from 'antd';
import Dropzone from 'react-dropzone';
import { Spin } from 'antd';

import { bandCategoryList, bandCategoryListEng } from '../data';
import { withTranslation } from 'react-i18next';
import NoImage  from '../assets/images/no-img.svg';

class DesktopHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            band: undefined,
            leaders: [],
            memberType: 0,
            isBandMember: false,
            isOpened: false,
            loading: true,
            bandImage: "",
            isLoadingForUploadingCoverImage: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBandInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandInfo = () => {
        let leaders = [], memberType;
        BandService.getBandInfo(this.props.bandId)
            .then(result => {
                leaders = result.bandMembers.filter(bandMember => bandMember.bandMemberRole === 0);
                if (result.isBandMember) {
                    memberType = 2; // 밴드 멤버
                } else {
                    if (result.bandMemberRole && result.bandMemberRole === 2) {
                        memberType = 1; // 밴드 가입 승인 대기
                    } else {
                        memberType = 0; // 밴드 미가입 멤버
                    }
                }
                if (this._isMounted) {
                    this.setState({
                        band: result.band,
                        leaders: leaders,
                        memberType: memberType,
                        isBandMember: result.isBandMember,
                        isOpened: result.isOpened,
                        loading: false,
                        bandImage: result.band.coverImage
                    });
                }
            })
            .catch(error => console.error(error));
    };

    bandCategory = (band) => {
        if (band && band.bandCategoryId) {

            let bandCategoryListVar;
            if (localStorage.getItem('i18nextLng') === "english") {
                bandCategoryListVar = bandCategoryListEng
            } else {
                bandCategoryListVar = bandCategoryList
            }

            const foundCategories = bandCategoryListVar.filter(category => category.id === band.bandCategoryId);
            if (foundCategories && foundCategories.length > 0) {
                return foundCategories[0].name;
            }
        }
        return "";
    };

    onDrop = (files) => {
        this.setState({ isLoadingForUploadingCoverImage: true })

        BandService.uploadCoverImageNode(this.props.bandId, files[0])
            .then(response => {
                this.setState({ bandImage: response.data.coverImageName, isLoadingForUploadingCoverImage: false })

                setTimeout(() => {
                    this.setState({ isLoadingForUploadingCoverImage: false })
                }, 1000);
            })
            .catch(err => console.error(err))
    }


    render() {
        const { band, leaders, loading, bandImage } = this.state;
        const { history, t } = this.props;

        const leader = leaders ? (leaders.length > 0 ? leaders[0].name : "") : "";

        let coverImage;
        if (band && !StringUtils.isNullOrUndefined(band.coverImage)) {
            if (COMPANY_NAME !== "UC WARE") {
                coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${bandImage}`;
            } else {
                coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/s_${bandImage}`;
            }
        } else {
            coverImage = NoImage;
        }

        let button;
        if (this.props.memberType === 0) {
            button = <button onClick={this.props.joinFunction}>{t('Home.join')}</button>;
        } else if (this.props.memberType === 1) {
            button = <button disabled={true}>{t('Home.watingforApproval')}</button>;
        } else {
            button = <a style={{ color: 'white' }} href={`/band/${this.props.bandId}/members`} ><button >{t('Home.invite')}</button></a>;
            // button = <a style={{ color: 'white' }} href={`/band/${this.props.bandId}/invitation`} ><button >{"초대하기"}</button></a>;
        }

        return (
            <div>
                {loading ?
                    <div></div> :
                    <>
                        <BandMenu pathName="home" bandId={this.props.bandId} historyProp={history} />
                        

                        <HideOnMobile>
                            {this.props.home &&
                                <>
                                    <SpacerForHeader />
                                    <Row style={{ maxWidth: '700px', width: '100%', margin: '32px auto ' }}>
                                        <Col span={8} >
                                            {this.props.isBandLeader ?
                                                <Dropzone
                                                    accept="image/*"
                                                    onDrop={this.onDrop}
                                                    multiple={false}
                                                    maxSize={800000000}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div style={{ cursor: 'pointer', width: "73%" }} {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <Avatar size={170} src={coverImage} alt="cover" />
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                :
                                                <Avatar size={170} src={coverImage} alt="cover" />
                                            }
                                        </Col>
                                        <Col span={1} />
                                        <Col span={9} >
                                            <Info>
                                                <Title>
                                                    <Name>{band.name}</Name>

                                                </Title>
                                                <Additional>{`${t('Home.leader')} ${leader} |  ${t('Home.numberOfMembers')} ${band.memberCount} | ${this.bandCategory(band)}`}</Additional>
                                                {band.tags &&
                                                    <Tags>
                                                        {band.tags.map((tag, index) => <span key={index}>#{tag}</span>)}
                                                    </Tags>
                                                }

                                                {this.props.home
                                                    &&
                                                    <Invite desktop>
                                                        {button}
                                                    </Invite>
                                                }

                                            </Info>
                                        </Col>
                                        <Col span={6}>

                                        </Col>
                                    </Row>
                                </>
                            }

                        </HideOnMobile>

                        {this.state.isLoadingForUploadingCoverImage &&
                            <div style={{ textAlign: 'center' }}>
                                <Spin tip="Loading..." />
                            </div>
                        }

                        <CenterContent schedule={this.props.schedule} home={this.props.home}>
                            {this.props.children}
                        </CenterContent>

                    </>}
            </div>
        )
    }
}


export default withTranslation()(DesktopHeader);

const SpacerForHeader = styled.div`
    height: 55px;
    @media (max-width: 500px) {
       height: 0px;
      }
`

const HideOnMobile = styled.div`
@media (max-width: 500px) {
    display: none;
  } 
`

const CenterContent = styled.div`
    max-width: ${props => props.home && "900px"};
    width:${props => props.home ? "100%" : props.schedule ? "80%" : "600px"};
    height: 100%;
    margin: 0 auto;
    margin-top:-70px;
    @media (max-width: 500px) {
        width: 100%;
      }
`;


const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
`;

const Name = styled.div`
    text-align: left;
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    width: fit-content;
    padding: 0rem 0 0.3rem;
`;

const Info = styled.div`
    padding: 0.5rem 0;
    @media (max-width: 500px) {
        padding: 1rem 1rem 0 1rem;
    }
`;

const Additional = styled.div`
    padding: .2rem 0rem;
    color: #8e8e93;
`;

const Tags = styled.div`
    padding: .3rem 0rem;
    span {
      padding-right: .5rem;
      font-weight: bold;
    }
    word-break: break-all;
`;


const Invite = styled.div`
    display: flex;
    max-width: 180px;
    flex-direction: row-reverse;
    padding: 0 .5rem;
    background-color: ${THEME_BACKGROUND_COLOR};
    border-radius:  ${props => props.desktop ? "0" : "1rem"};
        color: #FFF;
        font-size: .8rem;
        font-weight: bold;
    button {
        padding: .5rem .5rem;
        color: #FFF;
        }
`;