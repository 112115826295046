import React from "react";
import styled from 'styled-components';
import { globalSettingsNode, updateGlobalSettingsNode } from "../../services/SettingsService";
import Layout from '../Layout/Layout';
import { Button } from 'antd';

class AdminSettings extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            maxBandNameLength: undefined,
            maxBandDescriptionLength: undefined,
            maxBandCoverImageSize: undefined,
            maxProfileImageSize: undefined,
            maxPostContentLength: undefined,
            maxFileSize: undefined,
            maxChatMessageLength: undefined,
            maxDiskUsage: undefined,
            maxPersonalDiskUsage: undefined
        };
    }

    componentDidMount() {

        this._isMounted = true;
        globalSettingsNode()
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        maxBandNameLength: localStorage.getItem('maxBandNameLength'),
                        maxBandDescriptionLength: localStorage.getItem('maxBandDescriptionLength'),
                        maxBandCoverImageSize: localStorage.getItem('maxBandCoverImageSize') / 1024 / 1024,
                        maxProfileImageSize: localStorage.getItem('maxProfileImageSize') / 1024 / 1024,
                        maxPostContentLength: localStorage.getItem('maxPostContentLength'),
                        maxFileSize: localStorage.getItem('maxFileSize') / 1024 / 1024,
                        maxChatMessageLength: localStorage.getItem('maxChatMessageLength'),
                        maxDiskUsage: localStorage.getItem('maxDiskUsage') / 1024 / 1024 / 1024,
                        maxPersonalDiskUsage: localStorage.getItem('maxPersonalDiskUsage') / 1024 / 1024 / 1024
                    });
                }
            })
            .catch(error => console.error(error));

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = (e) => {
        if (this.state.hasOwnProperty(e.target.name)) {
            if ("maxFileSize" === e.target.name && Number(e.target.value) > 300) {
                alert("첨부파일은 300MB를 넘을 수 없습니다.");
                return;
            }
            if (this._isMounted) {
                this.setState({
                    [e.target.name]: Number(e.target.value),
                }, () => console.log(this.state));
            }
        }
    };

    save = () => {
        const {
            maxBandNameLength, maxBandDescriptionLength,
            maxBandCoverImageSize, maxProfileImageSize, maxPostContentLength,
            maxFileSize, maxChatMessageLength, maxDiskUsage, maxPersonalDiskUsage } = this.state;
        updateGlobalSettingsNode(maxBandNameLength, maxBandDescriptionLength,
            maxBandCoverImageSize, maxProfileImageSize, maxPostContentLength,
            maxFileSize, maxChatMessageLength, maxDiskUsage, maxPersonalDiskUsage)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        maxBandNameLength: localStorage.getItem('maxBandNameLength'),
                        maxBandDescriptionLength: localStorage.getItem('maxBandDescriptionLength'),
                        maxBandCoverImageSize: localStorage.getItem('maxBandCoverImageSize') / 1024 / 1024,
                        maxProfileImageSize: localStorage.getItem('maxProfileImageSize') / 1024 / 1024,
                        maxPostContentLength: localStorage.getItem('maxPostContentLength'),
                        maxFileSize: localStorage.getItem('maxFileSize') / 1024 / 1024,
                        maxChatMessageLength: localStorage.getItem('maxChatMessageLength'),
                        maxDiskUsage: localStorage.getItem('maxDiskUsage') / 1024 / 1024 / 1024,
                        maxPersonalDiskUsage: localStorage.getItem('maxPersonalDiskUsage') / 1024 / 1024 / 1024
                    });
                }
                alert("성공적으로 설정이 저장되었습니다.")
            })
            .catch(error => console.error(error));
    };

    render() {

        const {
            maxBandNameLength, maxBandDescriptionLength,
            maxBandCoverImageSize,
            // maxProfileImageSize, 
            maxPostContentLength,
            maxFileSize, maxChatMessageLength, maxDiskUsage, maxPersonalDiskUsage } = this.state;
        return <>
            <Layout>
                <h2>
                    전체 협업팜 설정
                </h2>
                <SettingInputWrapper >
                    <Wrapper>
                        <Title>
                            최대 협업팜 명 길이
                        </Title>
                        <input type="number" pattern="[0-9]*" inputMode="numeric" name="maxBandNameLength" value={maxBandNameLength} onChange={this.handleChange} />
                        &nbsp;자
                    </Wrapper>
                    <Wrapper>
                        <Title>
                            최대 협업팜 설명 길이
                        </Title>
                        <input type="number" pattern="[0-9]*" inputMode="numeric" name="maxBandDescriptionLength" value={maxBandDescriptionLength} onChange={this.handleChange} />
                        &nbsp;자
                    </Wrapper>
                    <Wrapper>
                        <Title>
                            최대 협업팜 커버 사이즈
                        </Title>
                        <input type="number" pattern="[0-9]*" inputMode="numeric" name="maxBandCoverImageSize" value={maxBandCoverImageSize} onChange={this.handleChange} />
                        &nbsp;MB
                    </Wrapper>
                    {/* <Wrapper>
                    <Title>
                        최대 사용자 프로필 사이즈
                        </Title>
                    <input type="number" pattern="[0-9]*" inputMode="numeric" name="maxProfileImageSize" value={maxProfileImageSize} onChange={this.handleChange} />
                        &nbsp;MB
                    </Wrapper> */}
                    <Wrapper>
                        <Title>
                            최대 게시글 길이
                        </Title>
                        <input type="number" pattern="[0-9]*" inputMode="numeric" name="maxPostContentLength" value={maxPostContentLength} onChange={this.handleChange} />
                        &nbsp;자
                    </Wrapper>
                    <Wrapper>
                        <Title>
                            최대 첨부 파일 크기
                        </Title>
                        <input type="number" pattern="[0-9]*" inputMode="numeric" name="maxFileSize" value={maxFileSize} onChange={this.handleChange} />
                        &nbsp;MB
                    </Wrapper>
                    <Wrapper>
                        <Title>
                            최대 채팅 메시지 길이
                        </Title>
                        <input type="number" pattern="[0-9]*" inputMode="numeric" name="maxChatMessageLength" value={maxChatMessageLength} onChange={this.handleChange} />
                        &nbsp;자
                    </Wrapper>
                    <Wrapper>
                        <Title>
                            최대 협업팜 디스크 사용량
                        </Title>
                        <input type="number" pattern="[0-9]*" inputMode="numeric" name="maxDiskUsage" value={maxDiskUsage} onChange={this.handleChange} />
                        &nbsp;GB
                    </Wrapper>
                    <Wrapper>
                        <Title>
                            최대 개인 디스크 사용량
                        </Title>
                        <input type="number" pattern="[0-9]*" inputMode="numeric" name="maxPersonalDiskUsage" value={maxPersonalDiskUsage} onChange={this.handleChange} />
                        &nbsp;GB
                    </Wrapper>
                    <div>
                        <Button style={{ width: '100%' }} onClick={this.save}><p>저장</p></Button>
                    </div>
                </SettingInputWrapper>
            </Layout>
        </>;
    }

}

const SettingInputWrapper = styled.div`
    max-width: 273px;
    input {
        width: 70px !important;
    }
`;

const Wrapper = styled.div`
    display: flex;
    margin-bottom: 3px;
    width: 100%;
    input {
        width: 70%;
        max-width: 240px;
        border: 1px solid #eeeeee;
    }
`;

const Title = styled.div`
    width: 175px;
    max-width: 200px;
    text-align: left;
`;

export default AdminSettings;
