import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "100%",
    marginBottom: '5px',
  },
  media: {
    height: 190,
  },
}));

function Media(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={           
            <Skeleton variant="circle" width={40} height={40} />
        }
        action={
          null 
        }
        title={<Skeleton height={6} width="80%" />}
        subheader={ <Skeleton height={6} width="40%" />}
      />
        <Skeleton variant="rect" className={classes.media} />

      <CardContent>

          <React.Fragment>
            <Skeleton height={6} />
            <Skeleton height={6} width="80%" />
          </React.Fragment>

      </CardContent>
    </Card>
  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};

export default function BandSkeleton() {
  return (
    <div>
      <Media />
    </div>
  );
} 