export const BEARER_TOKEN_PREFIX = "Bearer ";

// SERVER URI
// export const SERVER_URI = "http://localhost";
// export const SERVER_URI = 'http://192.168.0.83'; // Oracle_test
export const SERVER_URI = "http://49.50.164.80"; // UC Portal
// export const SERVER_URI = 'http://10.134.44.81'; // EWP
// export const SERVER_URI = 'http://10.1.1.6'; // 국토 연구원
// export const SERVER_URI = 'http://192.168.0.58'; // 사내 서버 (오라클 디비)
// export const SERVER_URI = 'http://203.233.204.61'; // 무역 협회
// export const SERVER_URI = "http://211.182.233.74"; // 부산 교육청 운영

// SOCKET SERVER URI
// export const SOCKET_SERVER_URI = "http://localhost:5000";
export const SOCKET_SERVER_URI = "http://49.50.164.80:5000"; // UC Portal
// export const SOCKET_SERVER_URI = 'http://10.134.44.81:5000'; // EWP
// export const SOCKET_SERVER_URI = 'http://10.1.1.6:5000'; // 국토 연구원
// export const SOCKET_SERVER_URI = 'http://192.168.0.58:5000'; // 사내 서버 (오라클 디비)
// export const SOCKET_SERVER_URI = 'http://203.233.204.61:5000'; // 무역 협회
// export const SOCKET_SERVER_URI = 'http://192.168.0.83:5000'; // Oracle_test
// export const SOCKET_SERVER_URI = "http://211.182.233.74:5000"; // 부산 교육청 운영

// export const DB_KIND = "ORACLE";
export const DB_KIND = "MYSQL";

export const COMPANY_NAME = "UC WARE";
// export const COMPANY_NAME = "HOME";  //국토 정보원
// export const COMPANY_NAME = "EWP";
// export const COMPANY_NAME = "협업팜";
// export const COMPANY_NAME = "무역센터";

export const THEME_BACKGROUND_COLOR = "#51A5C1";
export const THEME_COLOR = "#808080";
export const BODY_JSON = "JSON";
export const BODY_FILE = "FILE";
export const POST_PAGE_SIZE = 20;

const iconHeart = require("../assets/images/icon-heart.svg");
const iconThumbUp = require("../assets/images/icon-thumbs-up.svg");
const iconLike = require("../assets/images/icon-like.svg");
const iconSad = require("../assets/images/icon-sad.svg");
const iconAngry = require("../assets/images/icon-angry.svg");

export const expressions = [
  { id: 0, icon: iconHeart, description: "사랑해요", eng: "Love" },
  { id: 1, icon: iconThumbUp, description: "최고에요", eng: "Best" },
  { id: 2, icon: iconLike, description: "좋아요", eng: "Like" },
  { id: 3, icon: iconSad, description: "슬퍼요", eng: "Sad" },
  { id: 4, icon: iconAngry, description: "화나요", eng: "Angry" },
];

export const viewOrderTypeOptions = [
  { value: 0, label: "최신순", eng: "By newest", ja: "最新のもの" },
  { value: 1, label: "오래된순", eng: "By oldest", ja: "最古のもの" },
  { value: 2, label: "이름순", eng: "By name", ja: "名前で" },
];

const iconChecked = require("../assets/images/checked.svg");
const iconUnchecked = require("../assets/images/unchecked.svg");
const iconNotYet = require("../assets/images/notyet.svg");

export const attendStatus = [
  { id: 0, icon: iconChecked, description: "참석", eng: "Attend" },
  { id: 1, icon: iconUnchecked, description: "불참", eng: "Nonattendance" },
  { id: 2, icon: iconNotYet, description: "미정", eng: "Undecided" },
];

export const scheduleColors = [
  `${THEME_BACKGROUND_COLOR}`,
  "#ff2d55",
  "#ff9500",
  "#ffcc00",
  "#4cd964",
  "#07b83c",
  "#007aff",
  "#5856d6",
  "#05d4b1",
];

//bandMemberRole[0] : 리더
//bandMemberRole[1] : 일반 멤버
//bandMemberRole[2] : 승인 되지 못한 멤버
//bandMemberRole[99]: 차단 멤버

// memberType = 0; // 리더
// memberType = 1; // 일반 멤버
// memberType = 2; // 가입 승인 요청 멤버
