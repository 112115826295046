import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip'

const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;
const __ISIOS__ = navigator.userAgent.match(/iPad|iPhone|iPod/i) ? true : false;

const radioUncheckedImg = require("../../../assets/images/radio-unchecked.svg");
const radioCheckedImg = require("../../../assets/images/radio-checked.svg");

class RadioButtonSelect extends React.Component {

    _isMounted = false;

    title;
    name;
    defaultValue;
    options;
    onChange;

    constructor(props) {
        super(props);
        this.state = {
            selectValue: this.props.defaultValue,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.defaultValue !== prevProps.defaultValue) {
            if (this._isMounted) {
                this.setState({ selectValue: this.props.defaultValue });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleOptionChange = e => {
        if (this._isMounted) {

            this.setState({
                selectValue: Number(e.target.value),
            }, () => this.props.onChange(this.props.name, this.state.selectValue));
        }
    };

    render() {
        const { title, options } = this.props;

        return (
            <Container>
                <Title>
                    {title}
                </Title>
                <Select>
                    {options.map((option, index) =>
                        <Option isMSIE={__ISMSIE__} key={`radio-select-option-${index + 1}`}>
                            <label>
                                <Input
                                    isMSIE={__ISMSIE__}
                                    isIOS={__ISIOS__}
                                    type="radio"
                                    value={option.value}
                                    checked={this.state.selectValue === Number(option.value)}
                                    onChange={this.handleOptionChange}
                                />
                                <span data-tip data-for={`happyface-${index}`} >{option.label}</span>
                                <ReactTooltip id={`happyface-${index}`} place="right" type={option.type} >
                                    <span>{option.tooltip}</span>
                                </ReactTooltip>
                            </label>
                        </Option>
                    )}
                </Select>
            </Container>
        );
    }
}

const Container = styled.div`
    padding: .5rem 0;
`;

const Title = styled.div`
    text-align: left;
    padding: 0 0 .5rem 0;
    font-size: 1rem;
    font-weight: bold;
`;

const Select = styled.div`
`;

const Option = styled.div`
    label {}
`;

const Input = styled.input`
    width: 17px;
    -webkit-appearance: ${props => props.isIOS ? null : `none`};
    background: ${props => props.isMSIE || props.isIOS ? null : `url(${radioUncheckedImg})`}; 
    background-size: 15px;
    height: 20px;
    background-repeat: ${props => props.isIOS ? null : `no-repeat`};
    background-position: 0px 5px;
    margin-bottom: -3px;
    margin-right: 3px;
    margin-top: 5px;

    &:checked {
    background: ${props => props.isMSIE || props.isIOS ? null : `url(${radioCheckedImg})`};
    width: 17px;
    -webkit-appearance: ${props => props.isIOS ? null : `none`};
    background-size: 15px;
    height: 20px;
    background-repeat: ${props => props.isIOS ? null : `no-repeat`};
    background-position: 0px 5px;
    margin-bottom: -3px;
    margin-right: 3px;
    margin-top: 5px;
    }
`;

export default RadioButtonSelect;
