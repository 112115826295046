import React from 'react';
import '../../../assets/css/_modal.scss';
import { withTranslation } from 'react-i18next';
import * as StringUtils from '../../../utils/StringUtils';

class UserProfileModal extends React.Component {

    show;
    onSelect;
    options;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            showAdding: false,
            loaded: false,
            selectedOption: 0,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    select = (selected) => {
        if (this._isMounted) {
            this.setState({
                selectedOption: selected,
            }, () => this.props.onSelect(selected));
        }
    };

    render() {
        if (!this.props.show) {
            return null;
        }

        const { t, profileImage, name, jobTitle, nickname, closeUserProfileModal } = this.props;

        return (
            <div className="modal_wrap">
                <div className="modal">
                    <div className="close_btn" onClick={closeUserProfileModal}></div>
                    <div>
                        <p className="title">{t('PostList.userProfile')}</p>
                        <div className="modal_content_userProfile">
                            <img
                                className="myThumb"
                                src={profileImage} alt={nickname}
                            />

                            <div className="userName">{name}</div>
                            <div className="userInfo">
                                {!StringUtils.isNullOrUndefined(jobTitle) && jobTitle}
                            </div>
                        </div>
                        <div>
                            <button className="modal_submit_btn" onClick={closeUserProfileModal}>
                                {t('PostList.submit')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(UserProfileModal);
