import React from 'react';
import styled from 'styled-components';

import * as FileService from '../../../services/FileService';
import * as ReplyService from '../../../services/ReplyService';
import * as StringUtils from '../../../utils/StringUtils';
import * as UserService from '../../../services/UserService';
import * as BandService from '../../../services/BandService';

import { SERVER_URI } from '../../../configs';

import SimpleEditorToolTip from '../ToolTip/SimpleEditorFileToolTip.tsx';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import btnAdd from '../../../assets/images/btn-add.svg';
import btnSend from '../../../assets/images/btn-send.svg';
import NoImage from '../../../assets/images/no-image.png';
import Modal from 'react-modal';
import { ReplyFileSelectModal } from '../Modal';

const MAX_SIZE_STORAGE = localStorage.getItem("maxPersonalDiskUsage");
class SimpleEditor extends React.Component {

  placeholder;
  onSubmit;
  bandId;
  defaultValue;
  _isMounted;

  defaultState = {
    openMenu: false,
    reply: "",
    fileId: undefined,
    attachFile: undefined,
    attachFileType: undefined,
    attachFilePreview: undefined,
    fileChangeType: "NONE",
  };

  constructor(props) {
    super(props);

    this.state = this.defaultState;

    this.inputOpenImageRef = React.createRef();
    this.inputOpenVideoRef = React.createRef();
    this.inputOpenFileRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!!prevProps.defaultValue && !!this.props.defaultValue) {
      this.loadReply();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadReply = () => {
    ReplyService.getReplyNode(this.props.bandId, this.props.defaultValue)
      .then((reply) => {
        console.log("reply", reply);
        if (this._isMounted) {
          this.setState(
            {
              reply: reply.reply,
              fileId: reply.fileId,
            },
            this.loadFileInfo
          );
        }
      })
      .catch((error) => console.error(error));
  };

  loadFileInfo = () => {
    if (this.state.fileId) {
      FileService.getFileInfoNode(this.props.bandId, this.state.fileId)
        .then((fileInfo) => {
          console.log("fileInfo :", fileInfo);
          let attachFile, attachFileType, attachFilePreview;
          attachFile = fileInfo.id;
          attachFileType =
            fileInfo.fileType === "IMAGE"
              ? "image"
              : fileInfo.fileType === "VIDEO"
              ? "video"
              : "file";
          if (attachFileType === "image" || attachFileType === "video") {
            attachFilePreview = `${SERVER_URI}:5000/_UPLOAD_FILES/${this.props.bandId}/${fileInfo.fileName}`;
          } else {
            attachFilePreview = NoImage;
          }

          if (this._isMounted) {
            this.setState({
              attachFile: attachFile,
              attachFileType: attachFileType,
              attachFilePreview: attachFilePreview,
            });
          }
        })
        .catch((error) => console.error(error));
    }
  };

  handleText = (e) => {
    if (this._isMounted) {
      this.setState({ reply: e.currentTarget.value });
    }
  };

  openMenu = () => {
    if (this._isMounted) {
      this.setState({ openMenu: true });
    }
  };

  closeMenu = () => {
    if (this._isMounted) {
      this.setState({ openMenu: false });
    }
  };

  selectMenu = (menu) => {
    if (menu === "image") {
      this.inputOpenImageRef.current.click();
    } else if (menu === "video") {
      this.inputOpenVideoRef.current.click();
    } else {
      this.inputOpenFileRef.current.click();
    }
    this.closeMenu();
  };

  attachImage = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.persist();

    let fileChangeType = "ADD";
    if (this.state.fileId) {
      fileChangeType = "CHANGED";
    }

    let getBandStatusResult = await BandService.getBandStatusNode(
      this.props.bandId
    );
    console.log("getBandStatusResult", getBandStatusResult);
    if (
      getBandStatusResult.diskUsage >
      localStorage.getItem("maxPersonalDiskUsage")
    ) {
      return alert(`밴드 디스크 용량을 초과했습니다.`);
    }

    let userUploadFileSizeResult = await UserService.userUploadFileSize();
    if (userUploadFileSizeResult.data.totalSize > MAX_SIZE_STORAGE) {
      return alert(
        ` ${this.props.t(
          "Schedule.Create.theCurrentLimitOfUploadPerPersonHasBeenExceeded"
        )} ${userUploadFileSizeResult.data.totalSize}byte`
      );
    }
    var file = e.target.files[0];
    if (file.size > Number(localStorage.getItem("maxFileSize"))) {
      alert(`${this.props.t("SimpleEditor.attachmentSizeIsTooLarge")}`);
    } else {
      let reader = new FileReader();
      reader.onloadend = () => {
        if (this._isMounted) {
          this.setState({
            attachFile: file,
            attachFileType: "image",
            attachFilePreview: reader.result,
            fileChangeType: fileChangeType,
          });
        }
      };
      e.target.value = "";
      reader.readAsDataURL(file);
    }
  };

  attachVideo = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.persist();

    let fileChangeType = "ADD";
    if (this.state.fileId) {
      fileChangeType = "CHANGED";
    }

    let getBandStatusResult = await BandService.getBandStatusNode(
      this.props.bandId
    );
    if (
      getBandStatusResult.diskUsage >
      localStorage.getItem("maxPersonalDiskUsage")
    ) {
      return message.warning(`밴드 디스크 용량을 초과했습니다.`);
    }

    let userUploadFileSizeResult = await UserService.userUploadFileSize();
    if (userUploadFileSizeResult.data.totalSize > MAX_SIZE_STORAGE) {
      return alert(
        ` ${this.props.t(
          "Schedule.Create.theCurrentLimitOfUploadPerPersonHasBeenExceeded"
        )} ${userUploadFileSizeResult.data.totalSize}byte`
      );
    }

    var file = e.target.files[0];
    if (file.size > Number(localStorage.getItem("maxFileSize"))) {
      var criteriaMb = localStorage.getItem("maxFileSize");

      if (criteriaMb.length === 8) {
        criteriaMb = criteriaMb.substr(0, 1) + 0;
        //alert(criteriaMb + "MB 이상의 파일은 업로드 할수 없습니다.");
        alert(criteriaMb + `${this.props.t('SimpleEditor.attachmentSizeAlert')}`);
      } else if (criteriaMb.length === 9) {
        criteriaMb = criteriaMb.substr(0, 2) + 0;
        //alert(criteriaMb + "MB 이상의 파일은 업로드 할수 없습니다.");
        alert(criteriaMb + `${this.props.t('SimpleEditor.attachmentSizeAlert')}`);
      } else {
        alert(`${this.props.t("SimpleEditor.attachmentSizeIsTooLarge")}`);
      }
    } else {
      let reader = new FileReader();
      reader.onloadend = () => {
        if (this._isMounted) {
          this.setState({
            attachFile: file,
            attachFileType: "video",
            attachFilePreview: reader.result,
            fileChangeType: fileChangeType,
          });
        }
      };
      e.target.value = "";
      reader.readAsDataURL(file);
    }
  };

  attachFile = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.persist();

    let fileChangeType = "ADD";
    if (this.state.fileId) {
      fileChangeType = "CHANGED";
    }

    let getBandStatusResult = await BandService.getBandStatusNode(
      this.props.bandId
    );
    if (
      getBandStatusResult.diskUsage >
      localStorage.getItem("maxPersonalDiskUsage")
    ) {
      return message.warning(`밴드 디스크 용량을 초과했습니다.`);
    }

    let userUploadFileSizeResult = await UserService.userUploadFileSize();
    if (userUploadFileSizeResult.data.totalSize > MAX_SIZE_STORAGE) {
      return alert(
        ` ${this.props.t(
          "Schedule.Create.theCurrentLimitOfUploadPerPersonHasBeenExceeded"
        )} ${userUploadFileSizeResult.data.totalSize}byte`
      );
    }

    var file = e.target.files[0];
    if (file.size > Number(localStorage.getItem("maxFileSize"))) {
      var criteriaMb = localStorage.getItem("maxFileSize");

      if (criteriaMb.length == 8) {
        criteriaMb = criteriaMb.substr(0, 1) + 0;
        alert(criteriaMb + "MB 이상의 파일은 업로드 할수 없습니다.");
        //alert(criteriaMb + `${this.props.t('SimpleEditor.attachmentSizeAlert')}`);
      } else if (criteriaMb.length == 9) {
        criteriaMb = criteriaMb.substr(0, 2) + 0;
        alert(criteriaMb + "MB 이상의 파일은 업로드 할수 없습니다.");
        // alert(criteriaMb + `${this.props.t('SimpleEditor.attachmentSizeAlert')}`);
      } else {
        alert(`${this.props.t("SimpleEditor.attachmentSizeIsTooLarge")}`);
      }

      message.warning(
        `${this.props.t("SimpleEditor.attachmentSizeIsTooLarge")}`
      );
    } else {
      let reader = new FileReader();
      reader.onloadend = () => {
        if (this._isMounted) {
          this.setState({
            attachFile: file,
            attachFileType: "file",
            attachFilePreview: NoImage,
            fileChangeType: fileChangeType,
          });
        }
      };
      e.target.value = "";
      reader.readAsDataURL(file);
    }
  };

  removeFile = () => {
    let fileChangeType = "NONE";
    if (this.state.fileId) {
      fileChangeType = "REMOVED";
    }

    if (this._isMounted) {
      this.setState({
        attachFile: undefined,
        attachFileType: undefined,
        attachFilePreview: undefined,
        fileChangeType: fileChangeType,
      });
    }
  };

  submit = () => {
    //reply is just plain text I typed in
    const reply = this.state.reply;
    //attachFile is files[0]
    const attachFile = this.state.attachFile;
    const fileChangeType = this.state.fileChangeType;
    // console.log('reply', reply)
    // console.log('attachFile', attachFile)
    // console.log('fileChangeType', fileChangeType)

    if (reply.length > 500) {
      return message.warning(
        `${this.props.t(
          "Post.Detail.theCharacterLimitHasBeenExceededPleaseReduceTheNumberOfCharacters"
        )}`
      );
    }

    if (!StringUtils.isNullOrUndefined(reply) || attachFile) {
      if (this._isMounted) {
        this.setState(
          this.defaultState,
          this.props.onSubmit(reply, attachFile, fileChangeType)
        );
      }
    } else {
      message.warning(`${this.props.t("SimpleEditor.pleaseCommentFirst")}`);
    }
  };

  render() {
    // const { t } = this.props;

    return (
      <InputArea reply={this.props.reply}>
        {this.state.attachFile && (
          <div style={{ padding: "0 0 0 0.5rem ", width: "8rem" }}>
            {this.state.attachFileType === "video" ? (
              <div id="attached-file-wrapper" onClick={this.removeFile}>
                <video
                  src={this.state.attachFilePreview}
                  controls
                  style={{ height: "4rem", width: "100%" }}
                />
              </div>
            ) : (
              <div id="attached-file-wrapper" onClick={this.removeFile}>
                <img
                  src={this.state.attachFilePreview}
                  alt={this.state.attachFile.filename}
                  style={{ height: "4rem", width: "100%" }}
                />
              </div>
            )}
          </div>
        )}

        {!this.props.fromPostList && (
          <>
            {this.props.reply ? (
              <button
                style={{ padding: "0.5rem 1rem 0.5rem .5rem" }}
                onClick={this.openMenu}
              >
                <img
                  style={{ width: "1.6rem", height: "1.6rem" }}
                  src={btnAdd}
                  alt="btn-emoticon"
                />
              </button>
            ) : (
              <SimpleEditorToolTip
                selectMenu={(menu) => this.selectMenu(menu)}
              />
            )}
          </>
        )}

        <textarea
          placeholder={this.props.placeholder}
          value={this.state.reply}
          onChange={this.handleText}
        />
        <button onClick={this.submit}>
          <img
            style={{ width: "1.8rem", height: "1.8rem" }}
            src={btnSend}
            alt="btn-emoticon"
          />
        </button>

        <Modal
          isOpen={this.state.openMenu}
          contentLabel="close"
          onRequestClose={this.closeMenu}
          style={CustomStyles}
        >
          <ReplyFileSelectModal
            selectMenu={this.selectMenu}
            onClose={this.closeMenu}
          />
        </Modal>

        <input
          type="file"
          accept="image/*"
          ref={this.inputOpenImageRef}
          style={{ display: "none" }}
          onChange={this.attachImage}
        />
        <input
          type="file"
          accept="video/*"
          ref={this.inputOpenVideoRef}
          style={{ display: "none" }}
          onChange={this.attachVideo}
        />
        <input
          type="file"
          accept="*"
          ref={this.inputOpenFileRef}
          style={{ display: "none" }}
          onChange={this.attachFile}
        />
      </InputArea>
    );
  }
}

const InputArea = styled.div`
  margin-bottom: 0;
  width: 100%;
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  resize: none;
  background-color: #ffffff;

  padding-top: 1rem;
  padding-bottom: 1rem;
  textarea {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    overflow: hidden;
    border-radius: 8px;
    background-color: #efeff4;
    border: none;
  }
  textarea::placeholder {
    color: #c7c7cc;
  }

  button {
    padding: 0.5rem 0.2rem 0.5rem 1rem;
  }

  padding: ${(props) => props.reply && "1rem"};
  border-radius: ${(props) => props.reply && "4px"};
`;

export default withTranslation()(SimpleEditor);

const CustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: { zIndex: 9999999999999999 },
};
