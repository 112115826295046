/* eslint-disable array-callback-return */
import React from 'react';
import { SERVER_URI, COMPANY_NAME } from '../../../configs';

import '../../../assets/css/_modal.scss';
import * as BandService from '../../../services/BandService';
import * as ArrayUtils from '../../../utils/CommonUtils';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import icon_sorting from '../../../assets/images/icon_sorting.png';
import * as StringUtils from '../../../utils/StringUtils';
const NoImage = require('../../../assets/images/no-image.png');

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    // console.log('list', list)
    // console.log('startIndex', startIndex)
    // console.log('endIndex', endIndex)
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    // console.log('removed', removed)
    // console.log(' [removed]', [removed])
    result.splice(endIndex, 0, removed);
    // const months = ['Jan', 'March', 'April', 'June'];
    // months.splice(1, 0, 'Feb');
    // inserts at index 1
    // console.log(months);
    // expected output: Array ["Jan", "Feb", "March", "April", "June"]
    // console.log('result', result)
    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "lightgreen" : null,

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : null,
    padding: grid,
});


class FixListSelectModal extends React.Component {

    onClose;
    show;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            initList: [],
            fixedList: [],
            unfixedList: [],
            loaded: false,
        };

        this.onDragEnd = this.onDragEnd.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (!this.state.loaded && localStorage.getItem('userId')) {
            let fixedList = [];
            let unfixedList = [];
            let initList = [];

            BandService.getUserBandListNode(localStorage.getItem('userId'), true)
                .then(bandIds => BandService.getBandListNode(bandIds, 0))
                .then(result => {

                    if (localStorage.getItem('bandFixedListUserId') && localStorage.getItem('bandFixedList') && localStorage.getItem('bandFixedListUserId') === localStorage.getItem('userId')) {

                        let returnArray = localStorage.getItem('bandFixedList').split(',').map(function (item) {
                            return parseInt(item, 10);
                        });

                        returnArray.map(i => {
                            fixedList.push((_.find(result.content, { 'id': i })).id);
                            initList.push((_.find(result.content, { 'id': i })));
                        })
                    } else {

                        if (result) {
                            result.content.forEach(content => {
                                fixedList.push(content.id);
                                initList.push(content);
                            });
                        }
                    }

                    // console.log('result', result)

                })
                .then(() => BandService.getUserBandListNode(localStorage.getItem('userId'), false))
                .then(bandIds => BandService.getBandListNode(bandIds, localStorage.getItem('viewOrderType')))
                .then(result => {
                    if (result) {
                        result.content.forEach(content => {
                            unfixedList.push(content.id);
                            initList.push(content);
                        });
                    }
                })
                .then(() => {
                    if (this._isMounted) {
                        this.setState({
                            loaded: true,
                            initList: initList,
                            fixedList: fixedList,
                            unfixedList: unfixedList,
                        });
                    }
                })
                .catch(error => console.error(error));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //fixed되어 있는것을 unfixed로  unfixed로 되어 있는 걸 fixed로 만들기.
    handleEvent(itemId) {
        //먼저 내가 클릭한 것이 fixed가 이미 되어있는지 체크,  포함 되어 있다면 isInFixedList = true 
        const isInFixedList = ArrayUtils.contains(this.state.fixedList, itemId);

        //fixedList에는  이미 포함되어 있다면 fixedList에서 filtering해서 없앤 후 저장
        //unfixedList에는 반대로!!!
        this.setState({
            fixedList: isInFixedList
                ? this.state.fixedList.filter(i => i !== itemId)
                : [...this.state.fixedList, itemId],
            unfixedList: isInFixedList
                ? [...this.state.unfixedList, itemId]
                : this.state.unfixedList.filter(i => i !== itemId)
        });
    }

    submit = () => {
        BandService.setFixedBandsNode(this.state.fixedList)
            .then(() => {
                if (this._isMounted) {
                    localStorage.setItem('bandFixedList', this.state.fixedList)
                    localStorage.setItem('bandFixedListUserId', localStorage.getItem('userId'))

                    this.setState({ loaded: false });
                }
            })
            .then(() => this.props.onClose())
            .catch(error => console.error(error));
    };

    modalClose = () => {
        this.props.onClose();
    }

    onDragEnd(result) {
        // console.log('result', result)
        // dropped outside the list
        if (!result.destination) { return; }

        const items = reorder(
            this.state.initList,
            result.source.index,
            result.destination.index
        );

        const reorderedFixedList = reorder(
            this.state.fixedList,
            result.source.index,
            result.destination.index
        );

        this.setState({
            initList: items,
            fixedList: reorderedFixedList
        });
    }

    render() {
        const { t } = this.props;
        if (!this.props.show) {
            return null;
        }

        const { initList, fixedList, unfixedList } = this.state;

        return (
            <div className="modal_wrap">
                <div className="modal">
                    <button className="close_btn" aria-label="모달창 닫기 버튼" tabIndex="1" onClick={this.modalClose}></button>                    <div>
                        <p className="title">{t('FixListSelectModal.fixedBandManagement')} </p>
                        <div>
                            <ItemList fixed
                                t={t}
                                key="fixed"
                                title={t('FixListSelectModal.fixedBand')}
                                handleEvent={this.handleEvent}
                                items={fixedList} initItems={initList}
                                empty={fixedList.length === 0 ? true : false}
                                onDragEnd={this.onDragEnd} />
                            <ItemList unfixed
                                t={t}
                                key="unfixed"
                                title={t('FixListSelectModal.unFixedBand')}
                                handleEvent={this.handleEvent}
                                items={unfixedList} initItems={initList} />
                        </div>
                        <div>
                            <button className="modal_submit_btn" onClick={this.submit}>
                                {t('FixListSelectModal.fixedBandManagement')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class ItemList extends React.Component {

    items;
    initItems;
    title;
    empty;

    render() {
        const { t } = this.props;
        const title = this.props.title;

        const itemArr = this.props.initItems;
        const myItems = this.props.items;

        const empty = this.props.empty;

        const listItems = itemArr.map((itemObj, index) => {
            if (!ArrayUtils.contains(myItems, itemObj.id)) return null;
            // console.log('itemObj', itemObj)
            let coverImage;
            if (StringUtils.isNullOrUndefined(itemObj.coverImage)) {
                coverImage = NoImage;
            } else {
                if (COMPANY_NAME !== "UC WARE") {
                    coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${itemObj.coverImage}`;
                } else {
                    coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${itemObj.coverThumbnail}`;
                }
            }

            let btn_unfix1 = <button className="fixRound"> {t('FixListSelectModal.unfix')}</button>
            let btn_fix1 = <button className="fixRound"> {t('FixListSelectModal.fix')}</button>

            if (this.props.fixed) {
                return <Draggable key={itemObj.id} draggableId={itemObj.id} index={index}>
                    {(provided, snapshot) => (
                        <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            className="flex" onClick={() => this.props.handleEvent(itemObj.id)}>
                            <img
                                style={{ flex: "10%" }}
                                className="thumbnail"
                                src={coverImage}
                                alt="thumbail"
                            />

                            <div
                                style={{ flex: "40%" }}
                            > {itemObj.name} </div>

                            {btn_unfix1}

                            {<img className="icon_sorting" src={icon_sorting} alt="햄버거 모양" />}

                        </li>
                    )}
                </Draggable>
            }
            else {
                return (
                    <li key={index} className="flex" onClick={() => this.props.handleEvent(itemObj.id)}>
                        <img
                            style={{ flex: "10%" }}
                            className="thumbnail"
                            src={coverImage}
                            alt="thumbail"
                        />

                        <div
                            style={{ flex: "40%" }}
                        > {itemObj.name} </div>
                        {btn_fix1}
                    </li>
                )
            }
        });

        return (
            <div>
                <div className="itemListTitle">
                    {title}
                </div>

                <ul className="list_unfixed">
                    {empty === true ? <div className="placeholder">
                        <br /><br /><br /> {t('FixListSelectModal.selectTheBandYouWantToFix')} </div>
                        :
                        <DragDropContext onDragEnd={this.props.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {listItems}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    }
                </ul>
            </div>
        );
    }
}


export default withTranslation()(FixListSelectModal);
