import React from 'react'
import Toolbar from 'react-big-calendar/lib/Toolbar';
import {
    PlusOutlined
} from '@ant-design/icons';
export default class CustomToolBar extends Toolbar {
    handleClick = () => {
        var today = new Date();
        var modDate = `${this.props.createURL}`
        modDate = modDate.replace('undefined','')
        window.location.href = modDate + today.getDate();
    }

    render() {
        let { localizer: { messages }, label,
            // view,
            t } = this.props
        // console.log('label', view)
        //언어 바꿔주기 
        messages.noEventsInRange = `${t('Schedule.thereAreNoEventsSpecifiedDuringThisPeriod')}`;
        messages.allDay = `${t('Schedule.allDay')}`;

        return (
            <div className="rbc-toolbar">
                <span className="rbc-btn-group">
                    <button type="button" onClick={this.navigate.bind(null, navigate.PREVIOUS)}> {'<'} </button>
                </span>

                <span className="rbc-toolbar-label" style={{ fontSize: '1.5rem', fontStyle: 'bold' }}>{label}</span>

                <span className="rbc-btn-group">
                    <button type="button" onClick={this.navigate.bind(null, navigate.NEXT)}> {'>'} </button>
                </span>

                <div className="rbc-btn-group">
                    <button type="button" onClick={this.view.bind(null, 'month')}>{t('Schedule.month')}</button>
                    {/* <button type="button" onClick={this.view.bind(null, 'week')}>Week</button>
                    <button type="button" onClick={this.view.bind(null, 'day')}>Day</button> */}
                    <button type="button" onClick={this.view.bind(null, 'agenda')}>{t('Schedule.agenda')}</button>
                    <button type="button" onClick={this.handleClick}><PlusOutlined /></button>
                </div>
            </div>
        )
    }
    navigate = action => {
        this.props.onNavigate(action)
    }
}

export let navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
}