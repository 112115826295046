import React from 'react';
import styled from "styled-components";

import toggleOn from '../../../assets/images/toggle-onoff.svg';
import toggleOff from '../../../assets/images/toggle-onoff-off.svg';

import {
    SelectBoxSelect,
} from '../Select';

import * as StringUtils from '../../../utils/StringUtils';
import * as DateTimeUtils from '../../../utils/DateTimeUtils';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { THEME_BACKGROUND_COLOR } from '../../../configs';

import { withTranslation } from 'react-i18next';

const FORMAT = 'yyyy/MM/dd';

function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, undefined, { locale });
    if (DateUtils.isDate(parsed)) {
        return parsed;
    }
    return undefined;
}

function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
}

const pollStatusDisplayTypeOptions = [
    { value: 0, label: "항상 보기", eng: "Always view" },
    { value: 1, label: "투표 참여 후 보기", eng: "View after voting" },
    { value: 2, label: "투표 종료 후 보기", eng: "View after voting ends" },
];

const multipleSelectCountOptions = [
    { value: 0, label: "제한 없음", eng: "No limit" },
    { value: 2, label: "2개", eng: "2" },
    { value: 3, label: "3개", eng: "3" },
    { value: 4, label: "4개", eng: "4" },
    { value: 5, label: "5개", eng: "5" }
];

const pollOptionOrderTypeOptions = [
    { value: 0, label: "항목번호 순", eng: "Item No." },
    { value: 1, label: "득표순", eng: "Voting order" }
];

function pad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

function getAllIndexes(arr, val) {
    var indexes = [], i = -1;
    while ((i = arr.indexOf(val, i + 1)) !== -1) {
        indexes.push(i);
    }
    return indexes;
}

class PollSettingModal extends React.Component {

    show;
    pollIndex;
    defaultValue;
    onSubmit;
    onUpdate;
    onClose;

    defaultState = {
        title: '',
        pollOptions: ['', '', ''],
        allowAnonymous: false,
        pollStatusDisplayType: 0,
        allowMultipleSelection: true,
        multipleSelectionCount: 0,
        dueDate: undefined,
        dueTime: "0000",
        pollOptionOrderType: 0,
        modifyInit: false,
        closed: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            title: '',
            pollOptions: ['', '', ''],
            allowAnonymous: false,
            pollStatusDisplayType: 0,
            allowMultipleSelection: true,
            multipleSelectionCount: 0,
            dueDate: undefined,
            dueTime: "0000",
            pollOptionOrderType: 0,
            modifyInit: false,
            closed: true,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.show && this.state.closed) {
            this.setState({ closed: false });
        }

        if (!!this.props.show && this.props.defaultValue && !this.state.modifyInit && !this.state.closed) {
            console.log(this.props.defaultValue.dueDateTime);
            console.log(StringUtils.isNullOrUndefined(this.props.defaultValue.dueDateTime));
            this.setState({
                title: this.props.defaultValue.title,
                pollOptions: this.props.defaultValue.pollOptions,
                allowAnonymous: this.props.defaultValue.allowAnonymous,
                pollStatusDisplayType: this.props.defaultValue.pollStatusDisplayType,
                allowMultipleSelection: this.props.defaultValue.allowMultipleSelection,
                multipleSelectionCount: this.props.defaultValue.multipleSelectionCount,
                dueDate: (!StringUtils.isNullOrUndefined(this.props.defaultValue.dueDateTime)) ? DateTimeUtils.momentByFormat(this.props.defaultValue.dueDateTime.substring(0, 8), "YYYYMMDD").toDate() : undefined,
                dueTime: (!StringUtils.isNullOrUndefined(this.props.defaultValue.dueDateTime)) ? this.props.defaultValue.dueDateTime.substring(8) : "0000",
                pollOptionOrderType: this.props.defaultValue.pollOptionOrderType,
                modifyInit: true,
            })
        }
    }

    handleTitleInput = (e) => {
        this.setState({
            title: e.currentTarget.value
        });
    };

    handlePollOption = (e) => {
        const pollOptions = this.state.pollOptions.map((pollOption, index) => {
            if (index === Number(e.currentTarget.id)) {
                return e.currentTarget.value;
            } else {
                return pollOption;
            }
        });
        this.setState({
            pollOptions: pollOptions
        });
    };

    onFocusPollOption = (e) => {
        const emptyCheck = getAllIndexes(this.state.pollOptions, "");
        if (emptyCheck.length < 2) {
            if ((Number(e.currentTarget.id) + 1) === this.state.pollOptions.length) {
                this.setState({
                    pollOptions: [...this.state.pollOptions, ''],
                })
            }
        }
    };

    allowAnonymous = () => {
        this.setState({
            allowAnonymous: !this.state.allowAnonymous
        })
    };

    allowMultipleSelection = () => {
        this.setState({
            allowMultipleSelection: !this.state.allowMultipleSelection
        })
    };


    optionValueChange = (name, value) => {
        this.setState({
            [name]: Number(value)
        }, () => console.log(this.state))
    };

    handleDueDate = (value) => {
        this.setState({ dueDate: value });
    };

    handleDueTime = (value) => {
        this.setState({ dueTime: value });
    };

    submit = () => {
        let pollOptionCount = 0;
        this.state.pollOptions.forEach(option => {
            if (!StringUtils.isNullOrUndefined(option)) {
                pollOptionCount = pollOptionCount + 1;
            }
        });
        if (StringUtils.isNullOrUndefined(this.state.title) || pollOptionCount === 0) {
            alert(`${this.props.t('PollSettingModal.pleaseEnterTheTitleAndOptionsInTheSurvey')}`);
        } else {
            let poll = {
                pollIndex: this.props.defaultValue ? this.props.defaultValue.pollIndex : this.props.pollIndex,
                title: this.state.title,
                pollOptions: this.state.pollOptions.filter(pollOption => !StringUtils.isNullOrUndefined(pollOption)),
                allowAnonymous: this.state.allowAnonymous,
                pollStatusDisplayType: this.state.pollStatusDisplayType,
                allowMultipleSelection: this.state.allowMultipleSelection,
                multipleSelectionCount: this.state.multipleSelectionCount,
                dueDateTime: this.state.dueDate ? dateFnsFormat(this.state.dueDate, "yyyyMMdd") + this.state.dueTime : undefined,
                pollOptionOrderType: this.state.pollOptionOrderType,
            };
            if (this.props.defaultValue) {
                this.setState(this.defaultState, this.props.onUpdate(poll));
            } else {
                this.setState(this.defaultState, this.props.onSubmit(poll));
            }
        }
    };

    cancel = () => {
        this.setState(this.defaultState, this.props.onClose);
    };

    render() {
        if (!this.props.show) {
            return null;
        }
        const { t } = this.props;

        const pollOptions = this.state.pollOptions;

        const AllowAnonymous = () => <SelectWrapper>
            <SelectLabel>
                {t('PollSettingModal.ballot')}
            </SelectLabel>
            <SelectOptions>
                <button onClick={this.allowAnonymous}>
                    {this.state.allowAnonymous ? <img src={toggleOn} alt="toggleOn" /> : <img src={toggleOff} alt="toggleOff" />}
                </button>
            </SelectOptions>
        </SelectWrapper>;

        const PollStatusDisplay = () => <SelectWrapper>
            <SelectBoxSelect
                poll
                title={`${t('PollSettingModal.viewVotingStatus')}`}
                name="pollStatusDisplayType"
                defaultValue={this.state.pollStatusDisplayType}
                options={pollStatusDisplayTypeOptions}
                onChange={this.optionValueChange}
            />
        </SelectWrapper>;

        const AllowMultipleSelection = () => <SelectWrapper>
            <SelectLabel>
                {t('PollSettingModal.allowMultipleSelection')}
            </SelectLabel>
            <SelectOptions>
                <button onClick={this.allowMultipleSelection}>
                    {this.state.allowMultipleSelection ? <img src={toggleOn} alt="toggleOn" /> : <img src={toggleOff} alt="toggleOff" />}
                </button>
            </SelectOptions>
        </SelectWrapper>;

        const MultipleSelectionCount = () => <SelectWrapper>
            <SelectBoxSelect
                poll
                title={`${t('PollSettingModal.multipleSelectionCount')}`}
                name="multipleSelectionCount"
                defaultValue={this.state.multipleSelectionCount}
                options={multipleSelectCountOptions}
                onChange={this.optionValueChange}
            />
        </SelectWrapper>;

        let TIME_OPTIONS = [];
        for (let i = 0; i < 48; i++) {
            TIME_OPTIONS.push({
                value: pad(Math.floor(i / 2) * 100 + (i % 2) * 30, 4),
                label: `${i < 24 ? "AM " : "PM "} ${pad((Math.floor(i / 2) >= 12 ? Math.floor(i / 2) - 12 : Math.floor(i / 2)), 2)}:${pad((i % 2) * 30, 2)}`
            });
        }

        const DueDateSelector = () => <DateTimeWrapper>
            <DateTimeLabel>
                {t('PollSettingModal.endDateSetting')}
            </DateTimeLabel>
            <DateTimeOptions>
                <DayPickerInput format={FORMAT}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder="YYYY/MM/DD"
                    value={this.state.dueDate}
                    onDayChange={day => this.handleDueDate(day)}
                    style={{ width: '52%' }}
                    dayPickerProps={{
                        selectedDays: this.state.dueDate,
                    }}
                />
                <Select value={this.state.dueTime} onChange={e => this.handleDueTime(e.target.value)}>
                    {TIME_OPTIONS.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
                </Select>
            </DateTimeOptions>
        </DateTimeWrapper>;

        const PollOptionOrder = () => <SelectWrapper>
            <SelectBoxSelect
                poll
                title={`${t('PollSettingModal.sortVotingItems')}`}
                name="pollOptionOrderType"
                defaultValue={this.state.pollOptionOrderType}
                options={pollOptionOrderTypeOptions}
                onChange={this.optionValueChange}
            />
        </SelectWrapper>;

        return (
            <div style={{ flexDirection: 'column', padding: '1rem' }}>
                <div>
                    <div>
                        <Title placeholder={`${t('PollSettingModal.title')}`} id="title" value={this.state.title} onChange={this.handleTitleInput} />
                    </div>
                    <Divider title={"true"} />
                    <div>
                        {pollOptions.map((pollOption, index) =>
                            <React.Fragment key={index}>
                                <div key={`poll-option-${index}`} >
                                    {`${index + 1}. `}
                                    <input style={{ width: '93%', border: 'none' }} id={index} placeholder={`${t('PollSettingModal.enterItem')}`} value={pollOption} onChange={this.handlePollOption} onFocus={this.onFocusPollOption} />
                                </div>
                                <Divider />
                            </React.Fragment>
                        )}
                    </div>
                    <br />
                    <DueDateSelector />
                    <AllowAnonymous />
                    <PollStatusDisplay />
                    <AllowMultipleSelection />
                    <MultipleSelectionCount />
                    <br />
                    <PollOptionOrder />
                </div>
                <SubmitButton>
                    <button className="modal_submit_btn" onClick={this.submit}>
                        {t('PollSettingModal.confirm')}
                    </button>

                    <button className="modal_submit_btn" onClick={this.cancel}>
                        {t('PollSettingModal.close')}
                    </button>
                </SubmitButton>

            </div>

        );
    }

}

const SubmitButton = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: center;
    height: 30px;
    vertical-align: middle;
    margin-top: 10px;

    button {
        background-color: ${THEME_BACKGROUND_COLOR};
        width: 35%;
        height: 100%;
        margin: 0 10px;
        color: white;
        border-radius: 7px;
    }
`;

const Divider = styled.div`
    border-bottom: solid 1px #e5e5ea;
    margin-bottom: ${props => props.title ? "1rem" : "0.5rem"};
`;

const Title = styled.input`
    width: 100%;
    border: none;
    font-size: 1.23rem;
`;

const SelectWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    padding: 0.25rem 0;
`;

const SelectLabel = styled.div`
    width: 50%;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    span {
      padding: 0 1.5rem;
      width: 2rem;
      display: inline-block;
      text-align: center;
    }    
`;

const SelectOptions = styled.div`
    width: 50%;
    text-align: right;
    button {}

    img {
        width: 36px;
        height: 18px;
    }
`;

const DateTimeWrapper = styled.div`
    
    width: 100%;
`;

const DateTimeLabel = styled.div`
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    span {
      padding: 0 1.5rem;
      width: 2rem;
      display: inline-block;
      text-align: center;
    }
`;

const DateTimeOptions = styled.div`
    padding: 0.5rem 0; 

    input {
        border: none;
    }
`;


const Select = styled.select`
   
    background-color: white;
    font-size: 14px;
    width: 47%;
    color: black;
    border: none;
    ;
    select::-ms-expand {
        display: none;
        -webkit-appearance: none;
    }    
    
`;

export default withTranslation()(PollSettingModal);
