import React from 'react';
import styled from 'styled-components';

import * as BandService from '../../../services/BandService';
import * as StringUtils from '../../../utils/StringUtils';

import { SERVER_URI, COMPANY_NAME } from '../../../configs';
import { bandCategoryList , bandCategoryListEng, bandCategoryListJap, bandCategoryListThai} from '../../../data';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';

const NoImage = require('../../../assets/images/blank-profile-picture.png');
const btn_editPic = require('../../../assets/images/btn-edit-pic.svg')

class BandSettingModal extends React.Component {

    onClose;
    show;
    bandId;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            bandCategoryId: undefined,
            coverImageFile: undefined,
            coverPreview: NoImage,
            coverImageChanged: false,
            name: undefined,
            description: undefined,
            loading: true,
            submitLoading: false
        };

        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;

        BandService.getBandNode(this.props.bandId)
            .then(band => {
                let coverImage;

                if (!StringUtils.isNullOrUndefined(band.coverThumbnail)) {
                    if (COMPANY_NAME !== "UC WARE") {
                        coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${band.coverImage}`;
                    } else {
                        coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${band.coverThumbnail}`;
                    }

                } else {
                    coverImage = NoImage;
                }
                this.setState({
                    coverPreview: coverImage,
                    bandCategoryId: band.bandCategoryId,
                    name: band.name,
                    description: band.description,
                    loading: false,
                });
            })
            .catch(error => console.error(error));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    selectCoverImage = (e) => {
        e.preventDefault();
        this.inputOpenFileRef.current.click();
    };

    handleImagePreview = (e) => {
        e.stopPropagation();
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            if (this._isMounted) {
                this.setState({
                    coverImageFile: file,
                    coverPreview: reader.result,
                    coverImageChanged: true,
                });
            }
        };

        reader.readAsDataURL(file);
    };

    changeBandCategory = (e) => {
        if (this._isMounted) {
            this.setState({
                bandCategoryId: e.currentTarget.value,
            });
        }
    };

    inputName = (e) => {
        if (this._isMounted) {
            if (e.currentTarget.value.length <= localStorage.getItem('maxBandNameLength')) {
                this.setState({
                    name: e.currentTarget.value,
                });
            } else {
                e.currentTarget.value = this.state.name;
            }
        }
    };

    inputDescription = (e) => {

        if (e.currentTarget.value.length <= localStorage.getItem('maxBandDescriptionLength')) {
            if (this._isMounted) {
                this.setState({
                    description: e.currentTarget.value,
                });
            }
        } else {
            e.currentTarget.value = this.state.description;
        }
    };

    submit = () => {
        if (this.state.coverImageChanged) {
            BandService.uploadCoverImageNode(this.props.bandId, this.state.coverImageFile)
                .then(() => BandService.updateBandNode(this.props.bandId,
                    this.state.bandCategoryId, this.state.name, this.state.description,
                    undefined, undefined, undefined, "one"))
                .then(() => this.props.onClose())
                .then(() => this.setState({ submitLoading: false }))
                .catch(error => console.error(error));
        } else {
            BandService.updateBandNode(this.props.bandId,
                this.state.bandCategoryId, this.state.name, this.state.description,
                undefined, undefined, undefined, "one")
                .then(() => this.props.onClose())
                .then(() => this.setState({ submitLoading: false }))
                .catch(error => console.error(error));
        }
    };

    render() {
        if (!this.props.show) {
            return null;
        }

        const { loading, coverPreview, name, description, submitLoading } = this.state;
        const { t } = this.props;

        let bandCategoryListVar;
        if (localStorage.getItem('i18nextLng') === "korean") {
            bandCategoryListVar = bandCategoryList
        } else if (localStorage.getItem('i18nextLng') === "english"){
            bandCategoryListVar =  bandCategoryListEng
        } else if (localStorage.getItem('i18nextLng') === "japanese"){
            bandCategoryListVar =  bandCategoryListJap
        } else if (localStorage.getItem('i18nextLng') === "thai"){
            bandCategoryListVar =  bandCategoryListThai
        }
     
        return (
            <div>
                {loading ?
                    <div></div> :
                    <>
                        <div className="modal_wrap">
                            <div className="modal">
                                <p className="title"> {t('BandSettingModal.bandInformationSetting')}</p>
                                 <button className="close_btn" aria-label="모달창 닫기 버튼"onClick={this.props.onClose}></button>

                                <div className="modal_content_userProfile">
                                    <img className="myThumb"
                                        src={coverPreview} alt={name}
                                        onClick={this.selectCoverImage}
                                    />

                                    <img
                                        className="btn_editPic"
                                        src={btn_editPic} alt="btn_editPic"
                                    />

                                    <div style={{ padding: '1rem 0' }}>
                                        <Category>
                                            <span> {t('BandSettingModal.bandCategory')}</span>
                                            <select value={this.state.bandCategoryId} onChange={this.changeBandCategory}>
                                                {bandCategoryListVar.map((option, index) =>
                                                    <option key={`key-${index}`} value={option.id}>{option.name}</option>
                                                )}
                                            </select>
                                        </Category>

                                        <hr className="container" />


                                        <InputWrapper>
                                            <span > {t('BandSettingModal.bandName')}</span>
                                            <input placeholder={t('BandSettingModal.writeTheNameOfTheBand')} defaultValue={name} onChange={this.inputName} />
                                        </InputWrapper>

                                        <hr className="container" />

                                        <TextAreaWrapper>
                                            <span > {t('BandSettingModal.bandDescription')}</span>
                                            <input placeholder={t('BandSettingModal.writeBandDescription')} value={description} onChange={this.inputDescription} />
                                        </TextAreaWrapper>

                                        <hr className="container" />

                                    </div>

                                    <Button loading={submitLoading} className="settingSubmitBtn" onClick={this.submit}> {t('BandSettingModal.save')}</Button>

                                    <input type="file" accept="image/*" ref={this.inputOpenFileRef} style={{ display: "none" }} onChange={this.handleImagePreview} />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }

}


const Category = styled.div`
    height: 1.5rem;
    padding: 0 0.5rem;
    span {
        float: left;
    }

    select {
        float: right;
        direction: rtl;
        color: black;
        border: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: white;
    }
`;

const InputWrapper = styled.div`
    padding: 0 0.5rem;
    text-align: left;

    input {
        float: right;
        text-align: right;
        border: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 70%
        margin-bottom: 0.5rem;
    }
    
`;

const TextAreaWrapper = styled.div`
    padding: 0 0.5rem;
    text-align: left;

    input {
        overflow:hidden;
        border: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 100%;
        resize: none;
        padding: 0.5rem 0 0 0;
    }   
`;

export default withTranslation()(BandSettingModal);
