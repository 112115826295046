import React from 'react'
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import {
    getSearchedChatMessages
} from "../../../_actions/chat_actions";
import { useTranslation } from 'react-i18next';

const { Search } = Input;

function ChatSearch(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const searchHandler = (value) => {
        if (!value.trim().length) { return alert(`${t('ChatSearch.pleaseWriteYourSearchTermsFirst')}`) }
        dispatch(getSearchedChatMessages(props.bandId, value))
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <Search
                placeholder={t('ChatSearch.findItBySearching')}
                onSearch={value => searchHandler(value)}
                style={{ width: '70%', marginTop: '10px', borderRadius: '17px' }}
            />
        </div>
    )
}

export default ChatSearch
