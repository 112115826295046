import React from 'react';
import '../../../assets/css/_modal.scss';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

class BandCategorySelectModal extends React.Component {

    onClose;
    show;
    bandId;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            members: [],
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { t } = this.props;

        return (
            <div className="modal_wrap">
                <div className="category">
                    <div>
                        <div className="close_btn" style={{ marginRight: '1rem' }} onClick={this.props.onRequestClose}></div>
                        <Title >{t('SearchBand.chooseAnotherTopic')}</Title>
                        <GrayWrapper>
                            <div className="modal_content view_order" style={{ marginBottom: '-20px' }}>
                                {this.props.bandCategoryListVariable.map((bandCategory, index) => {
                                    if (bandCategory.id !== Number(this.props.selectedBandCategory)) {
                                        return <div key={bandCategory.id}>
                                            <ThemeList onClick={this.props.selectBandCategory(bandCategory.id)}>
                                                {bandCategory.name}
                                            </ThemeList>
                                            <Divider />
                                        </div>
                                    } else {
                                        return false;
                                    }
                                })}
                                <br />
                                <div>
                                </div>
                            </div>
                        </GrayWrapper>
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}



export default withTranslation()(BandCategorySelectModal);
const Title = styled.div`
    padding-left: 2rem;
    font-size: 1.25em;
`;

const GrayWrapper = styled.div`
    margin: 20px 0;
    background-color: #f9f9fb;
    border-top: solid 1.5px #e5e5ea;
    border-bottom: solid 1.5px #e5e5ea;
`;

const ThemeList = styled.div`
    padding: 1rem 2rem;
    cursor: pointer;
`;

const Divider = styled.div`
    border-bottom: solid 1px #e5e5ea;
`;