/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////// 부산 교육청  ///////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////

// export const bandCategoryList = [
//     {
//         id: 1,
//         name: '협업',
//         icon: require('../assets/images/cat-10-g.svg'),
//     },
//     {
//         id: 2,
//         name: '팀,부서',
//         icon: require('../assets/images/cat-2-g.svg'),
//     },
//     {
//         id: 3,
//         name: 'T/F',
//         icon: require('../assets/images/cat-3-g.svg'),
//     },
//     {
//         id: 4,
//         name: '지식공유',
//         icon: require('../assets/images/cat-5-g.svg'),
//     },
//     {
//         id: 5,
//         name: '연구과제',
//         icon: require('../assets/images/cat-1-g.svg'),
//     },
//     {
//         id: 6,
//         name: '위원회',
//         icon: require('../assets/images/cat-4-g.svg'),
//     },
//     {
//         id: 7,
//         name: '동호회',
//         icon: require('../assets/images/cat-6-g.svg'),
//     },
//     {
//         id: 8,
//         name: '기타',
//         icon: require('../assets/images/cat-11-g.svg'),
//     }
// ];

// export const bandCategoryListEng = [
//     {
//         id: 1,
//         name: 'Research',
//         icon: require('../assets/images/cat-1-g-eng.svg'),
//     },
//     {
//         id: 2,
//         name: 'Team',
//         icon: require('../assets/images/cat-2-g-eng.svg'),
//     },
//     {
//         id: 3,
//         name: 'T/F',
//         icon: require('../assets/images/cat-3-g-eng.svg'),
//     },
//     {
//         id: 4,
//         name: 'Committee',
//         icon: require('../assets/images/cat-4-g-eng.svg'),
//     },
//     {
//         id: 5,
//         name: 'Knowledge',
//         icon: require('../assets/images/cat-5-g-eng.svg'),
//     },
//     {
//         id: 6,
//         name: 'Club',
//         icon: require('../assets/images/cat-6-g-eng.svg'),
//     },
//     {
//         id: 7,
//         name: 'Excercise',
//         icon: require('../assets/images/cat-7-g-eng.svg'),
//     },
//     {
//         id: 8,
//         name: 'Study',
//         icon: require('../assets/images/cat-8-g-eng.svg'),
//     },
//     {
//         id: 9,
//         name: 'Religion',
//         icon: require('../assets/images/cat-9-g-eng.svg'),
//     },
// ];



// export const bandCategoryListInNewsFeed = [
//     {
//         id: 1,
//         name: '협업',
//         icon: require('../assets/images/cat-10-g.svg'),
//     },
//     {
//         id: 2,
//         name: '팀,부서',
//         icon: require('../assets/images/cat-2-g.svg'),
//     },
//     {
//         id: 3,
//         name: 'T/F',
//         icon: require('../assets/images/cat-3-g.svg'),
//     },
//     {
//         id: 4,
//         name: '지식공유',
//         icon: require('../assets/images/cat-5-g.svg'),
//     },
//     {
//         id: 5,
//         name: '연구과제',
//         icon: require('../assets/images/cat-1-g.svg'),
//     },
//     {
//         id: 6,
//         name: '위원회',
//         icon: require('../assets/images/cat-4-g.svg'),
//     },
//     {
//         id: 7,
//         name: '동호회',
//         icon: require('../assets/images/cat-6-g.svg'),
//     },
//     {
//         id: 8,
//         name: '기타',
//         icon: require('../assets/images/cat-11-g.svg'),
//     }
// ];


// export const bandCategoryListInNewsFeedEng = [
//     {
//         id: 1,
//         name: 'Research',
//         icon: require('../assets/images/cat1-g-eng.svg'),
//     },
//     {
//         id: 2,
//         name: 'Team',
//         icon: require('../assets/images/cat2-g-eng.svg'),
//     },
//     {
//         id: 3,
//         name: 'T/F',
//         icon: require('../assets/images/cat3-g-eng.svg'),
//     },
//     {
//         id: 4,
//         name: 'Committee',
//         icon: require('../assets/images/cat4-g-eng.svg'),
//     },
//     {
//         id: 5,
//         name: 'Knowledge',
//         icon: require('../assets/images/cat5-g-eng.svg'),
//     },
//     {
//         id: 6,
//         name: 'Club',
//         icon: require('../assets/images/cat6-g-eng.svg'),
//     },
//     {
//         id: 7,
//         name: 'Exercise',
//         icon: require('../assets/images/cat7-g-eng.svg'),
//     },
//     {
//         id: 8,
//         name: 'Study',
//         icon: require('../assets/images/cat8-g-eng.svg'),
//     },
//     {
//         id: 9,
//         name: 'Religion',
//         icon: require('../assets/images/cat9-g-eng.svg'),
//     },
// ];

/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////// UC WARE /////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////

export const bandCategoryList = [
    {
        id: 1,
        name: '연구과제',
        icon: require('../assets/images/cat-1-g.svg'),
    },
    {
        id: 2,
        name: '팀,부서',
        icon: require('../assets/images/cat-2-g.svg'),
    },
    {
        id: 3,
        name: 'T/F',
        icon: require('../assets/images/cat-3-g.svg'),
    },
    {
        id: 4,
        name: '위원회',
        icon: require('../assets/images/cat-4-g.svg'),
    },
    {
        id: 5,
        name: '지식공유',
        icon: require('../assets/images/cat-5-g.svg'),
    },
    {
        id: 6,
        name: '동호회',
        icon: require('../assets/images/cat-6-g.svg'),
    },
    {
        id: 7,
        name: '운동',
        icon: require('../assets/images/cat-7-g.svg'),
    },
    {
        id: 8,
        name: '스터디',
        icon: require('../assets/images/cat-8-g.svg'),
    },
    {
        id: 9,
        name: '종교',
        icon: require('../assets/images/cat-9-g.svg'),
    },
];

export const bandCategoryListEng = [
    {
        id: 1,
        name: 'Research',
        icon: require('../assets/images/cat-1-g-eng.svg'),
    },
    {
        id: 2,
        name: 'Team',
        icon: require('../assets/images/cat-2-g-eng.svg'),
    },
    {
        id: 3,
        name: 'T/F',
        icon: require('../assets/images/cat-3-g-eng.svg'),
    },
    {
        id: 4,
        name: 'Committee',
        icon: require('../assets/images/cat-4-g-eng.svg'),
    },
    {
        id: 5,
        name: 'Knowledge',
        icon: require('../assets/images/cat-5-g-eng.svg'),
    },
    {
        id: 6,
        name: 'Club',
        icon: require('../assets/images/cat-6-g-eng.svg'),
    },
    {
        id: 7,
        name: 'Excercise',
        icon: require('../assets/images/cat-7-g-eng.svg'),
    },
    {
        id: 8,
        name: 'Study',
        icon: require('../assets/images/cat-8-g-eng.svg'),
    },
    {
        id: 9,
        name: 'Religion',
        icon: require('../assets/images/cat-9-g-eng.svg'),
    },
];


export const bandCategoryListJap = [
    {
        id: 1,
        name: '調査',
        icon: require('../assets/images/cat-1-g-eng.svg'),
    },
    {
        id: 2,
        name: 'チーム',
        icon: require('../assets/images/cat-2-g-eng.svg'),
    },
    {
        id: 3,
        name: 'T/F',
        icon: require('../assets/images/cat-3-g-eng.svg'),
    },
    {
        id: 4,
        name: 'コミュニティー',
        icon: require('../assets/images/cat-4-g-eng.svg'),
    },
    {
        id: 5,
        name: '知識',
        icon: require('../assets/images/cat-5-g-eng.svg'),
    },
    {
        id: 6,
        name: 'クラブ',
        icon: require('../assets/images/cat-6-g-eng.svg'),
    },
    {
        id: 7,
        name: '運動',
        icon: require('../assets/images/cat-7-g-eng.svg'),
    },
    {
        id: 8,
        name: '勉強',
        icon: require('../assets/images/cat-8-g-eng.svg'),
    },
    {
        id: 9,
        name: '宗教',
        icon: require('../assets/images/cat-9-g-eng.svg'),
    },
];

export const bandCategoryListThai = [
    {
        id: 1,
        name: 'การวิจัย',
        icon: require('../assets/images/cat-1-g-eng.svg'),
    },
    {
        id: 2,
        name: 'ทีม',
        icon: require('../assets/images/cat-2-g-eng.svg'),
    },
    {
        id: 3,
        name: 'T/F',
        icon: require('../assets/images/cat-3-g-eng.svg'),
    },
    {
        id: 4,
        name: 'คอมมูนิตี้',
        icon: require('../assets/images/cat-4-g-eng.svg'),
    },
    {
        id: 5,
        name: 'ภูมิปัญญา',
        icon: require('../assets/images/cat-5-g-eng.svg'),
    },
    {
        id: 6,
        name: 'สโมสร',
        icon: require('../assets/images/cat-6-g-eng.svg'),
    },
    {
        id: 7,
        name: 'การออกกำลังกาย',
        icon: require('../assets/images/cat-7-g-eng.svg'),
    },
    {
        id: 8,
        name: 'เรียน',
        icon: require('../assets/images/cat-8-g-eng.svg'),
    },
    {
        id: 9,
        name: 'ศาสนา',
        icon: require('../assets/images/cat-9-g-eng.svg'),
    },
];



export const bandCategoryListInNewsFeed = [
    {
        id: 1,
        name: '연구과제',
        icon: require('../assets/images/cat1-g.svg'),
    },
    {
        id: 2,
        name: '팀, 부서',
        icon: require('../assets/images/cat2-g.svg'),
    },
    {
        id: 3,
        name: 'T/F',
        icon: require('../assets/images/cat3-g.svg'),
    },
    {
        id: 4,
        name: '위원회',
        icon: require('../assets/images/cat4-g.svg'),
    },
    {
        id: 5,
        name: '지식공유',
        icon: require('../assets/images/cat5-g.svg'),
    },
    {
        id: 6,
        name: '동호회',
        icon: require('../assets/images/cat6-g.svg'),
    },
    {
        id: 7,
        name: '운동',
        icon: require('../assets/images/cat7-g.svg'),
    },
    {
        id: 8,
        name: '스터디',
        icon: require('../assets/images/cat8-g.svg'),
    },
    {
        id: 9,
        name: '종교',
        icon: require('../assets/images/cat9-g.svg'),
    },
];


export const bandCategoryListInNewsFeedEng = [
    {
        id: 1,
        name: 'Research',
        icon: require('../assets/images/cat1-g-eng.svg'),
    },
    {
        id: 2,
        name: 'Team',
        icon: require('../assets/images/cat2-g-eng.svg'),
    },
    {
        id: 3,
        name: 'T/F',
        icon: require('../assets/images/cat3-g-eng.svg'),
    },
    {
        id: 4,
        name: 'Committee',
        icon: require('../assets/images/cat4-g-eng.svg'),
    },
    {
        id: 5,
        name: 'Knowledge',
        icon: require('../assets/images/cat5-g-eng.svg'),
    },
    {
        id: 6,
        name: 'Club',
        icon: require('../assets/images/cat6-g-eng.svg'),
    },
    {
        id: 7,
        name: 'Exercise',
        icon: require('../assets/images/cat7-g-eng.svg'),
    },
    {
        id: 8,
        name: 'Study',
        icon: require('../assets/images/cat8-g-eng.svg'),
    },
    {
        id: 9,
        name: 'Religion',
        icon: require('../assets/images/cat9-g-eng.svg'),
    },
];


/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////// 국토 연구원 /////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////

// export const bandCategoryList = [
//     {
//         id: 1,
//         name: '팀/부서',
//         icon: require('../assets/images/cat-1.svg'),
//     },
//     {
//         id: 2,
//         name: '계열사',
//         icon: require('../assets/images/cat-2.svg'),
//     },
//     {
//         id: 3,
//         name: 'TF',
//         icon: require('../assets/images/cat-3.svg'),
//     },
//     {
//         id: 4,
//         name: '직급',
//         icon: require('../assets/images/cat-4.svg'),
//     },
//     {
//         id: 5,
//         name: '스터디',
//         icon: require('../assets/images/cat-5.svg'),
//     },
//     {
//         id: 6,
//         name: '취미/동호회',
//         icon: require('../assets/images/cat-6.svg'),
//     },
//     {
//         id: 7,
//         name: '운동',
//         icon: require('../assets/images/cat-7.svg'),
//     },
//     {
//         id: 8,
//         name: '동문',
//         icon: require('../assets/images/cat-8.svg'),
//     },
//     {
//         id: 9,
//         name: '종교',
//         icon: require('../assets/images/cat-9.svg'),
//     },
// ];

// export const bandCategoryListEng = [
//     {
//         id: 1,
//         name: 'Team',
//         icon: require('../assets/images/cat-1-eng.svg'),
//     },
//     {
//         id: 2,
//         name: 'Subsidiary',
//         icon: require('../assets/images/cat-2-eng.svg'),
//     },
//     {
//         id: 3,
//         name: 'TF',
//         icon: require('../assets/images/cat-3-eng.svg'),
//     },
//     {
//         id: 4,
//         name: 'Rank',
//         icon: require('../assets/images/cat-4-eng.svg'),
//     },
//     {
//         id: 5,
//         name: 'Study',
//         icon: require('../assets/images/cat-5-eng.svg'),
//     },
//     {
//         id: 6,
//         name: 'Hobby',
//         icon: require('../assets/images/cat-6-eng.svg'),
//     },
//     {
//         id: 7,
//         name: 'Excercise',
//         icon: require('../assets/images/cat-7-eng.svg'),
//     },
//     {
//         id: 8,
//         name: 'Alumni',
//         icon: require('../assets/images/cat-8-eng.svg'),
//     },
//     {
//         id: 9,
//         name: 'Religion',
//         icon: require('../assets/images/cat-9-eng.svg'),
//     },
// ];



// export const bandCategoryListInNewsFeed = [
//     {
//         id: 1,
//         name: '팀/부서',
//         icon: require('../assets/images/cat1.svg'),
//     },
//     {
//         id: 2,
//         name: '계열사',
//         icon: require('../assets/images/cat2.svg'),
//     },
//     {
//         id: 3,
//         name: 'TF',
//         icon: require('../assets/images/cat3.svg'),
//     },
//     {
//         id: 4,
//         name: '직급',
//         icon: require('../assets/images/cat4.svg'),
//     },
//     {
//         id: 5,
//         name: '스터디',
//         icon: require('../assets/images/cat5.svg'),
//     },
//     {
//         id: 6,
//         name: '취미/동호회',
//         icon: require('../assets/images/cat6.svg'),
//     },
//     {
//         id: 7,
//         name: '운동',
//         icon: require('../assets/images/cat7.svg'),
//     },
//     {
//         id: 8,
//         name: '동문',
//         icon: require('../assets/images/cat8.svg'),
//     },
//     {
//         id: 9,
//         name: '종교',
//         icon: require('../assets/images/cat9.svg'),
//     },
// ];


// export const bandCategoryListInNewsFeedEng = [
//     {
//         id: 1,
//         name: '팀/부서',
//         icon: require('../assets/images/cat1-eng.svg'),
//     },
//     {
//         id: 2,
//         name: '계열사',
//         icon: require('../assets/images/cat2-eng.svg'),
//     },
//     {
//         id: 3,
//         name: 'TF',
//         icon: require('../assets/images/cat3-eng.svg'),
//     },
//     {
//         id: 4,
//         name: '직급',
//         icon: require('../assets/images/cat4-eng.svg'),
//     },
//     {
//         id: 5,
//         name: '스터디',
//         icon: require('../assets/images/cat5-eng.svg'),
//     },
//     {
//         id: 6,
//         name: '취미/동호회',
//         icon: require('../assets/images/cat6-eng.svg'),
//     },
//     {
//         id: 7,
//         name: '운동',
//         icon: require('../assets/images/cat7-eng.svg'),
//     },
//     {
//         id: 8,
//         name: '동문',
//         icon: require('../assets/images/cat8-eng.svg'),
//     },
//     {
//         id: 9,
//         name: '종교',
//         icon: require('../assets/images/cat9-eng.svg'),
//     },
// ];