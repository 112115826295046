/* eslint-disable no-unused-vars */
import React from 'react';
import ReactTable from 'react-table';

import { getBandSystemStatusNode, getBandsStatusNode } from "../../services/BandService";
import { bandCategoryList } from "../../data";
import Layout from '../Layout/Layout';

class AdminStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bandCount: 0,
            userCount: 0,
            bands: [],
            loading: true
        };
    }

    async componentDidMount() {

        try {
            let systemStatus = await getBandSystemStatusNode();

            this.setState({
                bandCount: systemStatus.data.bandCount,
                userCount: systemStatus.data.userCount,
            })

            let bandStatus = await getBandsStatusNode();
            console.log('bandStatus', bandStatus)

            this.setState({
                bands: bandStatus.data,
                loading: false
            })
        } catch (error) {
            alert(error)
        }
    }

    render() {
        const columns = [{
            Header: '협업팜명',
            accessor: 'name'
        }, 
        // {
        //     id: 'bandCategory', // Required because our accessor is not a string
        //     Header: '카테고리',
        //     accessor: b => bandCategoryList.filter(category => category.id === b.bandCategory)[0].name
        // },
         {
            Header: '멤버수',
            accessor: 'memberCount',
        }, {
            Header: '게시글 수',
            accessor: 'postCount',
        }, {
            Header: '디스크 사용량',
            accessor: 'diskUsage',
        }, {
            Header: '리더',
            accessor: 'leaders',
        }, {
            Header: '생성일',
            accessor: 'createDateTime'
        }];

        return (
            <Layout>
                <h2>협업팜 정보</h2>
                <h3>협업팜 수 : {this.state.bandCount} 개 | 사용자 수 : {this.state.userCount} 명</h3>
                <div>
                    {
                        this.state.loading ?
                            "loading..."
                            :
                            <ReactTable
                                data={this.state.bands}
                                columns={columns}
                            />
                    }
                </div>
            </Layout>
        )
    }
}



export default AdminStatus;
