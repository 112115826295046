/* eslint-disable array-callback-return */
import React from 'react';
import styled from "styled-components";
import { BandMenu, BandTopButtons } from '../../Menu';
import * as BandService from '../../../services/BandService';
import * as ScheduleService from '../../../services/ScheduleService';
import * as DateTimeUtils from '../../../utils/DateTimeUtils';
import moment from 'moment'
import Modal from 'react-awesome-modal';
import { THEME_BACKGROUND_COLOR } from '../../../configs';
import dateFnsFormat from 'date-fns/format';
import { withTranslation } from 'react-i18next';
import { ScheduleCreateModal } from '../../../common/components/Modal';
import CalendarComponent from './Sections/CalendarComponent';
// import { Calendar, Agenda } from '../../components/Calendar';
// import { Agenda } from '../../components/Calendar';
// import Datetime from 'react-datetime';
// import 'rc-time-picker/assets/index.css';
// import TimePicker from 'rc-time-picker';

const currentLanguage = window.localStorage.i18nextLng === 'korean' ? 'korean' : 'english';
if (currentLanguage === "korean") {
    require('moment/locale/ko');
}
// const format = 'h:mm a';
// const formatForHandler = 'HHmm';
// const now = moment();

class Schedule extends React.Component {
    talkInput;

    _isMounted = false;

    constructor(props) {
        super(props);

        const bandId = this.props.match.params["bandId"];
        const current = DateTimeUtils.current();
        const startDate = current.clone().startOf('month');
        const endDate = current.clone().endOf('month');

        this.state = {
            bandId: bandId,
            band: undefined,
            isBandMember: false,
            isOpened: false,
            startDate: startDate,
            endDate: endDate,
            scheduleList: [],
            yearMonth: current.format("YYYYMM"),
            selected: undefined,
            events: [],
            isEventModalOpen: false,
            isAllDay: true,
            title: "",
            scheduleColor: `${THEME_BACKGROUND_COLOR}`,
            scheduleColorModalVisible: false,
            startDateTime: undefined,
            endDateTime: undefined,
            startDateForInput: undefined,
            startTimeForInput: "1200",
            endDateForInput: undefined,
            endTimeForInput: "1200",
            yearDateTimeForCreateSchedule: undefined
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadBandInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandInfo = () => {
        BandService.getBandInfo(this.state.bandId)
            .then(result => {
                if (this._isMounted) {
                    this.setState({
                        band: result.band,
                        isBandMember: result.isBandMember,
                        isOpened: result.isOpened,
                    }, this.loadSchedules);
                }
            })
            .catch(error => console.error(error));
    };

    loadSchedules = () => {
        if (this.state.isOpened) {
            ScheduleService.getBandScheduleListNode(this.state.bandId, this.state.startDate.format("YYYYMM"))
                .then(schedules => {
                    console.log('schedules', schedules)
                    let events = []

                    schedules.map(schedule => {

                        let event = {
                            id: schedule.id,
                            title: schedule.title,
                            bandId: this.state.bandId,
                            startDateQuery: this.state.startDate.format("YYYYMM"),
                            dayNumber: schedule.endDateTime.toString().substring(6, 8),
                            start: new Date(Number(schedule.startDateTime.toString().substring(0, 4)), Number(schedule.startDateTime.toString().substring(4, 6) - 1), Number(schedule.startDateTime.toString().substring(6, 8)), Number(schedule.startDateTime.toString().substring(8, 10)), Number(schedule.startDateTime.toString().substring(10, 12)), Number(schedule.startDateTime.toString().substring(12, 14))),
                            end: new Date(Number(schedule.endDateTime.toString().substring(0, 4)), Number(schedule.endDateTime.toString().substring(4, 6) - 1), Number(schedule.endDateTime.toString().substring(6, 8)), Number(schedule.endDateTime.toString().substring(8, 10)), Number(schedule.endDateTime.toString().substring(10, 12)), Number(schedule.endDateTime.toString().substring(12, 14))),
                            scheduleColor: schedule.scheduleColor
                        }
                        events.push(event)

                    })

                    if (this._isMounted) {
                        this.setState({
                            scheduleList: ScheduleService.listToMap(schedules, this.state.startDate, this.state.endDate),
                            events
                        });
                    }
                })
                .catch(error => console.error(error));
        }
    };

    handleChange = (startDate, endDate) => {
        let schedules = [];
        ScheduleService.getBandScheduleListNode(this.state.bandId, startDate.format("YYYYMM"))
            .then(list => schedules = list)
            .then(() => {
                if (this._isMounted) {
                    this.setState({
                        yearMonth: startDate.format("YYYYMM"),
                        startDate: startDate,
                        endDate: endDate,
                        scheduleList: ScheduleService.listToMap(schedules, startDate, endDate),
                    });
                }
            })
            .catch(error => console.error(error));
    };

    routeScheduleDetail = (scheduleId, dayNumber) => {
        console.log('this.state.startDate.format("YYYYMM")', this.state.startDate.format("YYYYMM"))
        this.props.history.push("/band/" + this.props.match.params["bandId"] + "/schedule/" + scheduleId
            + "?day=" + this.state.startDate.format("YYYYMM") + dayNumber);
    };

    onSelect = (selected) => {
        if (this._isMounted) {
            if (selected === this.state.selected) {
                this.setState({
                    selected: undefined,
                });
            } else {
                this.setState({
                    selected: selected,
                });
            }
        }
    };

    handleInput = (e) => {
        if (this.state.hasOwnProperty(e.currentTarget.name)) {
            if (this._isMounted) {
                console.log('[e.currentTarget.name]', [e.currentTarget.name])
                console.log('[e.currentTarget.value]', e.currentTarget.value)

                this.setState({ [e.currentTarget.name]: e.currentTarget.value });
            }
        }
    };

    closeEventModal = () => {
        if (this._isMounted) {
            this.setState({
                isEventModalOpen: false,
            });
        }
    };

    handleToggleChange = (name, value) => {
        if (this.state.hasOwnProperty(name)) {
            if (this._isMounted) {
                this.setState({
                    [name]: value,
                });
            }
        }
    };

    handleSelect = ({ start, end }) => {
        let yearDateTimeForCreateSchedule = moment(start).format("YYYYMMDDHHmm") + "0000";
        // console.log("yearDateTimeForCreateSchedule", yearDateTimeForCreateSchedule) //yearDateTimeForCreateSchedule 2020040300000000
        // console.log('end', end) //end Fri Apr 03 2020 00:00:00 GMT+0900 (Korean Standard Time)
        this.setState({
            startDateForInput: start,
            endDateForInput: end,
            isEventModalOpen: true,
            yearDateTimeForCreateSchedule: yearDateTimeForCreateSchedule
        });
    }

    handleSubmit = () => {
        let { t } = this.props;
        // console.log('this.state.startDateForInput', this.state.startDateForInput)
        // console.log('this.state.startDateForInput', this.state.startTimeForInput)

        //first! check if title is filled
        if (!this.state.title) {
            alert(`${t('Schedule.typeTheTitleFirst')}`);
            return;
        }

        //날짜를 입력할때 직접 작성을 하면 undefined이 나와서 직접 클릭으로 선택해줘야 한다.
        if ((this.state.startDateForInput === undefined) && this.state.endDateForInput) {
            alert('날짜를 직접 클릭해주세요')
            return;
        }

        //second! set startDate & endDate !
        let startDate = dateFnsFormat(this.state.startDateForInput, "yyyyMMdd");
        let endDate = dateFnsFormat(this.state.endDateForInput, "yyyyMMdd");

        //third! put startDate & startTime together and put endDate & endTime together !
        let startDateTime = startDate + this.state.startTimeForInput;
        if (this.state.isAllDay) {
            startDateTime = startDate + "0000";
        }
        let endDateTime = endDate + this.state.endTimeForInput;
        if (this.state.isAllDay) {
            endDateTime = endDate + "2359";
        }

        // console.log('startDateTime', startDateTime)
        // console.log('endDateTime', endDateTime)

        //여기서 startDateTime 202004080000 이런식으로 와야 된다. 
        if (startDateTime >= endDateTime) {
            alert(`${t('Schedule.theEndTimeMustBeAfterTheStartTime')}`);
            return;
        }
        this.createSchedule(startDateTime, endDateTime)
    }

    selectScheduleColor = (pickedColor) => {
        this.setState({
            scheduleColor: pickedColor,
            scheduleColorModalVisible: !this.state.scheduleColorModalVisible
        })
    }

    createSchedule = (startDateTime, endDateTime) => {

        ScheduleService.createScheduleNode(
            this.state.bandId, this.state.title, undefined, "", "",
            this.state.isAllDay, startDateTime, endDateTime, 0,
            false, false, undefined, this.state.scheduleColor
        )
            .then(() => {
                window.location.reload()
            })
            .catch(err => {
                alert(err)
            })

        // let title = this.state.title
        // let start = new Date(Number(startDateTime.toString().substring(0, 4)),
        //     Number(startDateTime.toString().substring(4, 6) - 1),
        //     Number(startDateTime.toString().substring(6, 8)),
        //     Number(startDateTime.toString().substring(8, 10)),
        //     Number(startDateTime.toString().substring(10, 12)),
        //     Number(startDateTime.toString().substring(12, 14)))
        // let end = new Date(Number(endDateTime.toString().substring(0, 4)),
        //     Number(endDateTime.toString().substring(4, 6) - 1),
        //     Number(endDateTime.toString().substring(6, 8)),
        //     Number(endDateTime.toString().substring(8, 10)),
        //     Number(endDateTime.toString().substring(10, 12)),
        //     Number(endDateTime.toString().substring(12, 14)))

        // if (title) {
        //     this.setState({
        //         events: [
        //             ...this.state.events,
        //             {
        //                 start,
        //                 end,
        //                 title,
        //             },
        //         ],
        //     },
        //         this.setState({
        //             title: "",
        //             isEventModalOpen: false,
        //             endDateTimeForInput: undefined
        //         })
        //     )
        // }
    }



    handleScheduleColorVisibleChange = (visible) => {
        this.setState({ scheduleColorModalVisible: visible });
    }

    handleStartDate = (value) => {
        if (this._isMounted) {
            this.setState({ startDateForInput: value });
        }
    };

    handleStartTime = (value) => {
        if (this._isMounted) {
            this.setState({ startTimeForInput: value });
        }
    };

    handleEndDate = (value) => {
        if (this._isMounted) {
            this.setState({ endDateForInput: value });
        }
    };

    handleEndTime = (value) => {
        if (this._isMounted) {
            this.setState({ endTimeForInput: value });
        }
    };

    handleOptionMoreClick = () => {
        window.location.href = `/band/${this.state.bandId}/schedule/create?createDate=${this.state.yearDateTimeForCreateSchedule}`
    }

    render() {
        const { bandId, band } = this.state;
        const { history } = this.props;
        let additionalButtons = [];
        if (this.state.isBandMember) {
            additionalButtons = [
                { routePath: `/band/${bandId}/schedule/create?createDate=${this.state.yearMonth}${this.state.selected}`, 
                buttonImage: require('../../../assets/images/btn-write.svg'),
                nameKo: "일정 만들기", nameEng: "Make a Schedule", nameJa: "スケジュールを立てる" },
            ];
        }

        return (
            <div className="wrapper">
                <BandTopButtons
                    bandName={band && band.name} fromPage={this.props.t('New.Schedule')}
                    bandId={bandId} additionalButtons={additionalButtons} historyProp={history} />
                <div className="contentsWrapSpacer" />
                <div className="contentsWrap" style={{ padding: "16px 16px 50px" }}>
                    {/* <HideOnMobileSize>{additionalButtons && additionalButtons.map((additional, index) =><a href={additional.routePath} key={`button-additional-${index}`} style={{ float: 'right', margin: 13 }}>{additional.buttonImage && <img src={additional.buttonImage} alt={`button-additional-img-${index}`} />}</a>)}</HideOnMobileSize> */}
                    {/* <CalendarWrapper><Calendar startDate={startDate} endDate={endDate} handleChange={this.handleChange} onSelect={this.onSelect} /></CalendarWrapper>*/}
                    <CalendarComponent events={this.state.events} selected={this.state.selected} yearMonth={this.state.yearMonth} bandId={bandId} handleSelect={this.handleSelect} calendarIsOpen={this.props.calendarIsOpen} />
                    <Spacer />
                    <Modal visible={this.state.isEventModalOpen} width="375px" onClickAway={() => this.closeEventModal()}>
                        <ScheduleCreateModal handleStartTime={this.handleStartTime} handleEndDate={this.handleEndDate} handleEndTime={this.handleEndTime} handleToggleChange={this.handleToggleChange} handleStartDate={this.handleStartDate} handleOptionMoreClick={this.handleOptionMoreClick} handleSubmit={this.handleSubmit} isAllDay={this.state.isAllDay} endTimeForInput={this.state.endTimeForInput} endDateForInput={this.state.endDateForInput} startTimeForInput={this.state.startTimeForInput} title={this.state.title} handleInput={this.handleInput} startDateForInput={this.state.startDateForInput} scheduleColorModalVisible={this.state.scheduleColorModalVisible} handleScheduleColorVisibleChange={this.handleScheduleColorVisibleChange} selectScheduleColor={this.selectScheduleColor} scheduleColor={this.state.scheduleColor} />
                    </Modal>
                    {/* <AgendaWrapper><Agenda bandId={bandId} scheduleList={scheduleList} yearMonth={this.state.yearMonth} selected={this.state.selected} isOpened={this.state.isBandMember}  routeFunction={this.routeScheduleDetail} />  </AgendaWrapper> */}
                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '65px' }}> <a href={`/band/${bandId}/schedule/create?createDate=${this.state.yearMonth}${this.state.selected}`}><ButtonPlus style={{ zIndex: 9999 }} src={btnPlus} alt="btn-plus" /></a> </div> */}
                </div>
                <BandMenu pathName="schedule" bandId={bandId} historyProp={history} />
                <SpacerForMobileSize />
            </div>
        );
    }
}

const SpacerForMobileSize = styled.div`
    @media (max-width: 500px) {
        width: 100%;
        height: 27px;
    }
`;

const Spacer = styled.div`
    width: 100%;
    height: 6%;
`;

// const AgendaWrapper = styled.div`
//     padding: 1rem 1rem;
//     margin-top: -1rem;

//     @media (max-width: 500px) {
//         padding: 1rem 1rem 4rem;
//     }
// `;
export default withTranslation()(Schedule);




