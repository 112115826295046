import React, { Component, Suspense } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import Props from './hoc/Props';
import Spinner from './common/components/Shared/Spinner';

/**
 * Personal Page 
 */
import LandingPage from './components/LandingPage/LandingPage';
import NewsFeedPage from './components/NewsFeedPage/NewsFeedPage';
import SearchBandPage from './components/SearchBandPage/SearchBandPage';

/**
 * Inside Band Page
 */
import HomePage from './components/HomePage/HomePage';
import ChatPage from './components/ChatPage/ChatPage';
import SchedulePage from './components/_Schedule/SchedulePage/SchedulePage';
import ContentsPage from './components/ContentsPage/ContentsPage';
import MembersPage from './components/MembersPage/MembersPage';
import SettingPage from './components/SettingPage/SettingPage';

/**
 * Related To Bands
 */
import BandCreatePage from './components/_Band/BandCreatePage/BandCreatePage';
import BandSearchPage from './components/_Band/BandSearchPage/BandSearchPage';
import BandNoticePage from './components/_Band/BandNoticePage/BandNoticePage';

/**
 * Related To Post
 */
import PostCreatePage from './components/_Post/PostCreatePage/PostCreatePage';
import PostModifyPage from './components/_Post/PostModifyPage/PostModifyPage';
import PostDetailPage from './components/_Post/PostDetailPage/PostDetailPage';
import PostViewStatePage from './components/_Post/ViewStatePage/ViewStatePage';

/**
 * Related To Schedule
 */
import ScheduleCreatePage from './components/_Schedule/ScheduleCreatePage/ScheduleCreatePage';
import ScheduleDetailPage from './components/_Schedule/ScheduleDetailPage/ScheduleDetailPage';
import ScheduleModifyPage from './components/_Schedule/ScheduleModifyPage/ScheduleModifyPage';

/**
 * Admin Page
 */
import AdminSettingsPage from './components-admin/SettingsPage/SettingsPage';
import AdminStatusPage from './components-admin/StatusPage/StatusPage';

import * as AuthService from "./services/AuthService";

import Org from "./components/MembersPage/Sections/Tree/OrgComponent";


@withRouter
class App extends Component {

    async componentDidMount() {
        
        let params = qs.parse(decodeURIComponent(this.props.location.search));

        if (params && params.lang) {
            window.localStorage.setItem("i18nextLng", params.lang);
        }
        if (params && params.accessToken) {
            let tokenFromServer = params.accessToken;
            localStorage.setItem('accessToken', tokenFromServer);
            try {
                const userIdResult = await AuthService.getUserIdNode()
                if (userIdResult) {
                    let userId = userIdResult.id
                    localStorage.setItem('userId', userId)
                    const myInfoResult = await AuthService.getMyInfo(userId)
                    localStorage.setItem('ucTalkId', myInfoResult.uc_talk_id)
                    localStorage.setItem('userName', myInfoResult.name)
                    localStorage.setItem('orgGroupCode', myInfoResult.org_group_code)
                    localStorage.setItem('profileImage', myInfoResult.profile_image)
                    if (this.props.location.pathname.length > 3) {
                        this.props.history.push(`${this.props.location.pathname}`);
                    } else {
                        this.props.history.push('/myBand');
                    }
                }
            } catch (error) {
                alert(`유저 아이디 가져오는데 실패 했습니다. ${error}`);
            }

        }
    }

    render() {
        return (
            <Suspense fallback={(<div> <Spinner size={"large"} /></div>)}>
                <Switch>
                    {/* <Route path="/error" component={Error} /> */}
                    {/* <Route exact path="/admin" component={Props(AdminStatusPage)} /> */}
                    <Route exact path="/admin/status" component={Props(AdminStatusPage)} />
                    <Route exact path="/admin/settings" component={Props(AdminSettingsPage)} />

                    <Route path="/band/create" component={Props(BandCreatePage)} />
                    <Route path="/band/:bandId/search" component={Props(BandSearchPage)} />
                    <Route path="/band/:bandId/post/create" component={Props(PostCreatePage)} />
                    <Route path="/band/:bandId/post/:postId/modify" component={Props(PostModifyPage)} />
                    <Route path="/band/:bandId/post/:postId/viewState" component={Props(PostViewStatePage)} />
                    <Route path="/band/:bandId/post/:postId" component={Props(PostDetailPage)} />
                    <Route path="/band/:bandId/schedule/create" component={Props(ScheduleCreatePage)} />
                    <Route path="/band/:bandId/schedule/:scheduleId/modify" component={Props(ScheduleModifyPage)} />
                    <Route path="/band/:bandId/schedule/:scheduleId" component={Props(ScheduleDetailPage)} />
                    <Route path="/band/:bandId/chat" component={Props(ChatPage)} />
                    <Route path="/band/:bandId/contents" component={Props(ContentsPage)} />
                    <Route path="/band/:bandId/home" component={Props(HomePage)} />
                    <Route path="/band/:bandId/members" component={Props(MembersPage)} />
                    <Route path="/band/:bandId/notice" component={Props(BandNoticePage)} />
                    <Route path="/band/:bandId/schedule" component={Props(SchedulePage)} />
                    <Route path="/band/:bandId/setting" component={Props(SettingPage)} />

                    <Route path="/org" component={Props(Org)} />


                    {/* <Route path="/band/:bandId/invitation" component={Props(BandInvitationThree)} /> */}
                    <Route path="/band/:bandId" component={Props(HomePage)} />
                    <Route path="/searchBand" component={Props(SearchBandPage)} />
                    <Route path="/newsFeed" component={Props(NewsFeedPage)} />
                    <Route path="/myBand" component={Props(LandingPage)} />
                    {/* <Route path="/signUp" component={SignUp} />
                    <Route path="/login" component={SignUp} /> */}
                </Switch>
            </Suspense>
        );
    }

}


export default App;
