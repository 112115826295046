import React from 'react';
import styled from 'styled-components';

import { NoticeList, PostList } from '../_Post';
import { BandMenu, BandTopButtons } from '../Menu';
import DesktopHeader from "../../hoc/DesktopHeader";

import * as BandService from '../../services/BandService';
import * as PostService from '../../services/PostService';
import * as DateTimeUtils from '../../utils/DateTimeUtils';
import * as StringUtils from '../../utils/StringUtils';
import * as UserService from '../../services/UserService';

import { SERVER_URI, POST_PAGE_SIZE, THEME_BACKGROUND_COLOR, COMPANY_NAME } from '../../configs';

import { bandCategoryList, bandCategoryListEng, bandCategoryListJap, bandCategoryListThai } from '../../data';
import Empty from '../../common/components/Shared/Empty';

import { withTranslation } from 'react-i18next';
import DialogComp from '../../common/components/Shared/DialogComp';

import NoImage from '../../assets/images/no-img.svg';
import btnPlus from '../../assets/images/btn-plus.svg';
import ReactTooltip from 'react-tooltip'

class BandHome extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            bandId: this.props.match.params["bandId"],
            band: undefined,
            leaders: [],
            isBandMember: false,
            isOpened: false,
            memberType: 0,
            noticeList: [],
            postList: [],
            page: 1,
            lastPage: true,
            needToApprove: false,
            loading: true,
            openDialog: false,
            waitingForJoin: [],
            selectedMembers: [],
            openDialogAlreadyApplyMember: false,
            deportedMembers: [],
            deportedMembersId: [],
            openDialogDeportedMember: false,
            openDialogJoinGroup: false,
            skeletonOut: false,
            isBandLeader: true,
            applyPublicProfile: true,
            notifyThroughUcTalk: true,
            postNotificationType: 0,
            replyNotificationType: 0,
            notifySchedule: true,
            notifyContent: true,
            notifyChat: true,
            pageSize: 0
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        this.loadBandInfo();
        this.loadMembers();

        setTimeout(() => {
            this.setState({ skeletonOut: true })
        }, 500);

        let userUploadFileSizeResult = await UserService.userUploadFileSize()
        localStorage.setItem('personalDiskUsage', userUploadFileSizeResult.data.totalSize)

        let getBandStatusResult = await BandService.getBandStatusNode(this.props.match.params["bandId"])
        localStorage.setItem('bandDiskUsage', getBandStatusResult.diskUsage)

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadBandInfoTwo = () => {
        BandService.getBandInfo(this.state.bandId)
            .then(result => {
                this.setState({
                    band: result.band,
                    isBandMember: result.isBandMember,
                    isBandLeader: result.isBandLeader,
                }, this.loadUserSettings);
            })
            .catch(error => console.error(error));
    };

    loadUserSettings = () => {
        if (this.state.isBandMember) {
            BandService.getSingleBandMember(this.state.bandId, localStorage.getItem('userId'))
                .then(bandMember => {
                    if (this._isMounted) {
                        this.setState({
                            profileImage: bandMember.profileImage,
                            profileName: bandMember.name,
                            applyPublicProfile: true,
                            notifyThroughUcTalk: bandMember.notifyThroughUcTalk,
                            postNotificationType: bandMember.postNotificationType,
                            replyNotificationType: bandMember.replyNotificationType,
                            notifySchedule: bandMember.notifySchedule,
                            notifyContent: bandMember.notifyContent,
                            notifyChat: bandMember.notifyChat,
                        }, this.loadBandSettings);
                    }
                })
                .catch(error => console.error(error));
        }
    };

    loadBandSettings = () => {
        this.setState({
            bandOpenType: this.state.band.openType,
            needToApprove: this.state.band.needToApprove,
            createMemberJoinPost: this.state.band.createMemberJoinPost,
            loading: false,
        }, this.handleBandUserSettingChange);
    };

    handleBandUserSettingChange = (name, value) => {

        if (this.state.isBandUserProfileSettingModalOpen) {
            this.setState({
                isBandUserProfileSettingModalOpen: false
            })
        }
        this.setState({
            applyPublicProfile: true,
        }, this.applyBandUserSettings);
    };

    applyBandUserSettings() {
        BandService.updateBandUserSettingsNode(this.state.bandId, localStorage.getItem('userId'),
            this.state.applyPublicProfile, this.state.notifyThroughUcTalk,
            this.state.postNotificationType, this.state.replyNotificationType,
            this.state.notifySchedule, this.state.notifyContent, this.state.notifyChat)
            .then(() => {
                localStorage.setItem(`profileInitialConfigChanged-${this.state.bandId}-${localStorage.getItem('userId')}`, true)
            })
            .catch(error => console.error(error));
    }


    loadBandInfo = () => {
        let leaders = [], memberType;
        BandService.updateBandMemberCount(this.state.bandId)
        BandService.getBandInfo(this.state.bandId, true)
            .then(result => {
                leaders = result.bandMembers.filter(bandMember => bandMember.bandMemberRole === 0);
                if (result.isBandMember) {
                    memberType = 2; // 밴드 멤버
                } else {
                    if (result.bandMemberRole && result.bandMemberRole === 2) {
                        memberType = 1; // 밴드 가입 승인 대기
                    } else {
                        memberType = 0; // 밴드 미가입 멤버
                    }
                }
                this.setState({
                    band: result.band,
                    leaders: leaders,
                    needToApprove: result.band.needToApprove,
                    memberType: memberType,
                    isBandLeader: memberType === 0 ? false : true,
                    isBandMember: result.isBandMember,
                    isOpened: result.isOpened,
                    loading: false,
                }, this.getBandPosts);
            })
            .catch(error => console.error(error));
    };

    getBandPosts = () => {

        if (localStorage.getItem(`profileInitialConfigChanged-${this.state.bandId}-${localStorage.getItem('userId')}`) !== true
            && this.state.memberType !== 0
        ) {
            this.loadBandInfoTwo();
        }


        if (this.state.isOpened) {
            const current = DateTimeUtils.formattedCurrent();
            let noticeList, postList;
            PostService.getBandNoticeListNode(this.state.bandId, current)
                .then(response => noticeList = response.content)
                .then(() => PostService.getBandPostListNode(this.state.bandId, current, 1, POST_PAGE_SIZE))
                .then(response => {
                    postList = response.content;
                })
                .then(() => {
                    this.setState({
                        noticeList: noticeList,
                        postList: postList,
                        pageSize: postList.length
                    });
                })
                .catch(error => console.error(error));
        }
    };

    loadMembers = () => {
        const notApprovedMemberRole = [2];
        let waitingForJoin = [], selectedMembers = [];
        BandService.getBandMembersNode(this.state.bandId, notApprovedMemberRole)
            .then(result => waitingForJoin = result)
            .then(() => {
                waitingForJoin.forEach(member => selectedMembers.push(member.userId));
                this.setState({
                    waitingForJoin: waitingForJoin,
                    selectedMembers: selectedMembers,
                });
            })
            .catch(error => console.error(error));

        const bandMemberRoles = [99];

        let deportedMembers = [], deportedMembersId = [];
        BandService.getBandMembersNode(this.state.bandId, bandMemberRoles)
            .then(result => deportedMembers = result)
            .then(() => {
                deportedMembers.forEach(member => deportedMembersId.push(member.userId));
                this.setState({
                    deportedMembers: deportedMembers,
                    deportedMembersId: deportedMembersId,
                });
            })
            .catch(error => console.error(error));
    };

    bandCategory = (band) => {
        if (band && band.bandCategoryId) {

            let bandCategoryListVar;
            if (localStorage.getItem('i18nextLng') === "korean") {
                bandCategoryListVar = bandCategoryList
            } else if (localStorage.getItem('i18nextLng') === "english"){
                bandCategoryListVar =  bandCategoryListEng
            } else if (localStorage.getItem('i18nextLng') === "japanese"){
                bandCategoryListVar =  bandCategoryListJap
            } else if (localStorage.getItem('i18nextLng') === "thai"){
                bandCategoryListVar =  bandCategoryListThai
            }

            const foundCategories = bandCategoryListVar.filter(category => category.id === band.bandCategoryId);
            if (foundCategories && foundCategories.length > 0) {
                return foundCategories[0].name;
            }
        }
        return "";
    };

    route = (url) => {
        this.props.history.push(url);
    };

    routePostOrBand = (bandId, postId) => {
        if (bandId && postId) {
            this.route("/band/" + bandId + "/post/" + postId);
        } else if (bandId) {
            this.route("/band/" + bandId);
        } else {
            alert("BandId & PostId are undefined");
        }
    };

    routeAllNotice = () => {
        this.route("/band/" + this.state.bandId + "/notice");
    };

    routeMyBand = () => {
        this.route("/myBand");
    };


    join = () => {
        let checkDeportedMember = this.state.deportedMembersId.indexOf(Number(localStorage.getItem('userId')))
        let checkAlreadyApplyMember = this.state.selectedMembers.indexOf(Number(localStorage.getItem('userId')))

        if (checkDeportedMember > -1) {
            this.setState({ openDialogDeportedMember: true })

            setTimeout(() => {
                this.setState({ openDialogDeportedMember: false })
            }, 2000);
            return null
        }

        if (checkAlreadyApplyMember > -1) {
            this.setState({ openDialogAlreadyApplyMember: true })

            setTimeout(() => {
                this.setState({ openDialogAlreadyApplyMember: false })
            }, 2000);

        } else {

            BandService.joinBandNode(this.state.bandId, DateTimeUtils.formattedCurrent(), this.state.needToApprove)
                .then(
                    () => {
                        if (this.state.needToApprove) {
                            this.setState({ openDialog: true });

                            setTimeout(() => {
                                this.setState({ openDialog: false })
                                this.routeMyBand()
                            }, 2000);
                        } else {
                            this.setState({ openDialogJoinGroup: true })

                            setTimeout(() => {
                                this.setState({ openDialogJoinGroup: false })
                                this.routeMyBand()

                            }, 2000);
                        }
                    }
                )
                .catch(error => this.routeMyBand());
        }
    };

    nextPage = () => {
        if (this._isMounted) {
            const current = DateTimeUtils.formattedCurrent();
            const page = this.state.page + 1;
            let postList = this.state.postList;

            PostService.getBandPostListNode(this.state.bandId, current, page, POST_PAGE_SIZE)
                .then(response => {
                    if (response.content) {
                        response.content.forEach(content => postList.push(content));
                    }
                    this.setState({
                        postList: postList,
                        page: page,
                        pageSize: response.content.length,
                    });
                })
                .catch(error => {
                    alert(`Failed to load list. - ${error}`);
                });
        }
    };

    moveToCreatePost = () => {
        if (this._isMounted) {
            this.props.history.push(`/band/${this.state.bandId}/post/create`)
        }
    }

    render() {
        const { loading, bandId, band, leaders, memberType, noticeList, postList } = this.state;
        const { history, t } = this.props;

        const leader = leaders ? (leaders.length > 0 ? leaders[0].name : "") : "";

        let coverImage;

        if (band && !StringUtils.isNullOrUndefined(band.coverImage)) {
            if (COMPANY_NAME !== "UC WARE") {
                coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/${band.coverImage}`;
            } else {
                coverImage = `${SERVER_URI}:5000/_COVER_IMAGES/s_${band.coverImage}`;
            }
        } else {
            coverImage = NoImage;
        }

        let additionalButtons = [];
        if (this.state.isBandMember) {
            additionalButtons = [
                { routePath: `/band/${bandId}/search`, buttonImage: require('../../assets/images/btn-search.svg'), nameKo: "검색으로 찾기", nameEng: "Search", nameJa: "探す" },
                { routePath: `/band/${bandId}/post/create`, buttonImage: require('../../assets/images/btn-write.svg'), nameKo: "글쓰기", nameEng: "Write Post", nameJa: "書き込みポスト" },
            ];
        }

        let button;
        if (memberType === 0) {
            button = <button onClick={this.join}>{t('Home.join')}</button>;
        } else if (memberType === 1) {
            button = <button disabled={true}>{t('Home.watingforApproval')}</button>;
        } else {
            button = <a style={{ color: 'white' }} href={`/band/${bandId}/members`} ><button >{t('Home.invite')}</button></a>;
            // button = <a style={{ color: 'white' }} href={`/band/${bandId}/invitation`} ><button >{"초대하기"}</button></a>;
        }

        return (
            <>
                {loading ?
                    <div></div> :
                    <div className="wrapper">
                        <DesktopHeader isBandLeader={this.state.isBandLeader} joinFunction={this.join} bandId={bandId} home memberType={memberType}>
                            <WidthChangeDiv>

                                <MobileHeader>
                                    <BandTopButtons home bandId={this.props.bandId}
                                        bandName={band.name} fromPage={this.props.t('New.Home')}
                                        routeToMain={true} additionalButtons={additionalButtons} historyProp={history} />

                                    <BandPicture>
                                        <img src={coverImage} alt="cover" />
                                    </BandPicture>

                                    <Info>
                                        <Title>
                                            <Name>{band.name}</Name>
                                            <Invite>
                                                {button}
                                            </Invite>
                                        </Title>
                                        <Additional>{`${t('Home.leader')} ${leader} | ${t('Home.numberOfMembers')} ${band.memberCount} | ${this.bandCategory(band)}`}</Additional>
                                        {/* {band.tags &&
                                            <Tags>
                                                {band.tags.map((tag, index) => <span key={index}>#{tag}</span>)}
                                            </Tags>
                                        } */}
                                    </Info>
                                </MobileHeader>

                                <HideOnMobileSize>
                                    <br />
                                    {additionalButtons && additionalButtons.map((additional, index) =>
                                        <a href={additional.routePath} key={`button-additional-${index}`} style={{ float: 'right' }}>
                                            {additional.buttonImage && <img src={additional.buttonImage} data-tip data-for={`${localStorage.getItem("i18nextLng") === "korean" ? `${additional.nameKo}` : `${additional.nameEng}`} `} className={`home-desktop-additional-img-${index}`} alt={`button-additional-img-${index}`} />}
                                            <ReactTooltip id={`${localStorage.getItem("i18nextLng") === "korean" ? `${additional.nameKo}` : `${additional.nameEng}`} `} > 
                                                <span>{`${localStorage.getItem("i18nextLng") === "korean" ? `${additional.nameKo}` : localStorage.getItem("i18nextLng") === "english" ?`${additional.nameEng}`:`${additional.nameJa}`} `}</span>
                                            </ReactTooltip>
                                        </a>
                                    )}
                                    <br />
                                    <br />

                                </HideOnMobileSize>

                                <div>
                                    <HR />
                                    {this.state.isOpened &&
                                        <div>
                                            {noticeList.length > 0 &&
                                                <>
                                                    <NoticeList bandId={bandId}
                                                        noticeList={noticeList}
                                                        isOpened={this.state.isBandMember}
                                                        routeFunction={this.routePostOrBand}
                                                        allNotice={this.routeAllNotice}
                                                    />
                                                    {/* <Divider /> */}
                                                </>
                                            }
                                            {postList.length > 0 ?
                                                <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                                                    <PostList
                                                        home
                                                        bandId={bandId}
                                                        postList={postList}
                                                        isOpened={this.state.isBandMember}
                                                        routeFunction={this.routePostOrBand}
                                                    />
                                                    {this.state.pageSize === 20 &&
                                                        <>
                                                            <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
                                                                <button style={{ padding: '1rem', borderRadius: '5px', background: `${THEME_BACKGROUND_COLOR}`, color: 'white', width: '100px', fontWeight: 'bold' }} onClick={this.nextPage}>{t('Home.loadMore')}</button>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                :
                                                <EmptyPost>
                                                    <Empty description={t('Home.thereAreNoRegisteredPosts')} />
                                                </EmptyPost>
                                            }
                                        </div>
                                    }
                                </div>

                                {this.state.isBandMember &&
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '65px' }}>
                                        <button data-tip data-for={`writePost`} onClick={this.moveToCreatePost}><ButtonPlus style={{ zIndex: 9999 }} src={btnPlus} alt="btn-plus" /></button>
                                        <ReactTooltip id="writePost" >
                                            <span>{t('New.WritePost')}</span>
                                        </ReactTooltip>
                                    </div>
                                }

                                <BandMenu pathName="home" bandId={bandId} historyProp={history} />
                            </WidthChangeDiv>
                        </DesktopHeader>
                        <Spacer />

                        <DialogComp description={t('New.IfTheLeaderApproves')} open={this.state.openDialog} />

                        <DialogComp description={t('Home.successfullyJoined')} open={this.state.openDialogJoinGroup} />

                        <DialogComp description={t('Home.cantSignUpBecauseOfBeingKickedOut')} open={this.state.openDialogDeportedMember} />

                    </div>
                }
            </>
        );
    }

}


const MobileHeader = styled.div`
      display: none;
      @media (max-width: 500px) {
          display: block;
      }
`;

const BandPicture = styled.div`
    height: 300px !important;
    object-fit: cover;
    background: black;
    overflow: hidden;

    img{ 
        width: 100%;
        height: 100%;
        opacity: 0.7;
    }
`;

const HideOnMobileSize = styled.div`
    padding: 0 1rem;
    @media (max-width: 500px) {
        display: none;
    }
`;

const EmptyPost = styled.div`
    text-align: center;
    vertical-align: middle;
    margin: 100px;
    font-size: 1rem;
    font-weight: bold;
`;


const Spacer = styled.div`
    width: 100%;
    height: 5rem;
`;

const HR = styled.hr`
    display: block; 
    height: 1px;
    border: 0; 
    border-top: 1px solid #DBDBDB;
    margin: 1em 0; 
    padding: 0;
`;

const WidthChangeDiv = styled.div`
    width: 100%;
    margin: 0 auto;

    @media (max-width: 500px) {
        width: 100%;
    }
`

const Name = styled.div`
    text-align: left;
    color: black;
    font-size: 2rem;
    font-weight: bold;
    width: calc( 100% - 82px );
    padding: 0rem 0 0.3rem;
    flex: 0 0 auto; 
    line-height: 1.1;
`;

const Info = styled.div`
    padding: 1rem 1rem 0 1rem;
`;

const Additional = styled.div`
    padding: .2rem 0rem;
    color: #8e8e93;
`;

const Tags = styled.div`
    padding: .3rem 0rem;
    span {
      padding-right: .5rem;
      font-weight: bold;
    }
    word-break: break-all;
`;

const Invite = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding: 0 .5rem;
    background-color: ${THEME_BACKGROUND_COLOR};
    border-radius:  ${props => props.desktop ? "0" : "1rem"};
        color: #FFF;
        font-size: .8rem;
        font-weight: bold;
    button {
        padding: .5rem .5rem;
        color: #FFF;
    }
    position: absolute;
    right: 16px;
    top: 0;
`;

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    flex-wrap: wrap;
    position: relative;
`;

const ButtonPlus = styled.img`
    position: fixed;
    bottom: 1rem;
    object-fit: contain;
    @media (max-width: 500px) {
        bottom: 5rem;
    }  
`;

export default withTranslation()(BandHome);
